import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  datePicker: {
    width: 256,
    // "&:nth-of-type(1)": {
    //   marginLeft: 32,
    // },
    // "&:nth-of-type(2)": {
    //   marginLeft: 16,
    // },
    "& .MuiInputBase-root": {
      height: 51,
      borderRadius: 8,
    },
  },
}));
