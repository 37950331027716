import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  filterButton: {
    padding: "unset",
    width: 123,
    height: 51,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 19,
    color: "rgba(222, 231, 250, 0.8)",
    border: "1px solid #DEE7FA",
    borderRadius: 8,
    "& .MuiButton-label": {
      width: "inherit",
      height: 51,
      display: "flex",
      justifyContent: "start",
      paddingLeft: 24,
      paddingRight: 24,
      "& > .MuiButton-startIcon": {
        margin: "unset",
        marginRight: 16,
        "& > .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
      },
    },
  },
  analyticsFilterModal: {
    "& > .MuiDialog-container": {
      display: "grid",
      gridTemplateColumns: "1fr auto 1fr",
      gridTemplateRows: "120px auto 1fr",
      "& > .MuiPaper-root": {
        margin: "unset",
        minWidth: 600,
        minHeight: 234,
        gridColumn: "2/3",
        gridRow: "2/3",
      },
    },
  },
  filterModalTitle: {
    position: "relative",
    boxShadow: "unset",
    "& > .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      "& > p": {
        marginLeft: 18,
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "21px",
      },
    },
  },
  modalCloseButton: {
    position: "absolute",
    right: 8,
  },
  filterModalContent: {
    flex: "unset",
    paddingTop: "unset",
    maxHeight: 650,
    paddingBottom: 76,
    overflow: "hidden",
    transform: "translateY(-8px)",
    "& > .MuiDialogContentText-root": {
      marginBottom: "unset",
    },
  },
  filterModalTextInput: {
    "&.MuiInputBase-root": {
      marginTop: 24,
      padding: 16,
      width: 552,
      height: 51,
      border: "1px solid #DEE7FA",
      borderRadius: 8,
      "& > .MuiInputBase-input": {
        opacity: 0.8,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "19px",
        color: "rgba(222, 231, 250, 0.8);",
      },
    },
  },
  filterModalActions: {
    flex: "unset",
    padding: "unset",
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 24,
    transform: "translateY(-8px)",
    backgroundColor: "unset",
    justifyContent: "start",
    "& > button:nth-of-type(1)": {
      width: 114,
      height: 33,
      backgroundColor: "#44D62C",
      borderRadius: 4,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "17px",
      color: "#fff",
      "& > .MuiButton-label": {
        width: "inherit",
        height: "inherit",
      },
    },
    "& > button:nth-of-type(2)": {
      width: 102,
      height: 33,
      borderRadius: 4,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "17px",
      color: "#44D62C",
      "& > .MuiButton-label": {
        width: "inherit",
        height: "inherit",
      },
    },
  },
  rowItem: {
    height: 51,
    borderTop: "1px solid #24272d",
  },
}));
