import React, { useEffect, useState, useRef } from "react";
import {
  KeyboardArrowDown,
  LastPage,
  FirstPage,
  NavigateNext,
  NavigateBefore,
} from "@material-ui/icons";
import { Box, Button, Typography, Menu, MenuItem } from "@material-ui/core";
import { FIRST_PAGE, LAST_PAGE, BEFORE_PAGE, NEXT_PAGE } from "./constant";
import useStyles from "./style";
const optionsRowPerPage = [20, 50, 100, 200];
const CustomPagination = ({ pagination, setPagination }) => {
  const style = useStyles();
  const [firstPage, setFirstPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const totalCount = pagination.totalCount;
  const open = Boolean(anchorEl);
  const paginationRef = useRef()
  const viewHeight= useRef(window.innerHeight)
  const [ positionBottom, setPositionBottom ] = useState(true)
  useEffect(() => {
    setFirstPage(pagination.skip + 1)
  }, [pagination])

  const handleClick = (event) => {
    const positionPagination = paginationRef.current.getBoundingClientRect().bottom
    if ( viewHeight.current - positionPagination < 150 ) {
      setPositionBottom(true)
    }
    else {
      setPositionBottom(false)
    }
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChangeRowsPerPage = (option) => {
    setPagination({ ...pagination, skip: 0, count: option })
    setFirstPage(1);
    setAnchorEl(null);
  };
  const handleNavigatePage = (type) => {
    if (type === NEXT_PAGE) {
      setPagination({ ...pagination, skip: firstPage + pagination.count - 1 })
    }
    if (type === BEFORE_PAGE) {
      setPagination({ ...pagination, skip: firstPage - pagination.count - 1 })
    }
    if (type === FIRST_PAGE) {
      setPagination({ ...pagination, skip: 0 })
    }
    if (type === LAST_PAGE) {
      setPagination({ ...pagination, skip: pagination.totalCount - pagination.totalCount % pagination.count })
    }
  };
  function returnPropsDropDownMenu(isBottom) {
    if ( isBottom ) 
      return {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "center",
        }
      }
    if ( !isBottom )
      return {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        }
      }
  }
  return (
    <Box
      id="paginationContainer"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      marginTop="24px"
      marginBottom="24px"
      ref={paginationRef}
    >
      <Typography classes={{ root: style.typography }}>
        Rows of Page:
      </Typography>
      <Button
        variant="contained"
        id="select-row-per-page"
        onClick={handleClick}
        disableElevation={true}
        disableFocusRipple={true}
        classes={{
          root: style.buttonSelect,
        }}
        endIcon={
          <KeyboardArrowDown classes={{ root: style.endIconButtonSelect }} />
        }
      >
        {pagination.count}
      </Button>
      <Menu
        id="select-row-per-page"
        anchorEl={anchorEl}
        {...returnPropsDropDownMenu(positionBottom)}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        classes={{
          list: style.list,
          paper: `
            ${style.menu}
            ${positionBottom && style.isPaginationBottom}
          `,
        }}
      >
        {optionsRowPerPage.map((option, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleChangeRowsPerPage(option)}
              ListItemClasses={{ root: style.item }}
              selected={ option === pagination.count ? true : false }
            >
              {option}
            </MenuItem>
          )
        })}
      </Menu>
      <Typography classes={{ root: style.typography }}>
        {`${firstPage}-${(firstPage + pagination.count - 1) < pagination.totalCount ? (firstPage + pagination.count - 1) : pagination.totalCount} of ${totalCount}`}
      </Typography>
      <Box color="#FFFFFF" alignItems="center" display="flex" marginLeft="16px">
        {[
          {
            willDisable: firstPage === 1,
            children: (
              <FirstPage onClick={() => handleNavigatePage(FIRST_PAGE)} />
            ),
          },
          {
            willDisable: firstPage === 1,
            children: (
              <NavigateBefore onClick={() => handleNavigatePage(BEFORE_PAGE)} />
            ),
          },
          {
            willDisable: firstPage + pagination.count - 1 >= pagination.totalCount,
            children: (
              <NavigateNext onClick={() => handleNavigatePage(NEXT_PAGE)} />
            ),
          },
          {
            willDisable: firstPage + pagination.count - 1 >= pagination.totalCount,
            children: (
              <LastPage onClick={() => handleNavigatePage(LAST_PAGE)} />
            ),
          },
        ].map((component, index) => {
          return (
            <Button
              key={index}
              classes={{ root: style.navigateButton }}
              variant="text"
              disableElevation
              disableTouchRipple
              disabled={component.willDisable}
            >
              {component.children}
            </Button>
          )
        })}
      </Box>
    </Box>
  )
}
export default CustomPagination
