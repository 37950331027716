import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { Box } from "@material-ui/core";
import { formatAmount } from "../../../utils";
import { Text40 } from "../../../../../components/Common/Styled/TypographyStyled";

export function AddonsAndConsumablesGraph() {
  const { url } = useRouteMatch();
  const { incomePerUpSellItemsData } = useSelector((state) => state.analytic);

  return (
    <Card
      headerSize="small"
      header="Total add-ons & consumables income"
      navigateTo={`${url}/addons-consumables`}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: 220,
            height: 220,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Text40>{formatAmount(incomePerUpSellItemsData?.totalIncome || 0)}</Text40>
        </Box>
      </Box>
    </Card>
  );
}
