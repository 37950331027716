import React from "react";

const ColumnInTable = (props) => {
  return (
    <div
      style={{
        minWidth: `${props.minWidth - 10}px`,
        marginRight: "10px",
        whiteSpace: "break-spaces",
      }}
    >
      {props.children}
    </div>
  );
};

export default ColumnInTable;
