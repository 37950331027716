import { Box, Grid, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import AlertMissingDocument from "../../components/AlertMissingDocument";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import DynamicTextField from "../../components/DynamicTextField";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import TemplateImageItemLicensee from "../../components/TemplateImageItemLicensee";
import TextFieldMask from "../../components/TextFieldMask";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { USER_ROLES, common } from "../../constants/common";
import axios from "../../helpers/axios";
import { checkIsDocumentExpiry } from "../../helpers/checkIsDocumentExpiry";
import { generatePropTextField } from "../../helpers/formik-help";
import { isFullDate } from "../../helpers/verify";
import { useLoading } from "../../hooks/useLoading";
import { useRedirectToHomeIfUnauthorized } from "../../hooks/useAuth";

const useStyles = makeStyles(() => ({
  grid: {
    display: "grid",
  },
  disabled: {
    pointerEvents: "none",
  },
  greyText: {
    "& .MuiInputBase-root > input": {
      color: "#8B8B8B",
    },
  },
  relative: {
    position: "relative",
  },
  radioButton: {
    "&$checked": {
      color: "#44D62C !important",
    },
    color: "#44D62C !important",
    margin: 0,
    position: "absolute",
    top: 5,
    right: 6,
  },
}));

const CONTENT_ALERT = {
  true: {
    header: "Trailer Missing Expiry Registration",
  },
  7: {
    header: "Trailer will be Expiry Registration in 7 days",
  },
  30: {
    header: "Trailer will be Expiry Registration in 30 days",
  },
  title: "Contact the Franchisee to discuss this issue",
};

const EditTrailerItemInLicensee = () => {
  useRedirectToHomeIfUnauthorized([USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER]);
  const classes = useStyles();

  const [trailerData, setTrailerData] = useState({
    name: "",
    registration: "",
    description: "",
    expiryRegistration: "",
    photos: [],
    origPrice: 0,
    origHireCost: 0,
    newPrice: undefined,
    price: 0,
    hirecost: undefined,
  });

  const [isUseOriginalPrice, setisUseOriginalPrice] = useState(true);
  const [isExpiry, setExpiry] = useState(false);
  const dataImageRef = useRef(null);
  const history = useHistory();
  const { licenseeId, trailerId } = useParams();
  const [showLoading, hideLoading] = useLoading();

  const handleAddRegistrationTrailer = async (
    registration,
    expiryRegistration,
    price,
    originalPrice,
    useOriginalPrice
  ) => {
    showLoading();
    try {
      const res = await axios.put(
        "v1/admin/trailer-licensee-registration/" + licenseeId + "/" + trailerId,
        {
          registration,
          expiryRegistration,
          price: (price + "").replace("$", "").replace(",", ""),
          originalPrice: (originalPrice + "").replace("$", "").replace(",", ""),
          useOriginalPrice,
        }
      );
      toast.success(res?.data?.message);
      hideLoading();
      history.goBack();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: trailerData,
    validationSchema: yup.object({
      registration: yup.string().required(" "),
      expiryRegistration: yup.string().min(10, " ").required(" "),
      price: yup.number().required(" "),
    }),
    onSubmit: (value) => {
      handleAddRegistrationTrailer(
        value.registration,
        value.expiryRegistration,
        value.newPrice,
        value.origPrice,
        isUseOriginalPrice
      );
    },
  });

  useEffect(() => {
    showLoading();
    axios
      .get("/v1/admin/licensee/trailers-Upsells/" + licenseeId)
      .then((response) => {
        const trailerOflicenseeList = response.data.licenseeTrailersObj;
        let item = trailerOflicenseeList.find((item) => item._id === trailerId);
        setTrailerData({
          name: item.trailerId.name,
          registration: item.registration || undefined,
          description: item.trailerId.description,
          price: item.price,
          hirecost: item.hirecost,
          origPrice: item.origPrice,
          origHireCost: item.origHireCost,
          newPrice: item.priceNew || undefined,
          useOriginalPrice: !item.priceNew || item.origPrice === item.price,
          expiryRegistration: item.expiryRegistration
            ? moment(item.expiryRegistration).format("DD/MM/YYYY")
            : "",
          photos: item.trailerId.photos?.map((item) => item.url),
        });
        setisUseOriginalPrice(!item.priceNew || item.origPrice === item.price);
        if (item.expiryRegistration === null) {
          setExpiry(true);
          return hideLoading();
        }
        const isRegistraionExpiry = checkIsDocumentExpiry(item.expiryRegistration);
        setExpiry(isRegistraionExpiry);
        return hideLoading();
      })
      .catch((err) => {
        hideLoading();
        toast.error(err?.response?.data?.message);
      });
  }, []);

  function onRadioClick() {
    setisUseOriginalPrice((previousValue) => !previousValue);
  }

  function formatPrice(value) {
    if (value === undefined) {
      return "";
    }

    if ((value + "").includes("$")) {
      value = parseFloat((value + "").replace("$", "").replace(",", ""));
    }

    const language = "en-AU";
    const options = {
      style: "currency",
      currency: "AUD",
      minimumFractionDigits: 0,
    };

    const formatter = new Intl.NumberFormat(language, options);
    return formatter.format(value);
  }

  return (
    <Box height="100%">
      <WrapHeaderSubPage nameHeader={trailerData.name}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Box display="flex">
        <TemplateImageItemLicensee
          dataImageRef={dataImageRef}
          photos={trailerData.photos}
          upload={false}
        />
        <WrapFormItemLicensee formik={formik}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Box className={classes.relative}>
                <DynamicTextField
                  {...generatePropTextField("price", formik)}
                  label="Standard Trailer Value"
                  value={formatPrice(formik.values?.origPrice) || 0}
                  className={`${classes.grid} ${classes.disabled} ${classes.greyText}`}
                />
                <Radio
                  checked={isUseOriginalPrice}
                  onChange={onRadioClick}
                  className={classes.radioButton}
                  name="orig-price-radio-button"
                  inputProps={{ "aria-label": "standard-trailer-value" }}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <DynamicTextField
                {...generatePropTextField("origHireCost", formik)}
                label="Standard Rate"
                value={`${formatPrice(formik.values?.origHireCost) || 0} per day`}
                className={`${classes.grid} ${classes.disabled} ${classes.greyText}`}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box className={classes.relative}>
                <DynamicTextField
                  {...generatePropTextField("newPrice", formik)}
                  label="New Trailer Value"
                  value={formatPrice(formik.values?.newPrice) || ""}
                  className={classes.grid}
                />
                <Radio
                  checked={!isUseOriginalPrice}
                  onChange={onRadioClick}
                  className={classes.radioButton}
                  name="new-price-radio-button"
                  inputProps={{ "aria-label": "new-trailer-value" }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <DynamicTextField
                {...generatePropTextField("hireCost", formik)}
                label="New Rate"
                value={
                  trailerData.newPrice ? `${formatPrice(formik.values?.hirecost) + " per day"}` : ""
                }
                className={`${classes.grid} ${classes.disabled}`}
              />
            </Grid>
          </Grid>
          <DynamicTextField
            {...generatePropTextField("registration", formik)}
            label="Trailer Registration"
          />
          <TextFieldMask
            formatChars={common.formatDate}
            mask="32/12/2222"
            value={formik.values.expiryRegistration}
            onChange={formik.handleChange}
            error={
              (formik.touched.expiryRegistration && Boolean(formik.errors.expiryRegistration)) ||
              !isFullDate(formik.values.expiryRegistration)
            }
            inputProps={{
              maxLength: 250,
            }}
            id="expiryRegistration"
            name="expiryRegistration"
            label="Expiry Registration"
          >
            {(inputProps) => <FieldFormAdd fullWidth variant="outlined" {...inputProps} />}
          </TextFieldMask>
          <span className="text-16">{trailerData.description}</span>
          <WrapButtonSubmitTrailer>
            <ButtonActionHeader
              style={{ marginTop: "32px" }}
              width="233px"
              onClick={() => formik.handleSubmit()}
            >
              Save Update
            </ButtonActionHeader>
          </WrapButtonSubmitTrailer>
        </WrapFormItemLicensee>
      </Box>
      {isExpiry !== false && (
        <AlertMissingDocument
          header={CONTENT_ALERT[isExpiry].header}
          title={CONTENT_ALERT.title}
          contact="04 26 854 685"
        />
      )}
    </Box>
  );
};

export default EditTrailerItemInLicensee;
