import moment from "moment";
export function checkIsDocumentExpiry(dayDocumentExpiry) {
  const timeLeftToExpiryDay = moment(dayDocumentExpiry).diff(moment(), "days");
  if (timeLeftToExpiryDay <= 0) {
    return true;
  }
  if (timeLeftToExpiryDay > 30) {
    return false;
  }
  if (timeLeftToExpiryDay <= 30 && timeLeftToExpiryDay > 7) {
    return 30;
  }
  if (timeLeftToExpiryDay <= 7) {
    return 7;
  }
}
