import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Card } from "../../components";
import { useStyles } from "../../Analytics.styles";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";

export function OutOfServiceTrailersPage() {
  const classes = useStyles();
  const { outOfServiceTrailersData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Out of Service Trailers';
    if (outOfServiceTrailersData?.items) {
      const dataTransformed = [...outOfServiceTrailersData?.items]
        .sort((a, b) => b.grossIncome - a.grossIncome)
        .map((item) => ({
          franchisee: `${item?.licenseeUser?.firstname} ${item?.licenseeUser?.lastname}`,
          trailerType: item.trailer.name,
          reason: item.reason,
        }));
      setData(dataTransformed);
    }
  }, [outOfServiceTrailersData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "Reason",
      key: "reason",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Out of service trailers">
        <SortableTable rows={data} columns={columns} gridTemplateColumns="30% 40% 30%" />
      </Card>
    </Box>
  );
}
