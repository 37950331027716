import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  menuSection: {
    marginTop: 24,
    display: "grid",
    gridTemplateColumns: "auto auto auto 1fr",
    gap: 64,
  },
  overviewTab: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    color: "#44D62C",
  },
  reportsTab: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    color: "#DEE7FA",
  },
}));
