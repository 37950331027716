import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Card } from "../../components";
import { useStyles } from "../../Analytics.styles";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { formatHour } from "../../utils";

export function TrailerUtilisationReportPage() {
  const classes = useStyles();
  const { trailerUtilisationReport } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent =
      "Trailer Utilisation Report";
    if (trailerUtilisationReport?.items) {
      const dataTransformed = [...trailerUtilisationReport?.items]
        .sort((a, b) => b?.income - a?.income)
        .map((item) => ({
          trailerRego: item?.registration ?? "--",
          trailerType: item?.trailer ?? "--",
          franchisee: item?.franchisee ?? "--",
          inService: item?.inService ?? "--",
          totalHireTime: formatHour(item?.totalHireDuration || 0),
          utilisation: item?.utilisation ?? "--",
        }));
      setData(dataTransformed);
    }
  }, [trailerUtilisationReport]);

  const columns = [
    {
      name: "Trailer Rego",
      key: "trailerRego",
      sortable: true,
    },
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "In service",
      key: "inService",
      sortable: true,
    },
    {
      name: "Total hire time",
      key: "totalHireTime",
      sortable: true,
    },
    {
      name: "Utilisation",
      key: "utilisation",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Trailer Utilisation Report">
        <SortableTable
          rows={data}
          columns={columns}
          gridTemplateColumns="10% 30% 30% 10% 10% 10%"
        />
      </Card>
    </Box>
  );
}
