import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatAmount } from "../../utils";

export function AvgIncomePerTrailerPage() {
  const classes = useStyles();
  const { incomePerTrailerData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Average Income Per Trailer';
    if (incomePerTrailerData?.items) {
      const dataTransformed = incomePerTrailerData.items.map((item) => ({
        name: item.trailer.name,
        totalSales: formatAmount(item.grossIncome, 0),
        totalHires: item.totalHires,
        averageIncome: formatAmount(item.grossIncome / item.totalHires, 0),
      }));
      setData(dataTransformed);
    }
  }, [incomePerTrailerData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
    {
      name: "Average Income",
      key: "averageIncome",
      sortable: true,
    },
  ];

  return (
    <>
      <Box component="main" className={classes.analyticsSinglePageContent}>
        <Card header="Average Income Per Trailer Type">
          <SortableTable
            rows={data}
            columns={columns}
            gridTemplateColumns="40% 20% 20% 20%"
          />
        </Card>
      </Box>
    </>
  );
}
