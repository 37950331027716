import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import { useParams, useLocation } from "react-router-dom";
import MaterialTable from "material-table";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import DialogTrailerServices from "../../components/DialogTrailerServices";
import moment from "moment";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios.js";
import { toast } from "react-toastify";
import { checkIsDocumentExpiry } from "../../helpers/checkIsDocumentExpiry";
import AlertMissingDocument from "../../components/AlertMissingDocument";

const CONTENT_ALERT = {
  empty: {
    header: "Service Documents",
    title: "Contact the Franchisee to upload a trailer services doc on",
  },
  true: {
    header: "Service Documents Overdue",
    title: "Contact the Franchisee to upload a trailer services doc on",
  },
  7: {
    header: "Service Document due in 7 days",
    title: "Contact the Franchisee if needed",
  },
  30: {
    header: "Service Document due in 30 days",
    title: "Contact the Franchisee if needed",
  },
};
const CONTENT_DAMAGED = {
  header: "Trailer was marked as damaged from trailer pickup",
  title: "Contact the Franchisee to discuss Trailer damages",
}
const TrailerServiceInLicensee = () => {
  const { licenseeId, trailerId } = useParams();
  const [showLoading, hideLoading] = useLoading();
  const [checkList, setCheckList] = useState([]);
  const location = useLocation()
  const [open, setOpen] = useState(false);
  const [ data, setData ] = useState({
    name: "",
    checklist: [],
    contact: ""
  });
  const [isExpiry, setExpiry] = useState(false);
  
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  
  function getAllCheckList() {
    showLoading();
    axios
      .get("/v1/admin/checklist")
      .then((response) => {
        hideLoading();
        return setCheckList(response.data.checklistObj);
      })
      .catch((error) => {
        hideLoading();
        toast.error(error?.response?.data?.message);
      });
  }
  function getCheckListAndNameOfTrailer() {
    showLoading();
    axios
      .get("/v1/admin/licensee/trailers-Upsells/" + licenseeId)
      .then((response) => {
        const trailerSelected = response.data.licenseeTrailersObj.find((item) => item._id === trailerId);
        hideLoading();
        if ( response.data.licenseeUserInfor === null) {
          return setData({
            name: trailerSelected.trailerId.name,
            checklist: newServiceTrailerList(trailerSelected).reverse(),
            contact: ""
          })
        }
        return setData({
          name: trailerSelected.trailerId.name,
          checklist: newServiceTrailerList(trailerSelected).reverse(),
          contact: response.data.licenseeUserInfor.mobileFormat
        })
      })
      .catch((error) => {
        hideLoading();
        toast.error(error?.response?.data?.message);
      });
  }
  function newServiceTrailerList(trailerSelected) {
    return trailerSelected.checklist?.map((item) => {
      return {
        name: "Services " + moment(item).format("DD/MM/YY"),
        day: moment(item).format("DD/MM/YY"),
        expiry: item,
      };
    });
  }
  function contentAlertDocument() {
    let header
    let title
    if ( location?.state?.isTrailerDamage ) {
      header= CONTENT_DAMAGED.header
      title= CONTENT_DAMAGED.title 
    }
    else {
      header= CONTENT_ALERT[isExpiry].header
      title= CONTENT_ALERT[isExpiry].title
    }
    return {
      header: header,
      title: title,
      contact: data.contact
    }
  }

  const mockData = [
    {
      title: "",
      field: "trailerType",
      render: (rowData) => {
        return <div className="list-item-name">{rowData.name}</div>;
      },
    },
    {
      title: "",
      field: "action",
      render: () => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage onClick={handleOpenDialog}>
              View
            </ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];
  useEffect(() => {
    getAllCheckList();
    getCheckListAndNameOfTrailer();
  }, [licenseeId, trailerId]);
  useEffect(() => {
    if (data.checklist.length === 0) {
      return setExpiry("empty");
    }
    const result = checkIsDocumentExpiry(
      moment(data.checklist[0].expiry).add(30, "days").toISOString()
    );
    setExpiry(result);
  }, [data]);
  return (
    <Box height="100%">
      <WrapHeaderSubPage
        nameHeader={`${data.name} Services`}
      >
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <DialogTrailerServices
        checkList={checkList}
        handleCloseDialog={handleCloseDialog}
        open={open}
      />
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={data.checklist}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          header: false,
          actionsCellStyle: { color: "#fff" },
          rowStyle: (rowData) => {
            return (
              rowData.tableData.id === 0 && {
                borderTop: "1px solid rgba(83, 95, 120, 0.2)",
              }
            );
          },
          actionsColumnIndex: -1,
        }}
      />
      {
        ( isExpiry !== false || location?.state?.isTrailerDamaged === true ) && 
          <AlertMissingDocument
            {...contentAlertDocument()}
          />
      }
    </Box>
  );
};
export default TrailerServiceInLicensee;
