import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DateFnsUtils from "@date-io/date-fns";
import { DateRangePicker } from "react-date-range";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  Radio,
  TextField,
  makeStyles,
  styled,
} from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import StickyNavigationSection from "../../../components/StickyNavigationSection";
import useModal, { MODAL_TYPES } from "../../../hooks/useModal";
import {
  CommonBox,
  FullScreenDrawer,
  Stack,
  customBoxStyles,
} from "../../../components/Common/Styled/CommonStyled";
import {
  Text14,
  Text14Weight400,
  Text16,
  Text16Weight400,
  Text16Weight600,
  Text16Weight700,
  Text18,
  Text18Weight600,
  Text18Weight700,
} from "../../../components/Common/Styled/TypographyStyled";
import { COLOR_LIST } from "../../../themes";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  DIALOG_AVAILABLE_TYPE,
  updateDataAtom,
  useCheckDistance,
  useCheckIfAvailableTrailersWithinSpecificTime,
  useConfirmUpdateOrder,
  useGetAvailableTrailers,
  useGetDetailItems,
  useGetFreeAccessories,
  useGetHireTheDriverServicesAndVouchers,
  useGetOrderDetail,
  useGetPaymentMethods,
  useGetPriceDetails,
} from "../../../hooks/UpdateOrder/useUpdateOrder";
import { Provider, useAtom } from "jotai";
import {
  Check as CheckIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { cloneDeep, debounce } from "lodash";
import { AutoSizer, List } from "react-virtualized";
import { formatCurrency, formatTime, placeToAddress } from "../../../helpers/format";
import moment from "moment";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import useLoadGoogleMapAPI from "../../../hooks/useLoadGoogleMapAPI";
import usePlacesAutocomplete, { getDetails, getGeocode } from "use-places-autocomplete";
import useDialog from "../../../hooks/useDialog";
import { ReactComponent as FailIcon } from "../../../img/fail.svg";
import { ReactComponent as SuccessIcon } from "../../../img/success.svg";
import { USER_ROLES, common } from "../../../constants/common";
import { useRedirectToHomeIfUnauthorized } from "../../../hooks/useAuth";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 350px",
  gap: 16,
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr",
  },
}));

const StickyBox = styled(Box)({
  position: "sticky",
  top: "74px",
  height: "max-content",
});

const StyledList = styled(List)({
  color: "#fff",
  padding: 0,
  boxShadow: `0px -1px 0px #535F78, 0px 1px 0px ${COLOR_LIST.COMET}`,
  "& .MuiListItemText-secondary": {
    color: COLOR_LIST.LAVENDER_MIST,
  },
});

const useStyles = makeStyles({
  bottomSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 12,
    padding: 16,
  },
});

const ROW_HEIGHT = 100;

const minimumBookingHourTest = (values, ctx) => {
  const [time1, time2] = values;
  if (moment(time1).format("DD-MM-YYYY") !== moment(time2).format("DD-MM-YYYY")) {
    return true;
  }
  // If DropOff Date and Pickup Date are the same date
  const momentTime1 = moment(time1);
  const momentTime2 = moment(time2);

  if (momentTime2.isBefore(momentTime1)) {
    return ctx.createError({
      message: "Pick up time should be after Drop off time",
    });
  }

  const time_difference = moment.duration(momentTime1.diff(momentTime2)).asHours();
  if (Math.abs(time_difference) < 3) {
    return ctx.createError({
      message: "The Minimum booking is at least 3 hours",
    });
  }
  return true;
};

function UpdateOrder() {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const { renderDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const { isLoading: isLoadingOrderDetail, isError } = useGetOrderDetail(id);
  const { data, isFetching: isFetchingGetPriceDetails } = useGetPriceDetails();
  const { mutate, isLoading: isLoadingUpdateOrder } = useConfirmUpdateOrder();
  useEffect(() => {
    if (isLoadingUpdateOrder) {
      handleOpenDialog({
        content: (
          <Box sx={{ display: "grid", placeContent: "center", height: 200 }}>
            <Stack sx={{ flexDirection: "column", gap: 24, alignItems: "center" }}>
              <CircularProgress color="primary" />
              <Text18Weight700>Payment processing</Text18Weight700>
            </Stack>
          </Box>
        ),
        hideApproveButton: true,
        hideDisapproveButton: true,
        showCloseIcon: true,
      });
    }
  }, [isLoadingUpdateOrder]);
  const onConfirm = (totalCost) => {
    mutate(totalCost, {
      onSuccess: () => {
        handleOpenDialog({
          content: (
            <Box sx={{ display: "grid", placeContent: "center", height: 200 }}>
              <Stack sx={{ flexDirection: "column", gap: 24, alignItems: "center" }}>
                <SuccessIcon />
                <Text18Weight700>Payment Successful</Text18Weight700>
              </Stack>
            </Box>
          ),
          handleConfirm: () => history.push(`/transaction-history/${id}`),
          hideDisapproveButton: true,
          approveText: "Close",
        });
      },
      onError: (error) => {
        let errorMessage =
          error?.response?.data?.message?.message ??
          error?.response?.data?.message ??
          error?.message ??
          common.SOMETHING_WENT_WRONG;
        if (typeof errorMessage !== "string") {
          errorMessage = common.SOMETHING_WENT_WRONG;
        }
        handleOpenDialog({
          content: (
            <Box sx={{ display: "grid", placeContent: "center", height: 200 }}>
              <Stack sx={{ flexDirection: "column", gap: 24, alignItems: "center" }}>
                <FailIcon />
                <Text18Weight700>Payment Failed</Text18Weight700>
                <Text14Weight400>{errorMessage}</Text14Weight400>
              </Stack>
            </Box>
          ),
          handleConfirm: handleCloseDialog,
          hideDisapproveButton: true,
          approveText: "Close",
        });
      },
    });
  };

  const onHandleDialogConfirmUpdate = () => {
    if (data?.updatedBooking?.charges?.total) {
      handleOpenDialog({
        title: "Are you sure you want to make this change?",
        content: `Tapping Confirm will ${
          data?.charges?.total < 0 ? "refund" : "charge"
        } ${formatCurrency(Math.abs(data?.charges?.total ?? 0))} to your debit card`,
        disapproveText: "Cancel",
        approveText: "Confirm",
        handleConfirm: () => onConfirm(data?.updatedBooking?.charges?.total),
        handleDecline: () => handleCloseDialog(),
        showCloseIcon: true,
      });
    }
  };

  const isLoading = isLoadingOrderDetail || isFetchingGetPriceDetails;
  const { isLoaded } = useLoadGoogleMapAPI();
  if (isLoadingOrderDetail || !isLoaded) {
    return (
      <Box sx={{ display: "grid", placeContent: "center", height: "80dvh" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }
  if (isError) {
    return <Box>Something went wrong!</Box>;
  }
  return (
    <Box paddingTop={"50px"} pb={10}>
      <Breadcrumbs />
      <CustomBox>
        <Stack sx={{ flexDirection: "column", gap: 10 }}>
          <DeliveryDetails />
          <TrailerSection />
          <AdditionalItems />
          <ConsumableItems />
          <FreeAccessories />
          <HireTheDriverSection />
          <PromotionsAndDiscountCodes />
          <TermsAndConditions />
          <PaymentSection id={id} />
        </Stack>
        <StickyBox>
          <CommonBox p={{ xs: 3, md: 2 }}>
            <PriceDetails />
          </CommonBox>
        </StickyBox>
      </CustomBox>
      <StickyNavigationSection isBottom>
        <Box className={classes.bottomSection}>
          <Button to={`/transaction-history/${id}`} color="primary" component={Link}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading || !data?.updatedBooking?.charges?.total}
            onClick={onHandleDialogConfirmUpdate}
          >
            Confirm Update
          </Button>
        </Box>
      </StickyNavigationSection>
      {renderDialog}
    </Box>
  );
}

const TermsAndConditions = () => {
  return (
    <CommonBox p={{ xs: 3, md: 5 }} sx={{ gap: 16 }}>
      <Text18>Terms and Conditions</Text18>
      <Text16Weight400 style={{ color: COLOR_LIST.LAVENDER_MIST, lineHeight: 1.5 }}>
        A minimum of 24 hours notice must be given on all cancellations prior to the start time and
        date of the booking.{" "}
        <a href="https://trailer2you.com.au/terms-conditions" target="_blank">
          <span style={{ whiteSpace: "nowrap", color: COLOR_LIST.PRIMARY }}>Read More</span>
        </a>
      </Text16Weight400>
    </CommonBox>
  );
};

const DRAWER_TYPE = {
  ADDRESS: "ADDRESS",
  DATES: "DATES",
  TIMES: "TIMES",
};

const DeliveryDetails = () => {
  const [updateData] = useAtom(updateDataAtom);
  const [drawerType, setDrawerType] = useState(null);

  const dates = useMemo(
    () =>
      `${formatTime({
        date: new Date(updateData?.startDate),
        designateFormat: "DD MMM",
      })} - ${formatTime({
        date: new Date(updateData?.endDate),
        designateFormat: "DD MMM",
      })}`,
    [updateData?.startDate, updateData?.endDate]
  );
  const times = useMemo(
    () =>
      `${formatTime({
        date: new Date(updateData?.startDate),
        designateFormat: "hh:mm a",
      })} - ${formatTime({
        date: new Date(updateData?.endDate),
        designateFormat: "hh:mm a",
      })}`,
    [updateData?.startDate, updateData?.endDate]
  );

  const onHandleCloseDrawer = useCallback(() => {
    setDrawerType(null);
  }, []);

  const drawerContent = useMemo(() => {
    switch (drawerType) {
      case DRAWER_TYPE.ADDRESS:
        return <ChangeAddressComponent onHandleClose={onHandleCloseDrawer} />;
      case DRAWER_TYPE.DATES:
        return <ChangeDatesComponent onHandleClose={onHandleCloseDrawer} />;
      case DRAWER_TYPE.TIMES:
        return <ChangeTimesComponent onHandleClose={onHandleCloseDrawer} />;
      default:
        return null;
    }
  }, [drawerType, onHandleCloseDrawer]);

  return (
    <CommonBox p={{ xs: 3, md: 5 }}>
      <Stack sx={{ flexDirection: "column", gap: 16 }}>
        <Text18>Delivery details</Text18>
        <TextField
          variant="outlined"
          value={updateData?.customerAddress?.streetAddress}
          onClick={() => setDrawerType(DRAWER_TYPE.ADDRESS)}
        />
        <Stack sx={{ flexDirection: "column" }}>
          {[
            {
              title: "Dates",
              value: dates,
              type: DRAWER_TYPE.DATES,
            },
            {
              title: "Drop off / Pick up time",
              value: times,
              type: DRAWER_TYPE.TIMES,
            },
          ].map(({ title, value, type }) => (
            <Button key={title} style={{ padding: 0 }} onClick={() => setDrawerType(type)}>
              <Stack style={{ ...customBoxStyles, alignItems: "center" }}>
                <Stack sx={{ flexDirection: "column", gap: 10, alignItems: "flex-start" }}>
                  <Text14Weight400 style={{ color: COLOR_LIST.LAVENDER_MIST }}>
                    {title}
                  </Text14Weight400>
                  <Text16Weight400>{value}</Text16Weight400>
                </Stack>
                <KeyboardArrowRightIcon />
              </Stack>
            </Button>
          ))}
        </Stack>
      </Stack>
      <FullScreenDrawer anchor="top" open={!!drawerType} onClose={onHandleCloseDrawer}>
        {drawerContent}
      </FullScreenDrawer>
    </CommonBox>
  );
};

const TrailerSection = () => {
  const { data: trailers, isLoading } = useGetAvailableTrailers();
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const onChange = useCallback((trailerId) => {
    setUpdateData((prevUpdateData) => ({ ...prevUpdateData, trailerId }));
  }, []);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (trailers?.length > 0) {
      return (
        <Stack sx={{ flexDirection: "column", maxHeight: 400, overflowY: "scroll" }}>
          {trailers?.map(({ name, liceseeTrailerId, registration, isActive }, index) => (
            <Button
              key={`${liceseeTrailerId}_${index}`}
              style={{ padding: 0 }}
              onClick={() => isActive && onChange(liceseeTrailerId)}
            >
              <Stack style={{ ...customBoxStyles, alignItems: "center" }}>
                <Text16Weight400 style={{ color: isActive ? "" : COLOR_LIST.STAR_DUST }}>
                  {name} - {registration}
                </Text16Weight400>
                <Stack sx={{ gap: 32, alignItems: "center" }}>
                  {isActive ? null : (
                    <Text14Weight400 style={{ color: COLOR_LIST.STAR_DUST }}>
                      Currently Deactivated
                    </Text14Weight400>
                  )}
                  <Radio
                    color="primary"
                    checked={updateData?.trailerId === liceseeTrailerId}
                    disabled={!isActive}
                  />
                </Stack>
              </Stack>
            </Button>
          ))}
        </Stack>
      );
    }
    return <Text14>No Available Trailers</Text14>;
  }, [isLoading, trailers, updateData?.trailerId, onChange]);

  return (
    <CommonBox p={{ xs: 3, md: 5 }}>
      <Stack sx={{ flexDirection: "column", gap: 16 }}>
        <Text18>Trailers</Text18>
        {content}
      </Stack>
    </CommonBox>
  );
};

const PaymentSection = ({ id }) => {
  const { renderModal, setOpenModal } = useModal({});
  const { data: paymentMethods, isFetching: isLoadingStripePaymentMethods } =
    useGetPaymentMethods(id);
  const [updateData, setUpdateData] = useAtom(updateDataAtom);

  const onChange = useCallback((id) => {
    setUpdateData((prevUpdateData) => ({ ...prevUpdateData, stripePaymentMethodId: id }));
  }, []);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      setUpdateData((prevUpdateData) => ({
        ...prevUpdateData,
        stripePaymentMethodId: paymentMethods?.[0]?.id,
      }));
    }
  }, [paymentMethods]);

  const content = useMemo(() => {
    if (isLoadingStripePaymentMethods) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (paymentMethods && paymentMethods?.length > 0) {
      return (
        <Stack sx={{ flexDirection: "column", gap: 16 }}>
          <Text18>How would you like to pay?</Text18>
          <Stack sx={{ flexDirection: "column" }}>
            {paymentMethods?.map(({ last4, brand, id }) => (
              <Button key={id} style={{ padding: 0 }} onClick={() => onChange(id)}>
                <Stack style={{ ...customBoxStyles, alignItems: "center" }}>
                  <Stack style={{ alignItems: "center", gap: 8 }} gap={1}>
                    <Text16Weight400>{brand.toUpperCase()}</Text16Weight400>
                    <Text16Weight400>•••• •••• •••• {last4}</Text16Weight400>
                  </Stack>
                  {updateData?.stripePaymentMethodId === id ? <CheckIcon /> : null}
                </Stack>
              </Button>
            ))}
          </Stack>
          <Button
            variant="contained"
            style={{
              padding: "16px",
              width: "max-content",
            }}
            onClick={() => setOpenModal({ modalType: MODAL_TYPES.ADD_PAYMENT_METHOD_MODAL })}
            color="primary"
          >
            <Text16Weight600>Add a new credit card</Text16Weight600>
          </Button>
        </Stack>
      );
    }
    return (
      <Stack sx={{ flexDirection: "column", gap: 24 }}>
        <Text18>Please add your payment method</Text18>
        <Text14 style={{ color: COLOR_LIST.LAVENDER_MIST }}>
          Add a payment method to complete your order
        </Text14>
        <Button
          color="primary"
          variant="contained"
          style={{
            padding: "16px",
            width: "max-content",
          }}
          onClick={() => setOpenModal({ modalType: MODAL_TYPES.ADD_PAYMENT_METHOD_MODAL })}
        >
          <Text16>Add payment method</Text16>
        </Button>
      </Stack>
    );
  }, [paymentMethods, isLoadingStripePaymentMethods, updateData?.stripePaymentMethodId, onChange]);

  return (
    <CommonBox p={{ xs: 3, md: 5 }}>
      {content}
      {renderModal}
    </CommonBox>
  );
};

const AdditionalItems = () => {
  const { data, isLoading } = useGetDetailItems("upsell");

  const rowRenderer = useCallback(
    ({ key, index, style }) => {
      const renderData = data?.[index];
      if (renderData?.premium) {
        return (
          <div key={key} style={style}>
            <PremiumItem _id={renderData?._id} name={renderData?.name} />
          </div>
        );
      }
      return (
        <div key={key} style={style}>
          <AddingItem
            _id={renderData?.licenseeUpsellItemId}
            upsellItemId={renderData?.upsellItemId}
            name={renderData?.name}
            updateDataProperty={"upsellItems"}
            count={renderData?.count}
          />
        </div>
      );
    },
    [data]
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (data && data?.length > 0) {
      return (
        <div style={{ display: "flex", flexDirection: "column", height: 400 }}>
          <AutoSizer style={{ flexGrow: 1 }}>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={data.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      );
    }
    return <Text14>No Available Additional Items</Text14>;
  }, [isLoading, data, rowRenderer]);
  return (
    <CommonBox p={{ xs: 3, md: 5 }}>
      <Stack sx={{ flexDirection: "column", gap: 16 }}>
        <Text18>Additional Items</Text18>
        {content}
      </Stack>
    </CommonBox>
  );
};

const ConsumableItems = () => {
  const { data, isLoading } = useGetDetailItems("consumable");

  const rowRenderer = useCallback(
    ({ key, index, style }) => {
      const renderData = data?.[index];
      return (
        <div key={key} style={style}>
          <AddingItem
            _id={renderData?.licenseeUpsellItemId}
            upsellItemId={renderData?.upsellItemId}
            name={renderData?.name}
            updateDataProperty={"consumableItems"}
            count={renderData?.quantity}
          />
        </div>
      );
    },
    [data]
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (data && data?.length > 0) {
      return (
        <div style={{ display: "flex", flexDirection: "column", height: 400 }}>
          <AutoSizer style={{ flexGrow: 1 }}>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={data.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      );
    }
    return <Text14>No Available Consumable Items</Text14>;
  }, [isLoading, data, rowRenderer]);
  return (
    <CommonBox p={{ xs: 3, md: 5 }}>
      <Stack sx={{ flexDirection: "column", gap: 16 }}>
        <Text18>Consumable Items</Text18>
        {content}
      </Stack>
    </CommonBox>
  );
};

const FreeAccessories = () => {
  const { data, isLoading } = useGetFreeAccessories();
  const [updateData, setUpdateData] = useAtom(updateDataAtom);

  const onChange = useCallback((id) => {
    setUpdateData((prevUpdateData) => ({ ...prevUpdateData, plugTypeId: id }));
  }, []);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (data?.length > 0) {
      return data?.map(({ _id, title }) => (
        <Button key={`${title}_${_id}`} style={{ padding: 0 }} onClick={() => onChange(_id)}>
          <Stack style={{ ...customBoxStyles, alignItems: "center" }}>
            <Text16Weight400>{title}</Text16Weight400>
            <Radio color="primary" checked={updateData?.plugTypeId === _id} />
          </Stack>
        </Button>
      ));
    }
    return <Text14>No Available Free Accessories</Text14>;
  }, [isLoading, data, updateData?.plugTypeId]);

  return (
    <CommonBox p={{ xs: 3, md: 5 }}>
      <Stack sx={{ flexDirection: "column", gap: 16 }}>
        <Text18>Free Accessories</Text18>
        <Stack sx={{ flexDirection: "column" }}>
          {content}
          <Stack style={{ ...customBoxStyles, alignItems: "center", height: 80 }}>
            <Text16Weight400>D-Shackle</Text16Weight400>
            <Checkbox
              checked={!!updateData?.isDShackleNeeded}
              onChange={(event) =>
                setUpdateData((prevUpdateData) => ({
                  ...prevUpdateData,
                  isDShackleNeeded: event.target.checked,
                }))
              }
              inputProps={{ "aria-label": "primary checkbox" }}
              color="primary"
            />
          </Stack>
        </Stack>
      </Stack>
    </CommonBox>
  );
};

const MAX_DURATION = 6;
const LEAST_DURATION = 3;
const MIN_DURATION = 0;

const HireTheDriverSection = () => {
  const { data, isLoading, isFetching } = useGetHireTheDriverServicesAndVouchers();
  const [updateData, setUpdateData] = useAtom(updateDataAtom);

  const onHandleChange = useCallback(
    (id) => {
      const set = new Set(updateData?.driverHireServices ?? []);
      if (set.has(id)) {
        set.delete(id);
      } else {
        set.add(id);
      }
      setUpdateData((prevUpdateData) => ({
        ...prevUpdateData,
        driverHireServices: Array.from(set),
      }));
    },
    [updateData]
  );

  const onHandleDecreaseDuration = useCallback(() => {
    let newDuration = Math.floor(updateData?.driverHireDuration - 1);
    if (newDuration === LEAST_DURATION - 1) newDuration = MIN_DURATION;
    setUpdateData((prevUpdateData) => ({
      ...prevUpdateData,
      driverHireDuration: newDuration < MIN_DURATION ? MIN_DURATION : newDuration,
    }));
  }, [updateData?.driverHireDuration]);
  const onHandleIncreaseDuration = useCallback(() => {
    let newDuration = Math.ceil(updateData?.driverHireDuration + 1);
    if (newDuration === 1) newDuration = LEAST_DURATION;
    setUpdateData((prevUpdateData) => ({
      ...prevUpdateData,
      driverHireDuration: newDuration > MAX_DURATION ? MAX_DURATION : newDuration,
    }));
  }, [updateData?.driverHireDuration]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    return (
      <Stack sx={{ flexDirection: "column" }}>
        <Stack style={{ ...customBoxStyles, alignItems: "center" }}>
          <Text16Weight400>Driver hired (hours)</Text16Weight400>
          <QuantityControlSection
            currentQuantity={updateData?.driverHireDuration}
            onHandleDecreaseItem={onHandleDecreaseDuration}
            onHandleIncreaseItem={onHandleIncreaseDuration}
          />
        </Stack>
        {data?.voucher ? (
          <AddingItem
            name={data?.voucher?.title}
            _id={data?.voucher?._id}
            updateDataProperty="driverHireVouchers"
          />
        ) : null}
        <Stack sx={{ flexDirection: "column", gap: 18, marginTop: 24 }}>
          <Text16Weight700>View the type of work the driver will do</Text16Weight700>
          {data?.services?.map(({ _id, title }, index) => (
            <Stack
              key={`${_id}_${index}`}
              sx={{ justifyContent: "space-between", alignItems: "center", minHeight: 40 }}
            >
              <Text16Weight400>{title}</Text16Weight400>
              <Checkbox
                checked={updateData?.driverHireServices?.includes(_id)}
                onChange={() => onHandleChange(_id)}
                inputProps={{ "aria-label": "primary checkbox" }}
                color="primary"
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  }, [isLoading, data, onHandleChange, updateData?.driverHireDuration]);
  return (
    <CommonBox p={{ xs: 3, md: 5 }} sx={{ gap: 16 }}>
      <Stack sx={{ gap: 16, alignItems: "center" }}>
        <Text18>Hire the Driver</Text18>
        {isFetching ? <CircularProgress color="primary" /> : null}
      </Stack>
      {content}
    </CommonBox>
  );
};

const PromotionsAndDiscountCodes = () => {
  const { data, isFetching } = useGetPriceDetails();
  const { renderModal, setOpenModal } = useModal({});
  const [updateData, setUpdateData] = useAtom(updateDataAtom);

  const listAvailableDiscount = useMemo(() => {
    let list = [
      ...(data?.originalBooking?.discounts || [])?.filter((item) => !item?.code && !!item?._id),
      ...(data?.updatedBooking?.discounts || [])?.filter((item) => !!item.code && !!item?._id),
      ...(data?.updatedBooking?.alternativeAutomaticDiscounts || []),
    ].sort((a) => (a?.code ? 1 : -1));

    const uniqueIds = new Set();
    list = list.filter((item) => {
      if (!uniqueIds.has(item._id)) {
        uniqueIds.add(item._id);
        return true;
      }
      return false;
    });

    list = list.map((item) => {
      return { ...item, isSelected: updateData?.overrideDiscounts?.includes(item?._id) };
    });
    return list;
  }, [data, updateData?.overrideDiscounts]);
  const alreadyHadDiscountCodeApplied = useMemo(
    () => listAvailableDiscount?.some(({ code }) => !!code),
    [listAvailableDiscount]
  );

  const onHandleChangeAutoDiscount = useCallback((id) => {
    setUpdateData((prevUpdateData) => ({
      ...prevUpdateData,
      overrideDiscounts: prevUpdateData?.overrideDiscounts?.includes(id)
        ? prevUpdateData?.overrideDiscounts?.filter((item) => item !== id)
        : [...prevUpdateData?.overrideDiscounts, id],
    }));
  }, []);

  const content = useMemo(() => {
    if (isFetching) {
      return (
        <Box sx={{ display: "grid", placeContent: "center", mb: 2 }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    return listAvailableDiscount?.map(({ _id, title, code, details, isSelected }, index) =>
      code ? (
        <Button
          key={`${details}_${index}`}
          style={{ padding: 0 }}
          onClick={() =>
            setOpenModal({
              modalType: MODAL_TYPES.ADD_DISCOUNT_CODE_MODAL,
            })
          }
        >
          <Stack sx={{ ...customBoxStyles, alignItems: "center" }}>
            <Stack sx={{ flexDirection: "column", alignItems: "flex-start", gap: 8 }}>
              <Text16Weight400>{code || "--"}</Text16Weight400>
              {details ? (
                <Text14Weight400 style={{ color: COLOR_LIST.LAVENDER_MIST }}>
                  {details?.trim()}
                </Text14Weight400>
              ) : null}
            </Stack>
            <KeyboardArrowRightIcon style={{ color: COLOR_LIST.LAVENDER_MIST }} />
          </Stack>
        </Button>
      ) : (
        <Stack key={`${details}_${index}`} sx={{ ...customBoxStyles, alignItems: "center" }}>
          <Stack sx={{ flexDirection: "column", alignItems: "flex-start", gap: 8 }}>
            <Text16Weight400>{title || code || "--"}</Text16Weight400>
            {details ? (
              <Text14Weight400 style={{ color: COLOR_LIST.LAVENDER_MIST }}>
                {details?.trim()}
              </Text14Weight400>
            ) : null}
          </Stack>
          <Checkbox
            checked={isSelected}
            onChange={() => onHandleChangeAutoDiscount(_id)}
            inputProps={{ "aria-label": "primary checkbox" }}
            color="primary"
          />
        </Stack>
      )
    );
  }, [isFetching, listAvailableDiscount, onHandleChangeAutoDiscount]);

  return (
    <>
      <CommonBox p={{ xs: 3, md: 5 }} sx={{ gap: 16 }}>
        <Text18>Promotions & discount codes</Text18>
        <Stack sx={{ flexDirection: "column" }}>
          {content}
          {!alreadyHadDiscountCodeApplied ? (
            <Button
              style={{ padding: 0 }}
              onClick={() => setOpenModal({ modalType: MODAL_TYPES.ADD_DISCOUNT_CODE_MODAL })}
            >
              <Stack sx={customBoxStyles} direction="row" alignItems="center">
                <Text16Weight400>Add discount code</Text16Weight400>
                <KeyboardArrowRightIcon style={{ color: COLOR_LIST.LAVENDER_MIST }} />
              </Stack>
            </Button>
          ) : null}
        </Stack>
      </CommonBox>
      {renderModal}
    </>
  );
};

const PriceDetailsBox = styled(Box)({
  display: "grid",
  gridTemplateColumns: "auto max-content",
  rowGap: 16,
  alignItems: "center",
});

const PriceDetails = () => {
  const [, setUpdateData] = useAtom(updateDataAtom);
  const { data, isLoading } = useGetPriceDetails();
  const { renderDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const onToggleDamageWaiver = useCallback(
    (event) => {
      const willCheck = event.target.checked;
      if (!willCheck) {
        handleOpenDialog({
          title: "You have deselected the damage waiver",
          content: `Therefore you could be charged up to ${formatCurrency(
            data?.trailer?.price ?? 0
          )} in the event the trailer is damaged.`,
          approveText: "I agree",
          disapproveText: "Go back",
          handleConfirm: () => {
            setUpdateData((prevUpdateData) => ({ ...prevUpdateData, isDamageWaiver: willCheck }));
            handleCloseDialog();
          },
        });
      } else {
        setUpdateData((prevUpdateData) => ({ ...prevUpdateData, isDamageWaiver: willCheck }));
      }
    },
    [data?.trailer?.price]
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ display: "grid", placeContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (data?.updatedBooking) {
      return (
        <>
          <PriceDetailsBox>
            {[
              {
                title: "Trailer cost",
                value: formatCurrency(
                  data?.updatedBooking?.originalCharges?.trailerCharge?.cost ?? 0
                ),
              },
              {
                title: "Additional Items",
                value: formatCurrency(
                  data?.updatedBooking?.originalCharges?.upsellCharges?.reduce(
                    (sum, item) => sum + item.cost,
                    0
                  ) ?? 0
                ),
              },
              {
                title: "Consumables",
                value: formatCurrency(
                  data?.updatedBooking?.originalCharges?.comsumableCharges?.reduce(
                    (sum, item) => sum + item.cost,
                    0
                  ) ?? 0
                ),
              },
              {
                title: "Electric brake controller",
                value: formatCurrency(
                  data?.updatedBooking?.originalCharges?.premiumCharges?.reduce(
                    (sum, item) => sum + item.cost,
                    0
                  ) ?? 0
                ),
              },
              {
                title: "Hire The Driver",
                value: formatCurrency(
                  data?.updatedBooking?.originalCharges?.hireTheDriverCharges?.reduce(
                    (sum, item) => sum + item.serviceCost,
                    0
                  ) ?? 0
                ),
              },
              {
                title: "Vouchers",
                value: formatCurrency(
                  data?.updatedBooking?.originalCharges?.hireTheDriverCharges?.reduce(
                    (sum, item) => sum + item.voucherCost,
                    0
                  ) ?? 0
                ),
              },
              {
                title: "Damage waiver",
                value: formatCurrency(data?.updatedBooking?.originalCharges?.damageWaiver ?? 0),
                isDamageWaiverRow: true,
              },
              {
                title: "GST included",
                value: formatCurrency(data?.updatedBooking?.charges?.gts ?? 0),
              },
              {
                title: "Transaction Fee",
                value: formatCurrency(data?.updatedBooking?.charges?.transitionFee ?? 0),
              },
              {
                title: "Promotions & discounts",
                value: `-${formatCurrency(data?.updatedBooking?.charges?.discount || 0)}`,
              },
            ].map(({ title, value, isDamageWaiverRow }) => {
              if (isDamageWaiverRow) {
                return (
                  <React.Fragment key={title}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data?.updatedBooking?.originalCharges?.isDamageWaiver}
                            onChange={onToggleDamageWaiver}
                            color="primary"
                          />
                        }
                        label={<Text16Weight400>{title}</Text16Weight400>}
                      />
                    </FormGroup>
                    <Text16Weight400 style={{ textAlign: "right" }}>{value}</Text16Weight400>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={title}>
                  <Text16Weight400>{title}</Text16Weight400>
                  <Text16Weight400 style={{ textAlign: "right" }}>{value}</Text16Weight400>
                </React.Fragment>
              );
            })}
          </PriceDetailsBox>
          <Stack sx={{ ...customBoxStyles, alignItems: "center" }}>
            <Text18Weight600>Total</Text18Weight600>
            <Text16Weight600 style={{ color: COLOR_LIST.PRIMARY }}>
              {formatCurrency(data?.updatedBooking?.charges?.total ?? 0)}
            </Text16Weight600>
          </Stack>
        </>
      );
    }
  }, [data, isLoading]);
  return (
    <Stack sx={{ flexDirection: "column", gap: 16 }}>
      <Text18 style={{ marginBlock: "16px" }}>Price details</Text18>
      {content}
      {renderDialog}
    </Stack>
  );
};

const AddingItem = ({ name, _id, updateDataProperty, upsellItemId, maxQuantity = 20, count }) => {
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const currentQuantity = useMemo(
    () =>
      upsellItemId
        ? updateData?.[updateDataProperty]?.find((item) => item.upsellItemId === upsellItemId)
            ?.quantity ?? 0
        : updateData?.[updateDataProperty]?.find((item) => item.id === _id)?.quantity ?? 0,
    [updateData]
  );
  const onHandleDecreaseItem = useCallback(() => {
    const selectedItemIndex = upsellItemId
      ? updateData?.[updateDataProperty]?.findIndex((item) => item.upsellItemId === upsellItemId)
      : updateData?.[updateDataProperty]?.findIndex((item) => item.id === _id);
    if (selectedItemIndex >= 0 && currentQuantity > 0) {
      const clonedUpSellItems = cloneDeep(updateData?.[updateDataProperty] ?? []);
      if (currentQuantity === 1) {
        // Remove from the list
        clonedUpSellItems.splice(selectedItemIndex, 1);
      } else {
        // Decrease the quantity to 1
        clonedUpSellItems.splice(selectedItemIndex, 1, {
          ...clonedUpSellItems[selectedItemIndex],
          quantity: currentQuantity - 1,
        });
      }
      setUpdateData((prevUpdateData) => ({
        ...prevUpdateData,
        [updateDataProperty]: clonedUpSellItems,
      }));
    }
  }, [updateData, currentQuantity]);

  const onHandleIncreaseItem = useCallback(() => {
    const selectedItemIndex = upsellItemId
      ? updateData?.[updateDataProperty]?.findIndex((item) => item.upsellItemId === upsellItemId)
      : updateData?.[updateDataProperty]?.findIndex((item) => item.id === _id);
    if (
      // Make sure max quantity is still available to increase quantity
      currentQuantity < (count ?? maxQuantity)
    ) {
      const clonedUpSellItems = cloneDeep(updateData?.[updateDataProperty] ?? []);
      if (selectedItemIndex >= 0) {
        // Already exists on the list
        clonedUpSellItems.splice(selectedItemIndex, 1, {
          ...clonedUpSellItems[selectedItemIndex],
          quantity: currentQuantity + 1,
        });
      } else {
        // Add new to the list
        clonedUpSellItems.push({
          id: _id,
          quantity: 1,
          ...(upsellItemId ? { upsellItemId } : {}),
        });
      }
      setUpdateData((prevUpdateData) => ({
        ...prevUpdateData,
        [updateDataProperty]: clonedUpSellItems,
      }));
    }
  }, [updateData, maxQuantity, currentQuantity]);

  const hasAvailableQuantity = useMemo(() => !isNaN(count) && typeof count === "number", [count]);
  const availableQuantity = useMemo(() => count - currentQuantity, [count, currentQuantity]);

  return (
    <Stack style={{ ...customBoxStyles, alignItems: "center" }}>
      <Text16Weight400>{name}</Text16Weight400>
      <Stack sx={{ gap: 16, alignItems: "center" }}>
        {hasAvailableQuantity ? (
          <Text14Weight400
            style={{
              color: hasAvailableQuantity < 0 ? COLOR_LIST.DANGER : COLOR_LIST.LAVENDER_MIST,
            }}
          >
            {availableQuantity} available
          </Text14Weight400>
        ) : null}
        <QuantityControlSection
          currentQuantity={currentQuantity}
          onHandleDecreaseItem={onHandleDecreaseItem}
          onHandleIncreaseItem={onHandleIncreaseItem}
        />
      </Stack>
    </Stack>
  );
};

const QuantityControlSection = ({
  currentQuantity,
  onHandleDecreaseItem,
  onHandleIncreaseItem,
}) => {
  return (
    <Stack alignItems="center" direction="row" gap={1}>
      <IconButton sx={{ p: 0 }} onClick={onHandleDecreaseItem}>
        <RemoveCircleOutlineIcon
          style={{
            color: "#fff",
            fontSize: 42,
            opacity: currentQuantity === 0 ? "0.5" : "1",
          }}
        />
      </IconButton>
      <Text16Weight600 style={{ color: COLOR_LIST.PRIMARY }}>{currentQuantity}</Text16Weight600>
      <IconButton sx={{ p: 0 }} onClick={onHandleIncreaseItem}>
        <AddCircleOutlineIcon
          style={{
            color: "#fff",
            fontSize: 42,
          }}
        />
      </IconButton>
    </Stack>
  );
};

const PremiumItem = ({ name, _id }) => {
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const hasSelected = useMemo(
    () => !!updateData?.premiumItems?.length > 0,
    [updateData?.premiumItems]
  );

  const handleChange = (event) => {
    if (event.target.checked) {
      setUpdateData((prevUpdateData) => ({
        ...prevUpdateData,
        premiumItems: [{ id: _id, quantity: 1 }],
      }));
    } else {
      setUpdateData((prevUpdateData) => ({ ...prevUpdateData, premiumItems: [] }));
    }
  };

  return (
    <Stack style={{ ...customBoxStyles, alignItems: "center", height: ROW_HEIGHT }}>
      <Text16Weight400>{name}</Text16Weight400>
      <Checkbox
        checked={hasSelected}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
        color="primary"
      />
    </Stack>
  );
};

const STATUS_CODE_SEARCH_ADDRESS = {
  OK: "OK",
  ZERO_RESULTS: "ZERO_RESULTS",
};

const ChangeAddressComponent = ({ onHandleClose }) => {
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const { renderDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const { mutate } = useCheckDistance();
  const {
    ready,
    setValue: setSuggestionValue,
    suggestions: { status, data, loading },
  } = usePlacesAutocomplete({
    defaultValue: updateData?.customerAddress?.streetAddress ?? "",
  });

  const onChangeSearch = debounce((value) => {
    setSuggestionValue(value);
  }, 500);

  const handleConfirm = (address) => {
    setUpdateData((prevUpdateData) => ({
      ...prevUpdateData,
      customerAddress: {
        streetAddress: address.fullAddress,
        coordinates: [address.lat, address.lng],
      },
    }));
    handleCloseDialog();
    onHandleClose();
  };

  const onHandleChange = async (e, value) => {
    const address = {
      fullAddress: "",
      line1: "",
      city: "",
      state: "",
      stateShortVersion: "",
      postal_code: "",
      country: "",
      countryShortVersion: "",
      lat: 0,
      lng: 0,
    };
    if (!value) {
      return;
    }
    const results = await getGeocode({ address: value.description });
    const place = await getDetails({
      placeId: results[0].place_id,
      fields: ["name", "place_id", "geometry", "address_components"],
    });
    const parsedAddress = placeToAddress(place);
    address.line1 = `${parsedAddress?.streetNumber?.long_name ?? ""} ${
      parsedAddress?.streetName?.long_name ?? ""
    }`.trim();
    address.fullAddress = value?.description ?? "";
    address.city = parsedAddress?.city?.long_name ?? "";
    address.state = parsedAddress?.state?.long_name ?? "";
    address.stateShortVersion = parsedAddress?.state?.short_name ?? "";
    address.country = parsedAddress?.country?.short_name ?? "";
    address.countryShortVersion = parsedAddress?.country?.short_name ?? "";
    address.postal_code = parsedAddress?.postal_code?.long_name ?? "";
    address.lat = place?.geometry?.location?.lat();
    address.lng = place?.geometry?.location?.lng();

    mutate(
      { lat: address.lat, lng: address.lng },
      {
        onSuccess: (response) => {
          if (response?.data?.isOutside) {
            handleOpenDialog({
              title: "Are you sure you want to make this change?",
              content: "This address is outside of the franchisee’s normal delivery range.",
              disapproveText: "Cancel",
              approveText: "Confirm",
              handleConfirm: () => handleConfirm(address),
              showCloseIcon: true,
            });
          }
        },
      }
    );
  };

  return (
    <Container maxWidth="sm">
      <Stack alignItems="center" sx={{ flexDirection: "column", gap: 16 }}>
        <Stack sx={{ justifyContent: "flex-end", width: "100%" }}>
          <IconButton onClick={onHandleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <TextField
          label={"Search Address"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {loading ? <CircularProgress color="primary" size={20} /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
          onChange={(e) => onChangeSearch(e.target.value)}
          disabled={!ready}
          variant="outlined"
        />
        {!loading && status === STATUS_CODE_SEARCH_ADDRESS.ZERO_RESULTS ? (
          <Stack sx={{ minHeight: 60 }} alignItems="center" justifyContent="center">
            <Text18Weight600>No Results</Text18Weight600>
          </Stack>
        ) : null}
        {status === STATUS_CODE_SEARCH_ADDRESS.OK && data?.length > 0 ? (
          <StyledList component="nav" style={{ width: "100%" }}>
            {data.map((item, index) => (
              <Fragment key={`${item.description}_${index}`}>
                <ListItem button disablePadding onClick={() => onHandleChange(undefined, item)}>
                  <ListItemText
                    primary={
                      <Text16Weight600 as="span">
                        {item?.structured_formatting?.main_text}
                      </Text16Weight600>
                    }
                    secondary={
                      <Text16Weight600 as="span" style={{ color: COLOR_LIST.LAVENDER_MIST }}>
                        {item?.structured_formatting?.secondary_text}
                      </Text16Weight600>
                    }
                  />
                </ListItem>
                {index !== data.length - 1 && <Divider sx={{ borderColor: COLOR_LIST.COMET }} />}
              </Fragment>
            ))}
          </StyledList>
        ) : null}
      </Stack>
      {renderDialog}
    </Container>
  );
};

const handleOpenDialogForAvailableTrailers = (
  { startDate, endDate },
  response,
  handleOpenDialog,
  handleChangeDatesOrTime
) => {
  if (response === DIALOG_AVAILABLE_TYPE.NO_TRAILERS_AVAILABLE) {
    return handleOpenDialog({
      title: (
        <Text18Weight700 style={{ textAlign: "center" }}>
          There are no trailers available
        </Text18Weight700>
      ),
      content: (
        <Text16Weight400>
          There are no trailers available on the selected date/time. Please try another date/time.
        </Text16Weight400>
      ),
      hideApproveButton: true,
      disapproveText: "Cancel",
      showCloseIcon: true,
    });
  }
  if (response === DIALOG_AVAILABLE_TYPE.SELECT_ANOTHER_TRAILER) {
    return handleOpenDialog({
      title: (
        <Text18Weight700 style={{ textAlign: "center" }}>
          Are you sure you want to make this change?
        </Text18Weight700>
      ),
      content: (
        <Text16Weight400 style={{ textAlign: "center", lineHeight: 1.5 }}>
          The selected trailer is not available on these dates/times. you will need to select
          another trailer.
        </Text16Weight400>
      ),
      disapproveText: "Cancel",
      approveText: "Confirm",
      handleConfirm: () => {
        handleChangeDatesOrTime({ startDate, endDate });
      },
      showCloseIcon: true,
    });
  }
  handleChangeDatesOrTime({ startDate, endDate });
};

const ChangeDatesComponent = ({ onHandleClose }) => {
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const { renderDialog, handleOpenDialog } = useDialog();
  const {
    mutate: mutateCheckIfAvailableTrailersWithinSpecificTime,
    isLoading: isLoadingCheckIfAvailableTrailersWithinSpecificTime,
  } = useCheckIfAvailableTrailersWithinSpecificTime();

  const handleChangeDates = ({ startDate, endDate }) => {
    setUpdateData((prevUpdateData) => ({
      ...prevUpdateData,
      startDate,
      endDate,
    }));
    onHandleClose();
  };

  const onSubmit = (values) => {
    const startDate = values.dates[0].getTime();
    const endDate = values.dates[1].getTime();
    mutateCheckIfAvailableTrailersWithinSpecificTime(
      { startDate, endDate },
      {
        onSuccess: (response) => {
          handleOpenDialogForAvailableTrailers(
            { startDate, endDate },
            response,
            handleOpenDialog,
            handleChangeDates
          );
        },
      }
    );
  };
  const schema = Yup.object().shape({
    dates: Yup.array().of(Yup.date()).length(2).required("Required").test({
      name: "minimumBookingHour",
      skipAbsent: true,
      test: minimumBookingHourTest,
    }),
  });

  const formik = useFormik({
    initialValues: {
      dates: [moment(updateData.startDate).toDate(), moment(updateData.endDate).toDate()],
    },
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  const { setValues, handleSubmit, errors, values } = formik;

  const onHandleChangeDates = (item) => {
    const startDate = moment(
      `${moment(item?.selection?.startDate).format("DD-MM-YYYY")} ${moment(values.dates[0]).format(
        "hh:mm a"
      )}`,
      "DD-MM-YYYY hh:mm a"
    ).toDate();
    const endDate = moment(
      `${moment(item?.selection?.endDate).format("DD-MM-YYYY")} ${moment(values.dates[1]).format(
        "hh:mm a"
      )}`,
      "DD-MM-YYYY hh:mm a"
    ).toDate();
    setValues({ dates: [startDate, endDate] });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormikProvider value={formik}>
        <Container maxWidth="sm">
          <Stack alignItems="center" sx={{ flexDirection: "column", gap: 16, mt: 4 }}>
            <DateRangePicker
              onChange={onHandleChangeDates}
              months={2}
              ranges={[
                {
                  startDate: values.dates[0] ?? new Date(),
                  endDate: values.dates[1] ?? new Date(),
                  key: "selection",
                },
              ]}
              direction="vertical"
              weekStartsOn={1}
            />
            {errors.dates ? (
              <Text18 style={{ color: COLOR_LIST.DANGER }}>{errors.dates}</Text18>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              style={{ width: 332 }}
              onClick={handleSubmit}
              disabled={isLoadingCheckIfAvailableTrailersWithinSpecificTime}
            >
              {isLoadingCheckIfAvailableTrailersWithinSpecificTime ? (
                <CircularProgress color="primary" />
              ) : (
                "Done"
              )}
            </Button>
            <Button
              variant="text"
              color="primary"
              style={{ width: 332 }}
              onClick={onHandleClose}
              disabled={isLoadingCheckIfAvailableTrailersWithinSpecificTime}
            >
              Cancel
            </Button>
          </Stack>
        </Container>
        {renderDialog}
      </FormikProvider>
    </MuiPickersUtilsProvider>
  );
};

const ChangeTimesComponent = ({ onHandleClose }) => {
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const { renderDialog, handleOpenDialog } = useDialog();
  const {
    mutate: mutateCheckIfAvailableTrailersWithinSpecificTime,
    isLoading: isLoadingCheckIfAvailableTrailersWithinSpecificTime,
  } = useCheckIfAvailableTrailersWithinSpecificTime();

  const schema = Yup.object().shape({
    times: Yup.array().of(Yup.date()).length(2).required("Required").test({
      name: "minimumBookingHour",
      skipAbsent: true,
      test: minimumBookingHourTest,
    }),
  });

  const handleChangeTimes = ({ startDate, endDate }) => {
    setUpdateData((prevUpdateData) => ({ ...prevUpdateData, startDate, endDate }));
    onHandleClose();
  };

  const onSubmit = ({ times }) => {
    const startDate = times[0].getTime();
    const endDate = times[1].getTime();
    mutateCheckIfAvailableTrailersWithinSpecificTime(
      { startDate, endDate },
      {
        onSuccess: (response) => {
          handleOpenDialogForAvailableTrailers(
            { startDate, endDate },
            response,
            handleOpenDialog,
            handleChangeTimes
          );
        },
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      times: [moment(updateData.startDate).toDate(), moment(updateData.endDate).toDate()],
    },
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  const { setFieldValue, handleSubmit, errors, values } = formik;

  const onHandleChange = (date, index) => {
    setFieldValue(`times[${index}]`, date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormikProvider value={formik}>
        <Container maxWidth="sm">
          <Stack alignItems="center" sx={{ flexDirection: "column", gap: 32, mt: 6 }}>
            <Stack sx={{ gap: 32, alignItems: "center" }}>
              <Stack sx={{ flexDirection: "column" }}>
                <Text16>Drop off</Text16>
                <TimePicker
                  onChange={(date) => onHandleChange(date, 0 /* startDate */)}
                  value={values.times[0]}
                  variant="inline"
                  minutesStep={10}
                />
              </Stack>
              <Stack sx={{ flexDirection: "column" }}>
                <Text16>Pick up</Text16>
                <TimePicker
                  onChange={(date) => onHandleChange(date, 1 /* startDate */)}
                  value={values.times[1]}
                  variant="inline"
                  minutesStep={10}
                />
              </Stack>
            </Stack>
            {errors.times ? (
              <Text18 style={{ color: COLOR_LIST.DANGER }}>{errors.times}</Text18>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              style={{ width: 332 }}
              onClick={handleSubmit}
              disabled={isLoadingCheckIfAvailableTrailersWithinSpecificTime}
            >
              {isLoadingCheckIfAvailableTrailersWithinSpecificTime ? (
                <CircularProgress color="primary" />
              ) : (
                "Done"
              )}
            </Button>
            <Button
              variant="text"
              color="primary"
              style={{ width: 332 }}
              onClick={onHandleClose}
              disabled={isLoadingCheckIfAvailableTrailersWithinSpecificTime}
            >
              Cancel
            </Button>
          </Stack>
        </Container>
        {renderDialog}
      </FormikProvider>
    </MuiPickersUtilsProvider>
  );
};

const UpdateOrderContainer = () => {
  useRedirectToHomeIfUnauthorized([
    USER_ROLES.ADMIN_OWNER,
    USER_ROLES.MANAGER,
    USER_ROLES.ADMIN_DEVELOPER,
  ]);
  return (
    <Provider>
      <UpdateOrder />
    </Provider>
  );
};

export default UpdateOrderContainer;
