import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../utils";
import { useStyles } from "./OverViewHireTheDriver.styles";

// const DUMMY_DATA = [
//   {
//     label: "T2Y HTD Income",
//     value: 500000,
//     isCurrency: true,
//   },
//   {
//     label: "Gross HTD Income",
//     value: 600000,
//     isCurrency: true,
//   },
//   {
//     label: "Franchisee HTD Earnings",
//     value: 100000,
//     isCurrency: true,
//   },
//   {
//     label: "Total HTD Hires",
//     value: 36554,
//   },
//   {
//     label: "AVG HTD Income Per Hire",
//     value: 500000,
//     isCurrency: true,
//   },
//   {
//     label: "AVG HTD Hours Per Hire",
//     value: 3.45,
//   },
// ];

export function OverViewHireTheDriver() {
  const classes = useStyles();
  const { htdSummaryData } = useSelector((state) => state.analytic);
  // const selectedFilterText = selectedFilterValues.map((item) => item.value).join(", ");
  const [overviewMainData, setOverviewMainData] = useState([]);

  useEffect(() => {
    if (htdSummaryData) {
      const data = htdSummaryData.filter((item) => item.label !== "HTD Ratio");
      setOverviewMainData(data);
    }
  }, [htdSummaryData]);

  return (
    <Grid container spacing={7}>
      {overviewMainData?.map(({ label, value }) => {
        const isCurrency = ["income", "earnings"].some((item) =>
          label.toLowerCase().includes(item)
        );
        return (
          <Grid item key={label} lg={4} md={6} sm={12} xs={12}>
            <Box component="div" className={classes.overviewItem}>
              <Typography component="h3">{label}</Typography>
              {/* <Typography component="small">{selectedFilterText || "All"}</Typography> */}
              <Typography component="h1">{isCurrency ? formatAmount(value) : value}</Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
