import * as React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useStyles } from "./Card.styles";

const headerSizeCollection = {
  small: 21,
  medium: 24,
};

const lineHeightCollection = {
  small: "25px",
  medium: "29px",
};

export function Card({ headerSize = "medium", header, navigateTo, customStyles, children }) {
  const classes = useStyles();
  // const { selectedFilterValues } = useSelector((state) => state.analytic);
  // const selectedFilterText = selectedFilterValues.map(item => item.value).join(', ');

  const headerStyles = {
    fontSize: headerSizeCollection[headerSize],
    lineHeight: lineHeightCollection[headerSize],
  };

  return (
    <Box component="div" className={`${classes.analyticsCard} ${customStyles}`}>
      <Box component="header" style={headerStyles}>
        {header}

        {navigateTo ? (
          <Button component={Link} to={navigateTo}>
            View
          </Button>
        ) : null}
      </Box>

      {/* <Box component="small">{selectedFilterText || 'All'}</Box> */}
      <Box component="div">{children}</Box>
    </Box>
  );
}
