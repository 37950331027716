import React from "react";
import { Field } from "formik";
import { FieldFormAdd } from "../FieldFormAdd";
import { Box } from "@material-ui/core";
import { Text14 } from "../Common/Styled/TypographyStyled";

const Input = (props) => {
  const { name, label, maxLength, showCountCharacters, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <Box sx={{ position: "relative" }}>
            <FieldFormAdd
              fullWidth
              {...field}
              label={label}
              inputProps={{
                onBlur: form.handleBlur,
                maxLength,
              }}
              variant="outlined"
              autoComplete="off"
              error={Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              {...rest}
            />
            {showCountCharacters ? (
              <Box sx={{ position: "absolute", bottom: 4, right: 10 }}>
                <Text14 color="primary">{meta?.value?.trim().length}</Text14>
              </Box>
            ) : null}
          </Box>
        );
      }}
    </Field>
  );
};

export default Input;
