import { makeStyles } from "@material-ui/core";
const useCssListItem = makeStyles((theme) => ({
  root: {},
  link: {
    color: "rgba(222, 231, 250, 1)",
    "&.active > div": {
      color: theme.palette.primary.main,
    },
  },
  gutters: {
    padding: "10px 20px",
  },
  button: {
    "&:focus": {
      backgroundColor: "rgba(32, 32, 32, 1)",
    },
    "&:hover": {
      backgroundColor: "rgba(32, 32, 32, 1)",
      color: theme.palette.primary.main,
    },
  },
}));
export default useCssListItem
