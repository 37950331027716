import React, { useMemo } from "react";
import WrapHeaderSubPage from "../../../../components/WrapHeaderSubPage";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@material-ui/core";
import StickyNavigationSection from "../../../../components/StickyNavigationSection";
import { useStyles } from "./styled";
import { useFormik, FormikProvider, useFormikContext, useField } from "formik";
import * as yup from "yup";
import FormikControl, { CONTROL_TYPE } from "../../../../components/Formik/FormikControl";
import {
  Search as SearchIcon,
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
} from "@material-ui/icons";
import { FieldFormAdd } from "../../../../components/FieldFormAdd";
import useModal, { MODAL_TYPES } from "../../../../hooks/useModal";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { getCRUD_AllowByRoles } from "../../../../helpers/common";
import { USER_ROLES } from "../../../../constants/common";
import { Stack } from "../../../../components/Common/Styled/CommonStyled";
import useLoadGoogleMapAPI from "../../../../hooks/useLoadGoogleMapAPI";
import {
  useCreateEditNotification,
  useGetNotificationDetails,
} from "../../../../hooks/Notifications/useNotifications";
import { Text14Weight400 } from "../../../../components/Common/Styled/TypographyStyled";

const CommonBox = styled(Box)({
  borderRadius: 8,
  border: "1px solid rgba(226, 233, 251, 0.298)",
  background: "rgba(251, 252, 254, 0.00)",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
});

const StickyBox = styled(Box)({
  position: "sticky",
  top: "74px",
  height: "max-content",
});

const StyledChip = styled(Chip)({
  backgroundColor: "rgba(54, 54, 54, 0.70)",
  color: "#fff",
  "&.MuiChip-deletable:focus": {
    backgroundColor: "rgba(54, 54, 54, 0.70)",
  },
  "& .MuiSvgIcon-root.MuiChip-deleteIcon": {
    color: "#000",
  },
});

const MAX_CHARS = {
  TITLE: 65,
  MESSAGE_CONTENT: 113,
};

export const NOTIFICATION_TYPES = [
  {
    value: "pushNotification",
    label: "Push Notification",
    subText: "Customers will see this title in the push notification",
  },
  {
    value: "sms",
    label: "SMS (Currently unavailable)",
    subText: "Customers will see this title in the SMS notification",
    isDisabled: true,
  },
];

export const RECIPIENTS_TYPE = [
  {
    value: "allCustomers",
    label: "All customers",
  },
  {
    value: "customersOfSpecificRegionOrArea",
    label: "Customers of specific region or area",
    placeHolder: "Search areas/regions",
  },
  {
    value: "customers",
    label: "Specific customers",
    placeHolder: "Search customers",
  },
];

const validationSchema = yup.object({
  methodType: yup.string().oneOf(NOTIFICATION_TYPES.map((item) => item.value)),
  title: yup
    .string()
    .required("Required")
    .transform((value) => value.trim())
    .test({
      name: "max-content-title",
      skipAbsent: true,
      test: (value, ctx) => {
        if (value.length > MAX_CHARS.TITLE) {
          return ctx.createError({
            message: `Title must be less than ${MAX_CHARS.TITLE} characters`,
          });
        }
        return true;
      },
    }),
  messageContent: yup
    .string()
    .required("Required")
    .transform((value) => value.trim())
    .test({
      name: "max-content-message-content",
      skipAbsent: true,
      test: (value, ctx) => {
        if (value.length > MAX_CHARS.MESSAGE_CONTENT) {
          return ctx.createError({
            message: `Message content must be less than ${MAX_CHARS.MESSAGE_CONTENT} characters`,
          });
        }
        return true;
      },
    }),
  recipients: yup.object({
    type: yup.string().oneOf(RECIPIENTS_TYPE.map(({ value }) => value)),
    items: yup
      .array()
      .of(
        yup.object({
          id: yup.string(),
          label: yup.string(),
        })
      )
      .when("type", {
        is: (type) =>
          [RECIPIENTS_TYPE?.[1]?.value, RECIPIENTS_TYPE?.[2]?.value].some((item) => item === type),
        then: (schema) => schema.min(1, "Required").required("Required"),
      }),
  }),
  sendDateTime: yup.number(),
});

const MyDatePicker = ({ name, minDate }) => {
  const [, { value }, { setValue }] = useField(name);
  const dateValue = moment(value).toDate();
  const onHandleChange = (date) => {
    setValue(date?.getTime());
  };
  return (
    <DatePicker
      style={{ flex: 1 }}
      onChange={onHandleChange}
      value={dateValue}
      variant="inline"
      format="dd MMM yyyy"
      minDate={minDate}
    />
  );
};

const MyTimePicker = ({ name }) => {
  const [, { value }, { setValue }] = useField(name);
  const timeValue = moment(value).toDate();
  const onHandleChange = (date) => {
    setValue(date?.getTime());
  };
  return (
    <TimePicker
      style={{ flex: 1 }}
      onChange={onHandleChange}
      value={timeValue}
      variant="inline"
      minutesStep={5}
    />
  );
};

const TypeContent = () => {
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const classes = useStyles();
  const handleChangeMethod = (event) => {
    setFieldValue("methodType", event.target.value);
  };

  const textField = useMemo(() => {
    const type =
      values?.methodType === NOTIFICATION_TYPES[0].value
        ? NOTIFICATION_TYPES[0]
        : NOTIFICATION_TYPES[1];
    return (
      <>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            marginTop: 24,
          }}
        >
          <Typography variant="h6" component="h6" className={classes.text16Weight700}>
            Title
          </Typography>
          <FormikControl
            fullWidth
            control={CONTROL_TYPE.INPUT}
            name="title"
            variant="outlined"
            style={{ marginBottom: 0 }}
            autoComplete="off"
            showCountCharacters
          />
          <Typography variant="h6" component="h6" className={classes.text16Weight500Gray}>
            {type.subText}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            marginTop: 24,
          }}
        >
          <Typography variant="h6" component="h6" className={classes.text16Weight700}>
            Message content
          </Typography>
          <FormikControl
            fullWidth
            control={CONTROL_TYPE.INPUT}
            name="messageContent"
            variant="outlined"
            style={{ marginBottom: 0 }}
            autoComplete="off"
            multiline
            maxRows={3}
            showCountCharacters
          />
        </Box>
      </>
    );
  }, [values?.methodType]);

  return (
    <CommonBox>
      <Box className={classes.contentOfBox}>
        <Box sx={{ display: "flex", gap: 10 }}>
          <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
            Type
          </Typography>
        </Box>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" className={classes.text16Weight700}>
          Method
        </Typography>
        <Box sx={{ mt: 2 }}>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            aria-label="method"
            name="method"
            onChange={handleChangeMethod}
          >
            {NOTIFICATION_TYPES.map(({ label, value, isDisabled }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio
                    color="primary"
                    checked={value === values?.methodType}
                    disabled={isDisabled}
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </Box>
        {textField}
      </Box>
    </CommonBox>
  );
};

const Recipients = () => {
  useLoadGoogleMapAPI();
  const { renderModal, setOpenModal } = useModal({});
  const formik = useFormikContext();
  const { values, setFieldValue, errors } = formik;
  const classes = useStyles();
  const items = values?.recipients?.items || [];
  const handleChangeRecipientsType = (event) => {
    setFieldValue("recipients.type", event.target.value);
    setFieldValue("recipients.items", []);
  };

  const selectedRecipientsType = useMemo(
    () => RECIPIENTS_TYPE.find(({ value }) => value === values?.recipients?.type) || "",
    [values?.recipients?.type]
  );

  const showSearchBar = useMemo(
    () =>
      [RECIPIENTS_TYPE[1].value, RECIPIENTS_TYPE[2].value].some(
        (value) => value === values?.recipients?.type
      ),
    [values?.recipients?.type]
  );
  const onHandleSearch = () => {
    setOpenModal({
      modalType: MODAL_TYPES.SELECT_RECIPIENTS_MODAL,
      data: {
        type: selectedRecipientsType?.value,
        formikName: "recipients.items",
        initialValues: values?.recipients?.items,
      },
    });
  };
  const onHandleRemoveItem = (id) => {
    const clonedItems = [...items];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === id);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
      setFieldValue("recipients.items", clonedItems);
    }
  };

  return (
    <CommonBox>
      {renderModal}
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Recipients
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" className={classes.text16Weight700}>
          Applies to
        </Typography>
        <Box sx={{ my: 2 }}>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            aria-label="applies-to"
            name="applies-to"
            onChange={handleChangeRecipientsType}
          >
            {RECIPIENTS_TYPE.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio color="primary" checked={value === values?.recipients?.type} />}
                label={label}
              />
            ))}
          </RadioGroup>
        </Box>
        {showSearchBar ? (
          <Box sx={{ display: "grid", gridTemplateColumns: "auto 114px", gap: 16 }}>
            <FieldFormAdd
              fullWidth
              autoComplete="off"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value=""
              placeholder={selectedRecipientsType?.placeHolder || ""}
              style={{ marginBottom: 0 }}
              onClick={onHandleSearch}
            />
            <Button variant="outlined" color="primary" onClick={onHandleSearch}>
              Browse
            </Button>
          </Box>
        ) : null}
        {items?.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 8, mt: 2, alignItems: "center" }}>
            <Text14Weight400 color="primary">{items?.length} selected</Text14Weight400>
            <Button variant="text" onClick={() => setFieldValue("recipients.items", [])}>
              Clear all
            </Button>
            {/* {items?.map(({ id, label }) => (
            <StyledChip
              clickable={false}
              key={id}
              label={label}
              onDelete={() => onHandleRemoveItem(id)}
              deleteIcon={<CloseIcon style={{ color: "#fff" }} />}
            />
          ))} */}
          </Box>
        ) : null}
        {showSearchBar && errors?.recipients?.items ? (
          <Typography color="error">{errors?.recipients?.items}</Typography>
        ) : null}
      </Box>
    </CommonBox>
  );
};

const SendDateTime = () => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CommonBox>
        <Box className={classes.contentOfBox}>
          <Box sx={{ display: "flex", gap: 10 }}>
            <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
              Send date/time
            </Typography>
          </Box>
        </Box>
        <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
        <Box className={classes.contentOfBox}>
          {/* Start Date */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Box
              sx={{
                width: 270,
                display: "flex",
                alignItems: "center",
                p: 2,
                border: "1px solid white",
                borderRadius: 8,
                gap: 16,
              }}
            >
              <CalendarTodayIcon />
              <MyDatePicker name="sendDateTime" />
            </Box>
            <Box
              sx={{
                width: 270,
                display: "flex",
                alignItems: "center",
                p: 2,
                border: "1px solid white",
                borderRadius: 8,
                gap: 16,
              }}
            >
              <AccessTimeIcon />
              <MyTimePicker name="sendDateTime" />
            </Box>
          </Box>
        </Box>
      </CommonBox>
    </MuiPickersUtilsProvider>
  );
};

const Summary = ({ notificationDetails }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const FORMAT_DATE = "DD MMM yyyy h:mm A";

  const recipientDetails = useMemo(() => {
    let content = null;
    const selectedType = RECIPIENTS_TYPE.find(({ value }) => value === values?.recipients?.type);
    if (selectedType) {
      content = <li className={classes.text16Weight500Gray}>{selectedType.label}</li>;
    }
    return content;
  }, [values?.recipients?.type]);
  return (
    <CommonBox sx={{ height: "max-content" }}>
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Summary
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Box sx={{ display: "grid", gap: 32 }}>
          <Stack sx={{ flexDirection: "column", gap: 8 }}>
            <Typography style={{ fontSize: 16 }}>Status</Typography>
            <Typography className={classes.text16Weight500Gray}>Scheduled</Typography>
          </Stack>
          <Stack sx={{ flexDirection: "column", gap: 8 }}>
            {<Typography style={{ fontSize: 16 }}>Type</Typography>}
            <Typography className={classes.text16Weight500Gray}>Push Notification</Typography>
          </Stack>
          <Stack sx={{ flexDirection: "column", gap: 8 }}>
            {values?.title ? (
              <Typography style={{ fontSize: 16 }}>{values?.title}</Typography>
            ) : null}
            {values?.messageContent ? (
              <Typography
                className={classes.text16Weight500Gray}
                style={{ whiteSpace: "pre-line" }}
              >
                {values?.messageContent}
              </Typography>
            ) : null}
          </Stack>
          <Box>
            <Typography style={{ fontSize: 16 }}>Recipients</Typography>
            <ul>{recipientDetails}</ul>
          </Box>
          <Box>
            <Typography style={{ fontSize: 16 }}>Date to be sent</Typography>
            <ul>
              <li className={classes.text16Weight500Gray}>
                {moment(values?.sendDateTime).format(FORMAT_DATE)}
              </li>
            </ul>
          </Box>
          <Stack sx={{ flexDirection: "column", gap: 8 }}>
            <Typography style={{ fontSize: 16 }}>Open</Typography>
            <Typography className={classes.text16Weight500Gray}>
              {notificationDetails?.opens ?? "--"}
            </Typography>
          </Stack>
          <Stack sx={{ flexDirection: "column", gap: 8 }}>
            <Typography style={{ fontSize: 16 }}>Clicks</Typography>
            <Typography className={classes.text16Weight500Gray}>
              {notificationDetails?.clicks ?? "--"}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </CommonBox>
  );
};

const CreateNewOrEditNotification = () => {
  const { isAllowToCreate, isAllowToUpdate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const params = useParams();
  const { mutate, isLoading } = useCreateEditNotification(params?.id);
  const classes = useStyles();
  // const { mutate, isLoading } = useCreateNewOrEditNotification(params?.id);
  const history = useHistory();
  const { data: notificationDetails, isLoading: isLoadingNotificationDetails } =
    useGetNotificationDetails(params?.id);

  const isEditing = !!params?.id;

  const initialValues = useMemo(() => {
    let recipientsType = RECIPIENTS_TYPE[0].value;
    let recipientsItems = [];
    if (notificationDetails?.recipients?.length > 0) {
      recipientsType = RECIPIENTS_TYPE[2].value;
      recipientsItems = notificationDetails?.recipients?.map((item) => ({
        id: item?._id,
        label: `${item?.firstName} ${item?.lastName}`,
      }));
    } else if (notificationDetails?.recipientRegions?.length > 0) {
      recipientsType = RECIPIENTS_TYPE[1].value;
      recipientsItems = notificationDetails?.recipientRegions?.map((item) => ({
        id: item?._id,
        label: item?.location,
      }));
    }
    let sendDateTime = new Date().getTime();
    if (notificationDetails?.triggerTime && moment(notificationDetails?.triggerTime).isValid()) {
      sendDateTime = moment(notificationDetails?.triggerTime).toDate().getTime();
    }
    return {
      methodType: notificationDetails?.notificationType ?? NOTIFICATION_TYPES[0].value,
      title: notificationDetails?.title ?? "",
      messageContent: notificationDetails?.content ?? "",
      recipients: {
        type: recipientsType,
        items: recipientsItems,
      },
      sendDateTime,
    };
  }, [notificationDetails]);

  const onSubmit = (data) => {
    const isSendToAllCustomers = data?.recipients?.type === RECIPIENTS_TYPE[0].value;
    const payload = {
      notificationType: data?.methodType,
      title: data?.title,
      content: data?.messageContent,
      triggerTime: data?.sendDateTime,
      isSendToAllCustomers,
      recipientIds: isSendToAllCustomers
        ? []
        : data?.recipients?.type === RECIPIENTS_TYPE[2].value
        ? data?.recipients?.items?.map((item) => item.id) ?? []
        : [],
      recipientRegions: isSendToAllCustomers
        ? []
        : data?.recipients?.type === RECIPIENTS_TYPE[1].value
        ? data?.recipients?.items?.map((item) => item.id) ?? []
        : [],
    };
    mutate(payload, { onSuccess: () => history.push("/notifications") });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const { handleSubmit } = formik;

  if (isEditing && isLoadingNotificationDetails) {
    return (
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Container maxWidth="lg">
        <Box className={classes.container}>
          <Box sx={{ width: "100%" }}>
            <WrapHeaderSubPage
              nameHeader={`${isEditing ? "Edit" : "Create"} Notification`}
              style={{ marginBottom: "50px" }}
            />
            <Breadcrumbs />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 330px",
                gap: 32,
              }}
            >
              <Box sx={{ display: "grid", gap: 16 }}>
                <TypeContent />
                <Recipients />
                <SendDateTime />
              </Box>
              <StickyBox>
                <Summary notificationDetails={notificationDetails} />
              </StickyBox>
            </Box>
            <StickyNavigationSection isBottom>
              <Box className={classes.bottomSection}>
                <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
                  Discard
                </Button>
                {isAllowToCreate && isAllowToUpdate ? (
                  <Button
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {isLoading ? <CircularProgress color="primary" /> : "Save"}
                  </Button>
                ) : null}
              </Box>
            </StickyNavigationSection>
          </Box>
        </Box>
      </Container>
    </FormikProvider>
  );
};

export default CreateNewOrEditNotification;
