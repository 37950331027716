import React, { useState, useEffect } from "react";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { useHistory, useParams } from "react-router-dom";
import { ButtonCircle } from "../../components/ButtomCircle";
import { Typography, Button } from "@material-ui/core";
import ItemAddTrailer from "../../components/ItemAddTrailer";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import { USER_ROLES } from "../../constants/common";
import { useRedirectToHomeIfUnauthorized } from "../../hooks/useAuth";

const AddTrailerLicensee = () => {
  useRedirectToHomeIfUnauthorized([USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER]);
  let history = useHistory();
  const { licenseeId, firstname } = useParams();
  const [trailerAddList, setTrailerAddList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [showLoading, hideLoading] = useLoading();
  useEffect(() => {
    axios.get("/v1/admin/get-all-trailers").then((response) => {
      return setTrailerList(
        response.data.dataObj.map((trailer) => {
          return { quantity: 0, trailer: trailer };
        })
      );
    });
  }, []);

  const handleChangeQuantity = (id, method, type) => {
    if (type === "trailer") {
      const temp = [...trailerList];
      for (let item of temp) {
        if (item.trailer._id === id) {
          if (method === "subtract") {
            item.quantity = item.quantity - 1;
          } else {
            item.quantity = item.quantity + 1;
          }
        }
      }
      let temp2 = [];
      for (let item of temp.filter((trailer) => trailer.quantity > 0)) {
        for (let i = 0; i < item.quantity; i++) {
          temp2.push({
            trailerId: item.trailer._id,
            registration: null,
            expiryRegistration: null,
          });
        }
      }
      setTrailerAddList(temp2);
      setTrailerList(temp);
    }
  };

  const handleAddOrUpdateTrailer = async () => {
    if (trailerAddList.length < 1) {
      history.goBack();
      return;
    }
    showLoading();
    try {
      const formData = new FormData();
      for (let item of trailerAddList) {
        formData.append("trailers[]", JSON.stringify(item));
      }
      const res = await axios.put("v1/admin/licensee/addtrailer/" + licenseeId, formData);
      toast.success(res?.data?.message);

      hideLoading();
      history.goBack();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <LayoutAddOrEdit titleDetail={`Add new trailer to ${firstname}'s' list`}>
      <div className="btn-exit">
        <ButtonCircle variant="contained" onClick={() => history.goBack()}>
          Exit
        </ButtonCircle>
      </div>
      <div className="form-main">
        <Typography variant="h3">Select trailers</Typography>
        <div className="list-add-trailer">
          {trailerList.map((item, index) => (
            <ItemAddTrailer
              key={index}
              name={item.trailer.name}
              urlImage={item.trailer.photos[0]?.url}
              quantity={item.quantity}
              handleSubQuantity={() =>
                handleChangeQuantity(item.trailer._id, "subtract", "trailer")
              }
              handleAddQuantity={() => handleChangeQuantity(item.trailer._id, "add", "trailer")}
            />
          ))}
        </div>
        <div className="footter-process">
          <div className="btn-add-trailer">
            <Button variant="contained" color="primary" onClick={() => handleAddOrUpdateTrailer()}>
              Add Trailer
            </Button>
          </div>
        </div>
      </div>
    </LayoutAddOrEdit>
  );
};

export default AddTrailerLicensee;
