import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatDistance } from "../../utils/formatDistance";

export function AvgDropOffDistancePage() {
  const classes = useStyles();
  const { avgDropOffDistanceByFranchisee } = useSelector(
    (state) => state.analytic
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent =
      "Average Drop-off Distance by Franchisee";
    if (avgDropOffDistanceByFranchisee?.items) {
      const dataTransformed = [...avgDropOffDistanceByFranchisee?.items].map(
        (item) => ({
          franchisee: item?.licensee?.fullname ?? "--",
          distance: item?.distance ? formatDistance(item.distance) : "--",
        })
      );
      setData(dataTransformed);
    }
  }, [avgDropOffDistanceByFranchisee]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Distance (KM)",
      key: "distance",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Average Drop-off Distance by Franchisee">
        <SortableTable
          rows={data}
          columns={columns}
          gridTemplateColumns="80% 20%"
        />
      </Card>
    </Box>
  );
}
