import React, { useState, useRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core";
import { TextField, FormControl, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  h2: {
    marginBottom: "25px",
  },
  focused: {},
  notchedOutline: {},
  input: {
    padding: "13px",
    paddingLeft: "43px",
    fontSize: "15px",
    color: "#000000",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  icon: {
    height: 32,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    marginLeft: "16px",
  },
  search_icon: {
    fontSize: "32px",
    color: "#535f78",
    paddingRight: "8px",
  },
  form_search: {
    height: "43px",
    width: "342px",
    marginRight: "16px",
    borderRadius: "4px",
  },
  form_filter: {
    width: "140px",
    margin: "0 8px",
  },
  icon_filter: {
    fontSize: "18px",
  },
  btnFiler: {
    width: "90px",
    padding: "5px 12px",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  dialog: {
    position: "absolute",
    top: 17,
  },
  addBtn: {
    width: "173px",
    textTransform: "none",
  },
  dialog__footer: {
    display: "flex",
    justifyContent: "space-between",
    height: "74px",
    padding: "0 28px",
  },
  but__done: {
    width: "97px",
  },
  dialogFilter: {
    width: "517px",
  },
  dialogContent: {
    padding: "0",
  },
  iconClose: {
    cursor: "pointer",
  },
}));
const SearchCustomer = ({ textSearch, onSubmit }) => {
  const classes = useStyles();
  const ref = useRef();
  const [fieldSearch, setFieldSearch] = useState("");

  function handleChangeFieldSreach(e) {
    setFieldSearch(e.target.value);
  }

  function submitSearchCustomer(e) {
    onSubmit(e, fieldSearch);
    ref.current.blur();
  }
  return (
    <form onSubmit={(e) => submitSearchCustomer(e)}>
      <FormControl variant="outlined" className={classes.form_search}>
        <InputAdornment position="start" className={classes.icon}>
          <SearchIcon className={classes.search_icon} />
        </InputAdornment>
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder={`Search ${textSearch}`}
          onChange={handleChangeFieldSreach}
          value={fieldSearch}
          autoComplete="off"
          inputRef={ref}
          InputProps={{
            classes: {
              root: classes.rootInput,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
            },
          }}
        />
      </FormControl>
    </form>
  );
};

export default SearchCustomer;
