import * as React from "react";
import { useSelector } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";

export function OutOfServiceTrailersGraph() {
  const { url } = useRouteMatch();
  const { outOfServiceTrailersData } = useSelector((state) => state.analytic);

  return (
    <Card
      headerSize="small"
      header="Out of service trailers %"
      navigateTo={`${url}/out-of-service-trailers`}
    >
      <PieGraph percentage={outOfServiceTrailersData?.percentage}/>
    </Card>
  );
}
