const NAVLINK_LIST = [
  {
    id: "featured-menu",
    name: "Featured",
    link: "/",
  },
  {
    id: "customer-menu",
    name: "Customer",
    link: "/customers",
  },
  {
    id: "licensee",
    name: "Franchisee",
    link: "/licensee",
  },
  {
    id: "rental-item-menu",
    name: "Rental Item",
    link: "/rental",
    options: [
      {
        name: "Trailers",
        link: "/rental/trailer",
      },
      {
        name: "Upsell Items",
        link: "/rental/upsell",
      },
      {
        name: "Hire the Driver",
        link: "/rental/hire-the-driver",
      },
    ],
  },
  {
    id: "employee-menu",
    name: "Admin Users",
    link: "/admin",
  },
  {
    id: "transaction-history",
    name: "Transaction History",
    link: "/transaction-history",
  },
  {
    id: "analytics",
    name: "Analytics",
    link: "/analytics",
    options: [
      {
        name: "Overview",
        link: "/analytics/overview",
      },
      {
        name: "Hire the Driver",
        link: "/analytics/hire-the-driver",
      },
      {
        name: "Reports",
        link: "/analytics/reports",
      },
    ],
  },
  {
    id: "discounts-and-promotions",
    name: "Discounts & Promotions",
    link: "/discounts-and-promotions",
  },
  {
    id: "notifications",
    name: "Notifications",
    link: "/notifications",
  },
];

export default NAVLINK_LIST;
