import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import SortableTable from '../../../../../components/SortableTable/SortableTable';
import { Card } from "../../../components";
import { formatPercentage } from "../../../utils";

export function LateHires() {
  const { url } = useRouteMatch();
  const { lateHiresData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (lateHiresData?.items) {
      const dataTransformed = [...lateHiresData?.items].sort((a, b) => b.grossIncome - a.grossIncome).map((item) => ({
        franchisee: `${item?.licenseeUser?.firstname} ${item?.licenseeUser?.lastname}`,
        perFranchisee: formatPercentage((item.lateHiresPercentage || 0)/100),
        numOfLateHires: item.lateHires,
        avgHoursLate: item.avgHoursLate.toFixed(2),
      }));
      setData(dataTransformed);
    }
  }, [lateHiresData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Per Franchisee",
      key: "perFranchisee",
      sortable: true,
    },
    {
      name: "Number of late hires",
      key: "numOfLateHires",
      sortable: true,
    },
    {
      name: "AVG hours Extended",
      key: "avgHoursLate",
      sortable: true,
    },
  ];

  return (
    <Card header="Late Hires" navigateTo={`${url}/late-hires`}>
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="30% 25% 20% 20%"
        rowsHeight={255}
      />
    </Card>
  );
}
