import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import axiosInstance from "../../helpers/axios";
import { generatePropTextField } from "../../helpers/formik-help";
import { useLoading } from "../../hooks/useLoading";
import placeholerImage from "../../img/hireTheDriverDefaultImg.svg";
import { getCRUD_AllowByRoles } from "../../helpers/common";
import { USER_ROLES } from "../../constants/common";

const useStyles = makeStyles(() => ({
  title: {},
  mainContainer: {
    marginBottom: 50,
  },
  imgContainer: {
    height: 400,
    marginRight: "auto",
    border: "1px dashed #53E83A",
    boxSizing: "border-box",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      maxWidth: 290,
      objectFit: "contain",
    },
  },
  infoContainer: {
    padding: "0 36px 0 92px",
  },
  orderTotalContainer: {
    padding: "0 21px",
    display: "flex",
    justifyContent: "space-between",
  },
  orderTotalTitle: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#969CA8",
  },
  orderTotalPrice: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    textAlign: "right",
    color: "#FFFFFF",
  },
  rowItem: {
    height: 73,
    borderTop: "1px solid #24272d",
  },
}));

function GlobalHireRatePage() {
  const { isAllowToUpdate } = getCRUD_AllowByRoles({
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  const [initialValues, setInitialValues] = useState({
    perHour: "",
    trailers: [],
  });

  async function getInitialValues() {
    try {
      showLoading();
      const globalHireRateResponse = await axiosInstance.get("/v1/admin/get-hire-the-driver");
      const globalHireRateSettings = globalHireRateResponse?.data?.hireTheDriverSettings;
      if (globalHireRateSettings) {
        const { perHour, trailerTypes } = globalHireRateSettings;
        setInitialValues({
          perHour,
          trailers: trailerTypes,
        });
      } else {
        toast.error("No data returned!");
      }
      hideLoading();
    } catch (error) {
      hideLoading();
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    getInitialValues();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      perHour: yup.string().required("Please enter global rate!"),
    }),
    onSubmit: async (values) => {
      showLoading();
      try {
        const updatedData = {
          perHour: values.perHour,
          trailers: values.trailers
            .filter((item) => item.isHireDriverSuitable)
            .map((item) => item._id),
        };
        await axiosInstance.put("/v1/admin/update-hire-the-driver/", updatedData).then((res) => {
          hideLoading();
          toast.success(res?.data?.message);
        });
      } catch (err) {
        hideLoading();
        toast.error(err?.response?.data?.message);
      }
    },
    enableReinitialize: true,
  });

  const onChangeChecked = (id) => {
    const newTrailers = initialValues.trailers.map((item) =>
      item._id === id ? { ...item, isHireDriverSuitable: !item.isHireDriverSuitable } : item
    );
    setInitialValues({
      ...initialValues,
      trailers: newTrailers,
    });
  };

  return (
    <Box>
      <Typography variant="h2" component="h2" className={classes.title}>
        Hire the Driver
      </Typography>
      <Breadcrumbs />
      <Box className={classes.mainContainer}>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridContainer}>
            <Box className={classes.imgContainer}>
              <img src={placeholerImage} alt="hire-the-driver-img" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} className={classes.trailersContainer}>
            <form onSubmit={formik.handleSubmit}>
              <FieldFormAdd
                value={formik.values.perHour}
                {...generatePropTextField("perHour", formik)}
                id="globalRate"
                name="perHour"
                label="Hire the Driver global rate (AUD / hour)"
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                error={Boolean(formik.errors.perHour)}
                helperText={formik.errors.perHour}
                fullWidth
              />
              <Typography variant="h5" style={{ margin: "20px 0" }}>
                Suitable trailers for Hire the Driver
              </Typography>
              <Box style={{ maxHeight: 500, overflow: "auto", marginBottom: 20 }}>
                {formik.values.trailers.map((item) => (
                  <Box
                    key={item._id}
                    className={classes.rowItem}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>{item.name || "Unamed"}</Box>
                    <Checkbox
                      name="checkedA"
                      checked={item.isHireDriverSuitable}
                      onChange={() => onChangeChecked(item._id)}
                      color="primary"
                    />
                  </Box>
                ))}
              </Box>
              {isAllowToUpdate ? (
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Save update
                </Button>
              ) : null}
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default GlobalHireRatePage;
