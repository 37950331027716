import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  overviewMain: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(390px, 1fr))",
    gap: 60,
  },
  overviewItem: {
    height: "100%",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1C1C1C",
    borderRadius: 16,
    position: "relative",
    "& > button": {
      width: 71,
      height: 27,
      border: "1px solid #39A8DF",
      borderRadius: 4,
      position: "absolute",
      top: 25,
      right: 16,
      "& > .MuiButton-label": {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "17px",
        color: "#39A8DF",
      },
    },
    "& > h3": {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "29px",
      color: "#fff",
    },
    "& > small": {
      marginTop: 8,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19px",
      color: "#44D62C",
    },
    "& > h1": {
      marginTop: 16,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 32,
      lineHeight: "38px",
      color: "#fff",
    },
  },
}));
