import React, { useState } from "react";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import { useFormik } from "formik";
import * as yup from "yup";
import { generatePropTextField } from "../../helpers/formik-help";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useLoading } from "../../hooks/useLoading";
import { ButtonCircle } from "../../components/ButtomCircle";

const ForgotPassword = () => {
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  const [openAlert, setOpenAlert] = useState(false);
  let history = useHistory();

  const onSendSubmit = async (email) => {
    showLoading();
    try {
      await axios.post("v1/admin/reset-password", { email });
      setOpenAlert(true);
      hideLoading();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup.string().required(" ").email(" "),
    }),
    onSubmit: (value) => {
      onSendSubmit(value.email);
    },
  });
  const handleClickExit = () => {
    history.push("/signin");
  };

  return (
    <LayoutAddOrEdit titleDetail="">
      {openAlert && (
        <div className="alert-forgot">
          <div className="text-16">
            Instructions to reset your password have been sent to you. Please Check your email.
          </div>
        </div>
      )}
      <div className=" btn-exit-login">
        <ButtonCircle variant="contained" onClick={handleClickExit}>
          Exit
        </ButtonCircle>
      </div>
      <div className="layout-invite">
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h3" className="title-send-invite">
            Forgot Password
          </Typography>
          <div className="form-signIn">
            <FieldFormAdd
              fullWidth
              {...generatePropTextField("email", formik)}
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              inputProps={{
                maxLength: 250,
              }}
            />
            <Button type="submit" className={classes.btnSubmit} color="primary" variant="contained">
              Send Reset Instructions
            </Button>
          </div>
        </form>
      </div>
    </LayoutAddOrEdit>
  );
};

export default ForgotPassword;
const useStyles = makeStyles(() => ({
  btnSubmit: {
    width: "303px",
    height: 51,
    marginTop: 40,
  },
}));
