import { Badge, Box, IconButton } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import axiosInstance from "../../helpers/axios";
import { renderNavigateNotification } from "../../helpers/navigateNotification";
import { useLoading } from "../../hooks/useLoading";
import { ReactComponent as LoadMore } from "../../img/LoadMore.svg";
import { ReactComponent as Notification } from "../../img/Notification.svg";
import { ReactComponent as Unread } from "../../img/UnRead.svg";
import useStyleMenu from "./style";

const ButtonNotifications = () => {
  const cssMenu = useStyleMenu();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const [unReadNotifyCount, setUnReadNotifyCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const [linkGet, setLinkGet] = useState(`/v1/admin/notification?pageSize=10&pageIndex=${page}`);
  function getAll() {
    axiosInstance.get(linkGet).then((response) => {
      if (response) {
        setUnReadNotifyCount(response?.data?.dataObj?.unReadNotifyCount);
        return setNotificationList([...notificationList, ...response?.data?.dataObj?.data]);
      }
    });
  }
  useEffect(() => {
    setLinkGet(`/v1/admin/notification?pageSize=10&pageIndex=${page}`);
  }, [page]);
  useEffect(() => {
    var check = setInterval(myTimer, 540000);
    function myTimer() {
      const timeNow = moment().format("HH:mm");
      const sub = moment(timeNow, "HH:mm").diff(moment("08:00", "HH:mm"), "minutes");
      if (sub > 0 && sub < 10) {
        getAll();
      }
    }
    return () => {
      clearInterval(check);
    };
  }, []);
  useEffect(() => {
    getAll();
  }, [linkGet]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const readNotification = (itemSelected) => {
    history.push(renderNavigateNotification(itemSelected));
    setAnchorEl(null);
    const isNotificationHadSeen = notificationList.find(
      (item) => item._id === itemSelected._id
    )?.isSeen;
    if (isNotificationHadSeen === false) {
      handleSubmitReadNotification(itemSelected._id);
    }
    return;
  };

  const handleSubmitReadNotification = async (id) => {
    showLoading();
    try {
      await axiosInstance.put("/v1/admin/notification/" + id).then(() => {
        hideLoading();
        const temp = [...notificationList];
        temp.find((item) => item._id === id).isSeen = true;
        setNotificationList(temp);
        setUnReadNotifyCount(unReadNotifyCount - 1);
      });
    } catch (error) {
      hideLoading();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        disableRipple
        style={{
          background: "none",
          padding: 0,
        }}
        onClick={handleClick}
      >
        <Badge
          badgeContent={unReadNotifyCount}
          color="primary"
          classes={{
            root: cssMenu.bagde_Root,
            colorPrimary: cssMenu.badge_Notification,
          }}
        >
          <Notification />
        </Badge>
      </IconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PopoverClasses={{
          paper: cssMenu.paper,
        }}
      >
        <MenuItem
          style={{
            fontSize: 18,
            opacity: 1,
            padding: "12px 16px",
            boxShadow: "inset 0px -1px 0px #DEE7FA",
            lineHeight: "21.48px",
          }}
          disabled
        >
          Notifications
        </MenuItem>
        {notificationList.map((item, index) => (
          <MenuItem
            key={index}
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "16.71px",
              padding: "16px",
              boxShadow: "inset 0px -1px 0px #DEE7FA",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              handleClose();
              readNotification(item);
            }}
          >
            <Box
              width="100%"
              height="18px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                width="100%"
                maxWidth="70%"
                textAlign="left"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                marginRight="10px"
              >
                {item.title}
              </Box>
              <Box margin="0 10px">{moment(item.time).format("DD/MM/YY")}</Box>
              <Box marginLeft="10px" width="18px">
                {!item.isSeen && <Unread />}
              </Box>
            </Box>
          </MenuItem>
        ))}
        <MenuItem
          style={{
            fontSize: 18,
            opacity: 1,
            padding: "13px 16px 11px",
            lineHeight: "21.48px",
          }}
          onClick={() => setPage(page + 1)}
        >
          <LoadMore style={{ margin: "auto" }} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default ButtonNotifications;
