import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  analyticsContent: {
    display: "flex",
    flexDirection: "column",
  },
  analyticsSinglePageContent: {
    marginTop: 64,
    marginBottom: 78,
    display: "flex",
    flexDirection: "column",
  },
  analyticsOverview: {
    marginTop: 64,
    display: "flex",
    flexDirection: "column",
  },
  twoColumnLayout: {
    marginTop: 64,
    display: "grid",
    gridTemplateColumns: "1fr minmax(400px, auto)",
    gap: 64,
  },
  section: {
    marginTop: 36,
  },
}));
