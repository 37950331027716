import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  table: ({ gridTemplateColumns }) => ({
    marginLeft: -16,
    marginRight: -16,
    "& > .MuiBox-root:nth-of-type(1)": {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 8,
      display: "grid",
      gridTemplateColumns: gridTemplateColumns,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "14px",
      color: "#DEE7FA",
      "& > span": {
        display: "flex",
        alignItems: "baseline",
        "& > svg": {
          marginLeft: 15,
          cursor: "pointer",
        },
      },
    },
    "& > .MuiBox-root:nth-of-type(2) > span": {
      padding: 16,
      display: "grid",
      gridTemplateColumns: gridTemplateColumns,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19px",
      color: "#DEE7FA",
      boxShadow: "0px -1px 0px #535F78, 0px 1px 0px #535F78",
      "&:not(:nth-of-type(1))": {
        boxShadow: "0px 1px 0px #535F78",
      },
    },
  }),
  tableRows: {
    overflow: "auto",
  },
}));
