import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import analyticSlice from './slices/analyticSlice';

const rootReducer = combineReducers({
  analytic: analyticSlice,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
