import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Box, Typography, ListItem, InputBase } from "@material-ui/core";
import { ReactComponent as Delete } from "../../img/delete.svg";
import { useDialogServiceChecklistStyle } from "./style";
import ButtonActionHeader from "../ButtonActionHeader";
import { atom, useAtom, useSetAtom } from "jotai";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { splitAtom } from "jotai/utils";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {
  useGetTrailerServiceChecklist,
  useSaveTrailerServiceChecklist,
} from "../../hooks/Trailers/useTrailers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatNameToLowercaseAndReplaceSpace } from "../../helpers/common";
import DialogChangeStatus from "../DialogChangeStatus";
import _ from "lodash";

const initialData = [
  // {
  //   key: string,
  //   name: string
  // }
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const checklistAtom = atom(initialData);
const checklistDisableAtom = atom(initialData);

const handleDeleteItemChecklistAtom = atom(null, (get, set, index) => {
  set(
    checklistAtom,
    get(checklistAtom).filter((_, i) => i !== index)
  );
});

const checklistItemAtomsAtom = splitAtom(checklistAtom);
const checklistDisableItemAtomsAtom = splitAtom(checklistDisableAtom);

const isChecklistValidAtom = atom((get) => {
  return get(checklistAtom).every((item) => !!item.name);
});

const Item = ({ checklistItemAtom, index, disabled }) => {
  const handleDeleteItemChecklist = useSetAtom(handleDeleteItemChecklistAtom);
  const [checklistItem, setChecklistItem] = useAtom(checklistItemAtom);
  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });

  const closePromptDialog = () => {
    setOpen(false);
  };

  const onHandleOpenPromptDelete = (index) => {
    setOpen(true);
    setDataDialog({
      isActive: false,
      header: "Are you sure you want to delete this checklist item?",
      subtitle: undefined,
      submitTitle: "Delete checklist item",
      onActive: () => {
        handleDeleteItemChecklist(index);
        setOpen(false);
      },
    });
  };
  const cssDialog = useDialogServiceChecklistStyle();
  if (disabled) {
    return (
      <ListItem
        classes={{
          root: `${cssDialog.itemContent} ${cssDialog.borderTop} ${cssDialog.borderBottom}`,
        }}
      >
        <InputBase
          value={checklistItem?.name ?? ""}
          inputProps={{ "aria-label": "naked", maxLength: 35 }}
          style={{ width: "100%" }}
          disabled
        />
      </ListItem>
    );
  }
  return (
    <>
      <Draggable key={index} draggableId={`${checklistItem?.key}_${index}`} index={index}>
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.draggableProps}>
            <ListItem
              classes={{
                root: `${cssDialog.itemContent} ${cssDialog.borderTop} ${cssDialog.borderBottom}`,
              }}
              style={
                !checklistItem?.name
                  ? {
                      borderBottomColor: "red",
                      background:
                        "linear-gradient(0deg, rgba(255, 0, 0, 0.20) 0%, rgba(255, 0, 0, 0.20) 100%), #202020",
                      boxShadow: "0px 1px 0px 0px #535F78, 0px -1px 0px 0px #535F78",
                    }
                  : {}
              }
            >
              <InputBase
                value={checklistItem?.name ?? ""}
                inputProps={{ "aria-label": "naked", maxLength: 35 }}
                onChange={(e) =>
                  setChecklistItem({
                    key: formatNameToLowercaseAndReplaceSpace(e.target.value),
                    name: e.target.value,
                  })
                }
                style={{ width: "100%" }}
                // disabled
              />
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  onClick={() => onHandleOpenPromptDelete(index)}
                  sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                >
                  <DeleteIcon style={{ color: !checklistItem?.name ? "red" : "inherit" }} />
                </Box>
                <Box {...provided.dragHandleProps} sx={{ display: "flex", alignItems: "center" }}>
                  <DragIndicatorIcon />
                </Box>
              </Box>
            </ListItem>
          </Box>
        )}
      </Draggable>
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closePromptDialog}
      />
    </>
  );
};

const DISABLE_LIST_KEY = ["isChecked"];

const getChecklistTemp = (data) => {
  if (data && data?.length > 0) {
    return data.reduce(
      (object, item) => {
        if (DISABLE_LIST_KEY.some((i) => i === item?.key)) {
          object.checklistDisableTemp.push(item);
        } else {
          object.checklistTemp.push(item);
        }
        return object;
      },
      {
        checklistTemp: [],
        checklistDisableTemp: [],
      }
    );
  }
  return {
    checklistTemp: [],
    checklistDisableTemp: [],
  };
};

const DialogServiceChecklist = ({ open, handleCloseDialog, header, trailerId }) => {
  const [isFirstSubmit, setIsFirstSubmit] = useState(false);
  const [checklistAtoms] = useAtom(checklistItemAtomsAtom);
  const [checklistDisableAtoms] = useAtom(checklistDisableItemAtomsAtom);
  const [isChecklistValid] = useAtom(isChecklistValidAtom);

  const showError = useMemo(
    () => isFirstSubmit && !isChecklistValid,
    [isFirstSubmit, isChecklistValid]
  );
  const [checklist, setChecklist] = useAtom(checklistAtom);
  const [checklistDisable, setChecklistDisable] = useAtom(checklistDisableAtom);
  const { data, isLoading, isError } = useGetTrailerServiceChecklist({ trailerId });
  const { mutate, isLoading: isLoadingMutate } = useSaveTrailerServiceChecklist({ trailerId });
  const cssDialog = useDialogServiceChecklistStyle();
  const scrollSectionRef = useRef();
  const onClickCloseModal = () => {
    handleCloseDialog();
  };
  useEffect(() => {
    if (data && data?.length > 0) {
      const { checklistTemp, checklistDisableTemp } = getChecklistTemp(data);
      setChecklist(checklistTemp);
      setChecklistDisable(checklistDisableTemp);
    }
  }, [data]);

  const onSubmit = () => {
    if (!isFirstSubmit) {
      setIsFirstSubmit(true);
    }
    if (isChecklistValid) {
      const payload = {
        items: [...checklist, ...checklistDisable],
      };
      mutate(payload, { onSuccess: () => handleCloseDialog() });
    }
  };

  const isDisabled = useMemo(() => {
    const { checklistTemp, checklistDisableTemp } = getChecklistTemp(data);
    return _.isEqual(
      [...checklistTemp, ...checklistDisableTemp],
      [...checklist, ...checklistDisable]
    );
  }, [checklist, checklistDisable, data]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(checklist, result.source.index, result.destination.index);

    setChecklist(items);
  };

  const onHandleAddNew = () => {
    setChecklist([...checklist, ""]);
    if (scrollSectionRef?.current) {
      setTimeout(() => {
        scrollSectionRef.current.scrollTop = scrollSectionRef.current.scrollHeight;
      }, 0);
    }
  };

  return (
    <Dialog open={open}>
      <Box classes={{ root: cssDialog.containerContentDialog }}>
        <Box width="inherit">
          <Box classes={{ root: cssDialog.fixedHeaderDialog }}>
            <Box classes={{ root: cssDialog.iconCloseDialog }} onClick={onClickCloseModal}>
              <Delete />
            </Box>
            <Typography align="center" classes={{ root: cssDialog.headerContentDialog }}>
              {header}
            </Typography>
          </Box>
        </Box>
        {isError ? (
          <Box sx={{ display: "flex", marginTop: 100, justifyContent: "center" }}>
            "Something went wrong!"
          </Box>
        ) : isLoading ? (
          <Box sx={{ display: "flex", marginTop: 100, justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box ref={scrollSectionRef} classes={{ root: cssDialog.scrollContainerContentDialog }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable direction="vertical" droppableId="droppable-service-checklist">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {checklistAtoms?.map((item, index) => (
                        <Item index={index} checklistItemAtom={item} key={item} />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {checklistDisableAtoms?.map((item, index) => (
                <Item disabled index={index} checklistItemAtom={item} key={item} />
              ))}
            </Box>
            <ButtonActionHeader
              width="100%"
              variant="text"
              style={{ marginTop: 8 }}
              onClick={onHandleAddNew}
            >
              Add new item
            </ButtonActionHeader>
            <ButtonActionHeader
              style={{ width: "100%", marginTop: "23px", height: "52px" }}
              onClick={() => onSubmit()}
              disabled={isLoadingMutate || isDisabled}
            >
              Save
            </ButtonActionHeader>
            {showError && (
              <Typography align="center" classes={{ root: cssDialog.errorMessage }}>
                Please delete empty checklist items
              </Typography>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};
export default DialogServiceChecklist;
