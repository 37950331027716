import {
  Box,
  Typography,
  IconButton,
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  styled,
  Button,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { FieldFormAdd } from "../FieldFormAdd";
import debounce from "lodash/debounce";
import { FixedSizeList } from "react-window";
import { RECIPIENTS_TYPE } from "../../pages/notifications/components/CreateNewOrEditNotification.jsx";
import { useGetRecipientsItems } from "../../hooks/Notifications/useNotifications.jsx";
import { Stack } from "../Common/Styled/CommonStyled.jsx";
import { Text14Weight400 } from "../Common/Styled/TypographyStyled.jsx";

const StyledListItem = styled(ListItem)({
  "& .MuiButtonBase-root": {
    marginLeft: 0,
  },
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const MAPPING = {
  [RECIPIENTS_TYPE?.[1].value]: {
    ADD_TEXT: "Select a region/area",
    PLACEHOLDER_TEXT: RECIPIENTS_TYPE?.[1].placeHolder,
  },
  [RECIPIENTS_TYPE?.[2].value]: {
    ADD_TEXT: "Select customers",
    PLACEHOLDER_TEXT: RECIPIENTS_TYPE?.[2].placeHolder,
  },
};

// const QUANTITY_LIMITATION = 20;

const SelectRecipientsModal = ({ onCloseModal, data }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const { type, formikName, initialValues } = data;
  const TEXT = MAPPING[type];
  const [items, setItems] = useState(initialValues || []);
  const [search, setSearch] = useState("");
  const { data: recipients, isLoading } = useGetRecipientsItems(search, type ?? "");
  // const isMaxQuantity = useMemo(() => items?.length >= QUANTITY_LIMITATION, [items]);

  const handleToggle = (toggleItem) => {
    const clonedItems = [...items];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === toggleItem.value);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
    } else {
      clonedItems.push({
        id: toggleItem.value,
        label: toggleItem.label,
        ...(toggleItem.type && { type: toggleItem.type }),
      });
    }
    setItems(clonedItems);
  };

  const onHandleSave = () => {
    setFieldValue(formikName, items);
    onCloseModal();
  };

  const onSelectAll = () => {
    setItems((prevItems) => {
      if (isSelectedAllChecked) {
        // Deselect all displayed items
        return prevItems.filter(
          (item) => !displayItems.some((displayItem) => displayItem._id === item.id)
        );
      } else {
        // Create a Map to ensure uniqueness by id
        const itemsMap = new Map(prevItems.map((item) => [item.id, item]));

        // Add or update items from displayItems
        displayItems.forEach((item) => {
          itemsMap.set(item._id, {
            id: item._id,
            label: item.name,
            type: item.type,
          });
        });

        // Convert Map values back to array
        return Array.from(itemsMap.values());
      }
    });
  };

  const displayItems = useMemo(
    () =>
      recipients?.filter(({ name }) => name.toLocaleLowerCase().includes(search.toLowerCase())) ||
      [],
    [search, recipients]
  );

  const onChangeSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  let content = <Typography style={{ textAlign: "center" }}>No results</Typography>;

  if (isLoading && search) {
    content = (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const isSelectedAllChecked = useMemo(() => {
    if (!displayItems || displayItems.length === 0) return false;
    return displayItems.every((displayItem) => items.some((item) => item.id === displayItem._id));
  }, [items, displayItems]);

  if (recipients && recipients?.length > 0) {
    content = (
      <>
        <StyledListItem role={undefined} button onClick={onSelectAll}>
          <ListItemIcon style={{ margin: 0 }}>
            <Checkbox
              color="primary"
              edge="start"
              checked={isSelectedAllChecked}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "select-all" }}
            />
          </ListItemIcon>
          <ListItemText
            id="select-all"
            primary={
              <Stack sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Text14Weight400>
                  {isSelectedAllChecked ? "Deselect all" : "Select all"}
                </Text14Weight400>
                <Text14Weight400 color="primary">({items?.length}) selected</Text14Weight400>
              </Stack>
            }
          />
        </StyledListItem>
        <FixedSizeList
          height={485}
          width={"100%"}
          itemCount={displayItems?.length || 0}
          itemSize={81}
          itemData={displayItems}
        >
          {({ data, index, style }) => {
            const { name, _id, type } = data[index];
            const labelId = `checkbox-list-label-${_id}`;
            return (
              <StyledListItem
                key={_id}
                role={undefined}
                button
                onClick={() => handleToggle({ value: _id, label: name, type })}
                style={style}
              >
                <ListItemIcon style={{ margin: 0 }}>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={items.some(({ id }) => id === _id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={name} />
              </StyledListItem>
            );
          }}
        </FixedSizeList>
      </>
    );
  }

  return (
    <>
      <IconButton onClick={onCloseModal} style={{ position: "absolute", top: 0, right: 0 }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: "grid", gap: 8, mt: 2, minWidth: 432 }}>
        <Typography style={{ fontSize: 18, fontWeight: 700, textAlign: "center" }}>
          {TEXT.ADD_TEXT}
        </Typography>
        <Box sx={{ display: "flex", gap: 8 }}>
          <FieldFormAdd
            fullWidth
            autoComplete="off"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={TEXT.PLACEHOLDER_TEXT}
            style={{ marginBottom: 0, marginTop: 16 }}
            onChange={(event) => onChangeSearch(event.target.value)}
          />
        </Box>
        {/* {isMaxQuantity ? (
          <Box>
            <Typography style={{ textAlign: "center", marginBlock: 8 }}>
              Max Quantity: {QUANTITY_LIMITATION}
            </Typography>
          </Box>
        ) : null} */}
        <List className={classes.root}>{content}</List>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }}>
          <Button variant={"outlined"} color="primary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button variant={"contained"} color="primary" onClick={onHandleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SelectRecipientsModal;
