import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      left: 5,
      right: 20,
    },
  },
  scales: {
    x: {
      ticks: {
        color: "white",
        font: {
          size: 10,
          weight: 300,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        lineWidth: 0.7,
        color: 'rgba(222, 231, 250, 0.7)',
      },
    },
  },
  maintainAspectRatio: false,
};

export const BarGraph = ({ labels, datasets }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  return <Bar height="275px" options={chartOptions} data={{ labels, datasets }} />;
};

export default BarGraph;
