import React from 'react';
import { Box, Checkbox, makeStyles } from "@material-ui/core";
const useStyle = makeStyles({
  container: {
    border: '1px solid #DEE7FA',
    borderRadius: "7px",
    marginBottom: "24px",
    boxSizing: "border-box",
    height: 51,
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: '100%',
    padding: '0 14px',
  },
  label: {
    fontWeight: 500,
  }
})

const InputCheckBox = ({ label, checked, onChange, disabled }) => {
  const classes = useStyle()
  return (
    <Box className={classes.container}>
      <Box className={classes.label}>{label}</Box>
      <Checkbox
        name="checkedA"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        color="primary"
      />
    </Box>
  );
};

export default InputCheckBox;
