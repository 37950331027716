import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import SortableTable from '../../../../../components/SortableTable/SortableTable';
import { Card } from "../../../components";
import { formatPercentage } from "../../../utils";

export function ExtHiresReport() {
  const { url } = useRouteMatch();
  const { extendedHiresData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (extendedHiresData?.items) {
      const dataTransformed = [...extendedHiresData?.items].sort((a, b) => b.grossIncome - a.grossIncome).map((item) => ({
        franchisee: `${item?.licenseeUser?.firstname} ${item?.licenseeUser?.lastname}`,
        perFranchisee: formatPercentage((item.extendedBookingsPercentage || 0)/100),
        numOfExtensions: item.totalExtendedBookings,
        avgHoursExtended: item.avgHoursExtended.toFixed(2),
      }));
      setData(dataTransformed);
    }
  }, [extendedHiresData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Per Franchisee",
      key: "perFranchisee",
      sortable: true,
    },
    {
      name: "Number of Extensions",
      key: "numOfExtensions",
      sortable: true,
    },
    {
      name: "AVG hours Extended",
      key: "avgHoursExtended",
      sortable: true,
    },
  ];

  return (
    <Card header="Extended Hires Report" navigateTo={`${url}/ext-hires-report`}>
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="30% 25% 20% 20%"
        rowsHeight={255}
      />
    </Card>
  );
}
