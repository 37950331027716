import { createTheme } from "@material-ui/core";
import tinycolor from "tinycolor2";

export const COLOR_LIST = {
  PRIMARY: "#44D62C",
  SECONDARY: "#39a8df",
  WARNING: "#FFC260",
  SUCCESS: "#3CD4A0",
  INFO: "#9013FE",
  DANGER: "#ff0000",
  LAVENDER_MIST: "#DEE7FA",
  COMET: "#535F78",
  DARK_JUNGLE_GREEN: "#1D1D1F",
  STAR_DUST: "#9E9E9E",
  BACKGROUND_BLACK: "#0F0F0F",

  BORDER_BOX: "#393939",
};

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: COLOR_LIST.PRIMARY,
      light: tinycolor(COLOR_LIST.PRIMARY).lighten(lightenRate).toHexString(),
      dark: tinycolor(COLOR_LIST.PRIMARY).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: COLOR_LIST.SECONDARY,
      light: tinycolor(COLOR_LIST.SECONDARY).lighten(lightenRate).toHexString(),
      dark: tinycolor(COLOR_LIST.SECONDARY).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: COLOR_LIST.WARNING,
      light: tinycolor(COLOR_LIST.WARNING).lighten(lightenRate).toHexString(),
      dark: tinycolor(COLOR_LIST.WARNING).darken(darkenRate).toHexString(),
    },
    success: {
      main: COLOR_LIST.SUCCESS,
      light: tinycolor(COLOR_LIST.SUCCESS).lighten(lightenRate).toHexString(),
      dark: tinycolor(COLOR_LIST.SUCCESS).darken(darkenRate).toHexString(),
    },
    info: {
      main: COLOR_LIST.INFO,
      light: tinycolor(COLOR_LIST.INFO).lighten(lightenRate).toHexString(),
      dark: tinycolor(COLOR_LIST.INFO).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#FFF",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    lavenderMist: {
      main: COLOR_LIST.LAVENDER_MIST,
    },
    background: {
      default: "#171717",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark: "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide: "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "14px",
        lineHeight: "14px",
      },
      body2: {
        fontSize: "12px",
        color: "#e0e8facc",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiDialog: {
      container: {
        backgroundColor: "#000000b3",
        "& .MuiPickersCalendarHeader-iconButton.MuiButtonBase-root.MuiIconButton-root": {
          backgroundColor: "transparent",
        },
      },
      paper: {
        backgroundColor: "#202020",
      },
    },
    MuiDialogTitle: {
      root: {
        boxShadow: "0px 1px 0px #24272d",
      },
    },
    MuiDialogActions: {
      root: {
        background: "#171717",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#171717",
        /* padding: "0 24px", */
      },
      elevation2: {
        boxShadow: "none",
        marginLeft: "4px",
      },
    },
    MuiButton: {
      root: {
        lineHeight: "30px",
        textTransform: "none",
      },
      outlined: {
        borderColor: "#Dee7fa",
        "&.Mui-disabled": {
          color: "rgb(222, 231, 250)",
          borderColor: "rgb(222, 231, 250)!important",
        },
      },
      contained: {
        color: "rgba(255, 255, 255)",
        backgroundColor: "#646565",
        "&.Mui-disabled": {
          backgroundColor: "#cccccc",
          color: "#666666",
        },
      },
      containedPrimary: {
        color: "#fff",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: "0px 0px 5px rgba(83, 95, 120, 0.3)",
        marginTop: "4px",
        /* minWidth: "0!important", */
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#DEE7FA",
      },
      input: {
        padding: "11.5px 14px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#FFF",
        fontSize: "14px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px,15px)",
      },
      formControl: {
        top: "3px",
      },
    },
    MuiInputBase: {
      input: {
        color: "#FFF",
      },
    },
    MuiList: {
      padding: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(0,0,0,0.1) !important",
          "&:focus": {
            backgroundColor: "rgba(0,0,0,0.3)",
          },
        },
        Mui: {
          "selected:hover": {
            backgroundColor: "rgba(0,0,0,0.3)",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "rgba(0,0,0,0.3)",
        },
        paddingLeft: "10px",
      },
      gutters: {
        paddingLeft: "0",
      },
    },
    MuiIconButton: {
      root: {
        color: "#fff",
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "#171717",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: "1px solid #24272d",
        padding: "7px 0",
      },
      head: {
        fontSize: "14px",
        color: "#dee7fa",
        lineHeight: "14px",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "transparent",
      },
    },
    MuiCheckbox: {
      root: {
        color: COLOR_LIST.PRIMARY,
        "&.MuiCheckbox-colorPrimary.Mui-disabled": {
          color: "rgb(255 255 255 / 26%)",
        },
      },
    },
    MuiRadio: {
      root: {
        "&.MuiRadio-colorPrimary.Mui-disabled": {
          color: COLOR_LIST.STAR_DUST,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        "&.Mui-disabled": {
          color: COLOR_LIST.STAR_DUST,
        },
      },
    },
  },
};

const overrides = {
  typography: {
    h1: {
      fontSize: "50px",
      lineHeight: "59,67px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "38,19px",
    },
    h4: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "28.64px",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "19.09px",
    },
    fontFamily: ["-apple-system", "SF pro", "sans-serif", "SF Pro Display"].join(","),
  },
};

const themes = {
  default: createTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
