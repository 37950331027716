import moment from "moment";

export function formatHour(hour) {
  try {
    const duration = moment.duration(hour, "hours");
    // console.log("hour", hour, duration.humanize(), duration.asHours());
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours} hours${minutes > 1 ? ` ${minutes} minutes` : ""}`;
  } catch (err) {
    return "--";
  }
}
