export const GET_TRAILERS = "GET_TRAILERS";
export const GET_ALL_TRAILERS = "GET_ALL_TRAILERS";
export const GET_TRAILER_SERVICE_CHECKLIST = "GET_TRAILER_SERVICE_CHECKLIST";
export const GET_ITEM_PROMOTIONS = "GET_ITEM_PROMOTIONS";
export const GET_PROMOTIONAL_BANNERS = "GET_PROMOTIONAL_BANNERS";
export const GET_DISCOUNT_LIST = "GET_DISCOUNT_LIST";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_DETAIL = "GET_NOTIFICATION_DETAIL";
export const GET_DISCOUNT_DETAILS = "GET_DISCOUNT_DETAILS";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const GET_AVAILABLE_TRAILERS = "GET_AVAILABLE_TRAILERS";
export const GET_BOOKING_PRICE = "GET_BOOKING_PRICE";
export const GET_DETAIL_ITEMS = "GET_DETAIL_ITEMS";
export const GET_FREE_ACCESSORIES = "GET_FREE_ACCESSORIES";
export const GET_HTD_SERVICES_AND_VOUCHERS = "GET_HTD_SERVICES_AND_VOUCHERS";

const getQueryKey = {
  getTrailersQueryKey: () => [GET_TRAILERS],
  getAllTrailersQueryKey: () => [GET_ALL_TRAILERS],
  getTrailerServiceChecklistQueryKey: (trailerId) => [GET_TRAILERS, trailerId],
  getItemPromotionsQueryKey: (keyword, type) => [GET_ITEM_PROMOTIONS, type, keyword],
  getPromotionalBannersQueryKey: () => [GET_PROMOTIONAL_BANNERS],
  getDiscountListQueryKey: (param) => [GET_DISCOUNT_LIST, param],
  getNotificationListQueryKey: (param) => [GET_NOTIFICATION_LIST, param],
  getNotificationDetailsQueryKey: (id) => [GET_NOTIFICATION_DETAIL, id],
  getDiscountDetailsQueryKey: (id) => [GET_DISCOUNT_DETAILS, id],
  getOrderDetailQueryKey: (id) => [GET_ORDER_DETAIL, id],
  getPaymentMethodsQueryKey: (id) => [GET_PAYMENT_METHODS, id],
  getAvailableTrailersQueryKey: (query) => [GET_AVAILABLE_TRAILERS, query],
  getBookingPriceQueryKey: (params) => [GET_BOOKING_PRICE, params],
  getDetailItemsQueryKey: (params) => [GET_BOOKING_PRICE, params],
  getFreeAccessoriesQueryKey: () => [GET_FREE_ACCESSORIES],
  getHTDServicesAndVouchersQueryKey: () => [GET_HTD_SERVICES_AND_VOUCHERS],
};

export default getQueryKey;
