import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { Card } from "../../components";
import { formatAmount } from "../../utils";
import { useStyles } from "../../Analytics.styles";

export function TrailerPerfPage() {
  const classes = useStyles();
  const { trailerPerformanceData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Trailer Performance";
    if (trailerPerformanceData?.items) {
      const dataTransformed = [...trailerPerformanceData.items]
        .sort((a, b) => b.grossIncome - a.grossIncome)
        .map((item) => ({
          name: item?.trailer?.name,
          franchiseeName: item?.licensee?.[0]?.fullname ?? "--",
          registration: item?.registration ?? "--",
          totalSales: formatAmount(item?.grossIncome, 0),
          totalHires: item?.totalHires,
        }));
      setData(dataTransformed);
    }
  }, [trailerPerformanceData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Franchisee Name",
      key: "franchiseeName",
      sortable: true,
    },
    {
      name: "Trailer Registration",
      key: "registration",
      sortable: true,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Trailer Performance">
        <SortableTable
          rows={data}
          columns={columns}
          // hasIndexColumn
          gridTemplateColumns="30% 17.5% 17.5% 17.5% 17.5%"
        />
      </Card>
    </Box>
  );
}
