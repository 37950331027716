import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Card } from "../../components";
import { useStyles } from "../../Analytics.styles";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";

export function UnavailableTrailerSearchesPage() {
  const classes = useStyles();
  const { unavailableTrailerSearches } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Unavailable Trailer Searches";
    if (unavailableTrailerSearches?.items) {
      const dataTransformed = [...unavailableTrailerSearches.items]
        .sort((a, b) => b.total - a.total)
        .slice(0, 5)
        .map((item) => ({
          trailer: item.trailer,
          total: item.total,
          location: item.location,
        }));
      setData(dataTransformed);
    }
  }, [unavailableTrailerSearches]);

  const columns = [
    {
      name: "",
      key: "trailer",
      sortable: false,
    },
    {
      name: "Unserviced Bookings",
      key: "total",
      sortable: true,
    },
    {
      name: "Location",
      key: "location",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Unavailable Trailer Searches">
        <SortableTable
          rows={data}
          columns={columns}
          hasIndexColumn
          gridTemplateColumns="8% 38% 16% 38%"
        />
      </Card>
    </Box>
  );
}
