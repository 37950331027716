import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../helpers/axios";
import { getIsAllowByUserRole } from "../helpers/common";
import { toast } from "react-toastify";

export function useAuth() {
  const { user, isLoggedin, logout } = useContext(AuthContext);
  return { user, isLoggedin, logout };
}

export function useVerify2FACode() {
  return useMutation({
    mutationFn: ({ code }) => axiosInstance.post("/v1/admin/employee/verify-auth-code", { code }),
    onSuccess: (response) => {
      const data = response?.data?.dataObj;
      if (data) {
        secureLocalStorage.setItem(
          "user",
          JSON.stringify({ ...data.employeeObj, is2FAVerified: true })
        );
        secureLocalStorage.setItem("token", data.token);
      }
    },
  });
}

export function useRedirectToHomeIfUnauthorized(roles) {
  const history = useHistory();
  const isAllowed = getIsAllowByUserRole(roles);
  if (!isAllowed) {
    toast.error("You are not allowed to access this page");
    history.replace("/");
  }
}
