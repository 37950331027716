import React, { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

import { getFromLocalStorage } from '../../helpers/common';

export const AuthContext = React.createContext({
  user: getFromLocalStorage("user"),
  isLoggedin: false,
  setUser: null,
  setIsLoggedin: null,
});

function AuthProvider(props) {
  const [user, setUser] = useState(getFromLocalStorage("user"));
  const [isLoggedin, setIsLoggedin] = useState(getFromLocalStorage("isLoggedin"));
  const login = (data) => {
    setUser(data.user);
    setIsLoggedin(true);
    secureLocalStorage.setItem("token", JSON.stringify(data.token));
    secureLocalStorage.setItem("user", JSON.stringify(data.user));
    localStorage.setItem("isLoggedin", JSON.stringify(true));
  };
  const logout = () => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");
    localStorage.removeItem("isLoggedin");
    setUser(null);
    setIsLoggedin(false);
    window.location.href = "/signin";
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedin,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
