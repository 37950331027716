import React, { useState, useRef, useEffect } from "react";
import TemplateImageModifyItemLicensee from "../../components/TemplateImageModifyItemLicensee";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import DynamicTextField from "../../components/DynamicTextField";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { Box, makeStyles } from "@material-ui/core";
import TextFieldMask from "../../components/TextFieldMask";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import DialogTrailerUpsellItem from "../../components/DialogTrailerUpsellItem";
import * as yup from "yup";
import { generatePropTextField } from "../../helpers/formik-help";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import { calculateHireCostTrailer } from "../../helpers/api";
import { useLoading } from "../../hooks/useLoading";
import { toast } from "react-toastify";
import InputCheckBox from "../../components/InputCheckBox";
import { useRedirectToHomeIfUnauthorized } from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants/common";
const defaultDataRef = {
  name: "",
  price: "",
  isPremium: false,
  tare: "",
  axle: "",
  size: "",
  maxLoad: "",
  gvm: "",
  description: "",
};
const TrailerAdd = () => {
  useRedirectToHomeIfUnauthorized([USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER]);
  const [trailerData] = useState(defaultDataRef);
  const prevUpsellItemSelectedList = useRef([]);
  const [pricePerDay, setPricePerDay] = useState();
  const [upsellList, setUpsellList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  let history = useHistory();
  // IMAGE
  const dataImageRef = useRef([]);
  //
  const typingTimeout = React.useRef(null);
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  const formik = useFormik({
    initialValues: trailerData,
    validationSchema: yup.object({
      name: yup.string().required(" "),
      price: yup.string().required(" "),
      tare: yup.number().required(" "),
      axle: yup.string().required(" "),
      size: yup.string().required(" "),
      maxLoad: yup.number().required(" "),
      gvm: yup.number().required(" "),
      description: yup.string().required(" "),
    }),
    onSubmit: (value) => {
      handleAddOrUpdateTrailer(value);
    },
  });

  const handleSelectUpsellItem = (indexOfItem) => {
    setUpsellList((prev) => [
      ...prev.slice(0, indexOfItem),
      {
        ...prev[indexOfItem],
        isActive: !prev[indexOfItem].isActive,
      },
      ...prev.slice(indexOfItem + 1),
    ]);
  };

  const handleCloseDialog = () => {
    setUpsellList(prevUpsellItemSelectedList.current);
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleAddUpsellItemToTrailer = () => {
    prevUpsellItemSelectedList.current = upsellList;
    setOpen(false);
  };

  const handleAddOrUpdateTrailer = async (trailerData) => {
    showLoading();
    try {
      const formData = new FormData();
      formData.append("name", trailerData.name);
      formData.append("size", trailerData.size);
      formData.append("price", trailerData.price.slice(1));
      formData.append("isPremium", trailerData.isPremium);
      formData.append("axel", trailerData.axle);
      formData.append("tare", trailerData.tare);
      formData.append("maxLoad", trailerData.maxLoad);
      formData.append("gvm", trailerData.gvm);
      formData.append("description", trailerData.description);
      if (dataImageRef.current.length !== 0) {
        let array = [];
        dataImageRef.current.map((item) => array.push(item.file));
        for (let item of array) {
          formData.append("photos", item);
        }
      }
      let result = [];
      upsellList.map((item) => {
        if (item.isActive === true) {
          result.push(item._id);
        }
      });
      for (let item of result) {
        formData.append("upsellItems[]", item);
      }
      const res = await axios.post("v1/admin/trailer", formData);
      toast.success(res?.data?.message);
      hideLoading();
      history.push("/rental/trailer");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    axios
      .get("/v1/admin/get-all-upsellitems")
      .then((response) => {
        const results = response.data.dataObj.map((item) => ({ ...item, isActive: false }));
        prevUpsellItemSelectedList.current = results;
        setUpsellList(results);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  useEffect(() => {
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      calculateHireCostTrailer(formik.values.price.toString().replace("$", ""), setPricePerDay);
    }, 1000);
  }, [formik.values.price]);

  return (
    <Box height="100%" paddingBottom="85px">
      <WrapHeaderSubPage nameHeader="Add trailer">
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Box display="flex">
        <TemplateImageModifyItemLicensee dataImageRef={dataImageRef} />
        <WrapFormItemLicensee formik={formik}>
          <DynamicTextField {...generatePropTextField("name", formik)} label="Trailer Name" />
          <TextFieldMask
            {...generatePropTextField("price", formik)}
            label="Trailer Value"
            mask="$99999999999"
            autoComplete="off"
            formatChars={{ 9: "[0-9]" }}
          >
            {(inputProps) => <DynamicTextField fullWidth variant="outlined" {...inputProps} />}
          </TextFieldMask>
          <InputCheckBox
            label="This is a premium trailer"
            checked={formik.values.isPremium}
            onChange={() => formik.setFieldValue("isPremium", !formik.values.isPremium)}
          />
          <div className="price-perDay">{!!pricePerDay && `$${pricePerDay} per day`}</div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DynamicTextField
              {...generatePropTextField("tare", formik)}
              label="Tare (kg)"
              className={classes.input50}
            />
            <DynamicTextField
              {...generatePropTextField("axle", formik)}
              label="Axle"
              className={classes.input50}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DynamicTextField
              {...generatePropTextField("size", formik)}
              label="Size (LxW)"
              className={classes.input50}
            />
            <DynamicTextField
              {...generatePropTextField("maxLoad", formik)}
              label="Max Load (kg)"
              className={classes.input50}
            />
          </div>
          <DynamicTextField {...generatePropTextField("gvm", formik)} label="GVM (kg)" />
          <DynamicTextField
            {...generatePropTextField("description", formik)}
            label="Description"
            multiline
            rows={5}
          />
          <WrapButtonSubmitTrailer>
            <ButtonActionHeader
              width="233px"
              style={{ marginRight: "12px" }}
              onClick={() => formik.handleSubmit()}
            >
              Upload new trailer
            </ButtonActionHeader>
            <ButtonActionHeader width="233px" onClick={() => handleOpenDialog()}>
              Add relevant upsell items
            </ButtonActionHeader>
          </WrapButtonSubmitTrailer>
        </WrapFormItemLicensee>
      </Box>
      <DialogTrailerUpsellItem
        checkList={upsellList}
        handleCloseDialog={handleCloseDialog}
        onSelectUpsellItem={handleSelectUpsellItem}
        onSubmitSelectUpsellItem={handleAddUpsellItemToTrailer}
        open={isOpen}
        header="Relevant upsell items to trailer"
        title="Please select relevant upsell items to this Trailer"
      />
    </Box>
  );
};
export default TrailerAdd;
const useStyles = makeStyles(() => ({
  input50: {
    width: "calc( (50vw - 200px ) / 2 - 9px)",
  },
}));
