import React, { useState, useEffect } from "react";
import { Dialog, Box, Typography, ListItem } from "@material-ui/core";
import { ReactComponent as Delete } from "../../img/delete.svg";
import { Checkbox } from "@material-ui/core";
import { useDialogTrailerServicesStyle } from "./style";
import { useCheckBoxItemTrailerServicesStyle } from "./style";

const DialogTrailerServices = ({ open, checkList, handleCloseDialog }) => {
  const [list, setList] = useState(
    checkList.map((item) => {
      return {
        name: item.name,
        isActive: true,
      };
    })
  );
  useEffect(() => {
    setList(
      checkList.map((item) => {
        return {
          name: item.name,
          isActive: true,
        };
      })
    );
  }, [checkList]);

  const cssDialog = useDialogTrailerServicesStyle();
  const cssCheckBox = useCheckBoxItemTrailerServicesStyle();

  return (
    <Dialog open={open}>
      <Box classes={{ root: cssDialog.containerContentDialog }}>
        <Box width="inherit" position="relative">
          <Box classes={{ root: cssDialog.fixedHeaderDialog }}>
            <Box
              classes={{ root: cssDialog.iconCloseDialog }}
              onClick={handleCloseDialog}
            >
              <Delete />
            </Box>
            <Typography
              align="center"
              classes={{ root: cssDialog.headerContentDialog }}
            >
              Service checklist
            </Typography>
          </Box>
        </Box>
        <Box classes={{ root: cssDialog.scrollContaierContentDialog }}>
          {list.map((service, index) => (
            <ListItem
              classes={{
                root: `
                    ${cssDialog.itemContent} 
                    ${index !== list.length && cssDialog.borderBottom}
                    ${index === 0 && cssDialog.borderTop}
                  `,
              }}
              key={index}
            >
              <Typography
                classes={{
                  root: cssDialog.itemTittle,
                }}
              >
                {service.name}
              </Typography>
              <Checkbox
                color="primary"
                id={service.name}
                classes={cssCheckBox}
                checked={list[index].isActive}
              />
            </ListItem>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
export default DialogTrailerServices;
