import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatHour } from "../../utils";

// const DUMMY_DATA = [
//   {
//     trailerType: "Large Cage Trailer",
//     franchisee: "T2Y Sunshine Coast",
//     totalHireTime: 3010,
//     totalHTDTime: 32.72,
//     utilisation: "30%",
//   },
//   {
//     trailerType: "Large Cage Trailer",
//     franchisee: "Southern Gold Coast T2Y",
//     totalHireTime: 3010,
//     totalHTDTime: 32.72,
//     utilisation: "30%",
//   },
//   {
//     trailerType: "Large Cage Trailer",
//     franchisee: "T2Y Sunshine Coast",
//     totalHireTime: 3010,
//     totalHTDTime: 32.72,
//     utilisation: "30%",
//   },
//   {
//     trailerType: "Large Cage Trailer",
//     franchisee: "T2Y Sunshine Coast",
//     totalHireTime: 3010,
//     totalHTDTime: 32.72,
//     utilisation: "30%",
//   },
//   {
//     trailerType: "Large Cage Trailer",
//     franchisee: "T2Y Sunshine Coast",
//     totalHireTime: 3010,
//     totalHTDTime: 32.72,
//     utilisation: "30%",
//   },
// ];

export function HTDUtilisationReportByTrailerTypeAndFranchiseePage() {
  const classes = useStyles();
  const { htdUtilisationReportByTrailerTypeAndFranchisee } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent =
      "HTD Utilisation Report by Trailer Type and Franchisee";
    if (htdUtilisationReportByTrailerTypeAndFranchisee?.items) {
      const dataTransformed = htdUtilisationReportByTrailerTypeAndFranchisee.items.map((item) => ({
        trailerType: item?.trailer?.name ?? "--",
        franchisee: item?.licensee?.firstname
          ? `${item?.licensee?.firstname} ${item?.licensee?.lastname}`
          : "--",
        totalHireTime: formatHour(item.totalHireDuration),
        totalHTDTime: formatHour(item.totalHtdHours),
        utilisation: item.htdUtilisationPercentage ?? "--",
        HTDRatio: item?.htdRatio ?? "--",
      }));
      setData(dataTransformed);
    }
  }, [htdUtilisationReportByTrailerTypeAndFranchisee]);

  const columns = [
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Total hire time",
      key: "totalHireTime",
      sortable: true,
    },
    {
      name: "Total HTD time",
      key: "totalHTDTime",
      sortable: true,
    },
    {
      name: "HTD Utilisation",
      key: "utilisation",
      sortable: true,
    },
    {
      name: "HTD Ratio",
      key: "HTDRatio",
      sortable: true,
    },
  ];

  return (
    <>
      <Box component="main" className={classes.analyticsSinglePageContent}>
        <Card header="HTD Utilisation Report by Trailer Type and Franchisee">
          <SortableTable
            rows={data}
            columns={columns}
            gridTemplateColumns="30% 30% 10% 10% 10% 10%"
          />
        </Card>
      </Box>
    </>
  );
}
