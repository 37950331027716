import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { common } from "../../constants/common";
import getQueryKey, { GET_NOTIFICATION_LIST } from "../../helpers/getQueryKey";
import { convertObjectIntoQueryString } from "../../helpers/common";
import axiosInstance from "../../helpers/axios";
import { RECIPIENTS_TYPE } from "../../pages/notifications/components/CreateNewOrEditNotification.jsx";
import { toast } from "react-toastify";

export const useGetNotificationDetails = (id) => {
  return useQuery({
    queryKey: getQueryKey.getNotificationDetailsQueryKey(id),
    queryFn: async () => {
      const response = await axiosInstance.get(`v1/admin/notifications/${id}`);
      if (response?.status === 200) {
        return response?.data;
      }
      return null;
    },
    staleTime: common.STALE_TIME.MIN_1,
  });
};

export const useGetNotificationList = ({ limit = 20, skip = 0, type = "all", keywords = "" }) => {
  const params = {
    limit,
    skip,
    type: !Object.entries(common.NOTIFICATION_STATUS)
      .map(([, status]) => status)
      .includes(type)
      ? common.NOTIFICATION_STATUS.ALL
      : type,
    keywords,
  };
  return useQuery({
    queryKey: getQueryKey.getNotificationListQueryKey(params),
    queryFn: async () => {
      if (
        [common.NOTIFICATION_STATUS.SCHEDULED, common.NOTIFICATION_STATUS.SENT].includes(
          params.type
        )
      ) {
        params.isPushed = common.NOTIFICATION_STATUS.SENT === type;
      }

      const query = convertObjectIntoQueryString(params);
      const response = await axiosInstance.get(`v1/admin/notifications?${query}`);

      if (response?.status === 200) {
        return response?.data;
      }
      return null;
    },
  });
};

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => {
      return axiosInstance.delete(`v1/admin/notifications/${id}`);
    },
    onSuccess: () => {
      toast.success("Deleted Notification");
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_NOTIFICATION_LIST]);
    },
  });
};

export const useGetRecipientsItems = (keyword, type) => {
  return useQuery({
    enabled: type === RECIPIENTS_TYPE?.[1]?.value ? !!keyword : true,
    queryKey: getQueryKey.getItemPromotionsQueryKey(keyword, type),
    queryFn: async () => {
      const url = `v1/admin/search?${convertObjectIntoQueryString({
        type: type === RECIPIENTS_TYPE?.[1]?.value ? "address" : type,
        limit: 100,
        keyword,
      })}`;
      const response = await axiosInstance.get(url);
      if (response?.status === 200) {
        return response?.data ?? (type === RECIPIENTS_TYPE?.[1]?.value ? null : []);
      }
      return [];
    },
    staleTime: common.STALE_TIME.MIN_1,
  });
};

export const useCreateEditNotification = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return id
        ? axiosInstance.put(`v1/admin/notifications/${id}`, payload)
        : axiosInstance.post(`v1/admin/notifications`, payload);
    },
    onSuccess: () => {
      toast.success(id ? "Updated Notification" : "Created new Notification");
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_NOTIFICATION_LIST]);
      id && queryClient.invalidateQueries(getQueryKey.getNotificationDetailsQueryKey(id));
    },
  });
};
