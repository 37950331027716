import {
  Box,
  Button,
  Checkbox,
  Dialog,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";

import { FieldFormAdd } from "../../components/FieldFormAdd";
import OnOffSwitch from "../../components/Switch/OnOffSwitch";
import axiosInstance from "../../helpers/axios";
import { generatePropTextField } from "../../helpers/formik-help";
import { useLoading } from "../../hooks/useLoading";
import { ReactComponent as Delete } from "../../img/delete.svg";

const useStyle = makeStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  paper: {
    backgroundColor: "#171717",
  },
  outlinedButton: {
    lineHeight: "14px",
    height: "35px",
    textTransform: "none",
    width: "150px",
    padding: "6px 9px",
    marginRight: "10px",
  },
  rowItem: {
    height: 73,
    borderTop: "1px solid #24272d",
  },
});
const DialogHireTheDriver = ({ open, data, globalHireRateSetting, onClose, getListLicensee }) => {
  const classes = useStyle();
  const hireTheDriverData = data.licensee_data?.hireTheDriver;
  const trailersData = data.licensee_data?.trailers || [];
  const [showLoading, hideLoading] = useLoading();
  const [initialValues, setInitialValues] = useState({
    isEnabled: hireTheDriverData.isEnabled,
    perHour: hireTheDriverData.perHour,
    trailers: trailersData.filter((item) => item.isHireDriverAvailable).map((item) => item._id),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      perHour: yup.string().required("Please enter individual rate!"),
    }),
    onSubmit: async (values) => {
      showLoading();
      try {
        await axiosInstance
          .put("/v1/admin/licensee/update-hire-the-driver/" + data.licensee_data._id, values)
          .then((res) => {
            hideLoading();
            toast.success(res?.data?.message);
            getListLicensee();
          });
      } catch (err) {
        hideLoading();
        toast.error(err?.response?.data?.message);
      }
    },
    enableReinitialize: true,
  });

  const onChangeChecked = (id) => {
    if (initialValues.trailers.includes(id)) {
      setInitialValues({
        ...initialValues,
        trailers: initialValues.trailers.filter((item) => item !== id),
      });
    } else {
      setInitialValues({ ...initialValues, trailers: [...initialValues.trailers, id] });
    }
  };

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <Box padding="25px" boxSizing="border-box" width="600px" position="relative">
        <Box position="absolute" right="24px" top="21px" className={classes.root} onClick={onClose}>
          <Delete />
        </Box>
        <Box display="flex" marginTop="40px" justifyContent="space-between">
          <Typography align="center" variant="h5">
            {data.firstname}’s Hire the Driver settings
          </Typography>
          <OnOffSwitch
            checked={formik.values.isEnabled}
            onChange={() => formik.setFieldValue("isEnabled", !formik.values.isEnabled)}
          />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" marginTop="40px" justifyContent="space-between">
            <FieldFormAdd
              value={globalHireRateSetting.perHour}
              style={{ marginRight: "17.5px" }}
              id="globalRate"
              name="globalRate"
              label="Global Rate (AUD / hour)"
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
              disabled
            />
            <FieldFormAdd
              {...generatePropTextField("perHour", formik)}
              id="perHour"
              name="perHour"
              label="Individual rate (AUD / hour)"
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              error={Boolean(formik.errors.perHour)}
              helperText={formik.errors.perHour}
              fullWidth
            />
          </Box>

          <Typography variant="h5" style={{ margin: "20px 0" }}>
            Suitable trailers for Hire the Driver
          </Typography>
          <Box style={{ maxHeight: 500, overflow: "auto" }}>
            {trailersData.map((item) => (
              <Box
                key={item._id}
                className={classes.rowItem}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>{item.trailerDetails.name || "Unamed"}</Box>
                <Checkbox
                  name="checkedA"
                  checked={formik.values.trailers.includes(item._id)}
                  onChange={() => onChangeChecked(item._id)}
                  disabled={!item.trailerDetails.isHireDriverSuitable}
                  color="primary"
                />
              </Box>
            ))}
          </Box>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            disabled={!formik.isValid}
          >
            Save update
          </Button>
        </form>
      </Box>
    </Dialog>
  );
};
export default DialogHireTheDriver;
