import {
  Box,
  Typography,
  IconButton,
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  styled,
  Button,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { FieldFormAdd } from "../FieldFormAdd";
import debounce from "lodash/debounce";
import {
  TYPE_PROMOTION_ITEMS,
  useItemPromotions,
} from "../../hooks/DiscountsPromotions/useDiscounts";
import { FixedSizeList } from "react-window";

const StyledListItem = styled(ListItem)({
  "& .MuiButtonBase-root": {
    marginLeft: 0,
  },
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const MAPPING = {
  [TYPE_PROMOTION_ITEMS.PRODUCTS]: {
    ADD_TEXT: "Add Products",
    PLACEHOLDER_TEXT: "Search products",
  },
  [TYPE_PROMOTION_ITEMS.LICENSEES]: {
    ADD_TEXT: "Add Franchisees",
    PLACEHOLDER_TEXT: "Search franchisees",
  },
  [TYPE_PROMOTION_ITEMS.CUSTOMERS]: {
    ADD_TEXT: "Add Customers",
    PLACEHOLDER_TEXT: "Search customers",
  },
};

const QUANTITY_LIMITATION = 20;

const AddItemPromotionsModal = ({ onCloseModal, data }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const { type, formikName, initialValues } = data;
  const TEXT = MAPPING[type];
  const [appliesToItems, setAppliesToItems] = useState(initialValues || []);
  const [search, setSearch] = useState("");
  const { data: promotionItems, isLoading } = useItemPromotions(search, type ?? "");
  const isMaxQuantity = useMemo(
    () => appliesToItems?.length >= QUANTITY_LIMITATION,
    [appliesToItems]
  );

  const handleToggle = (toggleItem) => {
    const clonedItems = [...appliesToItems];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === toggleItem.value);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
    } else if (!isMaxQuantity) {
      clonedItems.push({
        id: toggleItem.value,
        label: toggleItem.label,
        ...(toggleItem.type && { type: toggleItem.type }),
      });
    }
    setAppliesToItems(clonedItems);
  };

  const onHandleSave = () => {
    setFieldValue(formikName, appliesToItems);
    onCloseModal();
  };

  const displayItems = useMemo(
    () =>
      promotionItems?.filter(({ name }) =>
        name.toLocaleLowerCase().includes(search.toLowerCase())
      ) || [],
    [search, promotionItems]
  );

  const onChangeSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  let content = <Typography style={{ textAlign: "center" }}>No results</Typography>;

  if (isLoading) {
    content = (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (promotionItems && promotionItems?.length > 0) {
    content = (
      <FixedSizeList
        height={485}
        width={"100%"}
        itemCount={displayItems?.length || 0}
        itemSize={81}
        itemData={displayItems}
      >
        {({ data, index, style }) => {
          const { name, _id, type } = data[index];
          const labelId = `checkbox-list-label-${_id}`;
          return (
            <StyledListItem
              key={_id}
              role={undefined}
              button
              onClick={() => handleToggle({ value: _id, label: name, type })}
              style={style}
            >
              <ListItemIcon style={{ margin: 0 }}>
                <Checkbox
                  color="primary"
                  edge="start"
                  checked={appliesToItems.some(({ id }) => id === _id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={name} />
            </StyledListItem>
          );
        }}
      </FixedSizeList>
    );
  }

  return (
    <>
      <IconButton onClick={onCloseModal} style={{ position: "absolute", top: 0, right: 0 }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: "grid", gap: 8, mt: 2, minWidth: 432 }}>
        <Typography style={{ fontSize: 18, fontWeight: 700, textAlign: "center" }}>
          {TEXT.ADD_TEXT}
        </Typography>
        <Box sx={{ display: "flex", gap: 8 }}>
          <FieldFormAdd
            fullWidth
            autoComplete="off"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={TEXT.PLACEHOLDER_TEXT}
            style={{ marginBottom: 0, marginTop: 16 }}
            onChange={(event) => onChangeSearch(event.target.value)}
          />
        </Box>
        {isMaxQuantity ? (
          <Box>
            <Typography style={{ textAlign: "center", marginBlock: 8 }}>
              Max Quantity: {QUANTITY_LIMITATION}
            </Typography>
          </Box>
        ) : null}
        <List className={classes.root}>{content}</List>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }}>
          <Button variant={"outlined"} color="primary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button variant={"contained"} color="primary" onClick={onHandleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddItemPromotionsModal;
