import React from "react";
import { Box } from "@material-ui/core";

const WrapButtonSubmitTrailer = ({children}) => {
  return (
    <Box display="flex" alignItems="center" marginTop="9px">
      {children}
    </Box>
  );
};
export default WrapButtonSubmitTrailer;
