import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";
import axiosInstance from "../../helpers/axios";

const initialState = {
  selectedFilterValues: [],
  analyticDate: {
    fromDate: null,
    toDate: null,
  },
  loading: false,
  summaryData: null,
  htdSummaryData: null,
  htdUtilisationReportByTrailerType: null,
  htdTotalHoursPerFranchisee: null,
  htdUtilisationReportByTrailerTypeAndFranchisee: null,
  incomePerTrailerData: null,
  trailerPerformanceData: null,
  incomePerLicenseeData: null,
  incomePerCityData: null,
  incomePerUpSellItemsData: null,
  outOfServiceTrailersData: null,
  lateHiresData: null,
  extendedHiresData: null,
  averageHireLength: null,
  avgDropOffDistanceByFranchisee: null,
  avgPickUpDistanceByFranchisee: null,
  avgDistanceGraphData: null,
  unavailableTrailerSearches: null,
  damageWaiverUtilisationReport: null,
  ordersByDayOfTheWeek: null,
  trailerUtilisationReport: null,
};

const appendParams = (fromDate, toDate, selectedFilterValues) => {
  const from = moment(fromDate).isValid() ? fromDate : null;
  const to = moment(toDate).isValid() ? toDate : null;
  const filters = JSON.stringify({
    searchFilters: selectedFilterValues.map((item) => ({ id: item.id, type: item.type })),
  });
  return `?fromDate=${from}&toDate=${to}&searchFilters=${filters}`;
};

export const getUnavailableTrailerSearches = createAsyncThunk(
  "analytics/getUnavailableTrailerSearches",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/unserviced-bookings/trailer-type${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getOrdersByDayOfTheWeek = createAsyncThunk(
  "analytics/getOrdersByDayOfTheWeek",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/day-of-week${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getDamageWaiverUtilisationReport = createAsyncThunk(
  "analytics/getDamageWaiverUtilisationReport",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/damage-waiver${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getTrailerUtilisationReport = createAsyncThunk(
  "analytics/getTrailerUtilisationReport",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/trailer-utilisation${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getSummaryData = createAsyncThunk(
  "analytics/getSummaryData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/summary${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj?.summary;
  }
);

export const getHTDSummaryData = createAsyncThunk(
  "analytics/getHTDSummaryData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/hire-the-driver${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getAvgDistanceGraphData = createAsyncThunk(
  "analytics/getAvgDistanceGraphData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/average-distance${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getHTDUtilisationReportByTrailerType = createAsyncThunk(
  "analytics/getHTDUtilisationReportByTrailerType",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/hire-the-driver/trailer-type${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getTotalHTDHoursPerFranchisee = createAsyncThunk(
  "analytics/getTotalHTDHoursPerFranchisee",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/hire-the-driver/franchisee${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getHTDUtilisationReportByTrailerTypeAndFranchisee = createAsyncThunk(
  "analytics/getHTDUtilisationReportByTrailerTypeAndFranchisee",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/hire-the-driver/franchisee-trailer-type${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getIncomePerTrailerData = createAsyncThunk(
  "analytics/getIncomePerTrailer",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/trailer-type${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getTrailerPerformanceData = createAsyncThunk(
  "analytics/getTrailerPerformance",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/trailer-type${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}&isIndividual=true`
    );
    return response.data?.dataObj;
  }
);

export const getIncomePerLicenseeData = createAsyncThunk(
  "analytics/getIncomePerLicenseeData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/licensee${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getUnserviceBookingPerformanceData = createAsyncThunk(
  "analytics/getUnserviceBookingPerformanceData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/unserviced-bookings${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getIncomePerCityData = createAsyncThunk(
  "analytics/getIncomePerCityData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/city${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getIncomePerUpSellItemsData = createAsyncThunk(
  "analytics/getIncomePerUpSellItemsData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/upsell${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getOutOfServiceTrailersData = createAsyncThunk(
  "analytics/getOutOfServiceTrailersData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/trailers/out-of-service${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getLateHiresData = createAsyncThunk(
  "analytics/getLateHiresData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/late-hires${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getAvgDropOffDistanceByFranchisee = createAsyncThunk(
  "analytics/getAvgDropOffDistanceByFranchisee",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/licensee/pickup-distance${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getAvgPickUpDistanceByFranchisee = createAsyncThunk(
  "analytics/getAvgPickUpDistanceByFranchisee",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/licensee/dropoff-distance${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

export const getExtendedHiresData = createAsyncThunk(
  "analytics/getExtendedHiresData",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/extended-hires${appendParams(fromDate, toDate, selectedFilterValues)}`
    );
    return response.data?.dataObj;
  }
);

export const getAverageHireLength = createAsyncThunk(
  "analytics/getAverageHireLength",
  async (_, { getState }) => {
    const { selectedFilterValues } = getState().analytic;
    const { fromDate, toDate } = getState().analytic.analyticDate;
    const response = await axiosInstance.get(
      `/v1/admin/analytics/trailers/hire-duration${appendParams(
        fromDate,
        toDate,
        selectedFilterValues
      )}`
    );
    return response.data?.dataObj;
  }
);

const analyticSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSelectedFilterValues: (state, action) => {
      state.selectedFilterValues = action.payload;
    },
    setAnalyticDate: (state, action) => {
      state.analyticDate = {
        ...state.analyticDate,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    // getSummaryData
    [getSummaryData.pending]: (state) => {
      state.loading = true;
    },
    [getSummaryData.fulfilled]: (state, action) => {
      state.loading = false;
      state.summaryData = action.payload;
    },
    [getSummaryData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getUnavailableTrailerSearches
    [getUnavailableTrailerSearches.pending]: (state) => {
      state.loading = true;
    },
    [getUnavailableTrailerSearches.fulfilled]: (state, action) => {
      state.loading = false;
      state.unavailableTrailerSearches = action.payload;
    },
    [getUnavailableTrailerSearches.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getDamageWaiverUtilisationReport
    [getDamageWaiverUtilisationReport.pending]: (state) => {
      state.loading = true;
    },
    [getDamageWaiverUtilisationReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.damageWaiverUtilisationReport = action.payload;
    },
    [getDamageWaiverUtilisationReport.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getTrailerUtilisationReport
    [getTrailerUtilisationReport.pending]: (state) => {
      state.loading = true;
    },
    [getTrailerUtilisationReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.trailerUtilisationReport = action.payload;
    },
    [getTrailerUtilisationReport.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getOrdersByDayOfTheWeek

    // getOrdersByDayOfTheWeek
    [getOrdersByDayOfTheWeek.pending]: (state) => {
      state.loading = true;
    },
    [getOrdersByDayOfTheWeek.fulfilled]: (state, action) => {
      state.loading = false;
      state.ordersByDayOfTheWeek = action.payload;
    },
    [getOrdersByDayOfTheWeek.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getHTDSummaryData
    [getHTDSummaryData.pending]: (state) => {
      state.loading = true;
    },
    [getHTDSummaryData.fulfilled]: (state, action) => {
      state.loading = false;
      state.htdSummaryData = action.payload;
    },
    [getHTDSummaryData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    //getHTDUtilisationReportByTrailerType
    [getHTDUtilisationReportByTrailerType.pending]: (state) => {
      state.loading = true;
    },
    [getHTDUtilisationReportByTrailerType.fulfilled]: (state, action) => {
      state.loading = false;
      state.htdUtilisationReportByTrailerType = action.payload;
    },
    [getHTDUtilisationReportByTrailerType.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    //getTotalHTDHoursPerFranchisee
    [getTotalHTDHoursPerFranchisee.pending]: (state) => {
      state.loading = true;
    },
    [getTotalHTDHoursPerFranchisee.fulfilled]: (state, action) => {
      state.loading = false;
      state.htdTotalHoursPerFranchisee = action.payload;
    },
    [getTotalHTDHoursPerFranchisee.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    //getHTDUtilisationReportByTrailerTypeAndFranchisee
    [getHTDUtilisationReportByTrailerTypeAndFranchisee.pending]: (state) => {
      state.loading = true;
    },
    [getHTDUtilisationReportByTrailerTypeAndFranchisee.fulfilled]: (state, action) => {
      state.loading = false;
      state.htdUtilisationReportByTrailerTypeAndFranchisee = action.payload;
    },
    [getHTDUtilisationReportByTrailerTypeAndFranchisee.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getIncomePerTrailerData
    [getIncomePerTrailerData.pending]: (state) => {
      state.loading = true;
    },
    [getIncomePerTrailerData.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomePerTrailerData = action.payload;
    },
    [getIncomePerTrailerData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getIncomePerTrailerData
    [getTrailerPerformanceData.pending]: (state) => {
      state.loading = true;
    },
    [getTrailerPerformanceData.fulfilled]: (state, action) => {
      state.loading = false;
      state.trailerPerformanceData = action.payload;
    },
    [getTrailerPerformanceData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getIncomePerLicenseeData
    [getIncomePerLicenseeData.pending]: (state) => {
      state.loading = true;
    },
    [getIncomePerLicenseeData.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomePerLicenseeData = action.payload;
    },
    [getIncomePerLicenseeData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getAvgDistanceGraphData
    [getAvgDistanceGraphData.pending]: (state) => {
      state.loading = true;
    },
    [getAvgDistanceGraphData.fulfilled]: (state, action) => {
      state.loading = false;
      state.avgDistanceGraphData = action.payload;
    },
    [getAvgDistanceGraphData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getUnserviceBookingPerformanceData
    [getUnserviceBookingPerformanceData.pending]: (state) => {
      state.loading = true;
    },
    [getUnserviceBookingPerformanceData.fulfilled]: (state, action) => {
      state.loading = false;
      state.unserviceBookingPerformanceData = action.payload;
    },
    [getUnserviceBookingPerformanceData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getIncomePerCityData
    [getIncomePerCityData.pending]: (state) => {
      state.loading = true;
    },
    [getIncomePerCityData.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomePerCityData = action.payload;
    },
    [getIncomePerCityData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getIncomePerUpSellItemsData
    [getIncomePerUpSellItemsData.pending]: (state) => {
      state.loading = true;
    },
    [getIncomePerUpSellItemsData.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomePerUpSellItemsData = action.payload;
    },
    [getIncomePerUpSellItemsData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getOutOfServiceTrailersData
    [getOutOfServiceTrailersData.pending]: (state) => {
      state.loading = true;
    },
    [getOutOfServiceTrailersData.fulfilled]: (state, action) => {
      state.loading = false;
      state.outOfServiceTrailersData = action.payload;
    },
    [getOutOfServiceTrailersData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getLateHiresData
    [getLateHiresData.pending]: (state) => {
      state.loading = true;
    },
    [getLateHiresData.fulfilled]: (state, action) => {
      state.loading = false;
      state.lateHiresData = action.payload;
    },
    [getLateHiresData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getAvgPickUpDistanceByFranchisee
    [getAvgPickUpDistanceByFranchisee.pending]: (state) => {
      state.loading = true;
    },
    [getAvgPickUpDistanceByFranchisee.fulfilled]: (state, action) => {
      state.loading = false;
      state.avgPickUpDistanceByFranchisee = action.payload;
    },
    [getAvgPickUpDistanceByFranchisee.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getAvgDropOffDistanceByFranchisee
    [getAvgDropOffDistanceByFranchisee.pending]: (state) => {
      state.loading = true;
    },
    [getAvgDropOffDistanceByFranchisee.fulfilled]: (state, action) => {
      state.loading = false;
      state.avgDropOffDistanceByFranchisee = action.payload;
    },
    [getAvgDropOffDistanceByFranchisee.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getExtendedHiresData
    [getExtendedHiresData.pending]: (state) => {
      state.loading = true;
    },
    [getExtendedHiresData.fulfilled]: (state, action) => {
      state.loading = false;
      state.extendedHiresData = action.payload;
    },
    [getExtendedHiresData.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },

    // getAverageHireLength
    [getAverageHireLength.pending]: (state) => {
      state.loading = true;
    },
    [getAverageHireLength.fulfilled]: (state, action) => {
      state.loading = false;
      state.averageHireLength = action.payload;
    },
    [getAverageHireLength.rejected]: (state, action) => {
      toast.error(action?.error?.message);
      state.loading = false;
    },
  },
});

export const { setSelectedFilterValues, setAnalyticDate } = analyticSlice.actions;

export default analyticSlice.reducer;
