import React, { useEffect, useRef, useState } from "react";
import WrapHeaderSubPage from "../../../../components/WrapHeaderSubPage";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { Box, Button, Popover, Typography, CircularProgress } from "@material-ui/core";
import { CommonBox, ContentOfBox, StyledDivider, Title } from "./styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as PreviousArrow } from "../../../../img/previousArrow.svg";
import { ReactComponent as ForwardArrow } from "../../../../img/fowardArrow.svg";
import { Image as NoImages } from "../../../../img/Image";
import { useUploadFiles } from "../../../../hooks/useUploadFiles";
import { atom, useAtom, useSetAtom } from "jotai";
import { focusAtom } from "jotai-optics";
import {
  useGetPromotionalBanners,
  useUpdatePromotionalBanners,
  useUploadPromotionalBanners,
} from "../../../../hooks/DiscountsPromotions/useDiscounts";
import StickyNavigationSection from "../../../../components/StickyNavigationSection";
import { SketchPicker } from "react-color";
import { getCRUD_AllowByRoles } from "../../../../helpers/common";
import { USER_ROLES } from "../../../../constants/common";

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
};

const mobileImageAtom = atom({ items: [], selectedIndex: undefined });
const webImageAtom = atom({ items: [], selectedIndex: undefined });
const mobileItemsAtom = focusAtom(mobileImageAtom, (optic) => optic.prop("items"));
const mobileSelectedIndexAtom = focusAtom(mobileImageAtom, (optic) => optic.prop("selectedIndex"));
const webItemsAtom = focusAtom(webImageAtom, (optic) => optic.prop("items"));
const webSelectedIndexAtom = focusAtom(webImageAtom, (optic) => optic.prop("selectedIndex"));

const UPDATE_TYPE = {
  MOBILE: "MOBILE",
  WEB: "WEB",
};

const updateColorAtom = atom(null, (get, set, update) => {
  // update: {type, color}
  let images = get(mobileItemsAtom);
  let currentIndex = get(mobileSelectedIndexAtom);
  let updateImagesAtom = mobileItemsAtom;
  if (update?.type === UPDATE_TYPE?.WEB) {
    images = get(webItemsAtom);
    currentIndex = get(webSelectedIndexAtom);
    updateImagesAtom = webItemsAtom;
  }
  if (currentIndex > -1) {
    const list = images?.map((item, index) => {
      if (index === currentIndex) {
        return {
          url: item.url,
          color: update?.color || DEFAULT_COLOR,
        };
      }
      return item;
    });
    set(updateImagesAtom, list);
  }
});

const DEFAULT_COLOR = "#44d62c";

const ColorPickerSection = ({ type, color }) => {
  const updateColor = useSetAtom(updateColorAtom);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (color) => {
    updateColor({ type, color: color.hex });
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          cursor: "pointer",
          border: "1px solid white",
          borderRadius: 8,
          p: 1.5,
        }}
        onClick={handleClick}
      >
        <Typography>Background Color: </Typography>
        <Box
          sx={{
            borderRadius: 8,
            width: 50,
            aspectRatio: "1/1",
            backgroundColor: color,
          }}
        />
      </Box>
      <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <SketchPicker color={color} onChange={handleChange} />
      </Popover>
    </>
  );
};

const UploadMobileImagesSection = ({ isAllow }) => {
  const { isAllowToCreate, isAllowToUpdate, isAllowToDelete } = isAllow;
  const inputRef = useRef();
  const [items, setItems] = useAtom(mobileItemsAtom);
  const [selectedIndex, setSelectedIndex] = useAtom(mobileSelectedIndexAtom);
  const { mutate, isLoading } = useUploadPromotionalBanners();
  const { handleFileChange } = useUploadFiles({
    types: ["image"] /* typeAllow */,
  });

  const onHandleUploadImages = (event) => {
    const allowImages = handleFileChange(event.target.files);
    mutate(allowImages, {
      onSuccess: (uploadedImages) => {
        if (uploadedImages && uploadedImages?.length > 0) {
          const uploadImagesWithColor = uploadedImages.map((url) => ({
            url,
            color: DEFAULT_COLOR,
          }));
          setItems([...items, ...uploadImagesWithColor]);
          setSelectedIndex(items.length + uploadedImages.length - 1);
        }
      },
    });
    inputRef.current.value = "";
  };

  const onChangeSelectedIndex = (newSelectedIndex) => {
    setSelectedIndex(newSelectedIndex);
  };

  const onHandleDeleteImage = () => {
    const clonedItems = [...items];
    if (selectedIndex !== undefined && selectedIndex > -1) {
      clonedItems.splice(selectedIndex, 1);
      setItems(clonedItems);
      setSelectedIndex(
        clonedItems.length > 0 ? (selectedIndex - 1 > -1 ? selectedIndex - 1 : 0) : 0
      );
    }
  };
  return (
    <CommonBox sx={{ height: "max-content" }}>
      <ContentOfBox>
        <Title>Mobile banner - 1920 x 1080px</Title>
      </ContentOfBox>
      <StyledDivider />
      <ContentOfBox sx={{ display: "grid", gap: "2rem" }}>
        <Box sx={{ display: "grid", placeItems: "center", px: "34px" }}>
          {items?.length > 0 ? (
            <Box>
              <Carousel
                selectedItem={selectedIndex}
                onChange={onChangeSelectedIndex}
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <Button onClick={onClickHandler} style={{ ...arrowStyles, left: -60 }}>
                      <PreviousArrow />
                    </Button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <Button onClick={onClickHandler} style={{ ...arrowStyles, right: -60 }}>
                      <ForwardArrow />
                    </Button>
                  )
                }
              >
                {items?.map(({ url }) => (
                  <div key={url}>
                    <img src={url} style={{ aspectRatio: "1/1", objectFit: "contain" }} />
                  </div>
                ))}
              </Carousel>
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                backgroundColor: "#282828",
                width: 300,
                aspectRatio: 2 / 1,
              }}
            >
              <NoImages width={63} height={53} />
            </Box>
          )}
        </Box>
        <Box px={4.5} sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {isAllowToCreate && isAllowToUpdate ? (
            <>
              <input
                accept="image/jpg, image/png, .jpg"
                multiple={true}
                style={{ display: "none" }}
                id="web-file"
                type="file"
                onChange={onHandleUploadImages}
                ref={inputRef}
              />
              <label htmlFor="web-file">
                <Button disabled={isLoading} variant="outlined" color="primary" component="span">
                  {isLoading ? "Uploading..." : "Upload Image"}
                </Button>
              </label>
            </>
          ) : null}
          {items?.length > 0 && isAllowToDelete ? (
            <Button
              variant="text"
              style={{ color: "red" }}
              onClick={onHandleDeleteImage}
              disabled={isLoading}
            >
              Delete Image
            </Button>
          ) : null}
          <Typography>PNG, JPG</Typography>
        </Box>
      </ContentOfBox>
    </CommonBox>
  );
};

const UploadWebImagesSection = ({ isAllow }) => {
  const { isAllowToCreate, isAllowToUpdate, isAllowToDelete } = isAllow;
  const inputRef = useRef();
  const [items, setItems] = useAtom(webItemsAtom);
  const [selectedIndex, setSelectedIndex] = useAtom(webSelectedIndexAtom);
  const { mutate, isLoading } = useUploadPromotionalBanners();
  const { handleFileChange } = useUploadFiles({
    types: ["image"] /* typeAllow */,
  });

  const onHandleUploadImages = (event) => {
    const allowImages = handleFileChange(event.target.files);
    mutate(allowImages, {
      onSuccess: (uploadedImages) => {
        if (uploadedImages && uploadedImages?.length > 0) {
          const uploadImagesWithColor = uploadedImages.map((url) => ({
            url,
            color: DEFAULT_COLOR,
          }));
          setItems([...items, ...uploadImagesWithColor]);
          setSelectedIndex(items.length + uploadedImages.length - 1);
        }
      },
    });
    inputRef.current.value = "";
  };

  const onChangeSelectedIndex = (newSelectedIndex) => {
    setSelectedIndex(newSelectedIndex);
  };

  const onHandleDeleteImage = () => {
    const clonedItems = [...items];
    if (selectedIndex !== undefined && selectedIndex > -1) {
      clonedItems.splice(selectedIndex, 1);
      setItems(clonedItems);
      setSelectedIndex(
        clonedItems.length > 0 ? (selectedIndex - 1 > -1 ? selectedIndex - 1 : 0) : 0
      );
    }
  };
  return (
    <CommonBox sx={{ height: "max-content" }}>
      <ContentOfBox>
        <Title>Web banner - 2400 x 400px</Title>
      </ContentOfBox>
      <StyledDivider />
      <ContentOfBox sx={{ display: "grid", gap: "2rem" }} style={{ paddingInline: 16 }}>
        <Box sx={{ display: "grid", placeItems: "center", px: "34px" }}>
          {items?.length > 0 ? (
            <Box>
              <Carousel
                selectedItem={selectedIndex}
                onChange={onChangeSelectedIndex}
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                  hasPrev && (
                    <Button onClick={onClickHandler} style={{ ...arrowStyles, left: -60 }}>
                      <PreviousArrow />
                    </Button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                  hasNext && (
                    <Button onClick={onClickHandler} style={{ ...arrowStyles, right: -60 }}>
                      <ForwardArrow />
                    </Button>
                  )
                }
              >
                {items?.map(({ url }) => (
                  <div key={url}>
                    <img
                      src={url}
                      style={{
                        objectFit: "contain",
                        height: 300,
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                backgroundColor: "#282828",
                width: 300,
                aspectRatio: 2 / 1,
              }}
            >
              <NoImages width={63} height={53} />
            </Box>
          )}
        </Box>
        <Box px={4.5} sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {isAllowToCreate && isAllowToUpdate ? (
            <>
              <input
                accept="image/jpg, image/png, .jpg"
                multiple={true}
                style={{ display: "none" }}
                id="mobile-file"
                type="file"
                onChange={onHandleUploadImages}
                ref={inputRef}
              />
              <label htmlFor="mobile-file">
                <Button disabled={isLoading} variant="outlined" color="primary" component="span">
                  {isLoading ? "Uploading..." : "Upload Image"}
                </Button>
              </label>
            </>
          ) : null}
          {items?.length > 0 && isAllowToDelete ? (
            <Button
              variant="text"
              style={{ color: "red" }}
              onClick={onHandleDeleteImage}
              disabled={isLoading}
            >
              Delete Image
            </Button>
          ) : null}
          <Typography>PNG, JPG</Typography>
          {isAllowToCreate && isAllowToUpdate ? (
            <ColorPickerSection
              type={UPDATE_TYPE.WEB}
              color={items?.[selectedIndex]?.color || DEFAULT_COLOR}
            />
          ) : null}
        </Box>
      </ContentOfBox>
    </CommonBox>
  );
};

const PromotionalBanners = () => {
  const isAllow = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
    D_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });

  const { isAllowToCreate, isAllowToUpdate } = isAllow;
  const { data, isLoading: isLoadingBanners } = useGetPromotionalBanners();
  const [webImage, setWebImage] = useAtom(webImageAtom);
  const [mobileImage, setMobileImage] = useAtom(mobileImageAtom);
  const { mutate, isLoading } = useUpdatePromotionalBanners();

  useEffect(() => {
    if (data?.desktopBanners?.length > 0) {
      setWebImage({
        items: data?.desktopBanners?.map(({ url, color }) => ({ url, color })),
        selectedIndex: 0,
      });
    }
    if (data?.mobileBanners?.length > 0) {
      setMobileImage({
        items: data?.mobileBanners?.map(({ url, color }) => ({ url, color })),
        selectedIndex: 0,
      });
    }
  }, [data]);
  const onHandleSave = () => {
    const payload = {
      mobileBanners: mobileImage.items,
      desktopBanners: webImage.items,
    };
    mutate(payload);
  };
  return (
    <>
      <WrapHeaderSubPage nameHeader="Promotional Banners" style={{ marginBottom: "50px" }} />
      <Breadcrumbs />
      {isLoadingBanners ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box mb={10} sx={{ display: "grid", gridTemplateColumns: "650px auto", gap: 16 }}>
          <UploadMobileImagesSection isAllow={isAllow} />
          <UploadWebImagesSection isAllow={isAllow} />
        </Box>
      )}
      {isAllowToCreate && isAllowToUpdate ? (
        <StickyNavigationSection isBottom>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 12,
              padding: 16,
            }}
          >
            <Button disabled={isLoading} variant="contained" color="primary" onClick={onHandleSave}>
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </StickyNavigationSection>
      ) : null}
    </>
  );
};

export default PromotionalBanners;
