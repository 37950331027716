import { Typography, styled } from "@material-ui/core";

export const Text50 = styled(Typography)({
  fontSize: "50px",
  fontWeight: 500,
});

export const Text40 = styled(Typography)({
  fontSize: "40px",
  fontWeight: 500,
});

export const Text32 = styled(Typography)({
  fontSize: "32px",
  fontWeight: 500,
});
export const Text32Weight700 = styled(Text32)({
  fontWeight: 700,
});

export const Text18 = styled(Typography)({
  fontSize: "18px",
  fontWeight: 500,
});
export const Text18Weight700 = styled(Text18)({
  fontWeight: 700,
});
export const Text18Weight600 = styled(Text18)({
  fontWeight: 600,
});

export const Text16 = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
});
export const Text16Weight700 = styled(Text16)({
  fontWeight: 700,
});
export const Text16Weight600 = styled(Text16)({
  fontWeight: 600,
});
export const Text16Weight400 = styled(Text16)({
  fontWeight: 400,
});

export const Text14 = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
});
export const Text14Weight400 = styled(Text14)({
  fontWeight: 400,
});
