import { useContext } from 'react';
import { OverLayContext } from '../context/OverlayProvider'

export function useLoading() {
    const { loading, setLoading } = useContext(OverLayContext);

    const showLoading = () => {
        setLoading(true);
    };

    const hideLoading = () => {
        setLoading(false);
    };

    return [showLoading, hideLoading, loading];
}
