import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "./Menu.styles";

export function Menu({ overviewTabStyles, reportsTabStyles, hireTheDriverTabStyles }) {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.menuSection}>
      <Button
        to="/analytics/overview"
        component={Link}
        className={`${classes.overviewTab} ${overviewTabStyles}`}
      >
        Overview
      </Button>
      <Button
        to="/analytics/hire-the-driver"
        component={Link}
        className={`${classes.reportsTab} ${hireTheDriverTabStyles}`}
      >
        Hire the Driver
      </Button>
      <Button
        to="/analytics/reports"
        component={Link}
        className={`${classes.reportsTab} ${reportsTabStyles}`}
      >
        Reports
      </Button>
    </Box>
  );
}
