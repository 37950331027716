import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import "./style.scss";
export default function OnOffSwitch({ checked, onChange }) {

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 65,
      height: 25,
      padding: 0,
      marginLeft: 2,
      display: "flex",
      borderRadius: "53.125px",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      marginLeft: 0,
      "&$checked": {
        transform: "translateX(40px)",
        color: theme.palette.common.white,

        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: "none",
    },
    track: {
      border: "none",
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "rgba(68,214,44,0.2)",
      "&:before, &:after": {
        position: "absolute",
        top: "41%",
        transform: "translateY(-50%)",
        fontWeight: 700,
        fontSize: 14,
        width: 16,
        height: 16,
      },
      "&:before": {
        content: !checked ? `"Off"` : '',
        left: 35,
      },
      "&:after": {
        content: checked ? `"On"` : '',
        right: 35,
      },
    },
    checked: {},
  }))(Switch);

  return (
      <AntSwitch checked={checked} onChange={onChange} name="checkedC" />
  );
}
