import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  analyticsContainer: {
    marginTop: 24,
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  analyticsHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  headerText: {
    lineHeight: "60px",
  },
  analyticsFunctionGroup: {
    display: "flex",
    transform: "translateY(4px)",
    flexWrap: "wrap",
    gap: 16,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  analyticsContent: {
    display: "flex",
    flexDirection: "column",
  },
}));
