import React, { useEffect, useState } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import { useLoading } from "../../hooks/useLoading";
import DialogChangeStatus from "../../components/DialogChangeStatus";
import { titleCase } from "../../helpers/format";
import ColumnInTable from "../../components/ColumnInTable";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core";
import { USER_ROLES, common } from "../../constants/common";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { getCRUD_AllowByRoles } from "../../helpers/common";

const DEACTIVATE = "Deactivate";
const REACTIVATE = "Reactivate";
const PURCHASED = "Purchased";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const useStyles = makeStyles((theme) => ({
  purchasedText: {
    color: theme.palette.primary.main,
    padding: "8px 9px",
    marginRight: "16px",
  },
}));

const UpSellDetailListInLicensee = () => {
  const { isAllowToCreate, isAllowToUpdate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  const { licenseeId, firstname, upsellItemId } = useParams();
  const history = useHistory();
  const [upsellList, setUpsellList] = useState([]);
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    getAll();
  }, []);

  // HANDLE OPEN DIALOG
  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });

  // HANDLE OPEN DIALOG
  function toggleDialog(data, method) {
    if (method === "active") {
      if (data.isActive) {
        setDataDialog({
          isActive: false,
          header: "Are you sure you want to deactivate this upsell item from the Franchisee ?",
          subtitle:
            "The Franchisee will lose access to this items. You can grant them access once any issues are resolved",
          submitTitle: "Deactivate item",
          onActive: () => handleChangeActivate(data.id),
        });
      } else {
        setDataDialog({
          isActive: true,
          header: "Are you sure you want to reactivate this upsell item from the Franchisee ?",
          subtitle: "The Franchisee will gain access to this item again",
          submitTitle: "Reactivate item",
          onActive: () => handleChangeActivate(data.id),
        });
      }
    }
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  function getAll() {
    showLoading();
    axios
      .get(`/v1/admin/licensee/trailers-Upsells/${licenseeId}?upsellItemId=${upsellItemId}`)
      .then((response) => {
        hideLoading();
        const list = response.data.licenseeUpsellItemsObj;
        return setUpsellList(setNumberForEachUpsellItemInList(list));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  function handleChangeActivate(id) {
    showLoading();
    axios
      .put(`v1/admin/upsellitem-licensee-active/${licenseeId}/${id}`)
      .then((response) => {
        hideLoading();
        getAll();
        setOpen(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const columns = [
    {
      title: <span className="title-table">Upsell Item</span>,
      field: "trailerType",
      render: (rowData) => {
        return (
          <div style={{ minWidth: "450px", marginRight: "25px" }}>
            {rowData.upsellItemId.name + "-" + rowData.number}
          </div>
        );
      },
    },
    {
      title: "Item Type",
      field: "itemType",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="194">
            {titleCase(
              rowData.upsellItemId?.consumeable
                ? common.itemType.consumable.label
                : rowData.upsellItemId?.premium
                ? common.itemType.premium.label
                : common.itemType.upsell.label
            )}
          </ColumnInTable>
        );
      },
    },

    {
      title: "Price",
      field: "price",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="164">
            {rowData.upsellItemId.consumeable
              ? "$" + Math.ceil(rowData.price)
              : "$" + rowData.hireCost?.toFixed(2) + " per day"}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => {
        return (
          <div
            style={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {rowData.upsellItemId.description}
          </div>
        );
      },
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        if (rowData.isSold) {
          return (
            <WrapButtonFlexRight>
              <div className={classes.purchasedText}>{PURCHASED}</div>
            </WrapButtonFlexRight>
          );
        }
        if (isAllowToUpdate) {
          return (
            <WrapButtonFlexRight>
              <ButtonActionSubPage
                colorError={rowData.isActive ? true : false}
                onClick={() => {
                  toggleDialog(
                    {
                      id: rowData._id,
                      isActive: rowData.isActive,
                    },
                    "active"
                  );
                }}
              >
                {rowData.isActive ? DEACTIVATE : REACTIVATE}
              </ButtonActionSubPage>
            </WrapButtonFlexRight>
          );
        }
        return null;
      },
    },
  ];

  function setNumberForEachUpsellItemInList(list) {
    let countSimilarId = {};
    return list.reduce((prev, current) => {
      if (countSimilarId[current.upsellItemId._id]) {
        countSimilarId = {
          ...countSimilarId,
          [current.upsellItemId._id]: ++countSimilarId[current.upsellItemId._id],
        };
        return [...prev, { ...current, number: countSimilarId[current.upsellItemId._id] }];
      }
      countSimilarId = { ...countSimilarId, [current.upsellItemId._id]: 1 };
      return [...prev, { ...current, number: 1 }];
    }, []);
  }

  return (
    <div>
      <WrapHeaderSubPage nameHeader={`${firstname}'s Upsell Items`}>
        {isAllowToCreate ? (
          <ButtonActionHeader
            onClick={() => history.push(`/licensee/${firstname}/${licenseeId}/upsellitems/add`)}
          >
            Add Item
          </ButtonActionHeader>
        ) : null}
      </WrapHeaderSubPage>
      <Breadcrumbs />
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={upsellList}
        options={tableOptions}
      />
    </div>
  );
};

export default UpSellDetailListInLicensee;
