import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import getQueryKey from "../../helpers/getQueryKey";
import axiosInstance from "../../helpers/axios";
import { toast } from "react-toastify";

export const useGetAllTrailers = () => {
  return useQuery({
    queryKey: getQueryKey.getTrailerServiceChecklistQueryKey(),
    queryFn: () => {
      return axiosInstance.get("/v1/admin/get-all-trailers");
    },
    select: (response) => response?.data?.dataObj,
  });
};

export const useReorderTrailers = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return axiosInstance.post("/v1/admin/trailers/reorder", payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey.getTrailerServiceChecklistQueryKey());
    },
  });
};

export const useGetTrailerServiceChecklist = ({ trailerId }) => {
  return useQuery({
    queryKey: getQueryKey.getTrailerServiceChecklistQueryKey(trailerId),
    queryFn: () => {
      return axiosInstance.get(`v1/admin/service-checklist/${trailerId}`);
    },
    select: (response) => response?.data?.data?.items ?? [],
  });
};

export const useSaveTrailerServiceChecklist = ({ trailerId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => axiosInstance.post(`v1/admin/service-checklist/${trailerId}`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey.getTrailerServiceChecklistQueryKey(trailerId));
      toast.success("Updated Service Checklist");
    },
  });
};
