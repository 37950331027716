import {
  Box,
  Typography,
  IconButton,
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  styled,
  Button,
  InputAdornment,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { FieldFormAdd } from "../FieldFormAdd";
import debounce from "lodash/debounce";

export const CATEGORIES = [
  { value: "trailers", label: "Trailers" },
  { value: "additionalItems", label: "Additional items" },
  { value: "consumableItems", label: "Consumable items" },
  { value: "hireTheDriver", label: "Hire the Driver" },
];

const StyledListItem = styled(ListItem)({
  "& .MuiButtonBase-root": {
    marginLeft: 0,
  },
});

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const AddCategoriesModal = ({ onCloseModal }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const items = values?.value?.appliesTo?.items || [];
  const [appliesToItems, setAppliesToItems] = useState(items || []);
  const [search, setSearch] = useState("");
  const handleToggle = (toggleItem) => {
    const clonedItems = [...appliesToItems];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === toggleItem.value);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
    } else {
      clonedItems.push({ id: toggleItem.value, label: toggleItem.label });
    }
    setAppliesToItems(clonedItems);
  };

  const isAllSelected = useMemo(
    () => appliesToItems?.length === CATEGORIES?.length,
    [appliesToItems]
  );

  const handleSelectAll = () => {
    if (isAllSelected) {
      setAppliesToItems([]);
    } else {
      setAppliesToItems(CATEGORIES.map(({ value, label }) => ({ id: value, label })));
    }
  };

  const onHandleSave = () => {
    setFieldValue("value.appliesTo.items", appliesToItems);
    onCloseModal();
  };

  const displayCategories = useMemo(
    () =>
      CATEGORIES.filter(({ label }) => label.toLocaleLowerCase().includes(search.toLowerCase())),
    [search]
  );

  const onChangeSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  return (
    <>
      <IconButton onClick={onCloseModal} style={{ position: "absolute", top: 0, right: 0 }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: "grid", gap: 8, mt: 2, minWidth: 432 }}>
        <Typography style={{ fontSize: 18, fontWeight: 700, textAlign: "center" }}>
          Add categories
        </Typography>
        <FieldFormAdd
          fullWidth
          autoComplete="off"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder={"Search Categories"}
          style={{ marginBottom: 0, marginTop: 16 }}
          onChange={(event) => onChangeSearch(event.target.value)}
        />
        <List className={classes.root}>
          <StyledListItem role={undefined} button onClick={handleSelectAll}>
            <ListItemIcon style={{ margin: 0 }}>
              <Checkbox
                color="primary"
                edge="start"
                checked={isAllSelected}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "select-all" }}
              />
            </ListItemIcon>
            <ListItemText id={"select-all"} primary="All categories" />
          </StyledListItem>
          {displayCategories.map(({ label, value }) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <StyledListItem
                key={value}
                role={undefined}
                button
                onClick={() => handleToggle({ value, label })}
              >
                <ListItemIcon style={{ margin: 0 }}>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={appliesToItems.some(({ id }) => id === value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={label} />
              </StyledListItem>
            );
          })}
        </List>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }}>
          <Button variant={"outlined"} color="primary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button variant={"contained"} color="primary" onClick={onHandleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddCategoriesModal;
