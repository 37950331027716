import React from "react";
import { isValidElement, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  styled,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Stack } from "../components/Common/Styled/CommonStyled";

// type DialogInfoType = {
//   title?: string,
//   content?: string | ReactElement,
//   handleConfirm: () => void,
//   handleDecline?: () => void,
//   approveText?: string,
//   disapproveText?: string,
//   hideApproveButton?: boolean,
//   hideDisapproveButton?: boolean,
//   showCloseIcon?: boolean,
//   additionalStyles?: SxProps<Theme>,
// };

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "24px",
  },
});

const useDialog = () => {
  const [open, setOpen] = useState(null);

  const handleOpenDialog = (info) => {
    setOpen(info);
  };

  const handleCloseDialog = () => {
    setOpen(null);
  };

  const renderDialog = useMemo(
    () =>
      open ? (
        <StyledDialog
          open={!!open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={open?.additionalStyles ? { ...open.additionalStyles } : {}}
        >
          {open?.title && (
            <DialogTitle
              id="alert-dialog-title"
              style={{ fontSize: 18, fontWeight: 700, color: "#fff" }}
            >
              {open.title}
            </DialogTitle>
          )}
          {open?.showCloseIcon ? (
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          {open?.content && (
            <DialogContent>
              {isValidElement(open.content) ? (
                open.content
              ) : (
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: 16, fontWeight: 400, color: "#fff", lineHeight: "24px" }}
                >
                  {open.content}
                </DialogContentText>
              )}
            </DialogContent>
          )}
          <DialogActions style={{ background: "inherit" }}>
            <Stack sx={{ gap: 18, width: "100%" }}>
              {open.hideDisapproveButton ? null : (
                <Button
                  onClick={open?.handleDecline ? open.handleDecline : handleCloseDialog}
                  variant="outlined"
                  color="primary"
                  style={{ flex: 1 }}
                >
                  {open?.disapproveText ?? "No"}
                </Button>
              )}
              {open.hideApproveButton ? null : (
                <Button
                  onClick={open?.handleConfirm}
                  autoFocus
                  variant="contained"
                  style={{ flex: 1 }}
                  color="primary"
                >
                  {open?.approveText ?? "Yes"}
                </Button>
              )}
            </Stack>
          </DialogActions>
        </StyledDialog>
      ) : null,
    [open]
  );

  return { renderDialog, handleOpenDialog, handleCloseDialog };
};

export default useDialog;
