import Input from "./Input";
import React from "react";
import Select from "./Select";

export const CONTROL_TYPE = {
  INPUT: "input",
  SELECT: "select",
};

const FormikControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case CONTROL_TYPE.INPUT:
      return <Input {...rest} />;
    case CONTROL_TYPE.SELECT:
      return <Select {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
