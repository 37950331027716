import * as React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useStyles } from "./DatePicker.styles";

export function DatePicker({
  label,
  value,
  onChange,
  ariaLabel,
  id = "date-picker-inline",
  variant = "inline",
  inputVariant = "outlined",
  format = "MM/dd/yyyy",
}) {
  const classes = useStyles();

  return (
    <KeyboardDatePicker
      id={id}
      label={label}
      variant={variant}
      inputVariant={inputVariant}
      format={format}
      value={value}
      onChange={onChange}
      className={classes.datePicker}
      KeyboardButtonProps={{
        "aria-label": ariaLabel,
      }}
    />
  );
}
