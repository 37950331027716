import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../helpers/axios";
import { toast } from "react-toastify";

export const useResendInvoice = (transactionId) => {
  return useMutation({
    mutationFn: () => axiosInstance.get(`/v1/admin/booking/${transactionId}/send-receipt`),
    onSuccess: () => {
      toast.success("Invoice has been re-sent to the customer");
    },
  });
};

export const useCancelOrder = (transactionId) => {
  return useMutation({
    mutationFn: () => axiosInstance.post(`/v1/admin/booking/${transactionId}/cancel`),
    onSuccess: () => {
      toast.success("Order had been canceled");
    },
  });
};
