import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatAmount, formatHour } from "../../utils";

export function TotalHTDHoursPerFranchiseePage() {
  const classes = useStyles();
  const { htdTotalHoursPerFranchisee } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Total HTD hours per Franchisee";
    if (htdTotalHoursPerFranchisee?.items) {
      const dataTransformed = htdTotalHoursPerFranchisee.items.map((item) => ({
        franchisee: item?.licensee?.firstname
          ? `${item?.licensee?.firstname} ${item?.licensee?.lastname}`
          : "--",
        numberOfHires: item?.hiresWithHtd ?? "--",
        totalHTDHours: formatHour(item.totalHtdHours),
        totalHTDIncome: formatAmount(item.totalHtdIncome, 2),
        averageHTDHoursPerHire: item?.averageHoursPerHire ?? "--",
        averageHTDIncomePerHire: formatAmount(item.averageIncomePerHire, 2),
        utilisation: item?.htdUtilisationPercentage ?? "--",
        HTDRatio: item?.htdRatio ?? "--",
      }));
      setData(dataTransformed);
    }
  }, [htdTotalHoursPerFranchisee]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Number of hires with HTD",
      key: "numberOfHires",
      sortable: true,
    },
    {
      name: "Total HTD hours",
      key: "totalHTDHours",
      sortable: true,
    },
    {
      name: "Total HTD income",
      key: "totalHTDIncome",
      sortable: true,
    },
    {
      name: "Average HTD hours per hire with HTD included",
      key: "averageHTDHoursPerHire",
      sortable: true,
    },
    {
      name: "Average HTD income per hire",
      key: "averageHTDIncomePerHire",
      sortable: true,
    },
    {
      name: "HTD Utilisation",
      key: "utilisation",
      sortable: true,
    },
    {
      name: "HTD Ratio",
      key: "HTDRatio",
      sortable: true,
    },
  ];

  return (
    <>
      <Box component="main" className={classes.analyticsSinglePageContent}>
        <Card header="Total HTD hours per Franchisee">
          <SortableTable
            rows={data}
            columns={columns}
            gridTemplateColumns="15% 10% 15% 15% 15% 10% 10% 10%"
          />
        </Card>
      </Box>
    </>
  );
}
