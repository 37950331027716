import React, { useMemo } from "react";
import WrapHeaderSubPage from "../../../../components/WrapHeaderSubPage";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  styled,
} from "@material-ui/core";
import StickyNavigationSection from "../../../../components/StickyNavigationSection";
import { useStyles } from "./styled";
import { useFormik, FormikProvider, useFormikContext, useField } from "formik";
import * as yup from "yup";
import FormikControl, { CONTROL_TYPE } from "../../../../components/Formik/FormikControl";
import NumericFormatCustom from "../../../../components/NumberFormat/NumberFormat";
import {
  Search as SearchIcon,
  Close as CloseIcon,
  CalendarToday as CalendarTodayIcon,
  AccessTime as AccessTimeIcon,
} from "@material-ui/icons";
import { FieldFormAdd } from "../../../../components/FieldFormAdd";
import useModal, { MODAL_TYPES } from "../../../../hooks/useModal";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { formatCurrency } from "../../../../helpers/format";
import {
  TYPE_PROMOTION_ITEMS,
  useCreateNewOrEditDiscount,
  useGetDiscountDetails,
} from "../../../../hooks/DiscountsPromotions/useDiscounts";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { CATEGORIES } from "../../../../components/Modals/AddCategoriesModal";
import { getCRUD_AllowByRoles } from "../../../../helpers/common";
import { USER_ROLES } from "../../../../constants/common";

const CommonBox = styled(Box)({
  borderRadius: 8,
  border: "1px solid rgba(226, 233, 251, 0.298)",
  background: "rgba(251, 252, 254, 0.00)",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
});

const StickyBox = styled(Box)({
  position: "sticky",
  top: "74px",
  height: "max-content",
});

const StyledChip = styled(Chip)({
  backgroundColor: "rgba(54, 54, 54, 0.70)",
  color: "#fff",
  "&.MuiChip-deletable:focus": {
    backgroundColor: "rgba(54, 54, 54, 0.70)",
  },
  "& .MuiSvgIcon-root.MuiChip-deleteIcon": {
    color: "#000",
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    marginLeft: 8,
  },
});

const METHODS = [
  {
    value: "discountCode",
    label: "Discount code",
    textFieldLabel: "Discount code",
    subText: "Customers must enter this code at checkout",
  },
  {
    value: "amountOffProducts",
    label: "Automatic discount",
    textFieldLabel: "Title",
    subText: "Customers will see this in their cart and at checkout.",
  },
];

const VALUE_TYPE = [
  {
    value: "percentage",
    label: "Percentage",
  },
  {
    value: "fixedAmount",
    label: "Fixed amount",
  },
];

const APPLIES_TO_TYPE = [
  {
    value: "entireOrder",
    label: "Entire order",
  },
  {
    value: "categories",
    label: "Specific categories",
    placeHolder: "Search categories",
  },
  {
    value: "products",
    label: "Specific products",
    placeHolder: "Search products",
  },
];

const LIST_DISCOUNT = [
  "overallDiscount",
  "trailerDiscount",
  "upsellItemsDiscount",
  "consumableItemsDiscount",
  "hireTheDriverDiscount",
];

const MAPPING = {
  trailers: "trailerDiscount",
  additionalItems: "upsellItemsDiscount",
  consumableItems: "consumableItemsDiscount",
  hireTheDriver: "hireTheDriverDiscount",
};

const MAPPING_1 = {
  trailer: "trailerDiscount",
  upsellItem: "upsellItemsDiscount",
  consumableItem: "consumableItemsDiscount",
  premiumItem: "upsellItemsDiscount",
};

const MAPPING_3 = {
  trailerDiscount: "trailer",
  upsellItemsDiscount: "upsellItem",
  consumableItemsDiscount: "consumableItem",
};

const MAPPING_2 = {
  trailerDiscount: "trailers",
  upsellItemsDiscount: "additionalItems",
  consumableItemsDiscount: "consumableItems",
  hireTheDriverDiscount: "hireTheDriver",
};

const MINIMUM_REQUIREMENTS = [
  {
    value: "noMinimum",
    label: "No minimum requirements",
  },
  {
    value: "amount",
    label: "Minimum purchase amount ($)",
  },
  {
    value: "quantity",
    label: "Minimum quantity of items",
  },
];

const CUSTOMER_ELIGIBILITIES = [
  {
    value: "allCustomers",
    label: "All customers",
  },
  {
    value: "specificFranchisees",
    label: "Customers of specific franchisees",
    placeHolder: "Search franchisees",
  },
  {
    value: "specificCustomers",
    label: "Specific customers",
    placeHolder: "Search customers",
  },
];

const MAXIMUM_DISCOUNT_USES = [
  {
    value: "limitNumberOfTimes",
    label: "Limit number of times this discount can be used in total",
  },
  {
    value: "limitToOneUse",
    label: "Limit to one use per customer",
  },
  {
    value: "noLimit",
    label: "No limit",
  },
];

const ACTIVE_DAYS = [
  { value: "sun", label: "Sun" },
  { value: "mon", label: "Mon" },
  { value: "tue", label: "Tue" },
  { value: "wed", label: "Wed" },
  { value: "thu", label: "Thu" },
  { value: "fri", label: "Fri" },
  { value: "sat", label: "Sat" },
];

const validationSchema = yup.object({
  amountOffProducts: yup.object({
    method: yup.string().oneOf(METHODS.map((item) => item.value)),
    value: yup
      .string()
      .required("Required")
      .transform((value) => value.trim()),
  }),
  value: yup.object({
    type: yup.string().oneOf(VALUE_TYPE.map((item) => item.value)),
    value: yup
      .string()
      .test({
        name: "is-valid-value",
        skipAbsent: true,
        test(value, ctx) {
          const type = ctx?.parent?.type;
          if (
            type === VALUE_TYPE?.[0]?.value &&
            (!value ||
              !/^(?:[1-9]\d?(?:\.\d{1,2})?|0(?:\.[1-9]|[1-9]\d{0,1}(?:\.\d{1,2})?)|100(?:\.0{1,2})?)$/.test(
                value
              ))
          ) {
            return ctx.createError({ message: "Value is not valid" });
          } else if (
            type === VALUE_TYPE?.[1]?.value &&
            (!value || !/^(?:[1-9]\d*(?:\.\d{1,2})?|0\.[1-9]\d?|[1-9]\d*)$/.test(value))
          ) {
            return ctx.createError({ message: "Value is not valid" });
          }
          return true;
        },
      })
      .required("Required"),
    appliesTo: yup.object({
      type: yup.string().oneOf(APPLIES_TO_TYPE.map(({ value }) => value)),
      items: yup
        .array()
        .of(
          yup.object({
            id: yup.string(),
            label: yup.string(),
            type: yup.string().optional(),
          })
        )
        .when("type", {
          is: (type) =>
            [APPLIES_TO_TYPE?.[1]?.value, APPLIES_TO_TYPE?.[2]?.value].some(
              (item) => item === type
            ),
          then: (schema) => schema.min(1, "Required").required("Required"),
        }),
    }),
  }),
  minimumPurchaseRequirements: yup.object({
    type: yup.string().oneOf(MINIMUM_REQUIREMENTS.map(({ value }) => value)),
    value: yup
      .string()
      .when("type", {
        is: (type) => {
          return [MINIMUM_REQUIREMENTS?.[1]?.value, MINIMUM_REQUIREMENTS?.[2]?.value].some(
            (item) => item === type
          );
        },
        then: (schema) => schema.required("Required"),
      })
      .test({
        name: "is-minimum-valid",
        skipAbsent: true,
        test(value, ctx) {
          const type = ctx?.parent?.type;
          if (
            type === MINIMUM_REQUIREMENTS?.[1]?.value &&
            !/^(?!0+(\.0+)?$)\d+(\.\d{1,2})?$/.test(value)
          ) {
            return ctx.createError({ message: "Value is not valid" });
          } else if (type === MINIMUM_REQUIREMENTS?.[2]?.value && !/^[1-9]\d*$/.test(value)) {
            return ctx.createError({ message: "Value is not valid" });
          }
          return true;
        },
      }),
  }),
  customerEligibility: yup.object({
    type: yup.string().oneOf(CUSTOMER_ELIGIBILITIES.map(({ value }) => value)),
    items: yup
      .array()
      .of(
        yup.object({
          id: yup.string(),
          label: yup.string(),
        })
      )
      .when("type", {
        is: (type) =>
          [CUSTOMER_ELIGIBILITIES?.[1]?.value, CUSTOMER_ELIGIBILITIES?.[2]?.value].some(
            (item) => item === type
          ),
        then: (schema) => schema.min(1, "Required").required("Required"),
      }),
  }),
  maximumDiscountUses: yup.object({
    type: yup.string().oneOf(MAXIMUM_DISCOUNT_USES.map(({ value }) => value)),
    value: yup
      .string()
      .when("type", {
        is: (type) => {
          return [MAXIMUM_DISCOUNT_USES?.[0]?.value].some((item) => item === type);
        },
        then: (schema) => schema.required("Required"),
      })
      .test({
        name: "is-maximum-valid",
        skipAbsent: true,
        test(value, ctx) {
          const type = ctx?.parent?.type;
          if (type === MAXIMUM_DISCOUNT_USES?.[0]?.value && !/^[1-9]\d*$/.test(value)) {
            return ctx.createError({ message: "Value is not valid" });
          }
          return true;
        },
      }),
  }),
  isCombination: yup.boolean(),
  isActive: yup.boolean(),
  activeDates: yup.object({
    startDate: yup.number(),
    haveEndDate: yup.boolean(),
    endDate: yup.number().test({
      name: "is-valid-end-date",
      message: "End Date should be after Start Date",
      test(value, ctx) {
        if (value && ctx.parent?.haveEndDate && value < ctx?.parent?.startDate) {
          return false;
        }
        return true;
      },
    }),
    activeDays: yup.array().of(yup.string()),
  }),
});

const AmountOffProducts = () => {
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const classes = useStyles();
  const handleChangeMethod = (event) => {
    setFieldValue("amountOffProducts.method", event.target.value);
  };

  const textField = useMemo(() => {
    const type = values?.amountOffProducts?.method === METHODS[0].value ? METHODS[0] : METHODS[1];
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          marginTop: 24,
        }}
      >
        <Typography variant="h6" component="h6" className={classes.text16Weight700}>
          {type.textFieldLabel}
        </Typography>
        <FormikControl
          fullWidth
          control={CONTROL_TYPE.INPUT}
          name="amountOffProducts.value"
          label={type.textFieldLabel}
          variant="outlined"
          style={{ marginBottom: 0 }}
          autoComplete="off"
        />
        <Typography variant="h6" component="h6" className={classes.text16Weight500Gray}>
          {type.subText}
        </Typography>
      </Box>
    );
  }, [values?.amountOffProducts?.method]);

  return (
    <CommonBox>
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Amount off products
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" className={classes.text16Weight700}>
          Method
        </Typography>
        <Box sx={{ mt: 2 }}>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            aria-label="method"
            name="method"
            onChange={handleChangeMethod}
          >
            {METHODS.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio color="primary" checked={value === values?.amountOffProducts?.method} />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </Box>
        {textField}
      </Box>
    </CommonBox>
  );
};

const Value = () => {
  const { renderModal, setOpenModal } = useModal({});
  const formik = useFormikContext();
  const { values, setFieldValue, errors } = formik;
  const items = values?.value?.appliesTo?.items || [];
  const classes = useStyles();
  const isPercentageSelected = useMemo(
    () => values?.value?.type === VALUE_TYPE[0].value,
    [values?.value?.type]
  );
  const handleChangeAppliesToType = (event) => {
    setFieldValue("value.appliesTo.type", event.target.value);
    setFieldValue("value.appliesTo.items", []);
  };

  const selectedAppliesToType = useMemo(
    () => APPLIES_TO_TYPE.find(({ value }) => value === values?.value?.appliesTo?.type) || "",
    [values?.value?.appliesTo?.type]
  );

  const showSearchBar = useMemo(
    () =>
      [APPLIES_TO_TYPE[1].value, APPLIES_TO_TYPE[2].value].some(
        (value) => value === values?.value?.appliesTo?.type
      ),
    [values?.value?.appliesTo?.type]
  );

  const onHandleSearch = () => {
    switch (values?.value?.appliesTo?.type) {
      case APPLIES_TO_TYPE[1].value:
        setOpenModal({
          modalType: MODAL_TYPES.ADD_CATEGORIES_MODAL,
        });
        break;
      case APPLIES_TO_TYPE[2].value:
        setOpenModal({
          modalType: MODAL_TYPES.ADD_ITEM_PROMOTIONS_MODAL,
          data: {
            type: TYPE_PROMOTION_ITEMS.PRODUCTS,
            formikName: "value.appliesTo.items",
            initialValues: values?.value?.appliesTo?.items,
          },
        });
        break;
      default:
    }
  };

  const onHandleRemoveItem = (id) => {
    const clonedItems = [...items];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === id);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
      setFieldValue("value.appliesTo.items", clonedItems);
    }
  };

  return (
    <CommonBox>
      {renderModal}
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Value
        </Typography>
      </Box>
      <Box className={classes.contentOfBox}>
        <Box sx={{ display: "flex", gap: 16, alignItems: "flex-start" }}>
          <Box sx={{ display: "flex" }}>
            {VALUE_TYPE.map(({ value, label }) => (
              <Button
                className={`${classes.toggleButton} ${
                  values?.value?.type === value ? classes.activeButton : ""
                }`}
                key={value}
                onClick={() => {
                  setFieldValue("value.type", value);
                  setFieldValue("value.value", "");
                }}
              >
                {label}
              </Button>
            ))}
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormikControl
              fullWidth
              control={CONTROL_TYPE.INPUT}
              name="value.value"
              label="Value"
              style={{ marginBottom: 0 }}
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              InputProps={
                isPercentageSelected
                  ? {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }
                  : {
                      inputComponent: NumericFormatCustom,
                    }
              }
            />
          </Box>
        </Box>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" className={classes.text16Weight700}>
          Applies to
        </Typography>
        <Box sx={{ my: 2 }}>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            aria-label="applies-to"
            name="applies-to"
            onChange={handleChangeAppliesToType}
          >
            {APPLIES_TO_TYPE.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio color="primary" checked={value === values?.value?.appliesTo?.type} />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </Box>
        {showSearchBar ? (
          <Box sx={{ display: "grid", gridTemplateColumns: "auto 114px", gap: 16 }}>
            <FieldFormAdd
              fullWidth
              autoComplete="off"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value=""
              placeholder={selectedAppliesToType?.placeHolder || ""}
              style={{ marginBottom: 0 }}
              onClick={onHandleSearch}
            />
            <Button variant="outlined" color="primary" onClick={onHandleSearch}>
              Browse
            </Button>
          </Box>
        ) : null}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 8, mt: 2 }}>
          {items?.map(({ id, label }) => (
            <StyledChip
              clickable={false}
              key={id}
              label={label}
              onDelete={() => onHandleRemoveItem(id)}
              deleteIcon={<CloseIcon style={{ color: "#fff" }} />}
            />
          ))}
        </Box>
        {showSearchBar && errors?.value?.appliesTo?.items ? (
          <Typography color="error">{errors?.value?.appliesTo?.items}</Typography>
        ) : null}
      </Box>
    </CommonBox>
  );
};

const MinimumPurchaseRequirements = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const handleChangeType = (event) => {
    setFieldValue("minimumPurchaseRequirements.type", event.target.value);
    setFieldValue("minimumPurchaseRequirements.value", "");
  };

  const isAmountSelected = useMemo(
    () => values?.minimumPurchaseRequirements?.type === MINIMUM_REQUIREMENTS[1].value,
    [values?.minimumPurchaseRequirements?.type]
  );

  const selectedAppliesTo = useMemo(
    () => values?.value?.appliesTo?.type,
    [values?.value?.appliesTo?.type]
  );

  return (
    <CommonBox>
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Minimum purchase requirements
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <RadioGroup
          style={{ display: "flex", flexDirection: "column" }}
          aria-label="minimum-purchase"
          name="minimum-purchase"
          onChange={handleChangeType}
        >
          {MINIMUM_REQUIREMENTS.map(({ label, value }) => (
            <React.Fragment key={value}>
              <FormControlLabel
                value={value}
                control={
                  <Radio
                    color="primary"
                    checked={value === values?.minimumPurchaseRequirements?.type}
                  />
                }
                label={label}
              />
              {value === values?.minimumPurchaseRequirements?.type &&
              [MINIMUM_REQUIREMENTS[1].value, MINIMUM_REQUIREMENTS[2].value].some(
                (item) => item === values?.minimumPurchaseRequirements?.type
              ) ? (
                <Box sx={{ display: "grid", gap: 16, ml: 5 }}>
                  <FormikControl
                    required
                    fullWidth
                    control={CONTROL_TYPE.INPUT}
                    name="minimumPurchaseRequirements.value"
                    label="Value"
                    style={{ marginBottom: 0 }}
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    InputProps={
                      isAmountSelected
                        ? {
                            inputComponent: NumericFormatCustom,
                          }
                        : {}
                    }
                  />
                  <Typography className={classes.text16Weight500Gray}>
                    Applies only to selected {selectedAppliesTo}.
                  </Typography>
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </RadioGroup>
      </Box>
    </CommonBox>
  );
};

const CustomerEligibility = () => {
  const { renderModal, setOpenModal } = useModal({});
  const classes = useStyles();
  const { values, setFieldValue, errors } = useFormikContext();
  const items = values?.customerEligibility?.items || [];

  const handleChangeCustomerEligibilityType = (event) => {
    setFieldValue("customerEligibility.type", event.target.value);
    setFieldValue("customerEligibility.items", [], true /* shouldValidate */);
  };

  const selectedType = useMemo(
    () =>
      CUSTOMER_ELIGIBILITIES.find(({ value }) => value === values?.customerEligibility?.type) || "",
    [values?.customerEligibility?.type]
  );

  const showSearchBar = useMemo(
    () =>
      [CUSTOMER_ELIGIBILITIES[1].value, CUSTOMER_ELIGIBILITIES[2].value].some(
        (value) => value === values?.customerEligibility?.type
      ),
    [values?.customerEligibility?.type]
  );

  const onHandleSearch = () => {
    switch (values?.customerEligibility?.type) {
      case CUSTOMER_ELIGIBILITIES?.[1]?.value:
        setOpenModal({
          modalType: MODAL_TYPES.ADD_ITEM_PROMOTIONS_MODAL,
          data: {
            type: TYPE_PROMOTION_ITEMS.LICENSEES,
            formikName: "customerEligibility.items",
            initialValues: values?.customerEligibility?.items,
          },
        });
        break;
      case CUSTOMER_ELIGIBILITIES?.[2]?.value:
        setOpenModal({
          modalType: MODAL_TYPES.ADD_ITEM_PROMOTIONS_MODAL,
          data: {
            type: TYPE_PROMOTION_ITEMS.CUSTOMERS,
            formikName: "customerEligibility.items",
            initialValues: values?.customerEligibility?.items,
          },
        });
        break;
      default:
    }
  };

  const onHandleRemoveItem = (id) => {
    const clonedItems = [...items];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === id);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
      setFieldValue("customerEligibility.items", clonedItems);
    }
  };

  return (
    <CommonBox>
      {renderModal}
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Customer Eligibility
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Box sx={{ mb: 2 }}>
          <RadioGroup
            style={{ display: "flex", flexDirection: "column" }}
            aria-label="applies-to"
            name="applies-to"
            onChange={handleChangeCustomerEligibilityType}
          >
            {CUSTOMER_ELIGIBILITIES.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio color="primary" checked={value === values?.customerEligibility?.type} />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        </Box>
        {showSearchBar ? (
          <Box sx={{ display: "grid", gridTemplateColumns: "auto 114px", gap: 16 }}>
            <FieldFormAdd
              fullWidth
              autoComplete="off"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value=""
              placeholder={selectedType?.placeHolder || ""}
              style={{ marginBottom: 0 }}
              onClick={onHandleSearch}
            />
            <Button variant="outlined" color="primary" onClick={onHandleSearch}>
              Browse
            </Button>
          </Box>
        ) : null}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 8, mt: 2 }}>
          {items?.map(({ id, label }) => (
            <StyledChip
              clickable={false}
              key={id}
              label={label}
              onDelete={() => onHandleRemoveItem(id)}
              deleteIcon={<CloseIcon style={{ color: "#fff" }} />}
            />
          ))}
        </Box>
        {showSearchBar && errors?.customerEligibility?.items ? (
          <Typography color="error">{errors?.customerEligibility?.items}</Typography>
        ) : null}
      </Box>
    </CommonBox>
  );
};

const MaximumDiscountUses = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const handleChangeType = (event) => {
    setFieldValue("maximumDiscountUses.type", event.target.value);
    setFieldValue("maximumDiscountUses.value", "");
  };

  const isLimitNumberOfTimesSelected = useMemo(
    () => values?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[0].value,
    [values?.maximumDiscountUses?.type]
  );

  return (
    <CommonBox>
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Maximum discount uses
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <RadioGroup
          style={{ display: "flex", flexDirection: "column" }}
          aria-label="maximum-discount-uses"
          name="maximum-discount-uses"
          onChange={handleChangeType}
        >
          {MAXIMUM_DISCOUNT_USES.map(({ label, value }) => (
            <React.Fragment key={value}>
              <FormControlLabel
                value={value}
                control={
                  <Radio color="primary" checked={value === values?.maximumDiscountUses?.type} />
                }
                label={label}
              />
              {value === values?.maximumDiscountUses?.type && isLimitNumberOfTimesSelected ? (
                <Box sx={{ display: "grid", gap: 16, ml: 5 }}>
                  <FormikControl
                    required
                    fullWidth
                    control={CONTROL_TYPE.INPUT}
                    name="maximumDiscountUses.value"
                    label="Times"
                    style={{ marginBottom: 0 }}
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </RadioGroup>
      </Box>
    </CommonBox>
  );
};

const Combinations = () => {
  const classes = useStyles();
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const showCombinations = useMemo(
    () => values?.amountOffProducts?.method === METHODS[0].value,
    [values?.amountOffProducts?.method]
  );

  const haveCombinations = values?.isCombination || false;
  if (!showCombinations) {
    return null;
  }
  return (
    <CommonBox>
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Combinations
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={haveCombinations}
              onChange={(e) => setFieldValue("isCombination", e?.target?.checked)}
            />
          }
          label="Can be combined with any discount"
          style={{ marginBlock: 16 }}
        />
      </Box>
    </CommonBox>
  );
};

const MyDatePicker = ({ name, minDate }) => {
  const [, { value }, { setValue }] = useField(name);
  const dateValue = moment(value).toDate();
  const onHandleChange = (date) => {
    setValue(date?.getTime());
  };
  return (
    <DatePicker
      style={{ flex: 1 }}
      onChange={onHandleChange}
      value={dateValue}
      variant="inline"
      format="dd MMM yyyy"
      minDate={minDate}
    />
  );
};

const MyTimePicker = ({ name }) => {
  const [, { value }, { setValue }] = useField(name);
  const timeValue = moment(value).toDate();
  const onHandleChange = (date) => {
    setValue(date?.getTime());
  };
  return (
    <TimePicker
      style={{ flex: 1 }}
      onChange={onHandleChange}
      value={timeValue}
      variant="inline"
      minutesStep={5}
    />
  );
};

const ActiveDays = () => {
  const { values, setFieldValue } = useFormikContext();

  const activeDays = values?.activeDates?.activeDays || [];
  const isAllSelected = useMemo(() => activeDays?.length === ACTIVE_DAYS?.length, [activeDays]);

  const onHandleToggle = (value) => {
    const clonedItems = [...activeDays];
    const foundItemIndex = clonedItems.findIndex((item) => item === value);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
    } else {
      clonedItems.push(value);
    }
    setFieldValue("activeDates.activeDays", clonedItems);
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setFieldValue("activeDates.activeDays", []);
    } else {
      setFieldValue(
        "activeDates.activeDays",
        ACTIVE_DAYS.map(({ value }) => value)
      );
    }
  };

  return (
    <Box sx={{ display: "grid", gap: 24, mt: 3 }}>
      <Box sx={{ display: "flex", gap: 32, alignItems: "center" }}>
        <StyledFormControlLabel
          control={<Checkbox checked={isAllSelected} color="primary" onChange={handleSelectAll} />}
          label="All"
          labelPlacement="top"
        />
        <Box sx={{ display: "flex", flex: 1, gap: 16, alignItems: "center" }}>
          {ACTIVE_DAYS.map(({ label, value }) => (
            <StyledFormControlLabel
              key={value}
              control={
                <Checkbox
                  checked={activeDays.includes(value)}
                  color="primary"
                  onChange={() => onHandleToggle(value)}
                />
              }
              label={label}
              labelPlacement="top"
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const ActiveDates = () => {
  const classes = useStyles();
  const { values, setFieldValue, errors } = useFormikContext();
  const haveEndDate = values?.activeDates?.haveEndDate || false;
  const minDateForEndDate = useMemo(
    () =>
      values?.activeDates?.haveEndDate
        ? moment(values?.activeDates?.startDate).toDate()
        : undefined,
    [values?.activeDates?.startDate, values?.activeDates?.haveEndDate]
  );
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CommonBox>
        <Box className={classes.contentOfBox}>
          <Box sx={{ display: "flex", gap: 10 }}>
            <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
              Active Dates
            </Typography>
            <Typography variant="h6" component="h6" className={classes.text16Weight500Gray}>
              This is applicable to when the order is placed
            </Typography>
          </Box>
        </Box>
        <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
        <Box className={classes.contentOfBox}>
          {/* Start Date */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 16 }}>
            <Box
              sx={{
                width: 270,
                display: "flex",
                alignItems: "center",
                p: 2,
                border: "1px solid white",
                borderRadius: 8,
                gap: 16,
              }}
            >
              <CalendarTodayIcon />
              <MyDatePicker name="activeDates.startDate" />
            </Box>
            <Box
              sx={{
                width: 270,
                display: "flex",
                alignItems: "center",
                p: 2,
                border: "1px solid white",
                borderRadius: 8,
                gap: 16,
              }}
            >
              <AccessTimeIcon />
              <MyTimePicker name="activeDates.startDate" />
            </Box>
          </Box>
          {/* Set End Date */}
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={haveEndDate}
                onChange={(e) => setFieldValue("activeDates.haveEndDate", e?.target?.checked)}
              />
            }
            label="Set end date"
            style={{ marginBlock: 16 }}
          />
          {/* End Date */}
          {haveEndDate ? (
            <Box sx={{ display: "grid", gap: 16 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 16 }}>
                <Box
                  sx={{
                    width: 270,
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    border: "1px solid white",
                    borderRadius: 8,
                    gap: 16,
                  }}
                >
                  <CalendarTodayIcon />
                  <MyDatePicker name="activeDates.endDate" minDate={minDateForEndDate} />
                </Box>
                <Box
                  sx={{
                    width: 270,
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    border: "1px solid white",
                    borderRadius: 8,
                    gap: 16,
                  }}
                >
                  <AccessTimeIcon />
                  <MyTimePicker name="activeDates.endDate" />
                </Box>
              </Box>
              {errors?.activeDates?.endDate ? (
                <Typography color="error">{errors?.activeDates?.endDate}</Typography>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </CommonBox>
    </MuiPickersUtilsProvider>
  );
};

const ApplicableDays = () => {
  const classes = useStyles();
  return (
    <CommonBox>
      <Box className={classes.contentOfBox}>
        <Box sx={{ display: "flex", gap: 10 }}>
          <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
            Applicable days
          </Typography>
          <Typography variant="h6" component="h6" className={classes.text16Weight500Gray}>
            This is applicable to drop-off and pickup days
          </Typography>
        </Box>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <ActiveDays />
      </Box>
    </CommonBox>
  );
};

const Summary = () => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const isDiscountCodeSelected = values?.amountOffProducts?.method === METHODS[0].value;
  const amountOffProductsType =
    METHODS.find(({ value }) => value === values?.amountOffProducts?.method)?.label || "";
  const amountOffProductsValue = values?.amountOffProducts?.value;

  const valueDetails = useMemo(() => {
    if (values?.value?.appliesTo?.type === APPLIES_TO_TYPE[0].value) {
      return <li className={classes.text16Weight500Gray}>Applies to the Entire Order</li>;
    }

    if (!values?.value?.value || !values?.value?.appliesTo?.items?.length) {
      return null;
    }
    let value = formatCurrency(values?.value?.value);
    let appendTextForValue = "products";
    if (values?.value?.type === VALUE_TYPE[0].value) {
      value = `${values?.value?.value}%`;
    }
    if (values?.value?.appliesTo?.type === APPLIES_TO_TYPE[0].value) {
      appendTextForValue = values?.value?.appliesTo?.items?.map(({ label }) => label)?.join(", ");
    }
    return <li className={classes.text16Weight500Gray}>{`${value} off ${appendTextForValue}`}</li>;
  }, [
    values?.value?.type,
    values?.value?.value,
    values?.value?.appliesTo?.type,
    values?.value?.appliesTo?.items,
  ]);

  const minimumPurchaseRequirementsDetails = useMemo(() => {
    let content = null;
    if (values?.minimumPurchaseRequirements?.type === MINIMUM_REQUIREMENTS[0]?.value) {
      content = <li className={classes.text16Weight500Gray}>{MINIMUM_REQUIREMENTS[0]?.label}</li>;
    } else {
      if (values?.minimumPurchaseRequirements?.value) {
        switch (values?.minimumPurchaseRequirements?.type) {
          case MINIMUM_REQUIREMENTS[1]?.value:
            content = (
              <li className={classes.text16Weight500Gray}>
                Minimum purchase amount of{" "}
                {formatCurrency(values?.minimumPurchaseRequirements?.value)}
              </li>
            );
            break;
          case MINIMUM_REQUIREMENTS[2]?.value:
            content = (
              <li className={classes.text16Weight500Gray}>
                Minimum {values?.minimumPurchaseRequirements?.value} quantity of items
              </li>
            );
            break;
          default:
        }
      }
    }
    return content;
  }, [values?.minimumPurchaseRequirements?.type, values?.minimumPurchaseRequirements?.value]);

  const activeDatesDetails = useMemo(() => {
    const FORMAT_DATE = "DD MMM yyyy";

    let content = `Active from ${moment(values?.activeDates?.startDate).format(FORMAT_DATE)}`;
    if (values?.activeDates?.haveEndDate) {
      content += ` to ${moment(values?.activeDates?.endDate).format(FORMAT_DATE)}`;
    }
    return <li className={classes.text16Weight500Gray}>{content}</li>;
  }, [
    values?.activeDates?.startDate,
    values?.activeDates?.haveEndDate,
    values?.activeDates?.endDate,
  ]);

  const customerEligibility = useMemo(() => {
    let content = null;
    switch (values?.customerEligibility?.type) {
      case CUSTOMER_ELIGIBILITIES[0].value:
        content = (
          <li className={classes.text16Weight500Gray}>Discount eligible for All Customers</li>
        );
        break;
      case CUSTOMER_ELIGIBILITIES[1].value:
        content = (
          <li className={classes.text16Weight500Gray}>
            Discount eligible for Customers of specific Franchisees
          </li>
        );
        break;
      case CUSTOMER_ELIGIBILITIES[2].value:
        content = (
          <li className={classes.text16Weight500Gray}>Discount eligible for specific Customers</li>
        );
        break;
      default:
    }

    return content;
  }, [values?.customerEligibility]);

  const maximumDiscountUsesDetails = useMemo(() => {
    let content = null;
    if (
      values?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[0].value &&
      values?.maximumDiscountUses?.value
    ) {
      content = (
        <li className={classes.text16Weight500Gray}>
          Limit {values?.maximumDiscountUses?.value} times this discount can be used in total
        </li>
      );
    } else if (values?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[1].value) {
      content = <li className={classes.text16Weight500Gray}>{MAXIMUM_DISCOUNT_USES[1].label}</li>;
    } else if (values?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[2].value) {
      content = <li className={classes.text16Weight500Gray}>No limit Discount uses</li>;
    }
    return content;
  }, [values?.maximumDiscountUses?.type, values?.maximumDiscountUses?.value]);

  const combinationsDetails = useMemo(() => {
    let content = null;
    if (values?.amountOffProducts?.method === METHODS[0].value && values?.isCombination) {
      content = <li className={classes.text16Weight500Gray}>Can be combined with any discount</li>;
    }
    return content;
  }, [values?.amountOffProducts?.method, values?.isCombination]);

  return (
    <CommonBox sx={{ height: "max-content" }}>
      <Box className={classes.contentOfBox}>
        <Typography variant="h6" component="h6" style={{ textTransform: "capitalize" }}>
          Summary
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "#e2e9fb4c" }} />
      <Box className={classes.contentOfBox}>
        <Box sx={{ display: "grid", gap: 32 }}>
          <Typography style={{ fontSize: 16 }}>
            {amountOffProductsValue
              ? amountOffProductsValue
              : isDiscountCodeSelected
              ? "No discount code yet"
              : "No title yet"}
          </Typography>
          <Box>
            <Typography style={{ fontSize: 16 }}>Type and method</Typography>
            <ul>
              <li className={classes.text16Weight500Gray}>Amount off products</li>
              <li className={classes.text16Weight500Gray}>{amountOffProductsType}</li>
            </ul>
          </Box>
          <Box>
            <Typography style={{ fontSize: 16 }}>Details</Typography>
            <ul>
              {valueDetails}
              {minimumPurchaseRequirementsDetails}
              {customerEligibility}
              {maximumDiscountUsesDetails}
              {combinationsDetails}
              {activeDatesDetails}
            </ul>
          </Box>
        </Box>
      </Box>
    </CommonBox>
  );
};

const CreateNewOrEditDiscount = () => {
  const { isAllowToCreate, isAllowToUpdate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  const params = useParams();
  const { mutate, isLoading } = useCreateNewOrEditDiscount(params?.id);
  const history = useHistory();
  const { data: discountDetails, isLoading: isLoadingDiscountDetails } = useGetDiscountDetails(
    params?.id
  );

  const isEditing = !!params?.id;

  const initialValues = useMemo(() => {
    let cacheData = discountDetails?.cache ? JSON.parse(discountDetails?.cache) : null;

    let valueType = VALUE_TYPE[0].value;
    // if (LIST_DISCOUNT.some((item) => discountDetails?.[item]?.amount)) {
    //   valueType = VALUE_TYPE[1].value;
    // }
    if (cacheData?.value?.type) {
      valueType = cacheData?.value?.type;
    }

    let valueValue = "";
    let findItem = LIST_DISCOUNT.find(
      (item) => discountDetails?.[item]?.amount || discountDetails?.[item]?.percentage
    );
    if (findItem) {
      valueValue = discountDetails[findItem]?.amount || discountDetails[findItem]?.percentage || "";
    }

    let minimumPurchaseRequirementsType = MINIMUM_REQUIREMENTS[0].value;
    // if (LIST_DISCOUNT.some((item) => discountDetails?.[item]?.purchaseRequirements?.amount)) {
    //   minimumPurchaseRequirementsType = MINIMUM_REQUIREMENTS[1].value;
    // } else if (
    //   LIST_DISCOUNT.some((item) => discountDetails?.[item]?.purchaseRequirements?.quantity)
    // ) {
    //   minimumPurchaseRequirementsType = MINIMUM_REQUIREMENTS[2].value;
    // }
    if (cacheData?.minimumPurchaseRequirements?.type) {
      minimumPurchaseRequirementsType = cacheData?.minimumPurchaseRequirements?.type;
    }

    let minimumPurchaseRequirementsValue = "";
    findItem = LIST_DISCOUNT.find(
      (item) =>
        discountDetails?.[item]?.purchaseRequirements?.amount ||
        discountDetails?.[item]?.purchaseRequirements?.quantity
    );
    if (findItem) {
      minimumPurchaseRequirementsValue =
        discountDetails?.[findItem]?.purchaseRequirements?.amount ||
        discountDetails?.[findItem]?.purchaseRequirements?.quantity ||
        "";
    }

    let appliesToItems = [];
    if (discountDetails?.appliesTo === APPLIES_TO_TYPE[1].value) {
      LIST_DISCOUNT.forEach((item) => {
        if (discountDetails?.[item]?.percentage || discountDetails?.[item]?.amount) {
          const findItem = CATEGORIES.find(({ value }) => value === MAPPING_2?.[item]);
          if (findItem) {
            appliesToItems.push({ id: findItem.value, label: findItem.label });
          }
        }
      });
    } else if (discountDetails?.appliesTo === APPLIES_TO_TYPE[2].value) {
      LIST_DISCOUNT.forEach((item) => {
        const type = MAPPING_3[item];
        if (discountDetails?.[item]?.items?.length > 0) {
          appliesToItems = [
            ...appliesToItems,
            ...discountDetails?.[item]?.items.map(({ _id, name }) => ({
              id: _id,
              label: name,
              type,
            })),
          ];
        }
      });
    }

    let maximumDiscountUsesType = MAXIMUM_DISCOUNT_USES[0].value;
    let maximumDiscountUsesValue = "";
    if (cacheData?.maximumDiscountUses?.type) {
      maximumDiscountUsesType = cacheData?.maximumDiscountUses?.type;
    }

    if (cacheData?.maximumDiscountUses?.value) {
      maximumDiscountUsesValue = cacheData?.maximumDiscountUses?.value;
    }

    return {
      amountOffProducts: {
        method: discountDetails?.title ? METHODS[1].value : METHODS[0].value,
        value: discountDetails?.code || discountDetails?.title || "",
      },
      value: {
        type: valueType,
        value: valueValue,
        appliesTo: {
          type: APPLIES_TO_TYPE.map(({ value }) => value).includes(discountDetails?.appliesTo)
            ? discountDetails?.appliesTo
            : APPLIES_TO_TYPE[0].value,
          items: appliesToItems,
        },
      },
      minimumPurchaseRequirements: {
        type: minimumPurchaseRequirementsType,
        value: minimumPurchaseRequirementsValue,
      },
      customerEligibility: {
        type:
          discountDetails?.eligibleCustomers?.length > 0 ||
          discountDetails?.eligibleLicensees?.length > 0
            ? discountDetails?.eligibleLicensees?.length > 0
              ? CUSTOMER_ELIGIBILITIES[1].value
              : CUSTOMER_ELIGIBILITIES[2].value
            : CUSTOMER_ELIGIBILITIES[0].value,
        items:
          discountDetails?.eligibleCustomers?.length > 0 ||
          discountDetails?.eligibleLicensees?.length > 0
            ? discountDetails?.eligibleCustomers?.length > 0
              ? discountDetails?.eligibleCustomers.map(({ _id, name }) => ({
                  id: _id,
                  label: name,
                }))
              : discountDetails?.eligibleLicensees.map(({ _id, fullname }) => ({
                  id: _id,
                  label: fullname,
                }))
            : [],
      },
      maximumDiscountUses: {
        type: maximumDiscountUsesType,
        value: maximumDiscountUsesValue,
      },
      isCombination: discountDetails?.isCombination ?? true,
      isActive: discountDetails?.isActive ?? true,
      activeDates: {
        startDate: new Date(discountDetails?.startDate)?.getTime() || new Date().getTime(),
        haveEndDate: !!discountDetails?.endDate,
        endDate: new Date(discountDetails?.endDate)?.getTime() || new Date().getTime(),
        activeDays: discountDetails?.daysApplied || ACTIVE_DAYS.map(({ value }) => value),
      },
    };
  }, [discountDetails]);

  const onSubmit = (data) => {
    const payload = {};
    payload.code = null;
    payload.title = null;
    payload[data?.amountOffProducts?.method === METHODS[0].value ? "code" : "title"] =
      data?.amountOffProducts?.value;

    const purchaseRequirements = {
      ...([MINIMUM_REQUIREMENTS[1].value, MINIMUM_REQUIREMENTS[2].value].includes(
        data?.minimumPurchaseRequirements?.type
      ) && {
        purchaseRequirements: {
          [data?.minimumPurchaseRequirements?.type === MINIMUM_REQUIREMENTS[1].value
            ? "amount"
            : "quantity"]: data?.minimumPurchaseRequirements?.value,
        },
      }),
    };

    if (data?.value?.appliesTo?.type === APPLIES_TO_TYPE[1].value) {
      data?.value?.appliesTo?.items.forEach((item) => {
        payload[MAPPING[item?.id]] = {
          [data?.value?.type === VALUE_TYPE[0].value ? "percentage" : "amount"]: data?.value?.value,
          ...purchaseRequirements,
        };
      });
    } else if (data?.value?.appliesTo?.type === APPLIES_TO_TYPE[2].value) {
      data?.value?.appliesTo?.items.forEach((item) => {
        const items = payload?.[MAPPING_1[item.type]]?.items || [];
        items.push(item.id);

        payload[MAPPING_1[item.type]] = {
          ...purchaseRequirements,
          [data?.value?.type === VALUE_TYPE[0].value ? "percentage" : "amount"]: data?.value?.value,
          items,
        };
      });
    } else if (data?.value?.appliesTo?.type === APPLIES_TO_TYPE[0].value) {
      payload.overallDiscount = {
        ...purchaseRequirements,
        [data?.value?.type === VALUE_TYPE[0].value ? "percentage" : "amount"]: data?.value?.value,
      };
    }

    payload.appliesTo = data?.value?.appliesTo?.type;

    // Customer Eligibility
    payload.eligibleLicensees = [];
    payload.eligibleCustomers = [];

    payload[
      data?.customerEligibility?.type === CUSTOMER_ELIGIBILITIES[1].value
        ? "eligibleLicensees"
        : "eligibleCustomers"
    ] = data?.customerEligibility?.items?.map(({ id }) => id);

    // Maximum Discount Uses
    if (data?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[2].value) {
      payload.maxUsageCount = -1;
      payload.maxUsagePerCustomer = -1;
    } else if (data?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[0].value) {
      payload.maxUsageCount = data?.maximumDiscountUses?.value;
    } else if (data?.maximumDiscountUses?.type === MAXIMUM_DISCOUNT_USES[1].value) {
      payload.maxUsagePerCustomer = 1;
    }

    // Combinations
    if (data?.amountOffProducts?.method === METHODS[0].value) {
      payload.isCombination = data?.isCombination;
    }

    // Active Dates
    payload.startDate = data?.activeDates?.startDate;
    payload.endDate = null;

    if (data?.activeDates?.haveEndDate) {
      payload.endDate = data?.activeDates?.endDate;
    }

    payload.daysApplied = data?.activeDates?.activeDays;

    if (params.id) {
      payload._id = params.id;
    }

    payload.isActive = data?.isActive;
    payload.cache = JSON.stringify(data);

    mutate(payload, {
      onSuccess: () => {
        toast.success(params.id ? "Updated Discount" : "Created New Discount");
        history.push("/discounts-and-promotions");
      },
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const { handleSubmit, values, setFieldValue } = formik;

  if (isEditing && isLoadingDiscountDetails) {
    return (
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const onHandleToggleActiveDiscount = (event) => {
    setFieldValue("isActive", event.target.checked);
  };

  return (
    <FormikProvider value={formik}>
      <Container maxWidth="lg">
        <Box className={classes.container}>
          <Box sx={{ width: "100%" }}>
            <WrapHeaderSubPage
              nameHeader={`${isEditing ? "Edit" : "Create"} product discount`}
              style={{ marginBottom: "50px" }}
            />
            <Breadcrumbs />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 330px",
                gap: 32,
              }}
            >
              <Box sx={{ display: "grid", gap: 16 }}>
                <AmountOffProducts />
                <Value />
                <MinimumPurchaseRequirements />
                <CustomerEligibility />
                <MaximumDiscountUses />
                <Combinations />
                <ActiveDates />
                <ApplicableDays />
              </Box>
              <StickyBox>
                <Summary />
              </StickyBox>
            </Box>
            <StickyNavigationSection isBottom>
              <Box className={classes.bottomSection}>
                {isEditing && isAllowToCreate && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values?.isActive}
                        onChange={onHandleToggleActiveDiscount}
                        name="isActive"
                        color="primary"
                      />
                    }
                    label={values?.isActive ? "Active" : "Inactive"}
                    labelPlacement="start"
                    style={{ marginRight: 16 }}
                  />
                )}
                <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
                  Discard
                </Button>
                {isAllowToCreate && isAllowToUpdate ? (
                  <Button
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {isLoading ? <CircularProgress color="primary" /> : "Save"}
                  </Button>
                ) : null}
              </Box>
            </StickyNavigationSection>
          </Box>
        </Box>
      </Container>
    </FormikProvider>
  );
};

export default CreateNewOrEditDiscount;
