import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../img/logo2.svg";
import { ReactComponent as Break } from "../../img/break.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../helpers/axios";

const ResetEmail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [userData, setUserData] = useState();
  let history = useHistory();

  useEffect(() => {
    if (id) {
      axios.get("v1/admin/detailuser/" + id).then((response) => {
        return setUserData(response.data.userDetail);
      });
    }
  }, [id]);
  return (
    <div className="reset-email">
      <Logo className="logo" />
      <div className="box-main">
        <Typography variant="h3" className="title">
          {`Hi ${userData?.name}, Here are your password reset instructions`}
        </Typography>
        <Break className="break" />
        <span className="text-16 description">
          A request to reset your Trailer2you admin password has been made. If
          you did not make this request, simply ignore this email. If you did
          make this request, please reset your password:{" "}
        </span>
        <Button
          type="submit"
          className={classes.btnSubmit}
          color="primary"
          variant="contained"
          onClick={() => history.push(`/set-password/${id}`)}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default ResetEmail;
const useStyles = makeStyles(() => ({
  btnSubmit: {
    width: "303px",
    height: 51,
    marginTop: 40,
  },
}));
