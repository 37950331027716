import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];

const useLoadGoogleMapAPI = () => {
  return useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    // @ts-ignore
    libraries,
    language: "en-AU", // Add the desired language here, for example "en-AU" for Australia
  });
};

export default useLoadGoogleMapAPI;
