import React, { useEffect, useState } from "react";
import { Card } from "../../../components";
import { formatHour } from "../../../utils";
import { useSelector } from "react-redux";
import SortableTable from "../../../../../components/SortableTable/SortableTable";

export function HTDUtilisationReportByTrailerTypeAndFranchisee() {
  const { htdUtilisationReportByTrailerTypeAndFranchisee } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (htdUtilisationReportByTrailerTypeAndFranchisee?.items) {
      const dataTransformed = htdUtilisationReportByTrailerTypeAndFranchisee.items.map((item) => ({
        trailerType: item?.trailer?.name ?? "--",
        franchisee: item?.licensee?.firstname
          ? `${item?.licensee?.firstname} ${item?.licensee?.lastname}`
          : "--",
        totalHireTime: formatHour(item.totalHireDuration),
        totalHTDTime: formatHour(item.totalHtdHours),
        utilisation: item.htdUtilisationPercentage ?? "--",
        HTDRatio: item?.htdRatio ?? "--",
      }));
      setData(dataTransformed);
    }
  }, [htdUtilisationReportByTrailerTypeAndFranchisee]);

  const columns = [
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Total hire time",
      key: "totalHireTime",
      sortable: true,
    },
    {
      name: "Total HTD time",
      key: "totalHTDTime",
      sortable: true,
    },
    {
      name: "HTD Utilisation",
      key: "utilisation",
      sortable: true,
    },
    {
      name: "HTD Ratio",
      key: "HTDRatio",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="HTD Utilisation Report by Trailer Type and Franchisee"
      navigateTo={`/analytics/hire-the-driver/htd-utilisation-report-by-trailer-type-and-franchisee`}
      style={{ maxHeight: 300 }}
    >
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="30% 30% 10% 10% 10% 10%"
        rowsHeight={255}
      />
    </Card>
  );
}
