import { useStripe } from "@stripe/react-stripe-js";
import axiosInstance from "../../helpers/axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import getQueryKey, {
  GET_ORDER_DETAIL,
  GET_PAYMENT_METHODS,
} from "../../helpers/getQueryKey";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import moment from "moment";
import { common } from "../../constants/common";

export const getQuantityAndId = (item) => ({
  id: item._id,
  quantity: item.quantity,
});
export const getQuantityAndId2 = (item) => ({
  id: item.licenseeUpsellItemId,
  quantity: item.quantity,
  upsellItemId: item.upsellItemId,
});
export const getQuantityAndId3 = (item) => ({
  id: item.id,
  quantity: item.quantity,
});

export const paramsPriceDetailAtom = atom(null);

export const updateDataAtom = atom(null);

export const useGetOrderDetail = (id) => {
  const [updateData, setUpdateData] = useAtom(updateDataAtom);
  const { data, isLoading, isError } = useQuery({
    queryKey: getQueryKey.getOrderDetailQueryKey(id),
    queryFn: () => {
      return axiosInstance.get(`v1/admin/get-booking-detail?id=${id}`);
    },
    select: (response) => response?.data?.dataObj?.bookingObj ?? null,
    staleTime: common.STALE_TIME.SEC_5,
  });
  useEffect(() => {
    if (data) {
      setUpdateData({
        customerId: data?.booking?.customerId ?? "",
        bookingId: id,
        customerAddress: {
          streetAddress: data?.booking?.customerLocation?.address ?? "",
          coordinates: data?.booking?.customerLocation?.coordinates ?? [],
        },
        startDate: moment(data?.booking?.startDate).toDate().getTime(),
        endDate: moment(data?.booking?.endDate).toDate().getTime(),
        upsellItems:
          data?.booking?.charges?.upsellCharges?.length > 0
            ? data?.booking?.charges?.upsellCharges.map(getQuantityAndId2)
            : [],
        consumableItems:
          data?.booking?.charges?.comsumableCharges?.length > 0
            ? data?.booking?.charges?.comsumableCharges.map(getQuantityAndId2)
            : [],
        plugTypeId: data?.booking?.plugType?._id ?? "",
        isDShackleNeeded: data?.booking?.isDShackleNeeded ?? true,
        driverHireServices:
          data?.booking?.charges?.hireTheDriverCharges?.[0]?.services?.length >
          0
            ? data?.booking?.charges?.hireTheDriverCharges?.[0]?.services
                ?.filter((item) => item.isSelected)
                .map((item) => item._id)
            : [],
        driverHireVouchers:
          data?.booking?.charges?.hireTheDriverCharges?.[0]?.vouchers?.length >
          0
            ? data?.booking?.charges?.hireTheDriverCharges?.[0]?.vouchers
                ?.filter((item) => !!item.quantity)
                ?.map(getQuantityAndId)
            : [],
        isDamageWaiver: data?.booking?.charges?.isDamageWaiver ?? true,
        premiumItems:
          data?.booking?.charges?.premiumCharges?.length > 0
            ? data?.booking?.charges?.premiumCharges.map(getQuantityAndId2)
            : [],
        licenseeId: data?.booking?.licenseeId ?? "",
        trailerId: data?.booking?.licenseeTrailerId ?? "",
        driverHireDuration: data?.booking?.charges?.hireTheDriverCharges?.[0]
          ? data?.booking?.charges?.hireTheDriverCharges?.[0].serviceCost /
            data?.booking?.charges?.hireTheDriverCharges?.[0].perHour
          : 0,
        discountCodes:
          data?.booking?.discounts
            ?.filter((item) => !!item.code)
            .map((item) => item.code) ?? [],
        stripePaymentMethodId: data?.paymentMethods?.[0]?.id ?? null,
        totalCost: 0,
        overrideDiscounts: (data?.booking?.discounts || [])
          .filter((item) => !item.code && !!item._id)
          ?.map((item) => item?._id),
      });
    }
  }, [data]);
  return { updateData, isLoading, isError };
};

export const useGetPriceDetails = () => {
  const [updateData] = useAtom(updateDataAtom);
  return useQuery({
    enabled: !!updateData,
    queryKey: getQueryKey.getBookingPriceQueryKey(updateData),
    queryFn: () =>
      axiosInstance.post("v1/admin/calculate-booking-price", updateData),
    select: (response) => response?.data?.dataObj ?? null,
    staleTime: common.STALE_TIME.MIN_1,
  });
};

export const useValidateDiscountCode = () => {
  return useMutation({
    mutationFn: (params) => {
      return axiosInstance.post("v1/admin/calculate-booking-price", params);
    },
  });
};

export const useGetAvailableTrailers = () => {
  const [updateData] = useAtom(updateDataAtom);
  return useQuery({
    enabled:
      !!updateData?.licenseeId &&
      !!updateData?.startDate &&
      !!updateData?.endDate,
    queryKey: getQueryKey.getAvailableTrailersQueryKey({
      licenseeId: updateData?.licenseeId,
      startDate: updateData?.startDate,
      endDate: updateData?.endDate,
    }),
    queryFn: () =>
      axiosInstance.get(
        `v1/admin/available-trailers?bookingId=${updateData?.bookingId}&startDate=${updateData?.startDate}&endDate=${updateData?.endDate}`
      ),
    select: (response) => response?.data ?? [],
  });
};

export const useGetPaymentMethods = (id) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: getQueryKey.getPaymentMethodsQueryKey(id),
    queryFn: () => {
      return axiosInstance.get(`v1/admin/get-booking-detail?id=${id}`);
    },
    initialData: () => {
      return queryClient.getQueryData(getQueryKey.getOrderDetailQueryKey(id));
    },
    staleTime: common.STALE_TIME.SEC_5,
    select: (response) =>
      response?.data?.dataObj?.bookingObj?.paymentMethods ?? [],
  });
};

export const useAddPaymentMethod = () => {
  const stripe = useStripe();
  const queryClient = useQueryClient();
  const [updateData] = useAtom(updateDataAtom);
  return useMutation({
    mutationFn: async ({ cardNumberElement, nameOnCard }) => {
      const response = await axiosInstance.get(
        `v1/admin/stripe-add-payment-method-intent?customerId=${updateData.customerId}`
      );
      const clientSecret = response?.data?.clientSecret ?? "";
      const stripeResp = await stripe?.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: nameOnCard,
          },
        },
      });
      if (stripeResp?.error?.message) {
        throw new Error(stripeResp?.error?.message);
      }
      return stripeResp;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PAYMENT_METHODS]);
    },
  });
};

export const useGetDetailItems = (type) => {
  const [updateData] = useAtom(updateDataAtom);
  return useQuery({
    enabled: !!type && !!updateData?.licenseeId,
    queryKey: getQueryKey.getDetailItemsQueryKey({
      type,
      startDate: updateData?.startDate,
      endDate: updateData?.endDate,
    }),
    queryFn: async () => {
      const promises = [
        axiosInstance.get(
          `v1/admin/upsell-items?type=${type}&pageSize=1000&pageIndex=0&licenseeId=${updateData?.licenseeId}&startDate=${updateData?.startDate}&endDate=${updateData?.endDate}`
        ),
      ];
      if (type === "upsell") {
        promises.push(
          axiosInstance.get(
            `v1/customer/premium-items?licenseeId=${updateData?.licenseeId}`
          )
        );
      }
      const response = await Promise.all(promises);
      const list = [...(response?.[0]?.data ?? [])];
      if (
        response?.length > 0 &&
        response?.[1]?.data?.dataObj?.premiumItems?.[0]
      ) {
        list.unshift(response?.[1]?.data?.dataObj?.premiumItems?.[0]);
      }
      return list;
    },
  });
};

export const useGetFreeAccessories = () => {
  return useQuery({
    queryKey: getQueryKey.getFreeAccessoriesQueryKey(),
    queryFn: () => {
      return axiosInstance.get("v1/customer/get-plug-types");
    },
    staleTime: common.STALE_TIME.MIN_1,
    select: (resp) => resp?.data?.dataObj?.plugTypes ?? [],
  });
};

export const useCheckDistance = () => {
  const [updateData] = useAtom(updateDataAtom);
  return useMutation({
    mutationFn: ({ lat, lng }) =>
      axiosInstance.get(
        `v1/admin/calculate-distance?licenseeId=${updateData.licenseeId}&lat=${lat}&lng=${lng}`
      ),
  });
};

export const useGetHireTheDriverServicesAndVouchers = () => {
  return useQuery({
    queryKey: getQueryKey.getHTDServicesAndVouchersQueryKey(),
    queryFn: async () => {
      const servicesPromise = axiosInstance.get(
        "v1/admin/hire-the-driver-services"
      );
      const vouchersPromise = axiosInstance.get("v1/admin/vouchers");
      const [servicesResponse, vouchersResponse] = await Promise.all([
        servicesPromise,
        vouchersPromise,
      ]);
      const voucher = vouchersResponse?.data?.[0] ?? null;
      const services = servicesResponse?.data ?? [];
      return { services, voucher };
    },
    staleTime: common.STALE_TIME.MIN_1,
  });
};

export const useConfirmUpdateOrder = () => {
  const [updateData] = useAtom(updateDataAtom);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (totalCost) => {
      const { customerId, ...payload } = updateData;
      console.log(customerId);
      return axiosInstance.post("v1/admin/booking-trailer", {
        ...payload,
        upsellItems: payload?.upsellItems.map(getQuantityAndId3),
        consumableItems: payload?.consumableItems.map(getQuantityAndId3),
        premiumItems: payload?.premiumItems.map(getQuantityAndId3),
        totalCost,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ORDER_DETAIL]);
    },
  });
};

export const DIALOG_AVAILABLE_TYPE = {
  NO_TRAILERS_AVAILABLE: "NO_TRAILERS_AVAILABLE",
  SELECT_ANOTHER_TRAILER: "SELECT_ANOTHER_TRAILER",
  NO_DIALOG: "NO_DIALOG",
};

export const useCheckIfAvailableTrailersWithinSpecificTime = () => {
  const [updateData] = useAtom(updateDataAtom);
  return useMutation({
    mutationFn: async ({ startDate, endDate }) => {
      const response = await axiosInstance.get(
        `v1/admin/available-trailers?bookingId=${updateData?.bookingId}&startDate=${startDate}&endDate=${endDate}`
      );
      const availableTrailers =
        response?.data?.filter((item) => !!item?.isActive) ?? [];
      if (availableTrailers?.length === 0) {
        return DIALOG_AVAILABLE_TYPE.NO_TRAILERS_AVAILABLE;
      }
      if (
        !availableTrailers.some(
          (item) => item.liceseeTrailerId === updateData?.trailerId
        )
      ) {
        return DIALOG_AVAILABLE_TYPE.SELECT_ANOTHER_TRAILER;
      }
      return DIALOG_AVAILABLE_TYPE.NO_DIALOG;
    },
  });
};
