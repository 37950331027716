import React from "react";
import { Dialog, Typography, Box, makeStyles, withStyles } from "@material-ui/core";
import { ReactComponent as Delete } from "../../img/delete.svg";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
const StyledTypography = withStyles({
  root: {
    textAlign: "center",
    height: "42px",
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "21.48px",
  },
  subtitle1: {
    marginTop: "11px",
    color: "rgba(222, 231, 250, 1)",
    lineHeight: "16.71px",
    fontSize: 14,
    opacity: 0.8,
  },
})(Typography);
const useStyle = makeStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  paper: {
    position: "absolute",
    top: 30,
  },
});
const DialogChangeStatus = ({
  isActive,
  isOpen,
  header,
  subtitle,
  submitTitle,
  onActive,
  onCloseDialog,
}) => {
  const cssDialog = useStyle();
  return (
    <Dialog open={isOpen} className={cssDialog.paper}>
      <Box
        position="relative"
        padding="52px 24px 25px 24px"
        width="432px"
        height="251px"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          position="absolute"
          right="24px"
          top="21px"
          className={cssDialog.root}
          onClick={onCloseDialog}
        >
          <Delete />
        </Box>
        <Box padding="0 24px">
          {!!header && <StyledTypography variant="subtitle2">{header}</StyledTypography>}
          {!!subtitle && <StyledTypography variant="subtitle1">{subtitle}</StyledTypography>}
        </Box>
        <ButtonActionSubPage
          colorError={!isActive}
          fontWeight={600}
          minWidth="100%"
          height="52px"
          padding="16px"
          onClick={onActive}
        >
          {submitTitle}
        </ButtonActionSubPage>
      </Box>
    </Dialog>
  );
};
export default DialogChangeStatus;
