import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useRouteMatch } from "react-router-dom";
import SortableTable from "../../../../../components/SortableTable/SortableTable";
import { Card } from "../../../components";

export function UnavailableTrailerSearches() {
  // const { url } = useRouteMatch();
  const { unavailableTrailerSearches } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (unavailableTrailerSearches?.items) {
      const dataTransformed = [...unavailableTrailerSearches.items]
        .sort((a, b) => b.total - a.total)
        .slice(0, 5)
        .map((item) => ({
          trailer: item.trailer,
          total: item.total,
          location: item.location,
        }));
      setData(dataTransformed);
    }
  }, [unavailableTrailerSearches]);

  const columns = [
    {
      name: "",
      key: "trailer",
      sortable: false,
    },
    {
      name: "Unserviced Bookings",
      key: "total",
      sortable: true,
    },
    {
      name: "Location",
      key: "location",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Unavailable Trailer Searches"
      navigateTo={`/analytics/unavailable-trailer-searches`}
    >
      <SortableTable
        rows={data}
        columns={columns}
        hasIndexColumn
        gridTemplateColumns="8% 38% 16% 38%"
      />
    </Card>
  );
}
