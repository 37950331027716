import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../../components/SortableTable/SortableTable";
import { Card } from "../../../components";
import { formatAmount } from "../../../utils";

export function FranchiseePerf() {
  const { incomePerLicenseeData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (incomePerLicenseeData?.items) {
      const dataTransformed = [...incomePerLicenseeData.items]
        .sort((a, b) => b.grossIncome - a.grossIncome)
        .slice(0, 5)
        .map((item) => ({
          name: item.licensee.fullname,
          totalSales: formatAmount(item.grossIncome, 0),
          totalHires: item.totalHires,
        }));
      setData(dataTransformed);
    }
  }, [incomePerLicenseeData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Franchisee Performance"
      navigateTo={`/analytics/franchisee-performance`}
    >
      <SortableTable
        rows={data}
        columns={columns}
        hasIndexColumn
        gridTemplateColumns="10% 45% 25% 20%"
      />
    </Card>
  );
}
