import { Box, Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ReactComponent as Delete } from "../../img/delete.svg";

const useStyle = makeStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  paper: {
    backgroundColor: "#171717",
  },
  outlinedButton: {
    lineHeight: "14px",
    height: "35px",
    textTransform: "none",
    width: "150px",
    padding: "6px 9px",
    marginRight: "10px",
  },
  transferItem: {
    height: 90,
    borderTop: "1px solid #24272d",
  },
});
const TransferBookingDialog = ({ open, transferList, onClose, onSelect }) => {
  const classes = useStyle();

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <Box padding="25px" boxSizing="border-box" width="600px" position="relative">
        <Box position="absolute" right="24px" top="21px" className={classes.root} onClick={onClose}>
          <Delete />
        </Box>
        <Box padding="15px" marginBottom="15px">
          <Typography align="center" variant="h5">
            Who would you like to transfer the booking too ?
          </Typography>
        </Box>
        <Box style={{ maxHeight: 500, overflow: "auto" }}>
          {transferList.map((item) => (
            <Box
              key={item.id}
              className={classes.transferItem}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <div className="text-16">{item.name}</div>
                <div>{item.address}</div>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                className={classes.outlinedButton}
                onClick={() => onSelect(item)}
              >
                Transfer
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
export default TransferBookingDialog;
