import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useRouteMatch } from "react-router-dom";
import SortableTable from "../../../../../components/SortableTable/SortableTable";
import { Card } from "../../../components";

export function UnservBookingEnq() {
  // const { url } = useRouteMatch();
  const { unserviceBookingPerformanceData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (unserviceBookingPerformanceData?.items) {
      const dataTransformed = [...unserviceBookingPerformanceData.items]
        .sort((a, b) => b.total - a.total)
        .slice(0, 5)
        .map((item) => ({
          city: item._id,
          unservicedBookings: item.total,
        }));
      setData(dataTransformed);
    }
  }, [unserviceBookingPerformanceData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Unserviced Bookings",
      key: "unservicedBookings",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Unserviced Booking Performance"
      navigateTo={`/analytics/unserv-booking-enquiries`}
    >
      <SortableTable
        rows={data}
        columns={columns}
        hasIndexColumn
        gridTemplateColumns="10% 50% 40%"
      />
    </Card>
  );
}
