import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Card } from "../../components";
import { useStyles } from "../../Analytics.styles";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { formatAmount } from "../../utils";

export function DamageWaiverUtilisationReportPage() {
  const classes = useStyles();
  const { damageWaiverUtilisationReport } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Damage Waiver Utilisation Report";
    if (damageWaiverUtilisationReport?.items) {
      const dataTransformed = [...damageWaiverUtilisationReport?.items]
        .sort((a, b) => b?.income - a?.income)
        .map((item) => ({
          trailer: item?.trailer,
          totalNumberOfHires: item?.totalHires ?? "--",
          totalHires: item?.total ?? "--",
          totalIncome: formatAmount(item?.income || 0),
          averageIncomePerHire: formatAmount(item?.averageIncomePerHire || 0),
          utilisation: item?.utilisation ?? "--",
        }));
      setData(dataTransformed);
    }
  }, [damageWaiverUtilisationReport]);

  const columns = [
    {
      name: "Trailer type",
      key: "trailer",
      sortable: true,
    },
    {
      name: "Total number of hires",
      key: "totalNumberOfHires",
      sortable: true,
    },
    {
      name: "Total hires including Damage Waiver",
      key: "totalHires",
      sortable: true,
    },
    {
      name: "Damage waiver income",
      key: "totalIncome",
      sortable: true,
    },
    {
      name: "Average damage waiver per hire",
      key: "averageIncomePerHire",
      sortable: true,
    },
    {
      name: "Utilisation",
      key: "utilisation",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Damage Waiver utilisation Report by Trailer Type">
        <SortableTable
          rows={data}
          columns={columns}
          gridTemplateColumns="50% 10% 10% 10% 10% 10%"
        />
      </Card>
    </Box>
  );
}
