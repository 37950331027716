import axios from "./axios";

export const calculateHireCostTrailer = (price, setPricePerDay) => {
  axios.get(`/v1/admin/calculate-hire-cost-trailer?price=${price}`).then((response) => {
    return setPricePerDay(response.data.dataObj);
  });
  return;
};

export const calculateHireCostUpsell = (price, setPricePerDay) => {
  axios.get(`/v1/admin/calculate-hire-cost-upsell?price=${price}`).then((response) => {
    return setPricePerDay(response.data.dataObj);
  });
  return;
};

export const getTransaction = async (
  { fromDate = "", toDate = "", search = "", count = 10, skip = 0, licenseeId = "" },
  options
) => {
  const data = await axios({
    ...options,
    method: "GET",
    url: "/v1/admin/transaction",
    params: { ...options?.params, fromDate, toDate, search, count, skip, licenseeId },
  }).then((res) => res.data);
  return data;
};

export const getTransactionDetail = async (id, options) => {
  const { transaction: newTransaction, history: newHistory } = await axios({
    ...options,
    method: "GET",
    url: `/v1/admin/transaction/${id}`,
  }).then((res) => res.data);
  return { newTransaction, newHistory };
};

// get report PDF blob to download
export const getFranchiseeTransactionHistoryReportPDF = async (
  { fromDate, toDate, franchiseeId },
  options
) => {
  return await axios({
    url: "v1/admin/transaction/report",
    method: "GET",
    responseType: "blob",
    ...options,
    params: {
      ...options?.params,
      fromDate,
      toDate,
      franchiseeId,
    },
  });
};

export const sendFranchiseeTransactionHistoryReportEmail = async (
  { fromDate, toDate, franchiseeId },
  options
) => {
  await axios({
    url: "v1/admin/transaction/report",
    method: "POST",
    ...options,
    data: {
      ...options?.data,
      fromDate,
      toDate,
      franchiseeId,
    },
  }).then((res) => res.data);
};
