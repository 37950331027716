import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import "./style.scss";

const useStyles = makeStyles(() => ({
  topLevelContainer: {
    marginBottom: 50,

    /* Styles to make the sticky header styles work on the adjacent table component */
    "& + div > div": {
      overflowX: "unset !important",
    },
    "& + div > div > div > div": {
      overflowY: "unset !important",
    },
  },
  root: {
    width: "100%",
  },
  h2: {
    marginBottom: "25px",
  },
  focused: {},
  notchedOutline: {},
  input: {
    padding: "13px",
    paddingLeft: "43px",
    fontSize: "15px",
    color: "#000000",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  icon: {
    height: 32,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    marginLeft: "16px",
  },
  search_icon: {
    fontSize: "32px",
    color: "#535f78",
    paddingRight: "8px",
  },
  form_search: {
    height: "43px",
    width: "342px",
    marginRight: "16px",
    borderRadius: "4px",
  },
  form_filter: {
    width: "140px",
    margin: "0 8px",
  },
  icon_filter: {
    fontSize: "18px",
  },
  btnFiler: {
    width: "90px",
    padding: "5px 12px",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  dialog: {
    position: "absolute",
    top: 17,
  },
  addBtn: {
    width: "173px",

    textTransform: "none",
  },
  dialog__footer: {
    display: "flex",
    justifyContent: "space-between",
    height: "74px",
    padding: "0 28px",
  },
  but__done: {
    width: "97px",
  },
  dialogFilter: {
    width: "517px",
  },
  dialogContent: {
    padding: "0",
  },
  iconClose: {
    cursor: "pointer",
  },
}));
const styleModal = makeStyles({
  paper: {
    position: "absolute",
    top: 20,
  },
});
const SearchAndFilter = ({
  optionFilter,
  textAdd,
  textSearch,
  linkAdd,
  showAdd,
  searchFilter,
  setSearchFilter,
  pagination,
  setPagination,
}) => {
  const classes = useStyles();
  const styleDialog = styleModal();
  const [open, setOpen] = useState(false);
  const [searchFilterTemp, setSearchFilterTemp] = useState(
    searchFilter.map((item) => {
      return Object.assign({}, item);
    })
  );

  useEffect(() => {
    if (searchFilter.length > 0) {
      setSearchFilterTemp(
        searchFilter.map((item) => {
          return Object.assign({}, item);
        })
      );
    }
  }, [searchFilter]);
  const [count, setCount] = useState(0);
  let history = useHistory();
  const handleClickAdd = () => {
    history.push(linkAdd);
  };

  const countFilter = (filter) => {
    let count = 0;
    for (let i = 0; i < filter.length - 1; i++) {
      for (let key in filter[i]) {
        if (filter[i][key]) {
          count += 1;
        }
      }
    }
    return count;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChangeSearch = (value) => {
    if (searchFilterTemp.length) {
      if (!value) {
        setSearchFilter(searchFilterTemp);
      }
      let temp = [...searchFilterTemp];
      temp[searchFilterTemp.length - 1][
        Object.keys(searchFilterTemp[searchFilterTemp.length - 1])[0]
      ] = value;

      setSearchFilterTemp(temp);
    }
  };
  const handleEnterSearch = (e) => {
    e.preventDefault();
    setPagination({ ...pagination, skip: 0, totalCount: 0 });
    setSearchFilter(searchFilterTemp);
  };
  const onChangeChecked = (indexFilter, indexOption) => {
    let temp = [...searchFilterTemp];
    const key = Object.keys(searchFilterTemp[indexFilter])[indexOption];
    temp[indexFilter][key] = !searchFilterTemp[indexFilter][key];
    setSearchFilterTemp(temp);
  };
  const handleClose = () => {
    setSearchFilterTemp(
      searchFilter.map((item) => {
        return Object.assign({}, item);
      })
    );
    setOpen(false);
  };
  // const handleCancelSelectorFilter = () => {
  //   setSearchFilterTemp(searchFilter);
  //   handleClose();
  // };
  const handleSetFilter = () => {
    setSearchFilter(searchFilterTemp);
    setCount(countFilter(searchFilterTemp));
    setOpen(false);
  };
  const handleResetFilter = () => {
    const temp = [...searchFilterTemp];
    for (let i = 0; i < temp.length - 1; i++) {
      for (let key in temp[i]) {
        temp[i][key] = false;
      }
    }
    setSearchFilter(temp);
    setCount(0);
    setOpen(false);
  };
  return (
    <div className={`filter-action-container ${classes.topLevelContainer}`}>
      <div className="filter-container">
        <form onSubmit={handleEnterSearch}>
          <FormControl variant="outlined" className={classes.form_search}>
            <InputAdornment position="start" className={classes.icon}>
              <SearchIcon className={classes.search_icon} />
            </InputAdornment>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={`Search ${textSearch}`}
              value={
                searchFilterTemp[searchFilterTemp.length - 1][
                  Object.keys(searchFilterTemp[searchFilterTemp.length - 1])[0]
                ]
              }
              onChange={(event) => handleChangeSearch(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
            />
          </FormControl>
        </form>
        <Button variant="outlined" onClick={() => handleClickOpen()} className={classes.btnFiler}>
          Filter {count !== 0 ? count : null} <FilterListIcon className={classes.icon_filter} />
        </Button>
      </div>
      {showAdd ? (
        <div className="action-container">
          <Button
            variant="contained"
            color="primary"
            className={classes.addBtn}
            onClick={() => handleClickAdd()}
          >
            {textAdd}
          </Button>
        </div>
      ) : null}
      <Dialog open={open} onClose={() => handleClose()} classes={styleDialog}>
        <DialogTitle className={classes.dialogFilter}>
          <div className="dialog-title">
            <CloseIcon onClick={() => handleClose()} className={classes.iconClose} />
            <span className="text-18">Filters</span>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {optionFilter.map((item, index) => {
            return (
              <div className="filter" key={index}>
                <div className="filter-title">
                  <span className="text-24">{item.name}</span>
                </div>
                <div>
                  <FormGroup row className="filter-option">
                    {item.options.map((option, indexOption) => {
                      return (
                        <FormControlLabel
                          key={indexOption}
                          className="filter-item"
                          control={
                            <Checkbox
                              name="checkedA"
                              checked={
                                searchFilterTemp[index][
                                  Object.keys(searchFilterTemp[index])[indexOption]
                                ]
                              }
                              onChange={() => onChangeChecked(index, indexOption)}
                              color="primary"
                            />
                          }
                          label={<span className="text-16">{option.text}</span>}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions className={classes.dialog__footer}>
          <Button onClick={handleResetFilter} color="primary">
            Reset Filters
          </Button>
          <Button
            onClick={() => handleSetFilter()}
            color="primary"
            variant="contained"
            className={classes.but__done}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchAndFilter;
