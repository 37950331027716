import React, { useState } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "./style.scss";
import { isValidDateInstance } from "../../helpers/verify";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
  topLevelContainer: {
    marginBottom: 50,

    /* Styles to make the sticky header styles work on the adjacent table component */
    "& + div > div": {
      overflowX: "unset !important",
    },
    "& + div > div > div > div": {
      overflowY: "unset !important",
    },
  },
  root: {
    width: "100%",
  },
  h2: {
    marginBottom: "25px",
  },
  focused: {},
  notchedOutline: {},
  input: {
    padding: "13px",
    paddingLeft: "43px",
    fontSize: "15px",
    color: "#000000",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  icon: {
    height: 32,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    marginLeft: "16px",
  },
  search_icon: {
    fontSize: "32px",
    color: "#535f78",
    paddingRight: "8px",
  },
  form_search: {
    height: "43px",
    width: "342px",
    marginRight: "16px",
    borderRadius: "4px",
  },
  form_filter: {
    width: "140px",
    margin: "0 8px",
  },
  icon_filter: {
    fontSize: "18px",
  },
  btnFiler: {
    width: "130px",
    padding: "5px 12px",
    "& .MuiButton-label": {
      justifyContent: "space-between",
    },
  },
  dialog: {
    position: "absolute",
    top: 17,
  },
  addBtn: {
    width: "173px",

    textTransform: "none",
  },
  dialog__footer: {
    display: "flex",
    justifyContent: "space-between",
    height: "74px",
    padding: "0 28px",
  },
  but__done: {
    width: "97px",
  },
  dialogFilter: {
    width: "517px",
  },
  dialogContent: {
    padding: "0",
  },
  iconClose: {
    cursor: "pointer",
  },
  gridContainer: {
    padding: "0 10%",
  },
  datePickerContainer: {
    flexBasis: "40%",
    "& button": {
      backgroundColor: "transparent",
    },
  },
}));
const styleModal = makeStyles({
  paper: {
    position: "absolute",
    top: 20,
  },
});

const validationSchema = Yup.object({
  search: Yup.string(),
  from: Yup.date()
    .nullable(true)
    .typeError("Invalid date value")
    .when("to", (value, schema) =>
      isValidDateInstance(value)
        ? schema.max(value, "Date from can not be larger than date to")
        : undefined
    ),
  to: Yup.date().nullable(true).typeError("Invalid date value"),
});

const SearchAndFilterDate = ({
  textAdd,
  textSearch,
  linkAdd,
  pagination,
  setPagination,
  searchFilter,
  setSearchFilter,
  defaultFilter,
}) => {
  const classes = useStyles();
  const styleDialog = styleModal();
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    errors,
    setValues,
    isValid,
    isValidating,
  } = useFormik({
    initialValues: searchFilter,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setPagination({ ...pagination, skip: 0, totalCount: 0 });
      setSearchFilter({ ...searchFilter, ...values });
    },
  });

  let history = useHistory();

  const handleClickAdd = () => {
    history.push(linkAdd);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValues(searchFilter);
  };

  const handleSubmitFilter = () => {
    if (isValid && !isValidating) {
      handleSubmit();
      setOpen(false);
    }
  };

  const handleResetFilter = () => {
    setSearchFilter({ ...defaultFilter, search: searchFilter.search });
    setPagination({ ...pagination, skip: 0, totalCount: 0 });
    setOpen(false);
  };

  return (
    <div className={`filter-action-container ${classes.topLevelContainer}`}>
      <div className="filter-container">
        <form onSubmit={handleSubmit}>
          <FormControl variant="outlined" className={classes.form_search}>
            <InputAdornment position="start" className={classes.icon}>
              <SearchIcon className={classes.search_icon} />
            </InputAdornment>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={`Search ${textSearch}`}
              value={values.search}
              name="search"
              onChange={handleChange}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
            />
          </FormControl>
        </form>
        <Button variant="outlined" onClick={() => handleClickOpen()} className={classes.btnFiler}>
          Filter Date <FilterListIcon className={classes.icon_filter} />
        </Button>
      </div>
      <div className="action-container">
        <Button
          variant="contained"
          color="primary"
          className={classes.addBtn}
          onClick={handleClickAdd}
        >
          {textAdd}
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose} classes={styleDialog}>
        <DialogTitle className={classes.dialogFilter}>
          <div className="dialog-title">
            <CloseIcon onClick={handleClose} className={classes.iconClose} />
            <span className="text-18">Filters</span>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className="filter">
            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.datePickerContainer}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-around"
                className={classes.gridContainer}
              >
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Select a date from"
                  format="dd/MM/yyyy"
                  value={values.from ? values.from : null}
                  onChange={(date) => setFieldValue("from", date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className={classes.datePickerContainer}
                  helperText={errors.from}
                  error={!!errors.from}
                />
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Select a date to"
                  format="dd/MM/yyyy"
                  value={values.to ? values.to : null}
                  onChange={(date) => setFieldValue("to", date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className={classes.datePickerContainer}
                  helperText={errors.to}
                  error={!!errors.to}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialog__footer}>
          <Button onClick={handleResetFilter} color="primary">
            Reset Filters
          </Button>
          <Button
            onClick={handleSubmitFilter}
            color="primary"
            variant="contained"
            className={classes.but__done}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchAndFilterDate;
