import * as React from "react";
import axios from "../../helpers/axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { ReactComponent as Edit } from "../../img/edit.svg";
import { ReactComponent as DragHandle } from "../../img/dragIndicator.svg";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import ColumnInTable from "../../components/ColumnInTable";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { USER_ROLES, common } from "../../constants/common";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { getCRUD_AllowByRoles } from "../../helpers/common";

const { useState, useEffect } = React;

const useRowStyles = makeStyles({
  focusedRow: {
    /* Styles to make the row stay highlighted while the drag event is ongoing. */
    backgroundColor: "#2F2E2E",
    boxShadow: "inset 0px -1px 0px #535F78, inset 0px 1px 0px #535F78",
  },
  tableBody: {
    /* Styles to prevent the :hover CSS pseudo class while the drag event is ongoing. */
    "& > tr": {
      pointerEvents: "none",
    },
  },
  table: {
    /* Styles to override the default sticky header configuration. */
    "& > thead > tr > th": {
      top: 54,
      left: "unset",
    },
  },
  tableContainer: {
    marginBottom: 60,
    overflowX: "unset",
  },
  tableRow: {
    backgroundColor: "#171717",
    transition: "background-color 250ms ease-in-out",

    "&:hover": {
      backgroundColor: "#2F2E2E",
      boxShadow: "inset 0px -1px 0px #535F78, inset 0px 1px 0px #535F78",
    },
  },
  upsellTypeHeader: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "21px",
  },
  upsellTypeRow: {
    width: "40%",

    "& > div": {
      marginRight: 8,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19px",
      display: "flex",
      alignItems: "center",
    },
  },
  itemTypeRow: {
    width: "10%",

    "& > div": {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  priceRow: {
    width: "10%",

    "& > div": {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  descriptionRow: {
    width: "30%",

    "& > div > div": {
      maxWidth: "120px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  actionButtonsRow: {
    width: "10%",
  },
  editIconContainer: {
    padding: 8,
    borderRadius: "50%",
    backgroundColor: "#171717",
    cursor: "pointer",
  },
  editIcon: {
    cursor: "pointer",
  },
  dragHandle: {
    marginLeft: 44,
    cursor: "pointer",
  },
});

/**
 * Utility function to help us with reordering the result.
 * @param { any[] } list
 * @param { number } startIndex
 * @param { number } endIndex
 * @returns any[]
 */
function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

/**
 * Renders the row item within the table body.
 * @param { { _id: string, consumable: boolean, name: string, price: number, hirecost: number, description: string }[] } rowData
 * @param { number } index
 * @returns JSX.Element
 */
function Row({ rowData, index, isAllowToUpdate }) {
  const classes = useRowStyles();
  const history = useHistory();

  return (
    <Draggable draggableId={rowData._id} index={index}>
      {(provided, snapshot) => {
        return (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${classes.tableRow} ${snapshot.isDragging ? classes.focusedRow : ""}`}
          >
            {/* Upsell item */}
            <TableCell className={classes.upsellTypeRow}>
              <div className="list-item-name">{rowData.name}</div>
            </TableCell>

            {/* Item type */}
            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="185">
                {rowData.consumeable
                  ? common.itemType.consumable.label
                  : rowData.premium
                  ? common.itemType.premium.label
                  : common.itemType.upsell.label}
              </ColumnInTable>
            </TableCell>

            {/* Price */}
            <TableCell className={classes.priceRow}>
              <ColumnInTable minWidth="165">
                {rowData.consumeable
                  ? "$" + Math.ceil(rowData.price)
                  : "$" + rowData.hirecost?.toFixed(2) + " per day"}
              </ColumnInTable>
            </TableCell>

            {/* Description */}
            <TableCell className={classes.descriptionRow}>
              <ColumnInTable minWidth="175">
                <div>{rowData.description}</div>
              </ColumnInTable>
            </TableCell>

            {/* Action buttons */}
            <TableCell className={classes.actionButtonsRow}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  className={classes.editIconContainer}
                  onClick={() => history.push(`/rental/upsell/edit/${rowData._id}`)}
                >
                  <Edit className={classes.editIcon} />
                </Box>

                {isAllowToUpdate ? (
                  <Box
                    component="span"
                    display="flex"
                    alignItems="center"
                    {...provided.dragHandleProps}
                  >
                    <DragHandle className={classes.dragHandle} />
                  </Box>
                ) : null}
              </Box>
            </TableCell>
          </TableRow>
        );
      }}
    </Draggable>
  );
}

/**
 * Renders the custom table component.
 * @returns JSX.Element
 */
export const CustomTable = ({ isAllowToUpdate }) => {
  const classes = useRowStyles();
  /*
   * Important note:
   * The `upsellList` will eventually have the following type.
   * { _id: string, consumable: boolean, name: string, price: number, hirecost: number, description: string }[]
   */
  const [upsellList, setUpsellList] = useState([]);

  useEffect(() => {
    async function fetchUpsellItems() {
      try {
        const response = await axios.get("/v1/admin/get-all-upsellitems");
        setUpsellList(response.data.dataObj);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }

    fetchUpsellItems();
  }, []);

  /**
   * Drag start event handler.
   * @returns void
   */
  function onDragStart() {
    /*
     * Add a little vibration if the browser supports it.
     * Adds a nice little physical feedback.
     */
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  }

  /**
   * Drag end event handler.
   * @param { {  source: { index: number }, destination: { index: number } } } result
   * @returns void
   */
  function onDragEnd(result) {
    if (
      result.source.index !== undefined &&
      result.destination.index !== undefined &&
      result.destination.index != result.source.index
    ) {
      const upsellItem = upsellList[result.source.index];
      const newIndex = result.destination.index;

      axios
        .post("/v1/admin/update-upsellitem-index", {
          UpsellItemTypeId: upsellItem._id,
          index: newIndex,
        })
        .then(() => {
          async function fetchUpsellItems() {
            try {
              const response = await axios.get("/v1/admin/get-all-upsellitems");
              setUpsellList(response.data.dataObj);
            } catch (error) {
              toast.error(error?.response?.data?.message);
            }
          }

          fetchUpsellItems();
        });
    }

    /* Combining item */
    if (result.combine) {
      /* Super simple - just removing the dragging item */
      const newUpsellList = [...upsellList];
      newUpsellList.splice(result.source.index, 1);
      setUpsellList(newUpsellList);
      return;
    }

    /* Do nothing when dropped outside the list or outside the `Droppable` area. */
    if (!result.destination) {
      return;
    }

    /* Do nothing when the item's original position and destination position are the same. */
    if (result.destination.index === result.source.index) {
      return;
    }

    const newUpsellList = reorder(upsellList, result.source.index, result.destination.index);
    setUpsellList(newUpsellList);
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="upsell-list-table" className={classes.table}>
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell>
                <Box className={classes.upsellTypeHeader}>Upsell Item</Box>
              </TableCell>
              <TableCell>Item Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Description</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          {/* Body */}
          <Droppable droppableId="upsell-list-table">
            {(provided, snapshot) => {
              return (
                <TableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={snapshot.isDraggingOver ? `${classes.tableBody}` : ""}
                >
                  {upsellList.map((rowData, index) => (
                    <Row
                      key={rowData._id}
                      rowData={rowData}
                      index={index}
                      isAllowToUpdate={isAllowToUpdate}
                    />
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </Table>
      </TableContainer>
    </DragDropContext>
  );
};

/**
 * Renders the header component of the Upsell list.
 * @param { React.Node } children
 * @returns JSX.Element
 */
export const Header = ({ children, isAllowToCreate }) => {
  const history = useHistory();

  return (
    <>
      <WrapHeaderSubPage nameHeader="Upsell Items">
        {isAllowToCreate ? (
          <ButtonActionHeader onClick={() => history.push("/rental/upsell/add")}>
            Add Item
          </ButtonActionHeader>
        ) : null}
      </WrapHeaderSubPage>
      <Breadcrumbs />
      {children}
    </>
  );
};

/**
 * Renders the entire Upsell list component.
 * @returns JSX.Element
 */
const UpsellList = () => {
  const { isAllowToCreate, isAllowToUpdate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  return (
    <Header isAllowToCreate={isAllowToCreate}>
      <CustomTable isAllowToUpdate={isAllowToUpdate} />
    </Header>
  );
};

export default UpsellList;
