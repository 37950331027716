import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputBase,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Close, FilterList } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../../../helpers/axios";
import { useDebounce } from "../../../../hooks/useDebounce";
import { ReactComponent as ClearIcon } from "../../../../img/clearIcon.svg";
import { setSelectedFilterValues } from "../../../../redux/slices/analyticSlice";
import { useStyles } from "./AnalyticsFilter.styles";
import { CustomFilterIcon } from "./CustomFilterIcon";

const { useState } = React;

export function AnalyticsFilter({ label, open, displayFilterModal, hideFilterModal }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedFilterValues } = useSelector((state) => state.analytic);
  const [filterText, setFilterText] = useState("");
  const [filterValues, setFilterValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState(selectedFilterValues ?? []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedValues(selectedFilterValues);
  }, [selectedFilterValues]);

  const getFilterValues = async (keyword, limit = 10) => {
    setSelectedValues([]);
    try {
      const response = await axiosInstance.get(
        `/v1/admin/analytics/search?keywords=${keyword}&count=${limit}`
      );
      const results = response.data?.dataObj?.results;
      if (results) setFilterValues(results);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleGetFilterValues = useDebounce(getFilterValues);

  useEffect(() => {
    if (filterText.trim()) {
      setLoading(true);
      handleGetFilterValues(filterText);
    }
  }, [filterText]);

  function updateFilterText(event) {
    setFilterText(event.target.value);
  }

  function onSelectAll() {
    if (filterValues.length === selectedValues.length) {
      setSelectedValues(
        filterValues.filter((el) => !selectedValues.some((el2) => el2.id === el.id))
      );
    } else {
      setSelectedValues(filterValues);
    }
  }

  const onCheckBoxClick = (item) => {
    let newSelectedFilterValues = [...selectedValues];
    if (selectedValues.some((el) => el.id === item.id)) {
      newSelectedFilterValues = selectedValues.filter((el) => el.id !== item.id);
    } else {
      newSelectedFilterValues.push(item);
    }
    setSelectedValues(newSelectedFilterValues);
  };

  const onApplyFilter = () => {
    dispatch(setSelectedFilterValues(selectedValues));
    hideFilterModal();
  };

  const onClearFilter = () => {
    setSelectedValues([]);
    setFilterText("");
  };

  return (
    <>
      <Button
        startIcon={<FilterList />}
        onClick={displayFilterModal}
        className={classes.filterButton}
        style={{
          borderColor: selectedFilterValues?.length > 0 ? theme.palette.primary.main : "inherit",
        }}
      >
        {label}
      </Button>

      <Dialog
        open={open}
        onClose={hideFilterModal}
        className={classes.analyticsFilterModal}
        aria-labelledby="analytics-filter-dialog"
        aria-describedby="filter-input-area"
      >
        <DialogTitle id="analytics-filter-dialog" className={classes.filterModalTitle}>
          <CustomFilterIcon color="white" />
          <Typography>Filter</Typography>

          <IconButton
            aria-label="hide-analytics-filter-modal"
            className={classes.modalCloseButton}
            onClick={hideFilterModal}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.filterModalContent}>
          <DialogContentText id="filter-input-area">
            Please select from list below
          </DialogContentText>

          <InputBase
            inputProps={{ "aria-label": "search trailer, trailer type, franchisee, location" }}
            placeholder="Search Trailer, trailer type, franchisee, location"
            className={classes.filterModalTextInput}
            onChange={updateFilterText}
            value={filterText}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setFilterText("")}
                  style={{ marginRight: -15 }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {loading ? (
            <Box
              style={{ marginTop: 20 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            filterText && (
              <Box style={{ maxHeight: 420, overflow: "auto", marginTop: 20 }}>
                {filterValues?.length > 0 ? (
                  <>
                    <Box
                      className={classes.rowItem}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Box>Select all</Box>
                      <Checkbox
                        name="selectAll"
                        checked={selectedValues.length === filterValues.length}
                        onChange={() => onSelectAll()}
                        color="primary"
                      />
                    </Box>
                    {filterValues.map((item) => (
                      <Box
                        key={item.id}
                        className={classes.rowItem}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box>{item.value}</Box>
                        <Checkbox
                          name={`${item.value}-${item.id}`}
                          checked={selectedValues.some((el) => el.id === item.id)}
                          onChange={() => onCheckBoxClick(item)}
                          color="primary"
                        />
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box>No data found</Box>
                )}
              </Box>
            )
          )}
        </DialogContent>

        <DialogActions className={classes.filterModalActions}>
          <Button color="primary" onClick={onApplyFilter}>
            Apply
          </Button>
          <Button color="primary" onClick={onClearFilter}>
            Clear all
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
