import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Container,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { MoreVert as MoreVertIcon } from "@material-ui/icons";
import InfoContainerForDetail from "./InfoContainerForDetail";
import { Link, useParams } from "react-router-dom";
import { useLoading } from "../../../hooks/useLoading";
import { formatCurrency } from "../../../helpers/format";
import placeholerImage from "../../../img/image.svg";
import { getTransactionDetail } from "../../../helpers/api";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { USER_ROLES, common } from "../../../constants/common";
import { Stack } from "../../../components/Common/Styled/CommonStyled";
import {
  Text16,
  Text18Weight700,
  Text32Weight700,
} from "../../../components/Common/Styled/TypographyStyled";
import moment from "moment";
import {
  useCancelOrder,
  useResendInvoice,
} from "../../../hooks/TransactionHistory/useTransactionHistory";
import useDialog from "../../../hooks/useDialog";
import { getCRUD_AllowByRoles } from "../../../helpers/common";

const useStyles = makeStyles(() => ({
  orderInfoContainer: {
    paddingBottom: "121px",
  },
  imgContainer: {
    // maxWidth: 624,
    // marginRight: "auto",
    // padding: "97px 167px",
    // margin: "0 36px 0 92px",
    border: "1px solid #54565C",
    boxSizing: "border-box",
    borderRadius: "4px",
    "&  img": {
      margin: "16px 0",
      maxWidth: 450,
      objectFit: "contain",
    },
  },
  imageTitle: {
    padding: "12px 0 12px 17px",
    borderBottom: "1px solid #54565C",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoContainer: {
    // padding: "0 36px 0 92px",
  },
  orderTotalContainer: {
    padding: "0 21px",
    display: "flex",
    justifyContent: "space-between",
  },
  orderTotalTitle: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "29px",
    /* identical to box height */

    color: "#969CA8",
  },
  orderTotalPrice: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    textAlign: "right",

    color: "#FFFFFF",
  },
}));

TransactionHistoryDetail.propTypes = {};

function TransactionHistoryDetail() {
  const { isAllowToUpdate } = getCRUD_AllowByRoles({
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const { renderDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const classes = useStyles();
  const { id } = useParams();
  const [showLoading, hideLoading, loading] = useLoading();
  const { mutate: mutateResendInvoice, isLoading: isLoadingResendInvoice } = useResendInvoice(id);
  const { mutate: mutateCancelOrder, isLoading: isLoadingCancelOrder } = useCancelOrder(id);
  const isLoading = isLoadingResendInvoice || isLoadingCancelOrder;
  const [transaction, setTransaction] = useState({
    _id: null,
    trailerInfor: {},
    orderTotal: 0,
    details: [],
    status: "",
  });
  const [history, setHistory] = useState([]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async () => {
      showLoading();
      try {
        const { newTransaction, newHistory } = await getTransactionDetail(id);
        setTransaction(newTransaction);
        setHistory(newHistory);
      } catch (err) {
        console.log(err);
      } finally {
        hideLoading();
      }
    })();
  }, [reload]);

  const ableToUpdateOrder =
    ![common.ORDER_STATUS.FINISHED, common.ORDER_STATUS.CANCELED].includes(transaction?.status) &&
    isAllowToUpdate;

  const onHandleResendInvoice = () => {
    handleCloseMenu();
    mutateResendInvoice();
  };

  const onHandleCancelOrder = () => {
    handleCloseMenu();
    handleOpenDialog({
      title: "Are you sure you want to cancel this order?",
      content:
        "Tapping Confirm will cancel the order and issue a full refund to the customer’s payment method.",
      handleConfirm: () =>
        mutateCancelOrder(undefined, {
          onSuccess: () => {
            setReload((prevState) => !prevState);
            handleCloseDialog();
          },
        }),
      approveText: "Confirm",
      disapproveText: "Cancel",
    });
  };

  const handleOpenMenu = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenuEl(null);
  };

  return (
    <Container style={{ maxWidth: 750 }} paddingTop={"50px"}>
      {renderDialog}
      {!loading ? (
        <>
          <Stack sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
            <Stack sx={{ flexDirection: "column", gap: 8 }}>
              <Text32Weight700>Order {id}</Text32Weight700>
              <Breadcrumbs />
            </Stack>

            {isAllowToUpdate ? (
              <Button
                variant="outlined"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                style={{ width: 41, height: 41 }}
              >
                {isLoading ? <CircularProgress size={12} /> : <MoreVertIcon />}
              </Button>
            ) : null}
            <Menu
              id="simple-menu"
              anchorEl={anchorMenuEl}
              keepMounted
              open={!!anchorMenuEl}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={onHandleResendInvoice}>Re-send invoice</MenuItem>
              {transaction?.status !== common.ORDER_STATUS.CANCELED ? (
                <MenuItem onClick={onHandleCancelOrder}>
                  Cancel order and issue a full refund
                </MenuItem>
              ) : null}
            </Menu>
          </Stack>
          <Box className={classes.orderInfoContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className={classes.imgContainer}>
                  <div className={classes.imageTitle}>
                    <Text18Weight700>{transaction.trailerInfor?.name}</Text18Weight700>
                    {ableToUpdateOrder ? (
                      <Button
                        to={`/transaction-history/update-order/${id}`}
                        color="primary"
                        component={Link}
                      >
                        Change
                      </Button>
                    ) : null}
                  </div>
                  <div className={classes.imageBox}>
                    <img
                      src={transaction.trailerInfor?.photo?.url || placeholerImage}
                      alt={transaction.trailerInfor?.name || ""}
                    />
                  </div>
                </Box>
              </Grid>
              {history?.map((individualTransaction, index) => (
                <IndividualTransaction
                  key={index}
                  individualTransaction={individualTransaction}
                  isOriginalOrder={index === 0}
                />
              ))}
              <IndividualTransaction
                individualTransaction={transaction}
                isOriginalOrder={history?.length === 0}
                isLatestTransaction
                ableToUpdateOrder={ableToUpdateOrder}
              />
            </Grid>
          </Box>
        </>
      ) : null}
    </Container>
  );
}

const IndividualTransaction = ({
  individualTransaction,
  isOriginalOrder,
  isLatestTransaction,
  ableToUpdateOrder = false,
}) => {
  const classes = useStyles();
  const transactionDate = useMemo(() => {
    const splitSection = individualTransaction?.details?.find(
      (item) => item?.title === common.TRANSACTION_DETAILS_TITLE.SPLITS
    );
    if (splitSection) {
      const transactionDate = splitSection?.items?.find(
        (item) => item?.name === "Transaction date"
      );

      if (transactionDate) {
        return moment(transactionDate?.value).format("DD MMM YYYY");
      }
    }
    return null;
  }, [individualTransaction]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 32 }}>
      <Box className={classes.infoContainer}>
        <Stack sx={{ alignItems: "flex-end", justifyContent: "space-between", mb: 4 }}>
          <Text32Weight700>
            {isOriginalOrder ? "Original Price Details" : "Updated Price Details"}
          </Text32Weight700>
          <Text16>{transactionDate}</Text16>
        </Stack>
        {individualTransaction.details.map((detail, index) => (
          <InfoContainerForDetail
            key={index}
            data={detail}
            isLatestTransaction={isLatestTransaction}
            ableToUpdateOrder={ableToUpdateOrder}
          />
        ))}
        <Box className={classes.orderTotalContainer}>
          <span className={classes.orderTotalTitle}>Order Total</span>
          <span className={classes.orderTotalPrice}>
            {formatCurrency(individualTransaction.orderTotal)}
          </span>
        </Box>
      </Box>
    </Grid>
  );
};

export default TransactionHistoryDetail;
