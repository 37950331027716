import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatHour } from "../../utils";

export function HTDUtilisationReportByTrailerTypePage() {
  const classes = useStyles();
  const { htdUtilisationReportByTrailerType } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (htdUtilisationReportByTrailerType?.items) {
      const dataTransformed = htdUtilisationReportByTrailerType.items.map((item) => ({
        name: item?.trailerType?.name ?? "--",
        HTDTime: formatHour(item.totalHtdHours),
        totalHireTime: item.totalHireDuration ?? "--",
        utilisation: item?.htdUtilisationPercentage ?? "--",
        HTDRatio: item?.htdRatio ?? "--",
      }));
      setData(dataTransformed);
    }
  }, [htdUtilisationReportByTrailerType]);

  const columns = [
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "HTD time",
      key: "HTDTime",
      sortable: true,
    },
    {
      name: "Total hire time",
      key: "totalHireTime",
      sortable: true,
    },
    {
      name: "Utilisation",
      key: "utilisation",
      sortable: true,
    },
    {
      name: "HTD Ratio",
      key: "HTDRatio",
      sortable: true,
    },
  ];

  return (
    <>
      <Box component="main" className={classes.analyticsSinglePageContent}>
        <Card header="HTD Utilisation Report By Trailer Type">
          <SortableTable rows={data} columns={columns} gridTemplateColumns="40% 15% 15% 15% 15%" />
        </Card>
      </Box>
    </>
  );
}
