import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  pieGraph: {
    height: 279,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  background: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -33%)",
    "& > .MuiCircularProgress-root": {
      width: "220px !important",
      height: "220px !important",
      "& > svg > circle": {
        strokeWidth: 3,
      },
    },
    "& > .MuiCircularProgress-colorPrimary": {
      color: "#44D62C", // Neon green
    },
  },
  foreground: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -33%)",
    "& > .MuiCircularProgress-root": {
      width: "220px !important",
      height: "220px !important",
      "& > svg > circle": {
        strokeWidth: 3,
      },
    },
    "& > .MuiCircularProgress-colorPrimary": {
      color: "#DEE7FA", // Light grey
    },
  },
  percentage: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 50,
    lineHeight: "60px",
    color: "#FFF",
    transform: "translateY(18px)",
  },
  percentageText: {
    marginTop: 12,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    color: "#DEE7FA",
    transform: "translateY(18px)",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
  secondaryText: {
    marginTop: 12,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 30,
    lineHeight: "17px",
    color: "#DEE7FA",
    transform: "translateY(18px)",
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
}));
