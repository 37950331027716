import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";

export function HTDRatio() {
  const { htdSummaryData } = useSelector((state) => state.analytic);
  const [htdRatioData, setHtdRatioData] = useState({});

  useEffect(() => {
    if (htdSummaryData) {
      const data = htdSummaryData.find((item) => item.label === "HTD Ratio");
      setHtdRatioData({ ...data, value: parseFloat(data?.value || 0) });
    }
  }, [htdSummaryData]);
  return (
    <Card headerSize="small" header="HTD Ratio" onClick={() => {}}>
      <PieGraph percentage={htdRatioData.value} outOfString="orders with \nHTD included" />
    </Card>
  );
}
