export const renderNavigateNotification = (data) => {
  if (data.notificationType.includes("rating-warning")) {
    return `/licensee/rating-star/${data.licenseeId}`
  }
  if (data.notificationType.includes("trailer-registration")) {
    return `/licensee/${data.licenseeId}/trailer/edit/${data.trailerId}`
  }
  if (data.notificationType.includes("trailer-service")) {
    return `/licensee/${data.licenseeId}/trailers-service/${data.trailerId}`
  }
  if ( data.notificationType.includes("trailer-damaged")) {
    return { 
      pathname: `/licensee/${data.licenseeId}/trailers-service/${data.trailerId}`,
      state: {
        isTrailerDamage: true
      }
    }
  }
  if (data.notificationType.includes("registration")) {
    return {
      pathname: `/licensee/${data.licenseeId}/insurance/business-registration`,
      state: {
        name: "Business Registrationxxx",
        keyData: "businessRegistration",
        keyExpiry: "businessRegistrationExpiry",
      }
    };
  }
  if (data.notificationType.includes("insurance-expiry")) {
    return {
      pathname: `/licensee/${data.licenseeId}/insurance/business-insurance`,
      state: {
        name: "Business insurance",
        keyData: "businessInsurance",
        keyExpiry: "businessInsuranceExpiry",
      }
    };
  }
};