import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
axiosInstance.interceptors.request.use(async (config) => {
  const token = secureLocalStorage.getItem("token");
  config.headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      toast.error("Network disconnected");
    }
    const statusCode = error?.response?.status;

    // if (statusCode === 404) {
    //   window.location.href = "/404";
    //   return;
    // }
    if (statusCode === 401) {
      secureLocalStorage.removeItem("token");
      secureLocalStorage.removeItem("user");
      window.location.href = "/signin";
      return;
    }
    if (statusCode === 403) {
      window.location.href = "/forbidden";
      return;
    }
    throw error;
  }
);

export default axiosInstance;
