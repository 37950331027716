import { Avatar, Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Rating } from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/styles";
import AlertMissingDocument from "../AlertMissingDocument";
import { useParams } from "react-router-dom";
import axios from "../../helpers/axios";

const RatingStarUser = () => {
  const [employee, setEmployee] = useState({
    fullname: "",
    url: "",
    ratingValue: 5,
  });
  const [ phone, setPhone ] = useState("")
  const cssRating = useStyleRating();
  const paramId = useParams();
  useEffect(() => {
    axios
      .get(`v1/admin/detaillicensee/${paramId.licenseeId}`)
      .then((response) => {
        const result = response.data.licenseeUserObj
        setEmployee({
          fullname: result.fullname,
          url: result.photo.data,
          ratingValue: result.licenseeId.ratingStar.ratingValue
        })
      });
  }, []);

  useEffect(() => {
    axios.get(`v1/admin/document-licensee/${paramId.licenseeId}`).then((response) => {
      setPhone(response.data.licenseeDocObj.mobileFormat)
    })
  }, [])

  return (
    <Box
      padding="60px"
      boxSizing="border-box"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
    >
      <Avatar
        alt="User"
        src={employee.url}
        style={{ width: "177px", height: "177px", marginBottom: "26px" }}
      />
      <Typography variant="h2" align="center" style={{ marginBottom: "13px" }}>
        {employee.fullname}
      </Typography>
      <Rating
        name="hover-feedback"
        value={employee.ratingValue}
        precision={0.5}
        readOnly
        icon={<StarIcon className={cssRating.icon} fontSize="inherit" />}
        emptyIcon={
          <StarIcon
            className={cssRating.icon}
            style={{ opacity: 0.55 }}
            fontSize="inherit"
          />
        }
        classes={{
          decimal: cssRating.decimal,
          icon: cssRating.icon,
          iconEmpty: `${cssRating.icon} ${cssRating.iconEmpty}`,
        }}
      />
      {
        employee.ratingValue < 4 && 
          <AlertMissingDocument 
            header="Star rating has fallen below 4 stars"
            title="Contact the Franchisee to discuss this issue"
            contact={phone}
          />
      }
    </Box>
  );
};

const useStyleRating = makeStyles({
  icon: {
    width: "100px",
    height: "100px",
  },
  iconEmpty: {
    color: "rgba(222, 231, 250, 0.4)",
  },
});

export default RatingStarUser;
