import { toast } from "react-toastify";

// const EXTERNAL_FILES = ["srt", "vtt", "wav", "mp3"];
const ACCEPTED_IMAGE_TYPE = ["jpg", "png", "jpeg"];

export const useUploadFiles = ({ types = [] }) => {
  const handleFileChange = (fileList) => {
    const isValid = Array.from(fileList).every((fileObj) => {
      let [generalType, specificType] = fileObj.type.split("/");
      // if (EXTERNAL_FILES.some((item) => types.includes(item))) {
      //   generalType = fileObj?.name?.split(".").slice(-1)[0];
      // }
      if (
        !generalType ||
        !types.some((item) => item === generalType) ||
        (generalType === "image" && !ACCEPTED_IMAGE_TYPE.some((item) => item === specificType))
      ) {
        let allowedTypes = types;
        if (generalType === "image") {
          allowedTypes = ACCEPTED_IMAGE_TYPE;
        }
        toast.error(`You can only upload ${allowedTypes.join(", ")} files.`);

        return false;
      }
      return true;
    });

    return isValid ? Array.from(fileList).map((file) => file) : null;
  };
  return { handleFileChange };
};
