import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";

export function ExtHiresReportGraph() {
  const { url } = useRouteMatch();
  const { extendedHiresData } = useSelector((state) => state.analytic);

  return (
    <Card headerSize="small" header="Extended Hires %" navigateTo={`${url}/ext-hires-report`}>
      <PieGraph percentage={extendedHiresData?.percentage} outOfString="Total Hires" />
    </Card>
  );
}
