import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import { Card } from "../../components";
import { formatPercentage } from '../../utils';
import { useStyles } from "../../Analytics.styles";

export function LateHiresPage() {
  const classes = useStyles();
  const { lateHiresData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (lateHiresData?.items) {
      // Override the title of Page Container
      document.querySelector('#analytic-title').textContent = 'Late Hires';
      const dataTransformed = [...lateHiresData?.items].sort((a, b) => b.grossIncome - a.grossIncome).map((item) => ({
        franchisee: `${item?.licenseeUser?.firstname} ${item?.licenseeUser?.lastname}`,
        perFranchisee: formatPercentage((item.lateHiresPercentage || 0)/100),
        numOfLateHires: item.lateHires,
        avgHoursLate: item.avgHoursLate.toFixed(2),
      }));
      setData(dataTransformed);
    }
  }, [lateHiresData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Per Franchisee",
      key: "perFranchisee",
      sortable: true,
    },
    {
      name: "Number of late hires",
      key: "numOfLateHires",
      sortable: true,
    },
    {
      name: "AVG hours Extended",
      key: "avgHoursLate",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Late Hires">
        <SortableTable
          rows={data}
          columns={columns}
          gridTemplateColumns="30% 25% 20% 25%"
        />

      </Card>
    </Box>
  );
}
