export const USER_ROLES = {
  ADMIN_OWNER: "ADMIN",
  MANAGER: "MANAGEMENT",
  VIEWER: "VIEWER",
  ADMIN_DEVELOPER: "ADMIN_DEVELOPER",
  DEVELOPER: "DEVELOPER",
};

export const common = {
  SOMETHING_WENT_WRONG: "Something went wrong",
  country: "Australia",
  states: [
    "New South Wales",
    "Victoria",
    "Tasmania",
    "Queensland",
    "South Australia",
    "Western Australia",
  ],
  businessType: ["Individual", "Company"],
  itemType: {
    upsell: { value: "upsell item", label: "Upsell Item" },
    consumable: { value: "consumable item", label: "Consumable Item" },
    premium: { value: "premium trailer add-on", label: "Premium Trailer Add-on" },
  },
  days: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
  // daysShort: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  daysShort: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
  hours: "1000-1900",
  hoursStart: "10:00",
  hoursEnd: "19:00",

  mcc: "7513",
  productDescription: "Trailer Rental",
  licenseeEmployeeType: ["employee", "owner", "representative", "director", "executive"],
  formatTime: {
    1: "[0-2]",
    2: "[0-9]",
    3: "[0-5]",
    4: "[0-9]",
  },
  formatDate: {
    1: "[0-1]",
    2: "[0-9]",
    3: "[0-3]",
  },
  formatNumber: {
    9: "[0-9]",
  },
  // apiUrl: "http://192.168.31.233:5000",
  // apiUrl: "https://t2ytest-private.herokuapp.com",
  apiUrl: "https://trailer2you-be.herokuapp.com",
  // apiUrl: "https://t2ybeta.herokuapp.com",
  LIST_TRAILER_COLOR: ["#39A8DF", "#2B6EF8", "#8269EB", "#E6CF05"],
  DATE_FORMAT: {
    DATE_1: "DD MMM YYYY" /* 11 May 2023 */,
    DATE_TIME_1: "DD MMM YYYY - h:mm A",
  },
  /* millisecond */
  STALE_TIME: {
    MIN_1: 1000 * 60,
    SEC_20: 1000 * 20,
    SEC_5: 1000 * 5,
  },
  DISCOUNT_PROMOTION_STATUS: {
    ALL: "all",
    ACTIVE: "active",
    INACTIVE: "inactive",
    SCHEDULED: "scheduled",
    EXPIRED: "expired",
  },
  NOTIFICATION_STATUS: {
    ALL: "all",
    SCHEDULED: "scheduled",
    SENT: "sent",
  },
  ORDER_STATUS: {
    CREATED: "created",
    PAID: "paid",
    DELIVERING: "delevering",
    DROPOFF: "dropoff",
    PICKINGUP: "pickingup",
    FINISHED: "finished",
    CANCELED: "canceled",
  },
  TRANSACTION_DETAILS_TITLE: {
    REVIEW: "Review",
    DRIVERS_LICENSE: "Driver's License",
    DELIVERED_BY: "Delivered by",
    PICKED_UP_BY: "Picked up by",
    SPLITS: "Splits",
  },
  USER_ROLES: [
    {
      value: USER_ROLES.ADMIN_OWNER,
      label: "T2Y Admin/Owner",
    },
    {
      value: USER_ROLES.MANAGER,
      label: "T2Y Management",
    },
    {
      value: USER_ROLES.VIEWER,
      label: "T2Y Viewer",
    },
    {
      value: USER_ROLES.ADMIN_DEVELOPER,
      label: "Admin Developer",
    },
    {
      value: USER_ROLES.DEVELOPER,
      label: "Developer",
    },
  ],
};
