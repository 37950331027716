import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import WrapHeaderSubPage from "../../../components/WrapHeaderSubPage";
import ColumnInTable from "../../../components/ColumnInTable";
import { useLoading } from "../../../hooks/useLoading";
import { useHistory } from "react-router-dom";
import CustomPagination from "../../../components/Pagination";
import SearchAndFilterDate from "../../../components/SearchAndFilterDate";
import moment from "moment";
import { Button } from "@material-ui/core";
import { formatCurrency } from "../../../helpers/format";
import { getTransaction } from "../../../helpers/api";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const defaultFilter = {
  from: "",
  to: "",
  search: "",
};

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });

  const [searchFilter, setSearchFilter] = useState({ ...defaultFilter });

  const handleNavigateDetail = (id) => {
    history.push(`/transaction-history/${id}`);
  };

  useEffect(() => {
    try {
      showLoading();
      getTransactionQuery();
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading();
    }
  }, [searchFilter, pagination.skip, pagination.count]);

  async function getTransactionQuery() {
    const transactionQuery = await getTransaction({
      ...searchFilter,
      fromDate: searchFilter.from,
      toDate: searchFilter.to,
      ...pagination,
    });

    setPagination({
      ...pagination,
      totalCount: transactionQuery.totalCount || 0,
    });
    setTransactions(transactionQuery.transactionsObj);
  }

  const columns = [
    {
      title: <span className="title-table">Amount</span>,
      field: "amount",
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: "100px",
              whiteSpace: "nowrap",
              lineHeight: "58px",
              paddingRight: "18px",
              fontWeight: "bold",
            }}
          >
            {formatCurrency(rowData.charges.total)}
          </div>
        );
      },
    },
    {
      title: "Stripe Fees",
      field: "amount",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {formatCurrency(rowData.charges?.transitionFee)}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Franchisee's received",
      field: "amount",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="180">
            {rowData?.splits
              ? formatCurrency(rowData.splits.licenseeReceive)
              : formatCurrency((rowData.charges?.total - rowData.charges?.transitionFee) * 0.85)}
          </ColumnInTable>
        );
      },
    },
    {
      title: "T2Y's received",
      field: "amount",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="180">
            {rowData?.splits
              ? formatCurrency(rowData.splits.t2yReceive)
              : formatCurrency(
                  rowData.charges?.total -
                    rowData.charges?.transitionFee -
                    (rowData.charges?.total - rowData.charges?.transitionFee) * 0.85
                )}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return <ColumnInTable maxWidth="100">{rowData.status}</ColumnInTable>;
      },
    },
    {
      title: "Rating",
      field: "starRating",
      render: (rowData) => {
        return (
          <ColumnInTable maxWidth="100">
            <span style={{ color: rowData.starRating > 3 ? "#44D62C" : "#FF0000" }}>
              {rowData.starRating}
            </span>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => {
        return (
          <ColumnInTable maxWidth="250">
            Payment for invoice(s) {rowData.stripePaymentIntentId}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Customer",
      field: "customer",
      render: (rowData) => {
        return <ColumnInTable minWidth="245">{rowData.customerInfor?.email ?? "--"}</ColumnInTable>;
      },
    },
    {
      title: "Franchisee",
      field: "franchisee",
      render: (rowData) => {
        return <ColumnInTable minWidth="245">{rowData.licenseeInfor?.email ?? "--"}</ColumnInTable>;
      },
    },
    {
      title: "Date",
      field: "date",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {moment(rowData.createdAt).format("DD MMM YY, hh:mm a")}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Trailer Type",
      field: "date",
      render: (rowData) => {
        return <ColumnInTable minWidth="140">{rowData?.trailerType?.name}</ColumnInTable>;
      },
    },
    {
      title: "Rego Number",
      field: "regoNumber",
      render: (rowData) => {
        return <ColumnInTable minWidth="140">{rowData?.trailerRegistration ?? "--"}</ColumnInTable>;
      },
    },
    {
      title: "View",
      field: "view",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            <Button
              color="primary"
              variant="contained"
              style={{ width: "100%" }}
              onClick={() => handleNavigateDetail(rowData._id)}
            >
              View
            </Button>
          </ColumnInTable>
        );
      },
    },
  ];

  return (
    <>
      <WrapHeaderSubPage
        nameHeader="Transaction History"
        style={{ marginBottom: "50px" }}
      ></WrapHeaderSubPage>
      <Breadcrumbs />
      <SearchAndFilterDate
        textAdd="Export report"
        textSearch="Franchisee or Customer"
        linkAdd="/transaction-history/report"
        defaultFilter={defaultFilter}
        pagination={pagination}
        setPagination={setPagination}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={transactions}
        options={tableOptions}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </>
  );
};

export default TransactionHistory;
