import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import LoadingOverlay from "../../components/Loading";
import { PageContainer } from "./components";
import { OverviewPage, ReportsPage, HireTheDriverPage } from "./containers";
import {
  AddonsAndConsumablesPage,
  AvgHireLengthPerTrailerPage,
  AvgIncomePerTrailerPage,
  ExtHiresReportPage,
  FranchiseePerfPage,
  GeographicalPerfPage,
  LateHiresPage,
  OutOfServiceTrailersPage,
  TrailerPerfPage,
  UnservBookingEnqPage,
  HTDUtilisationReportByTrailerTypePage,
  TotalHTDHoursPerFranchiseePage,
  HTDUtilisationReportByTrailerTypeAndFranchiseePage,
  UnavailableTrailerSearchesPage,
  DamageWaiverUtilisationReportPage,
  AvgFranchiseeIncomePerHirePage,
  TrailerUtilisationReportPage,
} from "./pages";
import { AvgPickUpDistancePage } from "./pages/AvgPickUpDistancePage";
import { AvgDropOffDistancePage } from "./pages/AvgDropOffDistancePage";

export function Analytics() {
  const { loading } = useSelector((state) => state.analytic);
  const { url } = useRouteMatch();

  return (
    <>
      <PageContainer title="Analytics">
        <Switch>
          {/* Overview or default page */}
          <Route path={`${url}/`} component={OverviewPage} exact />
          <Route path={`${url}/overview`} component={OverviewPage} exact />
          <Route path={`${url}/avg-inc-per-trailer`} component={AvgIncomePerTrailerPage} exact />
          <Route
            path={`${url}/avg-hire-length-per-trailer`}
            component={AvgHireLengthPerTrailerPage}
            exact
          />
          <Route path={`${url}/franchisee-performance`} component={FranchiseePerfPage} exact />
          <Route path={`${url}/geo-performance`} component={GeographicalPerfPage} exact />
          <Route path={`${url}/trailer-performance`} component={TrailerPerfPage} exact />
          <Route path={`${url}/unserv-booking-enquiries`} component={UnservBookingEnqPage} exact />
          <Route
            path={`${url}/unavailable-trailer-searches`}
            component={UnavailableTrailerSearchesPage}
            exact
          />

          {/* Hire The Driver */}
          <Route path={`${url}/hire-the-driver`} component={HireTheDriverPage} exact />
          <Route
            path={`${url}/hire-the-driver/htd-utilisation-report-by-trailer-type`}
            component={HTDUtilisationReportByTrailerTypePage}
            exact
          />
          <Route
            path={`${url}/hire-the-driver/total-htd-hours-per-franchisee`}
            component={TotalHTDHoursPerFranchiseePage}
            exact
          />
          <Route
            path={`${url}/hire-the-driver/htd-utilisation-report-by-trailer-type-and-franchisee`}
            component={HTDUtilisationReportByTrailerTypeAndFranchiseePage}
            exact
          />

          {/* Reports */}
          <Route path={`${url}/reports`} component={ReportsPage} exact />
          <Route path={`${url}/reports/ext-hires-report`} component={ExtHiresReportPage} exact />
          <Route path={`${url}/reports/late-hires`} component={LateHiresPage} exact />
          <Route
            path={`${url}/reports/addons-consumables`}
            component={AddonsAndConsumablesPage}
            exact
          />
          <Route
            path={`${url}/reports/out-of-service-trailers`}
            component={OutOfServiceTrailersPage}
            exact
          />
          <Route
            path={`${url}/reports/avg-pick-up-distance`}
            component={AvgPickUpDistancePage}
            exact
          />
          <Route
            path={`${url}/reports/avg-drop-off-distance`}
            component={AvgDropOffDistancePage}
            exact
          />
          <Route
            path={`${url}/reports/damage-waiver-utilisation-report`}
            component={DamageWaiverUtilisationReportPage}
            exact
          />
          <Route
            path={`${url}/reports/avg-franchisee-income-per-hire`}
            component={AvgFranchiseeIncomePerHirePage}
            exact
          />
          <Route
            path={`${url}/reports/trailer-utilisation-report`}
            component={TrailerUtilisationReportPage}
            exact
          />
        </Switch>
      </PageContainer>
      {loading && <LoadingOverlay />}
    </>
  );
}
