import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import { Box, Button, IconButton, styled } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";
import FormikControl, { CONTROL_TYPE } from "../Formik/FormikControl";
import { useAddPaymentMethod } from "../../hooks/UpdateOrder/useUpdateOrder";
import { Text18 } from "../Common/Styled/TypographyStyled";

const ContainerPaymentElement = styled(Box)({
  border: "1px solid #fff",
  padding: "1rem",
  borderRadius: "4px",
  width: "100%",
});

const Stack = styled(Box)({
  display: "flex",
});

const cardElementStyle = {
  base: {
    "::placeholder": {
      color: "#fff",
      fontFamily: "sans-serif",
    },
    color: "#fff",
    fontSize: "16px",
  },
};

const schema = yup.object({
  nameOnCard: yup.string().required("Required"),
});

const AddPaymentMethodModal = ({ onCloseModal }) => {
  const [cardNumber, setCardNumber] = useState(false);
  const [cardMonth, setCardMonth] = useState(false);
  const [cardCvv, setCardCvv] = useState(false);
  const elements = useElements();

  const { mutate, isLoading } = useAddPaymentMethod();
  const isValidCard = useMemo(
    () => cardNumber && cardMonth && cardCvv,
    [cardNumber, cardMonth, cardCvv]
  );

  const onHandleAddPaymentMethod = (value) => {
    const payload = value;
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (cardNumberElement) {
      mutate(
        { nameOnCard: payload.nameOnCard, cardNumberElement },
        { onSettled: () => onCloseModal() }
      );
    }
  };

  const formik = useFormik({
    initialValues: { nameOnCard: "" },
    validationSchema: schema,
    onSubmit: onHandleAddPaymentMethod,
    enableReinitialize: true,
  });

  const { handleSubmit, isValid } = formik;

  return (
    <FormikProvider value={formik}>
      <Stack sx={{ flexDirection: "column", position: "relative", gap: 16 }}>
        <IconButton
          style={{ position: "absolute", right: -16, top: -30 }}
          onClick={() => onCloseModal()}
        >
          <CloseIcon style={{ color: "white", fontSize: "30px" }} />
        </IconButton>
        <Text18>How would you like to pay?</Text18>
        <FormikControl
          name="nameOnCard"
          control={CONTROL_TYPE.INPUT}
          label="Card holder name"
          inputProps={{
            maxLength: 50,
          }}
          style={{ marginBottom: 0 }}
        />
        <ContainerPaymentElement>
          <CardNumberElement
            onChange={(event) => {
              setCardNumber(event.complete);
            }}
            options={{
              showIcon: true,
              placeholder: "Card number",
              style: cardElementStyle,
            }}
          />
        </ContainerPaymentElement>
        <Stack sx={{ flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
          <ContainerPaymentElement>
            <Stack sx={{ flexDirection: "row", alignItems: "flex-end", gap: 8 }}>
              <div style={{ flexGrow: 1 }}>
                <CardExpiryElement
                  options={{
                    placeholder: "Expiry Date",
                    style: cardElementStyle,
                  }}
                  onChange={(event) => {
                    setCardMonth(event.complete);
                  }}
                />
              </div>
            </Stack>
          </ContainerPaymentElement>
          <ContainerPaymentElement>
            <Stack sx={{ flexDirection: "row", alignItems: "flex-end", gap: 8 }}>
              <div style={{ flexGrow: 1 }}>
                <CardCvcElement
                  options={{
                    placeholder: "CVC",
                    style: cardElementStyle,
                  }}
                  onChange={(event) => {
                    setCardCvv(event.complete);
                  }}
                />
              </div>
            </Stack>
          </ContainerPaymentElement>
        </Stack>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          disabled={!isValid || !isValidCard || isLoading}
        >
          Save details
        </Button>
      </Stack>
    </FormikProvider>
  );
};

export default AddPaymentMethodModal;
