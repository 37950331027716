import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import ColumnInTable from "../../components/ColumnInTable";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { ReactComponent as Edit } from "../../img/edit.svg";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { useGetAllTrailers, useReorderTrailers } from "../../hooks/Trailers/useTrailers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as DragHandle } from "../../img/dragIndicator.svg";
import { getCRUD_AllowByRoles } from "../../helpers/common";
import { USER_ROLES } from "../../constants/common";

const useRowStyles = makeStyles({
  focusedRow: {
    /* Styles to make the row stay highlighted while the drag event is ongoing. */
    backgroundColor: "#2F2E2E",
    boxShadow: "inset 0px -1px 0px #535F78, inset 0px 1px 0px #535F78",
  },
  tableBody: {
    /* Styles to prevent the :hover CSS pseudo class while the drag event is ongoing. */
    "& > tr": {
      pointerEvents: "none",
    },
  },
  table: {
    /* Styles to override the default sticky header configuration. */
    "& > thead > tr > th": {
      top: 54,
      left: "unset",
    },
  },
  tableContainer: {
    marginBottom: 60,
    overflowX: "unset",
  },
  tableRow: {
    backgroundColor: "#171717",
    transition: "background-color 250ms ease-in-out",

    "&:hover": {
      backgroundColor: "#2F2E2E",
      boxShadow: "inset 0px -1px 0px #535F78, inset 0px 1px 0px #535F78",
    },
  },
  trailerTypeHeader: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "21px",
  },
  trailerTypeRow: {
    width: "40%",

    "& > div": {
      marginRight: 8,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19px",
      display: "flex",
      alignItems: "center",
    },
  },
  itemTypeRow: {
    width: "10%",

    "& > div": {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  actionButtonsRow: {
    width: "10%",
  },
  editIconContainer: {
    padding: 8,
    borderRadius: "50%",
    backgroundColor: "#171717",
    cursor: "pointer",
  },
  editIcon: {
    cursor: "pointer",
  },
  dragHandle: {
    marginLeft: 44,
    cursor: "pointer",
  },
});

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function Row({ rowData, index, trailerList, isAllowToUpdate }) {
  const classes = useRowStyles();
  const history = useHistory();

  return (
    <Draggable draggableId={rowData._id} index={index}>
      {(provided, snapshot) => {
        return (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${classes.tableRow} ${snapshot.isDragging ? classes.focusedRow : ""}`}
          >
            {/* Trailer type */}
            <TableCell className={classes.trailerTypeRow}>
              <div className="list-item-name">{rowData.name}</div>
            </TableCell>

            {/* Size */}
            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="132">{rowData.size}</ColumnInTable>
            </TableCell>

            {/* Axle */}
            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="132">{rowData.axel}</ColumnInTable>
            </TableCell>

            {/* Tare */}
            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="132">{rowData.tare + "kg"}</ColumnInTable>
            </TableCell>

            {/* Max Load */}
            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="132">{rowData.maxLoad + "kg"}</ColumnInTable>
            </TableCell>

            {/* GVM */}
            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="132">{rowData.maxLoad + "kg"}</ColumnInTable>
            </TableCell>

            <TableCell className={classes.itemTypeRow}>
              <ColumnInTable minWidth="152">${rowData.hirecost?.toFixed(2)} per day</ColumnInTable>
            </TableCell>

            {/* Action buttons */}
            <TableCell className={classes.actionButtonsRow}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  className={classes.editIconContainer}
                  onClick={() =>
                    history.push({
                      pathname: `/rental/trailer/edit/${rowData._id}`,
                      state: { allTrailer: trailerList },
                    })
                  }
                >
                  <Edit className={classes.editIcon} />
                </Box>

                {isAllowToUpdate ? (
                  <Box
                    component="span"
                    display="flex"
                    alignItems="center"
                    {...provided.dragHandleProps}
                  >
                    <DragHandle className={classes.dragHandle} />
                  </Box>
                ) : null}
              </Box>
            </TableCell>
          </TableRow>
        );
      }}
    </Draggable>
  );
}

const TrailerList = () => {
  const { isAllowToCreate, isAllowToUpdate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const history = useHistory();
  const classes = useRowStyles();
  const { data: trailerData } = useGetAllTrailers();
  const { mutate } = useReorderTrailers();
  const [trailerList, setTrailerList] = useState([]);
  useEffect(() => {
    if (trailerData && trailerData?.length > 0) {
      setTrailerList(trailerData);
    }
  }, [trailerData]);
  const onDragEnd = (result) => {
    /* Do nothing when dropped outside the list or outside the `Droppable` area. */
    if (!result.destination) {
      return;
    }

    /* Do nothing when the item's original position and destination position are the same. */
    if (result.destination.index === result.source.index) {
      return;
    }

    const trailerItem = trailerList[result.source.index];
    const newIndex = result.destination.index;
    mutate({
      trailerId: trailerItem?._id,
      index: newIndex,
    });
    const newTrailerList = reorder(trailerList, result.source.index, result.destination.index);
    setTrailerList(newTrailerList);
  };

  return (
    <>
      <WrapHeaderSubPage nameHeader="All Trailers">
        {isAllowToCreate ? (
          <ButtonActionHeader onClick={() => history.push("/rental/trailer/add")}>
            Add Trailer
          </ButtonActionHeader>
        ) : null}
      </WrapHeaderSubPage>
      <Breadcrumbs />

      <DragDropContext onDragEnd={onDragEnd}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader aria-label="trailer-list-table" className={classes.table}>
            {/* Header */}
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box className={classes.trailerTypeHeader}>Trailer type</Box>
                </TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Axle</TableCell>
                <TableCell>Tare</TableCell>
                <TableCell>Max Load</TableCell>
                <TableCell>GVM</TableCell>
                <TableCell>Hire Cost</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            {/* Body */}
            <Droppable droppableId="trailer-list-table">
              {(provided, snapshot) => {
                return (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? `${classes.tableBody}` : ""}
                  >
                    {trailerList?.map((rowData, index) => (
                      <Row
                        trailerList={trailerList}
                        key={rowData._id}
                        rowData={rowData}
                        index={index}
                        isAllowToUpdate={isAllowToUpdate}
                      />
                    ))}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </Table>
        </TableContainer>
      </DragDropContext>
    </>
  );
};

export default TrailerList;
