import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  topLevelContainer: {
    marginBottom: 50,

    /* Styles to make the sticky header styles work on the adjacent table component */
    "& + div > div": {
      overflowX: "unset !important",
    },
    "& + div > div > div > div": {
      overflowY: "unset !important",
    },
  },
  focused: {},
  notchedOutline: {},
  input: {
    padding: "13px",
    paddingLeft: "43px",
    fontSize: "15px",
    color: "#000000",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
  icon: {
    height: 32,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    marginLeft: "16px",
  },
  search_icon: {
    fontSize: "32px",
    color: "#535f78",
    paddingRight: "8px",
  },
  form_search: {
    height: "43px",
    width: "342px",
    marginRight: "16px",
    borderRadius: "4px",
  },
  addBtn: {
    width: "173px",
    textTransform: "none",
  },
}));
