import React from 'react'
import { Box, MenuItem } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { FieldFormAdd } from '../FieldFormAdd'
export const CustomTextField = (props) => {
  const container = useRef()
  const [minWidth, setMinWidth] = useState()
  useEffect(() => {
    if (container.current.id !== null) {
      const containerWidth = document.getElementById('containerSelectField')
      setMinWidth(containerWidth.clientWidth)
    }
  }, [])
  return (
    <Box id="containerSelectField" ref={container}>
      <FieldFormAdd
        fullWidth
        variant="outlined"
        select
        // form
        className={props.classTextField}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        // property
        label={props.label}
        id={props.id}
        // style
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                minWidth: `calc(${minWidth}px - 32px)`
              }
            }
          },
        }}
      >
        {
          props.options.map((option) => {
            return (
              <MenuItem
                key={option}
                value={option.toLowerCase()}
                className={props.classMenuItem}
              >
                {option}
              </MenuItem>
            )
          }
          )
        }
      </FieldFormAdd>
    </Box>
  )
}