import * as React from "react";

export function UpArrowIcon({ width = 10, height = 5 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 5"
      fill="none"
    >
      <path d="M0 5L5 0L10 5H0Z" fill="white" />
    </svg>
  );
}
