import { makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import ButtonView from "../../components/ButtonView";
import ColumnInTable from "../../components/ColumnInTable";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { USER_ROLES, common } from "../../constants/common";
import axios from "../../helpers/axios";
import { titleCase } from "../../helpers/format";
import { useLoading } from "../../hooks/useLoading";
import { getCRUD_AllowByRoles } from "../../helpers/common";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const useStyles = makeStyles((theme) => ({
  purchasedText: {
    color: theme.palette.primary.main,
    padding: "8px 9px",
    marginRight: "16px",
  },
}));

const UpSellListInLicensee = () => {
  const { isAllowToCreate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  const { licenseeId, firstname } = useParams();
  const history = useHistory();
  const [upsellList, setUpsellList] = useState([]);
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    getAll();
  }, []);

  function getAll() {
    showLoading();
    axios
      .get("/v1/admin/licensee/trailers-upsells-grouped/" + licenseeId)
      .then((response) => {
        hideLoading();
        const list = response.data.items;
        return setUpsellList(list);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const columns = [
    {
      title: <span className="title-table">Upsell Item</span>,
      field: "trailerType",
      render: (rowData) => {
        return <div style={{ minWidth: "450px", marginRight: "25px" }}>{rowData.name}</div>;
      },
    },
    {
      title: "Item Type",
      field: "itemType",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="194">
            {titleCase(
              rowData.consumeable
                ? common.itemType.consumable.label
                : rowData.premium
                ? common.itemType.premium.label
                : common.itemType.upsell.label
            )}
          </ColumnInTable>
        );
      },
    },

    {
      title: "Price",
      field: "price",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="164">
            {rowData.consumeable
              ? "$" + Math.ceil(rowData.price)
              : "$" + rowData.price?.toFixed(2) + " per day"}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => {
        return (
          <div
            style={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {rowData.description}
          </div>
        );
      },
    },
    {
      title: "Qty In stock",
      field: "quantity",
      render: (rowData) => {
        return <div style={{ minWidth: "100px", marginRight: "25px" }}>x{rowData.quantity}</div>;
      },
    },
    {
      title: "",
      field: "",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            <ButtonView router={`/licensee/${firstname}/${licenseeId}/upsellitems/${rowData._id}`}>
              View
            </ButtonView>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <WrapHeaderSubPage nameHeader={`${firstname}'s Upsell Items`}>
        {isAllowToCreate ? (
          <ButtonActionHeader
            onClick={() => history.push(`/licensee/${firstname}/${licenseeId}/upsellitems/add`)}
          >
            Add Item
          </ButtonActionHeader>
        ) : null}
      </WrapHeaderSubPage>
      <Breadcrumbs />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={upsellList}
        options={tableOptions}
      />
    </div>
  );
};

export default UpSellListInLicensee;
