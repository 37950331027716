import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import { Card } from "../../components";
import { formatAmount } from "../../utils";
import { useStyles } from "../../Analytics.styles";

export function GeographicalPerfPage() {
  const classes = useStyles();
  const { incomePerCityData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Geographical Performance';
    if (incomePerCityData?.items) {
      const dataTransformed = [...incomePerCityData.items].sort((a, b) => b.grossIncome - a.grossIncome).map((item) => ({
        city: item._id,
        totalSales: formatAmount(item.grossIncome, 0),
        totalHires: item.totalHires,
      }));
      setData(dataTransformed);
    }
  }, [incomePerCityData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
  ];
  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Geographical Performance">
    <SortableTable
      rows={data}
      columns={columns}
      hasIndexColumn
      gridTemplateColumns="10% 40% 30% 20%"
    />
      </Card>
    </Box>
  );
}
