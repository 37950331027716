import React from "react";
import useBoolean from "../../hooks/useBoolean";
import Box from "@material-ui/core/Box";
import { Badge, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  customBadge: {
    width: "21px",
    height: "21px",
    top: "11px",
    right: "-10px",
    color: "white",
    cursor: 'pointer',
    fontSize: '12px',
  },
  rowContent: {
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
});

const ExpandableCell = ({ array }) => {
  const classes = useStyles();
  const [expand, setExpand] = useBoolean(false);
  if (!array.length) {
    return <Box>No data</Box>;
  }
  let component = null;

  switch (expand) {
    case false:
      if (array.length > 1) {
        component = (
          <Badge
            classes={{
              badge: classes.customBadge,
            }}
            badgeContent={`+${array.length - 1}`}
            color="primary"
            onClick={() => setExpand()}
          >
            <Box className={classes.rowContent}>{array[0]}</Box>
          </Badge>
        );
      } else {
        component = <Box className={classes.rowContent}>{array[0]}</Box>;
      }
      break;

    case true:
      component = (
        <Badge
          classes={{
            badge: classes.customBadge,
          }}
          badgeContent={`-`}
          color="error"
          onClick={setExpand}
        >
          <Box display={"flex"} flexDirection={"column"}>
            {array.map((item) => (
              <Box key={item} className={classes.rowContent}>{item}</Box>
            ))}
          </Box>
        </Badge>
      );
      break;

    default:
      break;
  }

  return component;
};

export default ExpandableCell;
