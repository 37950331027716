import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DiscountsAndPromotions from "./components/DiscountsAndPromotions";
import CreateNewOrEditDiscount from "./components/CreateNewOrEditDiscount";
import PromotionalBanners from "./components/PromotionalBanners";

function DiscountsAndPromotionsComponent() {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={match.url} component={DiscountsAndPromotions} exact />
        <Route path={`${match.url}/create-new`} component={CreateNewOrEditDiscount} exact />
        <Route path={`${match.url}/edit/:id`} component={CreateNewOrEditDiscount} exact />
        <Route path={`${match.url}/promotional-banners`} component={PromotionalBanners} exact />
      </Switch>
    </div>
  );
}

export default DiscountsAndPromotionsComponent;
