import * as React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Box, Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "../DatePicker";
import { AnalyticsFilter } from "../AnalyticsFilter";
import { useStyles } from "./PageContainer.styles";
import { useSelector } from "react-redux";
import {
  getIncomePerCityData,
  getIncomePerLicenseeData,
  getIncomePerTrailerData,
  getIncomePerUpSellItemsData,
  getLateHiresData,
  getOutOfServiceTrailersData,
  getSummaryData,
  getUnserviceBookingPerformanceData,
  setAnalyticDate,
  getExtendedHiresData,
  getAverageHireLength,
  getTrailerPerformanceData,
  getHTDSummaryData,
  getTotalHTDHoursPerFranchisee,
  getHTDUtilisationReportByTrailerType,
  getHTDUtilisationReportByTrailerTypeAndFranchisee,
  getAvgDropOffDistanceByFranchisee,
  getAvgPickUpDistanceByFranchisee,
  getAvgDistanceGraphData,
  getUnavailableTrailerSearches,
  getDamageWaiverUtilisationReport,
  getOrdersByDayOfTheWeek,
  setSelectedFilterValues,
  getTrailerUtilisationReport,
} from "../../../../redux/slices/analyticSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { Text14Weight400 } from "../../../../components/Common/Styled/TypographyStyled";
import { Stack } from "../../../../components/Common/Styled/CommonStyled";

const { useState } = React;

export function PageContainer({ title, children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedFilterValues } = useSelector((state) => state.analytic);
  const { fromDate, toDate } = useSelector((state) => state.analytic.analyticDate);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    getDataForAnalytics();
  }, [fromDate, toDate, selectedFilterValues]);

  const getDataForAnalytics = () => {
    dispatch(getSummaryData());
    dispatch(getHTDSummaryData());
    dispatch(getHTDUtilisationReportByTrailerType());
    dispatch(getTotalHTDHoursPerFranchisee());
    dispatch(getHTDUtilisationReportByTrailerTypeAndFranchisee());
    dispatch(getIncomePerTrailerData());
    dispatch(getTrailerPerformanceData());
    dispatch(getIncomePerLicenseeData());
    dispatch(getUnserviceBookingPerformanceData());
    dispatch(getIncomePerCityData());
    dispatch(getIncomePerUpSellItemsData());
    dispatch(getOutOfServiceTrailersData());
    dispatch(getLateHiresData());
    dispatch(getExtendedHiresData());
    dispatch(getAverageHireLength());
    dispatch(getAvgDistanceGraphData());
    dispatch(getAvgDropOffDistanceByFranchisee());
    dispatch(getAvgPickUpDistanceByFranchisee());
    dispatch(getUnavailableTrailerSearches());
    dispatch(getDamageWaiverUtilisationReport());
    dispatch(getOrdersByDayOfTheWeek());
    dispatch(getTrailerUtilisationReport());
  };

  function setFromDate(date) {
    if (
      moment(date).isValid() &&
      moment(toDate).isValid() &&
      moment(date).isAfter(moment(toDate))
    ) {
      toast.error("From Date must be before To Date");
    } else {
      dispatch(
        setAnalyticDate({
          fromDate: moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : date,
        })
      );
    }
  }

  function setToDate(date) {
    if (
      moment(fromDate).isValid() &&
      moment(date).isValid() &&
      moment(date).isBefore(moment(fromDate))
    ) {
      toast.error("To Date must be after from Date");
    } else {
      dispatch(
        setAnalyticDate({
          toDate: moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : date,
        })
      );
    }
  }

  function displayFilterModal() {
    setOpen(true);
  }

  function hideFilterModal() {
    setOpen(false);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box component="header" className={classes.analyticsContainer}>
        <Box component="div" className={classes.analyticsHeader}>
          <Typography
            id="analytic-title"
            variant="h1"
            component="h1"
            className={classes.headerText}
          >
            {title}
          </Typography>

          <Box component="div" variant="outlined" className={classes.analyticsFunctionGroup}>
            {selectedFilterValues?.length > 0 ? (
              <Stack sx={{ alignItems: "center", gap: 8 }}>
                <Text14Weight400 color="primary">
                  {selectedFilterValues?.length} selected
                </Text14Weight400>
                <Button variant="text" onClick={() => dispatch(setSelectedFilterValues([]))}>
                  Clear all
                </Button>
              </Stack>
            ) : null}
            <AnalyticsFilter
              label="Filter"
              open={open}
              displayFilterModal={displayFilterModal}
              hideFilterModal={hideFilterModal}
            />

            <DatePicker
              label="Select a date from"
              value={fromDate}
              onChange={setFromDate}
              ariaLabel="Select a date from"
            />

            <DatePicker
              label="Select a date to"
              value={toDate}
              onChange={setToDate}
              ariaLabel="Select a date to"
            />
          </Box>
        </Box>
      </Box>

      {children}
    </MuiPickersUtilsProvider>
  );
}
