import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  analyticsContent: {
    display: "flex",
    flexDirection: "column",
    "& > .MuiBox-root:last-of-type": {
      marginBottom: 76,
    },
  },
  overviewTab: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    color: "#DEE7FA",
  },
  reportsTab: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    color: "#44D62C",
  },
  twoColumnLayout: {
    marginTop: 64,
    display: "grid",
    gridTemplateColumns: "minmax(400px, auto) 1fr",
    gap: 60,
  },
  threeEqualColumnLayout: {
    marginTop: 64,
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 60,
    "& > .MuiBox-root": {
      gridColumn: "1/3",
    },
  },
  section: {
    marginTop: 36,
  },
}));
