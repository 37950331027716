import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../utils";
import { useStyles } from "./OverviewMain.styles";

export function OverviewMain() {
  const classes = useStyles();
  const { summaryData } = useSelector((state) => state.analytic);
  // const selectedFilterText = selectedFilterValues.map((item) => item.value).join(", ");
  const [overviewMainData, setOverviewMainData] = useState([]);

  useEffect(() => {
    if (summaryData) {
      const data = summaryData.filter(
        (item) => typeof item.value === "number" && !item.label.includes("%")
      );
      setOverviewMainData(data);
    }
  }, [summaryData]);

  return (
    <Grid container spacing={7}>
      {overviewMainData.map(({ label, value }) => {
        const isHires = label.toLowerCase().includes("hires");
        return (
          <Grid item key={label} lg={3} md={6} sm={12} xs={12}>
            <Box component="div" className={classes.overviewItem}>
              <Typography component="h3">{label}</Typography>
              {/* <Typography component="small">{selectedFilterText || "All"}</Typography> */}
              <Typography component="h1">{isHires ? value : formatAmount(value)}</Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
