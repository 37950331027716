import React from "react";
import InputMask from "react-input-mask";

const TextFieldMask = (props) => {
  const { mask, formatChars, children, ...other } = props;
  return (
    <InputMask {...other} maskChar={null} formatChars={formatChars} mask={mask}>
      {children}
    </InputMask>
  );
};

export default TextFieldMask;
