import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 100,
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 12,
    padding: 16,
  },
  commonBox: {
    borderRadius: 8,
    border: "1px solid rgba(226, 233, 251, 0.298)",
    background: "rgba(251, 252, 254, 0.00)",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
  },
  contentOfBox: {
    padding: "1rem",
    "& p": {
      lineHeight: "1.25rem",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      wordBreak: "break-all",
    },
  },
  text16Weight700: {
    fontSize: 16,
    fontWeight: 700,
    color: "rgba(255, 255, 255, 0.80)",
  },
  text16Weight500Gray: {
    fontSize: 16,
    fontWeight: 500,
    color: "rgba(222, 231, 250, 0.80)",
  },
  toggleButton: {
    backgroundColor: "#202020",
    padding: "10px 18px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  activeButton: {
    backgroundColor: "#379D26",
    "&:hover": {
      backgroundColor: "#379D26",
    },
  },
});
