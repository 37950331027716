import "../../App.scss";

import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import { ReactComponent as Logo } from "../../img/logo.svg";
import ButtonMenuNavlink from "../ButtonMenuNavlink";
import ButtonNavLink from "../ButtonNavlink";
import ButtonNotifications from "../ButtonNotifications";
import NAVLINK_LIST from "./constant";
import { useStyles } from "./style";

function AppHeader({ showMenu }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.colorPrimary}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box display="flex" alignItems="center" width="100%">
              <Logo />
              {showMenu && (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginLeft="10px"
                >
                  <Box display="flex" alignItems="center">
                    {NAVLINK_LIST.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          {!item.options ? (
                            <ButtonNavLink numberKey={i} link={item.link} name={item.name} />
                          ) : (
                            <ButtonMenuNavlink name={item.name} options={item.options} />
                          )}
                        </Fragment>
                      );
                    })}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box marginRight="16px">
                      <Typography color="textPrimary">v1.6.0</Typography>
                    </Box>
                    <ButtonNotifications />
                    <Box borderLeft="1px solid #FFFFFF" height="36px" marginRight="16px" />
                    <Button
                      className={classes.navLogoutButton}
                      onClick={() => {
                        localStorage.removeItem("expiry");
                        secureLocalStorage.removeItem("user");
                        secureLocalStorage.removeItem("token");
                        localStorage.removeItem("aclSettingsAdmin");
                        history.push("/signin");
                      }}
                    >
                      Log out
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppHeader;
