import React, { useEffect, useState } from "react";
import { Card } from "../../../components";
import { formatHour } from "../../../utils";
import { useSelector } from "react-redux";
import SortableTable from "../../../../../components/SortableTable/SortableTable";

export function AvgHireLengthPerTrailerType() {
  const { averageHireLength } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (averageHireLength?.items) {
      const dataTransformed = averageHireLength.items.map((item) => ({
        name: item?.trailer?.name ?? "--",
        totalHireLength: formatHour(item.totalHireDuration),
        averageHireLength: formatHour(item.averageHireDuration),
      }));
      setData(dataTransformed);
    }
  }, [averageHireLength]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Hire Length",
      key: "totalHireLength",
      sortable: true,
    },
    {
      name: "Average Hire Length",
      key: "averageHireLength",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Average Hire Length Per Trailer Type"
      navigateTo={`/analytics/avg-hire-length-per-trailer`}
      style={{ maxHeight: 300 }}
    >
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="40% 20% 20% 20%"
        rowsHeight={255}
      />
    </Card>
  );
}
