import { Box, Drawer, styled } from "@material-ui/core";
import { COLOR_LIST } from "../../../themes";

export const CommonBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: COLOR_LIST.BACKGROUND_BLACK,
  borderRadius: 8,
  border: `1px solid ${COLOR_LIST.BORDER_BOX}`,
});

export const customBoxStyles = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  border: `1px solid ${COLOR_LIST.COMET}`,
  borderLeft: "none",
  borderRight: "none",
  paddingBlock: "1rem",
  marginInline: 0,
};

export const FullScreenDrawer = styled(Drawer)(() => ({
  "& > .MuiPaper-root": {
    backgroundColor: COLOR_LIST.DARK_JUNGLE_GREEN,
    minHeight: "100vh",
  },
}));

export const Stack = styled(Box)({
  display: "flex",
});
