import React, { useState, useRef, useEffect } from "react";
import TemplateImageModifyItemLicensee from "../../components/TemplateImageModifyItemLicensee";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import DynamicTextField from "../../components/DynamicTextField";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { Box, makeStyles } from "@material-ui/core";
import TextFieldMask from "../../components/TextFieldMask";
import { useFormik } from "formik";
import * as yup from "yup";
import { generatePropTextField } from "../../helpers/formik-help";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import { calculateHireCostTrailer } from "../../helpers/api";
import { useLoading } from "../../hooks/useLoading";
import { toast } from "react-toastify";
import axios from "../../helpers/axios";
import { useHistory, useParams } from "react-router-dom";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import DialogTrailerUpsellItem from "../../components/DialogTrailerUpsellItem";
import InputCheckBox from "../../components/InputCheckBox";
import DialogServiceChecklist from "../../components/DialogServiceChecklist";
import { getCRUD_AllowByRoles } from "../../helpers/common";
import { USER_ROLES } from "../../constants/common";

const defaultDataRef = {
  name: "",
  price: "",
  isCarTrailer: false,
  isPremium: false,
  tare: "",
  axel: "",
  size: "",
  maxLoad: "",
  gvm: "",
  description: "",
};
const TrailerEdit = () => {
  const { isAllowToUpdate } = getCRUD_AllowByRoles({
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const [trailerData, setTrailerData] = useState(defaultDataRef);
  const [pricePerDay, setPricePerDay] = useState();
  const prevUpsellItemSelectedList = useRef([]);
  const [upsellList, setUpsellList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isOpenServiceChecklist, setOpenServiceChecklist] = useState(false);
  const [photos, setPhotos] = useState([]);
  const { id } = useParams();
  let history = useHistory();
  // IMAGE
  const dataImageRef = useRef([]);
  const dataImageDeleteRef = useRef([]);

  const typingTimeout = React.useRef(null);
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  const formik = useFormik({
    initialValues: trailerData,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required(" "),
      price: yup.string().required(" "),
      tare: yup.number().required(" "),
      axel: yup.string().required(" "),
      size: yup.string().required(" "),
      maxLoad: yup.number().required(" "),
      gvm: yup.number().required(" "),
      description: yup.string().required(" "),
    }),
    onSubmit: (value) => {
      handleAddOrUpdateTrailer(value);
    },
  });

  const handleAddOrUpdateTrailer = async (trailerData) => {
    showLoading();
    try {
      const formData = new FormData();
      formData.append("name", trailerData.name);
      formData.append("size", trailerData.size);
      formData.append(
        "price",
        trailerData.price.toString().charAt(0) === "$"
          ? trailerData.price.slice(1)
          : trailerData.price
      );
      formData.append("isCarTrailer", trailerData.isCarTrailer);
      formData.append("isPremium", trailerData.isPremium);
      formData.append("axel", trailerData.axel);
      formData.append("tare", trailerData.tare);
      formData.append("maxLoad", trailerData.maxLoad);
      formData.append("gvm", trailerData.gvm);
      formData.append("description", trailerData.description);
      let result = [];
      upsellList.map((item) => {
        if (item.isActive === true) {
          result.push(item._id);
        }
      });
      for (let item of result) {
        formData.append("upsellItems[]", item);
      }
      // item to add
      if (dataImageRef.current.length !== 0) {
        let array = [];
        dataImageRef.current.map((item) => array.push(item.file));
        for (let item of array) {
          formData.append("photos", item);
        }
      }
      // if ( dataImageRef.current.length === 0 ) {
      //   formData.append("photos", []);
      // }
      // item to delete
      if (dataImageDeleteRef.current.length !== 0) {
        for (let item of dataImageDeleteRef.current) {
          formData.append("deletedPhotosId[]", item);
        }
      }
      const res = await axios.put("v1/admin/trailer/" + id, formData);
      toast.success(res?.data?.message);
      hideLoading();
      history.push("/rental/trailer");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  const handleSelectUpsellItem = (indexOfItem) => {
    setUpsellList((prev) => [
      ...prev.slice(0, indexOfItem),
      {
        ...prev[indexOfItem],
        isActive: !prev[indexOfItem].isActive,
      },
      ...prev.slice(indexOfItem + 1),
    ]);
  };

  const handleCloseDialog = () => {
    setUpsellList(prevUpsellItemSelectedList.current);
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleOpenServiceChecklistDialog = () => {
    setOpenServiceChecklist(true);
  };

  const handleCloseServiceChecklistDialog = () => {
    setOpenServiceChecklist(false);
  };

  const handleAddUpsellItemToTrailer = () => {
    prevUpsellItemSelectedList.current = upsellList;
    setOpen(false);
  };

  function getListUpsellItemSelected(idList) {
    let results = [];
    axios
      .get("/v1/admin/get-all-upsellitems")
      .then((response) => {
        results = response.data.dataObj.map((item) => {
          const isItemSelected = idList.some((id) => id === item._id);
          if (isItemSelected === true) {
            return { ...item, isActive: true };
          }
          return { ...item, isActive: false };
        });
        prevUpsellItemSelectedList.current = results;
        setUpsellList(results);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  useEffect(() => {
    axios.get("v1/admin/detailtrailertype/" + id).then((response) => {
      setPhotos(
        response.data.trailerTypeObj.photos?.map((item) => ({
          _id: item._id,
          url: item.url,
        }))
      );
      const idUpsellItemsSelected = response.data.trailerTypeObj.upsellItems;
      getListUpsellItemSelected(idUpsellItemsSelected);
      return setTrailerData({
        ...trailerData,
        ...response.data.trailerTypeObj,
      });
    });
  }, []);
  useEffect(() => {
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      calculateHireCostTrailer(formik.values.price.toString().replace("$", ""), setPricePerDay);
    }, 1000);
  }, [formik.values.price]);
  return (
    <Box height="100%" paddingBottom="85px">
      <WrapHeaderSubPage nameHeader={trailerData.name}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Box display="flex">
        <TemplateImageModifyItemLicensee
          dataImageRef={dataImageRef}
          dataImageDeleteRef={dataImageDeleteRef}
          photos={photos}
          upload={isAllowToUpdate}
        />
        <WrapFormItemLicensee formik={formik}>
          <DynamicTextField {...generatePropTextField("name", formik)} label="Trailer Name" />
          <TextFieldMask
            {...generatePropTextField("price", formik)}
            label="Trailer Value"
            mask="$99999999999"
            autoComplete="off"
            formatChars={{ 9: "[0-9]" }}
          >
            {(inputProps) => <DynamicTextField fullWidth variant="outlined" {...inputProps} />}
          </TextFieldMask>
          <InputCheckBox
            label="This is a vehicle trailer"
            checked={formik.values.isCarTrailer}
            onChange={() => formik.setFieldValue("isCarTrailer", !formik.values.isCarTrailer)}
          />
          <InputCheckBox
            label="This is a premium trailer"
            checked={formik.values.isPremium}
            onChange={() => formik.setFieldValue("isPremium", !formik.values.isPremium)}
          />
          <div className="price-perDay">{!!pricePerDay && `$${pricePerDay} per day`}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DynamicTextField
              {...generatePropTextField("tare", formik)}
              label="Tare (kg)"
              className={classes.input50}
            />
            <DynamicTextField
              {...generatePropTextField("axel", formik)}
              label="Axle"
              className={classes.input50}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DynamicTextField
              {...generatePropTextField("size", formik)}
              label="Size (LxW)"
              className={classes.input50}
            />
            <DynamicTextField
              {...generatePropTextField("maxLoad", formik)}
              label="Max Load (kg)"
              className={classes.input50}
            />
          </div>
          <DynamicTextField {...generatePropTextField("gvm", formik)} label="GVM (kg)" />
          <DynamicTextField
            {...generatePropTextField("description", formik)}
            label="Description"
            multiline
            rows={5}
          />
          {isAllowToUpdate ? (
            <WrapButtonSubmitTrailer>
              <ButtonActionHeader type="submit" style={{ marginRight: "12px" }} width="233px">
                Save Update
              </ButtonActionHeader>
              <ButtonActionHeader
                width="233px"
                variant="outlined"
                style={{ marginRight: "12px" }}
                onClick={() => handleOpenDialog()}
              >
                Add relevant upsell items
              </ButtonActionHeader>
              <ButtonActionHeader
                width="233px"
                variant="outlined"
                onClick={() => handleOpenServiceChecklistDialog()}
              >
                Edit Service Checklist
              </ButtonActionHeader>
            </WrapButtonSubmitTrailer>
          ) : null}
        </WrapFormItemLicensee>
      </Box>
      <DialogTrailerUpsellItem
        checkList={upsellList}
        handleCloseDialog={handleCloseDialog}
        onSelectUpsellItem={handleSelectUpsellItem}
        onSubmitSelectUpsellItem={handleAddUpsellItemToTrailer}
        open={isOpen}
        header={`Relevant upsell items to ${trailerData.name.toLocaleLowerCase()}`}
        title="Please select relevant upsell items to this Trailer"
      />
      {isOpenServiceChecklist && (
        <DialogServiceChecklist
          open={isOpenServiceChecklist}
          handleCloseDialog={handleCloseServiceChecklistDialog}
          header={`Edit & re-order the service checklist for ${trailerData.name}`}
          trailerId={trailerData._id ?? ""}
        />
      )}
    </Box>
  );
};
export default TrailerEdit;
const useStyles = makeStyles(() => ({
  input50: {
    width: "calc( (50vw - 200px ) / 2 - 9px)",
  },
}));
