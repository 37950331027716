import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { toast } from "react-toastify";
import ImageIcon from "@material-ui/icons/Image";
import {
  Button,
  Avatar,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import "../../App.scss";
import axios from "../../helpers/axios";
import CustomPagination from "../../components/Pagination";
import { useLoading } from "../../hooks/useLoading";
import { useParams } from "react-router-dom";
import { ButtonCircle } from "../../components/ButtomCircle";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",

    "& > h2 ~ div.MuiPaper-root > div": {
      overflowX: "unset !important",
    },
    "& > h2 ~ div.MuiPaper-root > div > div > div": {
      overflowY: "unset !important",
    },
  },
  title: {},
  iconEdit: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  iconCreate: {
    color: "#FF0000",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  btnInTable: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
  licenseeInfo: {
    minWidth: "250px",
    marginRight: "6px",
  },
  licenseeContact: {
    minWidth: "170px",
    marginRight: "6px",
  },
  widthColumnView: {
    minWidth: "140px",
  },
  widthColumnRating: {
    minWidth: "92px",
  },
  widthColumnInvite: {
    minWidth: "120px",
  },
}));

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

export default function LicenseeList() {
  const classes = useStyles();
  const paramId = useParams();

  const [showLoading, hideLoading] = useLoading();
  const [searchFilter] = useState([
    {
      star4: false,
      belowStar4: false,
    },
    { active: false, deactivated: false },
    { verified: false, unverified: false },
    { search: "" },
  ]);

  const [listEmployee, setListEmployee] = useState([]);
  const [licensee, setLicensee] = useState({});

  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });
  const [linkGetLicense, setLinkGetLicensee] = useState(
    `/v1/admin/licensee?count=${pagination.count}&&skip=0&&filters=&&verified=&&accountStatus=&&ratingStar=`
  );

  useEffect(() => {
    const temp = {
      search: "",
      verified: "",
      accountStatus: "",
      ratingStar: "",
    };
    if (searchFilter[0].star4 === searchFilter[0].belowStar4) {
      temp.ratingStar = "";
    } else if (searchFilter[0].star4) {
      temp.ratingStar = "above";
    } else if (searchFilter[0].belowStar4) {
      temp.ratingStar = "below";
    }
    if (searchFilter[1].active === searchFilter[1].deactivated) {
      temp.accountStatus = "";
    } else if (searchFilter[1].active) {
      temp.accountStatus = true;
    } else if (searchFilter[1].deactivated) {
      temp.accountStatus = false;
    }
    if (searchFilter[2].verified === searchFilter[2].unverified) {
      temp.verified = "";
    } else if (searchFilter[2].verified) {
      temp.verified = true;
    } else if (searchFilter[2].unverified) {
      temp.verified = false;
    }
    temp.search = searchFilter[3].search;
    let query = `/v1/admin/licensee?count=${pagination.count}&&skip=${pagination.skip}&&filters=${temp.search}&&verified=${temp.verified}&&accountStatus=${temp.accountStatus}&&ratingStar=${temp.ratingStar}`;
    setLinkGetLicensee(query);
  }, [searchFilter, pagination]);

  useEffect(() => {
    showLoading();
    // axios
    //   .get(linkGetLicense)
    //   .then((response) => {
    //     hideLoading();
    //     setPagination({
    //       ...pagination,
    //       totalCount: response.data.licenseeList[0]?.count[0]?.totalCount
    //         ? response.data.licenseeList[0]?.count[0]?.totalCount
    //         : null,
    //     });
    //     return setListLicensee(response.data.licenseeList[0].licenseeData);
    //   })
    //   .catch((error) => {
    //     toast.error(error?.response?.data?.message);
    //   });

    axios
      .get(`v1/admin/detaillicensee/${paramId.licenseeId}`)
      .then((response) => {
        setPagination({
          ...pagination,
          totalCount: response.data.licenseeUserObj.employees
            ? response.data.licenseeUserObj.employees.length
            : null,
        });
        hideLoading();
        setLicensee(response.data.licenseeUserObj);
        return setListEmployee(response.data.licenseeUserObj.employees);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, [linkGetLicense]);

  //-------------------------------------------------------------------------
  // REF FOR TABLE LIST LICENSEE
  const tableRef = React.createRef();

  const columns = [
    {
      title: <span className="title-table">Employees</span>,
      field: "licenseeInfo",
      render: (rowData) => {
        return (
          <div className={classes.licenseeInfo}>
            <ListItem>
              <ListItemAvatar>
                {rowData.data ? (
                  <Avatar alt="Logo" src={rowData.data} />
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText>
                <div className="list-contact">
                  <span className="text-16">{rowData.firstname + " " + rowData.lastname}</span>
                  <span className="text-14 text-secondary">
                    {rowData.licensee_data?.licenseeRef}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          </div>
        );
      },
    },
    {
      title: "Driver's License Front",
      field: "trailers",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            {rowData.driversOfLicensee && rowData.driversOfLicensee.length > 0 ? (
              <Button
                variant="outlined"
                color="secondary"
                className={classes.btnInTable}
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = rowData.driversOfLicensee[0].data;
                  link.target = "_blank";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                View
              </Button>
            ) : (
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--</p>
            )}
          </div>
        );
      },
    },
    {
      title: "Driver's License Back",
      field: "trailers",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            {rowData.driversOfLicensee && rowData.driversOfLicensee.length > 0 ? (
              <Button
                variant="outlined"
                color="secondary"
                className={classes.btnInTable}
                onClick={() => {
                  const link1 = document.createElement("a");
                  link1.href = rowData.driversOfLicensee[1].data;
                  link1.target = "_blank";
                  document.body.appendChild(link1);
                  link1.click();
                  document.body.removeChild(link1);
                }}
              >
                View
              </Button>
            ) : (
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--</p>
            )}
          </div>
        );
      },
    },
    {
      title: "Photo",
      field: "upsellItems",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            {rowData.data ? (
              <Button
                variant="outlined"
                color="secondary"
                className={classes.btnInTable}
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = rowData.data;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                View
              </Button>
            ) : (
              <p>&nbsp;&nbsp;&nbsp;&nbsp;--</p>
            )}
          </div>
        );
      },
    },
  ];

  const history = useHistory();

  return (
    <div className={`t2y-list t2y-licenseelist ${classes.root}`}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h2" component="h2" className={classes.title}>
          {licensee.firstname} {licensee.lastname}'s' Employees ID
        </Typography>
        <ButtonCircle
          variant="contained"
          onClick={() => {
            history.goBack();
          }}
        >
          Exit
        </ButtonCircle>
      </div>
      <Breadcrumbs />
      <MaterialTable
        className="table-data"
        tableRef={tableRef}
        columns={columns}
        data={listEmployee}
        options={tableOptions}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
}
