import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";

export function TrailerUtilisationReportGraph() {
  const { url } = useRouteMatch();
  const { trailerUtilisationReport } = useSelector((state) => state.analytic);
  return (
    <Card
      headerSize="small"
      header="Average utilisation rate"
      navigateTo={`${url}/trailer-utilisation-report`}
    >
      <PieGraph percentage={trailerUtilisationReport?.averageUtilisation} />
    </Card>
  );
}
