import React, { useEffect, useState } from "react";
import { Box, Dialog, Typography, makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import { ReactComponent as Delete } from "../../img/delete.svg";
import { DIALOG_TYPES } from "../../pages/licensee_rentalitems/Identification";
const convertTimeTo_DD_MM_YY = (time) => {
  return moment(time).format("DD/MM/YY");
};
const useStyle = makeStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  paper: {
    backgroundColor: "#171717",
  },
});
const DialogIdentifycation = ({ open = false, onClose, data }) => {
  const [itemList, setItemList] = useState([]);
  const cssDialog = useStyle();
  const mockData = [
    {
      field: "trailerType",
      render: (rowData) => {
        const nameList = rowData?.data?.split("/");
        const name =
          data?.type === DIALOG_TYPES.PHOTO_OF_EMPLOYEE
            ? `${rowData?.firstname} ${rowData?.lastname}`
            : nameList?.[nameList?.length - 1] ??
              `${convertTimeTo_DD_MM_YY(
                rowData.createdAt
              )}.${typeOfItemFileDocument(rowData.contentType)}`;
        return (
          <div style={{ display: "flex", width: "100%" }}>
            <span
              style={{
                textOverflow: "ellipsis",
                fontSize: 16,
                lineHeight: "19px",
                flex: 1,
                maxWidth: "50%",
              }}
            >
              <span style={{ lineBreak: "anywhere" }}>{name}</span>
            </span>
            <span
              style={{
                fontSize: 12,
                lineHeight: "12px",
                flex: 1,
                maxWidth: "50%",
                textAlign: "center",
              }}
            >
              {rowData.updatedAt
                ? "Last updated on: " +
                  moment(rowData.updatedAt).format("DD/MM/YYYY").toString()
                : ""}
            </span>
          </div>
        );
      },
    },
    {
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage href={rowData.data}>View</ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  const typeOfItemFileDocument = (type) => {
    return type?.split("/")?.[1]?.toUpperCase();
  };

  useEffect(() => {
    data.length !== 0 && setItemList(data.list);
  }, [data]);

  return (
    <Dialog open={open} classes={{ paper: cssDialog.paper }}>
      <Box
        padding="25px"
        boxSizing="border-box"
        width="600px"
        position="relative"
      >
        <Box
          position="absolute"
          right="24px"
          top="21px"
          className={cssDialog.root}
          onClick={onClose}
        >
          <Delete />
        </Box>
        <Box padding="15px" marginBottom="15px">
          <Typography align="center" variant="h5">
            {data.header}
          </Typography>
        </Box>
        <MaterialTable
          className="table-data"
          columns={mockData}
          data={itemList}
          options={{
            header: false,
            toolbar: false,
            showTitle: false,
            draggable: false,
            search: false,
            sorting: false,
            filtering: false,
            paging: false,
            actionsCellStyle: { color: "#fff" },
            rowStyle: (rowData) => {
              // FIRST ELEMENT
              return (
                rowData.tableData.id === 0 && { borderTop: "1px solid #24272d" }
              );
            },
            actionsColumnIndex: -1,
          }}
        />
      </Box>
    </Dialog>
  );
};
export default DialogIdentifycation;
