import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import { useLayoutEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { Menu } from "../../components";
import { useStyles } from "./HireTheDriverPage.styles";
import {
  HTDRatio,
  HTDUtilisationReportByTrailerType,
  HTDUtilisationReportByTrailerTypeAndFranchisee,
  OverViewHireTheDriver,
  TotalHTDHoursPerFranchisee,
} from "../../tabs/HireTheDriver";

export function HireTheDriverPage() {
  const classes = useStyles();

  useLayoutEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Analytics";
  });

  return (
    <Box component="main" className={classes.analyticsContent}>
      <Breadcrumbs />
      <Menu
        overviewTabStyles={classes.overviewTab}
        hireTheDriverTabStyles={classes.hireTheDriverTab}
        reportsTabStyles={classes.reportsTab}
      />

      <Grid container spacing={7} className={classes.section}>
        <Grid item xs={12}>
          <OverViewHireTheDriver />
        </Grid>
      </Grid>
      <Grid container spacing={7} className={classes.section}>
        <Grid item md={4} sm={12} xs={12}>
          <HTDRatio />
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <HTDUtilisationReportByTrailerType />
        </Grid>
      </Grid>
      <Grid container spacing={7} className={classes.section}>
        <Grid item md={12} sm={12} xs={12}>
          <TotalHTDHoursPerFranchisee />
        </Grid>
      </Grid>
      <Grid container spacing={7} className={classes.section}>
        <Grid item md={12} sm={12} xs={12}>
          <HTDUtilisationReportByTrailerTypeAndFranchisee />
        </Grid>
      </Grid>
    </Box>
  );
}
