import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useRouteMatch } from "react-router-dom";
import SortableTable from '../../../../../components/SortableTable/SortableTable';
import { Card } from "../../../components";

export function OutOfServiceTrailers() {
  const { url } = useRouteMatch();
  const { outOfServiceTrailersData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (outOfServiceTrailersData?.items) {
      const dataTransformed = [...outOfServiceTrailersData?.items].sort((a, b) => b.grossIncome - a.grossIncome).map((item) => ({
        franchisee: `${item?.licenseeUser?.firstname} ${item?.licenseeUser?.lastname}`,
        trailerType: item.trailer.name,
        reason: item.reason,
      }));
      setData(dataTransformed);
    }
  }, [outOfServiceTrailersData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "Reason",
      key: "reason",
      sortable: true,
    },
  ];

  return (
    <Card header="Out of service trailers" navigateTo={`${url}/out-of-service-trailers`}>
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="30% 40% 30%"
        rowsHeight={255}
      />
    </Card>
  );
}
