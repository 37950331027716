import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Notifications from "./components/Notifications";
import CreateNewOrEditNotification from "./components/CreateNewOrEditNotification.jsx";

function NotificationsComponent() {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={match.url} component={Notifications} exact />
        <Route path={`${match.url}/create-new`} component={CreateNewOrEditNotification} exact />
        <Route path={`${match.url}/edit/:id`} component={CreateNewOrEditNotification} exact />
      </Switch>
    </div>
  );
}

export default NotificationsComponent;
