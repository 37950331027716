import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import { Card } from "../../components";
import { formatAmount } from "../../utils";
import { useStyles } from "../../Analytics.styles";

export function AddonsAndConsumablesPage() {
  const classes = useStyles();
  const { incomePerUpSellItemsData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Add Ons and Consumables';
    if (incomePerUpSellItemsData?.items) {
      const dataTransformed = [...incomePerUpSellItemsData?.items].sort((a, b) => b.grossIncome - a.grossIncome).map((item) => ({
        name: item.upsellItem.name,
        grossIncome: formatAmount(item.grossIncome || 0, 0),
        t2yIncome: formatAmount(item.t2yIncome || 0, 0),
        franchiseeEarnings: formatAmount(item.franchiseeEarnings || 0, 0),
        totalHires: item.totalHires,
      }));
      setData(dataTransformed);
    }
  }, [incomePerUpSellItemsData]);

  const columns = [
    {
      name: "Add on or Consumable",
      key: "name",
      sortable: true,
    },
    {
      name: "Gross Income",
      key: "grossIncome",
      sortable: true,
    },
    {
      name: "T2Y Income",
      key: "t2yIncome",
      sortable: true,
    },
    {
      name: "Franchisee Earnings",
      key: "franchiseeEarnings",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Add Ons & Consumables">
        <SortableTable
          rows={data}
          columns={columns}
          gridTemplateColumns="30% 1fr 1fr 21% 1fr"
        />
      </Card>
    </Box>
  );
}
