import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import AuthProvider from '../../context/AuthContext';
import { getFromLocalStorage } from '../../helpers/common';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = getFromLocalStorage("user");
  const token = secureLocalStorage.getItem("token");
  const isValidUser = user && token && token !== "undefined";
  let location = useLocation();

  return (
    <AuthProvider>
      <Route
        {...rest}
        render={(props) => {
          if (user && !user?.is2FAVerified && location.pathname !== '/verify-account') {
            return <Redirect to={{ pathname: "/verify-account" }} />;
          } else if (isValidUser) {
            return <Component {...props} />;
          } else {
            secureLocalStorage.removeItem("token");
            return <Redirect to={{ pathname: "/signin" }} />;
          }
        }}
      />
    </AuthProvider>
  );
};
