import React, { useEffect } from "react";
import { object, string } from "yup";
import { updateDataAtom, useValidateDiscountCode } from "../../hooks/UpdateOrder/useUpdateOrder";
import { useAtom } from "jotai";
import { useFormik, FormikProvider } from "formik";
import { Stack } from "../Common/Styled/CommonStyled";
import { Button, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Text14Weight400, Text16, Text18 } from "../Common/Styled/TypographyStyled";
import { COLOR_LIST } from "../../themes";
import FormikControl, { CONTROL_TYPE } from "../Formik/FormikControl";

const schema = object().shape({
  discountCode: string().required("Required"),
});

const AddDiscountCodeModal = ({ onCloseModal }) => {
  const { mutate, isLoading } = useValidateDiscountCode();
  const [updateData, setUpdateData] = useAtom(updateDataAtom);

  const onHandleApplyCode = (data) => {
    if (updateData) {
      mutate(
        { ...updateData, discountCodes: [data.discountCode] },
        {
          onSuccess: () => {
            setUpdateData({ ...updateData, discountCodes: [data.discountCode] });
            onCloseModal();
          },
          onError: () => {
            createError();
          },
        }
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      discountCode: "",
    },
    validationSchema: schema,
    onSubmit: onHandleApplyCode,
  });

  const { handleSubmit, setFieldError, setErrors, values } = formik;

  useEffect(() => {
    if (values.discountCode) {
      setErrors({});
    }
  }, [values.discountCode]);

  const createError = () => {
    setFieldError("discountCode", "Invalid discount code");
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={() => handleSubmit()}>
        <Stack style={{ flexDirection: "column", position: "relative", gap: 24 }}>
          <IconButton
            style={{ position: "absolute", right: 0, top: -10 }}
            onClick={() => onCloseModal()}
          >
            <CloseIcon style={{ color: "white", fontSize: "30px" }} />
          </IconButton>
          <Text18>Gift card or discount code</Text18>
          <Text14Weight400 style={{ color: COLOR_LIST.LAVENDER_MIST }}>
            Please enter the gift card number or discount code below
          </Text14Weight400>
          <FormikControl
            name="discountCode"
            control={CONTROL_TYPE.INPUT}
            label="Discount Code"
            InputLabelProps={{ shrink: true }}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <Button
            style={{ minHeight: 50 }}
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            <Text16>Save details</Text16>
          </Button>
        </Stack>
      </form>
    </FormikProvider>
  );
};

export default AddDiscountCodeModal;
