import * as React from "react";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useStyles } from "./PieGraph.styles";

export function PieGraph({
  percentage = 0,
  outOfString = "Fleet",
  secondaryText = "",
  overrideSubText = "",
}) {
  const classes = useStyles();
  const percentageFormatted = parseFloat(percentage).toFixed(2);
  const lines = outOfString.split("\\n");
  return (
    <Box component="div" className={classes.pieGraph}>
      <Box component="span" className={classes.background}>
        <CircularProgress variant="determinate" value={100} />
      </Box>
      <Box component="span" className={classes.foreground}>
        <CircularProgress variant="determinate" value={100 - percentageFormatted} />
      </Box>
      <Typography component="h1" className={classes.percentage}>
        {percentageFormatted}%
      </Typography>
      {secondaryText && (
        <Typography noWrap={false} component="h6" className={classes.secondaryText}>
          {secondaryText}
        </Typography>
      )}
      <Typography noWrap={false} component="h6" className={classes.percentageText}>
        {overrideSubText ? (
          overrideSubText
        ) : (
          <>
            of{" "}
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </>
        )}
      </Typography>
    </Box>
  );
}
