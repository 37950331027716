import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./SortableTable.styles";
import { DownArrowIcon, UpArrowIcon } from "../../pages/analytics/icons";
import { copyAndSort } from "../../helpers/common";
import PropTypes from "prop-types";

const SortableTable = ({
  rows,
  columns,
  defaultSorting,
  hasIndexColumn = false,
  gridTemplateColumns = "repeat(auto-fill, minmax(100px, 1fr))",
  rowsHeight
}) => {
  const classes = useStyles({ gridTemplateColumns });
  const [rowsData, setRowsData] = useState(rows);
  const [columnsData, setColumnsData] = useState(columns);
  const [sortValue, setSortValue] = useState(defaultSorting || {});

  useEffect(() => {
    let newRows = [...rows];
    let newColumns = [...columns];
    if (sortValue.key) {
      newRows = copyAndSort(newRows, sortValue.key, sortValue.isDescending);
    }
    if (hasIndexColumn) {
      newRows = newRows.map((item, index) => ({ index: index + 1, ...item }));
      newColumns = [
        {
          name: "",
          key: "index",
          sortable: false,
        },
        ...columns,
      ];
    }
    setRowsData(newRows);
    setColumnsData(newColumns);
  }, [sortValue, rows]);

  const onClickSorting = (item) => {
    if (sortValue.key === item.key) {
      setSortValue({ ...sortValue, isDescending: !sortValue.isDescending });
    } else {
      setSortValue({ key: item.key, isDescending: true });
    }
  };

  return (
    <Box component="div" className={`${classes.table}`}>
      <Box component="div" className={classes.tableHeader}>
        {columnsData.map((item, index) => {
          return (
            <Box
              key={index}
              component="span"
              onClick={() => item.sortable && onClickSorting(item)}
              style={{ cursor: item.sortable ? "pointer" : "default" }}
            >
              <span>{item.name}</span>
              <span style={{ marginLeft: 10 }}>
                {sortValue && sortValue.key === item.key ? (
                  sortValue.isDescending ? (
                    <DownArrowIcon />
                  ) : (
                    <UpArrowIcon />
                  )
                ) : null}
              </span>
            </Box>
          );
        })}
      </Box>

      <Box component="div" className={classes.tableRows} style={{height: rowsHeight}}>
        {rowsData.map((item, index) => (
          <Box component="span" key={`row-${index}`}>
            {Object.keys(item).map((key) => (
              <Box component="span" key={`row-${index}-cell-${key}`}>
                {item[key]}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

SortableTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      sortable: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultSorting: PropTypes.shape({
    key: PropTypes.string.isRequired,
    isDescending: PropTypes.bool.isRequired,
  }),
  hasIndexColumn: PropTypes.bool,
  gridTemplateColumns: PropTypes.string,
  rowsHeight: PropTypes.number,
};

// Sample Input
/*
const rows = [
  {
    name: "item 1",
    prop1: "abc",
    prop2: 789,
    prop3: "xyz",
  },
  {
    name: "test 2",
    prop1: "xyz",
    prop2: 123,
    prop3: "abc",
  },
];
const columns = [
  {
    name: "",
    key: "name",
    sortable: false,
  },
  {
    name: "Prop 1",
    key: "prop1",
    sortable: true,
  },
  {
    name: "Prop 2",
    key: "prop2",
    sortable: true,
  },
  {
    name: "Prop 3",
    key: "prop3",
    sortable: true,
  },
];
const defaultSorting = {
  key: "prop1",
  isDescending: true,
};
*/

export default SortableTable;
