import moment from "moment";

export const formatContacts = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{3})$/);

  if (match) {
    return match[1] + " " + match[2] + " " + match[3] + " " + match[4];
  }

  return null;
};
export const formatDriverNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);

  if (match) {
    return match[1] + " " + match[2] + " " + match[3];
  }

  return null;
};
export const formatMobileNumber = (mobileNumber) => {
  if (mobileNumber.charAt(0) === "0") {
    return "+61" + mobileNumber.slice(1);
  } else {
    return "+61" + mobileNumber;
  }
};

export const titleCase = (str) => {
  if (str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
};

export const formatCurrency = (value) => {
  const numberValue = typeof value === "number" ? value : parseFloat(value);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numberValue);
};

export const formatTime = ({ date, originalFormat, designateFormat }) => {
  let formattedDate = "--";
  try {
    if (originalFormat) {
      formattedDate = moment(date, originalFormat).format(designateFormat);
    } else {
      formattedDate = moment(date).format(designateFormat);
    }
  } catch (err) {
    /* Do nothing */
  }
  return formattedDate;
};

export function placeToAddress(place) {
  const address = {};
  place.address_components.forEach(function (c) {
    switch (c.types[0]) {
      case "street_number":
        address.streetNumber = c;
        break;
      case "route":
        address.streetName = c;
        break;
      case "neighborhood":
      case "locality": // North Hollywood or Los Angeles?
        address.city = c;
        break;
      case "administrative_area_level_1": //  Note some countries don't have states
        address.state = c;
        break;
      case "postal_code":
        address.postal_code = c;
        break;
      case "country":
        address.country = c;
        break;
      /*
       *   . . .
       */
    }
  });

  return address;
}
