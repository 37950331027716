import React from "react";
import { Dialog, Box, Typography, ListItem } from "@material-ui/core";
import { ReactComponent as Delete } from "../../img/delete.svg";
import { Checkbox } from "@material-ui/core";
import { useDialogTrailerServicesStyle } from "./style";
import { useCheckBoxItemTrailerServicesStyle } from "./style";
import ButtonActionHeader from "../ButtonActionHeader";

const DialogTrailerUpsellItem = ({
  open,
  checkList,
  onSelectUpsellItem,
  handleCloseDialog,
  header,
  title,
  onSubmitSelectUpsellItem,
}) => {
  const cssDialog = useDialogTrailerServicesStyle();
  const cssCheckBox = useCheckBoxItemTrailerServicesStyle();
  return (
    <Dialog open={open}>
      <Box classes={{ root: cssDialog.containerContentDialog }}>
        <Box width="inherit">
          <Box classes={{ root: cssDialog.fixedHeaderDialog }}>
            <Box classes={{ root: cssDialog.iconCloseDialog }} onClick={handleCloseDialog}>
              <Delete />
            </Box>
            <Typography align="center" classes={{ root: cssDialog.headerContentDialog }}>
              {header}
            </Typography>
            <Typography align="center" classes={{ root: cssDialog.titleContentDialog }}>
              {title}
            </Typography>
          </Box>
        </Box>
        <Box classes={{ root: cssDialog.scrollContaierContentDialog }}>
          {checkList.map((item, index) => (
            <ListItem
              classes={{
                root: `
                    ${cssDialog.itemContent} 
                    ${index !== checkList.length && cssDialog.borderBottom}
                    ${index === 0 && cssDialog.borderTop}
                  `,
              }}
              key={index}
            >
              <Typography
                classes={{
                  root: cssDialog.itemTittle,
                }}
              >
                {item.name}
              </Typography>
              <Checkbox
                color="primary"
                id={item.name}
                classes={cssCheckBox}
                checked={item.isActive}
                onClick={() => onSelectUpsellItem(index)}
              />
            </ListItem>
          ))}
        </Box>
        <ButtonActionHeader
          style={{ width: "100%", marginTop: "33px", height: "52px" }}
          onClick={() => onSubmitSelectUpsellItem()}
        >
          Done
        </ButtonActionHeader>
      </Box>
    </Dialog>
  );
};
export default DialogTrailerUpsellItem;
