import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Card } from "../../components";
import { useStyles } from "../../Analytics.styles";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";

export function UnservBookingEnqPage() {
  const classes = useStyles();
  const { unserviceBookingPerformanceData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Unserviced Booking Enquiries';
    if (unserviceBookingPerformanceData?.items) {
      const dataTransformed = [...unserviceBookingPerformanceData.items]
        .sort((a, b) => b.total - a.total)
        .map((item) => ({
          city: item._id,
          unservicedBookings: item.total,
        }));
      setData(dataTransformed);
    }
  }, [unserviceBookingPerformanceData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Unserviced Bookings",
      key: "unservicedBookings",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Unserviced Booking Performance">
        <SortableTable
          rows={data}
          columns={columns}
          hasIndexColumn
          gridTemplateColumns="10% 50% 40%"
        />
      </Card>
    </Box>
  );
}
