import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";

export function CancelledHires() {
  const { summaryData } = useSelector((state) => state.analytic);
  const [cancelledHiresData, setCancelledHiresData] = useState({});

  useEffect(() => {
    if (summaryData) {
      const data = summaryData.find(
        (item) => typeof item.value === "string" && item.value.includes("%")
      );
      setCancelledHiresData({ ...data, value: parseFloat(data?.value || 0) });
    }
  }, [summaryData]);
  return (
    <Card headerSize="small" header="Cancelled Hires %" onClick={() => {}}>
      <PieGraph percentage={cancelledHiresData.value} outOfString="Total" />
    </Card>
  );
}
