import React, { useState, useEffect } from "react";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import ColumnInTable from "../../components/ColumnInTable";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import { ReactComponent as Edit } from "../../img/edit.svg";
import { useHistory } from "react-router-dom";
import CustomPagination from "../../components/Pagination";
import DialogChangeStatus from "../../components/DialogChangeStatus";
import axiosInstance from "../../helpers/axios";
import ImageIcon from "@material-ui/icons/Image";
import { useLoading } from "../../hooks/useLoading";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { getCRUD_AllowByRoles } from "../../helpers/common";
import { USER_ROLES } from "../../constants/common";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const PENDING = "Pending";
const COMPLETE = "Complete";

function WrapText(props) {
  const { children, ...rest } = props;
  return (
    <span className="text-14" {...rest}>
      {children}
    </span>
  );
}

const AdminUserList = () => {
  const history = useHistory();
  const [userList, setUserList] = useState([]);
  const [showLoading, hideLoading] = useLoading();
  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });
  const [linkGetLicense, setLinkGetLicensee] = useState(
    `/v1/admin/user?count=${pagination.count}&&skip=${pagination.skip}`
  );

  const { isAllowToCreate, isAllowToUpdate, isAllowToDelete } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    D_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });

  useEffect(() => {
    setLinkGetLicensee(`/v1/admin/user?count=${pagination.count}&&skip=${pagination.skip}`);
  }, [pagination]);

  useEffect(() => {
    getAllUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkGetLicense]);
  function getAllUser() {
    showLoading();
    axiosInstance.get(linkGetLicense).then((response) => {
      hideLoading();
      setPagination({
        ...pagination,
        totalCount: response.data.total ? response.data.total : null,
      });
      return setUserList(response.data.userList);
    });
  }

  const columns = [
    {
      title: <span className="title-table">Users</span>,
      field: "users",
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: "301px",
              whiteSpace: "nowrap",
              lineHeight: "58px",
              paddingRight: "18px",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                {rowData.photos?.data ? (
                  <Avatar alt="Logo" src={rowData.photos.data}></Avatar>
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText>
                <div className="list-contact">
                  <span className="text-16">{rowData.name}</span>
                </div>
              </ListItemText>
            </ListItem>
          </div>
        );
      },
    },
    {
      title: "Email",
      field: "email",
      render: (rowData) => {
        return <ColumnInTable minWidth="300">{rowData.email}</ColumnInTable>;
      },
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="485">
            <WrapText className={rowData.isActive ? "text-success" : "text-error"}>
              {rowData.isActive ? COMPLETE : PENDING}
            </WrapText>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Role",
      field: "role",
      render: (rowData) => {
        return <ColumnInTable minWidth="300">{rowData.role ?? "--"}</ColumnInTable>;
      },
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            {isAllowToUpdate ? (
              <div
                style={{
                  marginRight: "33px",
                  padding: "9.5px",
                  width: "fit-content",
                  cursor: "pointer",
                }}
                onClick={() =>
                  history.push({
                    pathname: `/admin/${rowData._id}`,
                    state: { detail: rowData },
                  })
                }
              >
                <Edit />
              </div>
            ) : null}
            {isAllowToDelete ? (
              <ButtonActionSubPage
                colorError
                minWidth="144px"
                onClick={() => toggleDialog({ id: rowData._id }, "remove")}
              >
                Remove user
              </ButtonActionSubPage>
            ) : null}
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });
  // HANDLE OPEN DIALOG
  function toggleDialog(data, method) {
    if (method === "remove") {
      setDataDialog({
        isActive: false,
        header: "Are you sure you want to remove user from the system ?",
        subtitle: "The admin will lose access to the panel.",
        submitTitle: "Remove user",
        onActive: () => handleRemove(data.id),
      });
    }
    setOpen(true);
  }
  function handleRemove(id) {
    showLoading();
    axiosInstance
      .delete(`v1/admin/user/${id}`)
      .then((response) => {
        hideLoading();
        getAllUser();
        setOpen(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function closeDialog() {
    setOpen(false);
  }

  return (
    <>
      <WrapHeaderSubPage nameHeader="Admin User">
        {isAllowToCreate ? (
          <ButtonActionHeader
            onClick={() =>
              history.push({
                pathname: `/admin/add`,
              })
            }
          >
            Add User
          </ButtonActionHeader>
        ) : null}
      </WrapHeaderSubPage>
      <Breadcrumbs />
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={userList}
        options={tableOptions}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </>
  );
};

export default AdminUserList;
