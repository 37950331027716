import React, { useEffect, useState } from "react";
import { sendFranchiseeTransactionHistoryReportEmail } from "../../../helpers/api";
import { Dialog, Typography, Box, makeStyles, withStyles } from "@material-ui/core";
import ButtonActionSubPage from "../../../components/ButtonActionSubPage";
import { ReactComponent as Close } from "../../../img/delete.svg";
import { ReactComponent as Success } from "../../../img/success.svg";
import { toast } from "react-toastify";

const StyledTypography = withStyles({
  root: {
    textAlign: "center",
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "21.48px",
  },
  subtitle1: {
    marginTop: "11px",
    color: "rgba(222, 231, 250, 1)",
    lineHeight: "16.71px",
    fontSize: 14,
    opacity: 0.8,
  },
})(Typography);
const useStyle = makeStyles({
  root: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  paper: {
    position: "absolute",
    top: 30,
  },
});

const DialogTransactionReportEmail = ({ isOpen, onCloseDialog, franchisee, values }) => {
  const cssDialog = useStyle();
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setEmailSent(false);
    setIsLoading(false);
  }, [isOpen]);

  const handleSubmitSendEmail = async () => {
    setIsLoading(true);
    try {
      await sendFranchiseeTransactionHistoryReportEmail(values);
      setEmailSent(true);
    } catch (err) {
      const message = err.response?.data?.message || err.message;
      toast.error(message);
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={isOpen} className={cssDialog.paper}>
      {isOpen && (
        <Box
          position="relative"
          padding="52px 24px 25px 24px"
          width="432px"
          minHeight="251px"
          boxSizing="border-box"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            position="absolute"
            right="24px"
            top="21px"
            className={cssDialog.root}
            onClick={onCloseDialog}
          >
            <Close />
          </Box>
          {emailSent ? (
            <Box marginBottom="41px" display="flex" flexDirection="column" alignItems="center">
              <Box marginBottom="30px">
                <Success />
              </Box>
              <StyledTypography variant="subtitle2">Email Sent</StyledTypography>
            </Box>
          ) : (
            <Box padding="0 24px" marginBottom={"27px"}>
              <StyledTypography variant="subtitle2">
                Are you sure you want to email transaction history to {franchisee?.name}?
              </StyledTypography>
              <StyledTypography variant="subtitle1">
                Please ensure you have selected the correct date range before sending the email.
              </StyledTypography>
            </Box>
          )}
          <ButtonActionSubPage
            fontWeight={600}
            minWidth="100%"
            height="52px"
            padding="16px"
            disabled={isLoading}
            onClick={!isLoading && emailSent ? onCloseDialog : handleSubmitSendEmail}
          >
            {emailSent ? "Done" : isLoading ? "Sending..." : "Send"}
          </ButtonActionSubPage>
        </Box>
      )}
    </Dialog>
  );
};
export default DialogTransactionReportEmail;
