import React, { useEffect, useState } from "react";
import { Card } from "../../../components";
import { formatAmount, formatHour } from "../../../utils";
import { useSelector } from "react-redux";
import SortableTable from "../../../../../components/SortableTable/SortableTable";

export function TotalHTDHoursPerFranchisee() {
  const { htdTotalHoursPerFranchisee } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (htdTotalHoursPerFranchisee?.items) {
      const dataTransformed = htdTotalHoursPerFranchisee.items.map((item) => ({
        franchisee: item?.licensee?.firstname
          ? `${item?.licensee?.firstname} ${item?.licensee?.lastname}`
          : "--",
        numberOfHires: item?.hiresWithHtd ?? "--",
        totalHTDHours: formatHour(item.totalHtdHours),
        totalHTDIncome: formatAmount(item.totalHtdIncome, 2),
        averageHTDHoursPerHire: item?.averageHoursPerHire ?? "--",
        averageHTDIncomePerHire: formatAmount(item.averageIncomePerHire, 2),
        utilisation: item?.htdUtilisationPercentage ?? "--",
        HTDRatio: item?.htdRatio ?? "--",
      }));
      setData(dataTransformed);
    }
  }, [htdTotalHoursPerFranchisee]);
  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Number of hires with HTD",
      key: "numberOfHires",
      sortable: true,
    },
    {
      name: "Total HTD hours",
      key: "totalHTDHours",
      sortable: true,
    },
    {
      name: "Total HTD income",
      key: "totalHTDIncome",
      sortable: true,
    },
    {
      name: "Average HTD hours per hire with HTD included",
      key: "averageHTDHoursPerHire",
      sortable: true,
    },
    {
      name: "Average HTD income per hire",
      key: "averageHTDIncomePerHire",
      sortable: true,
    },
    {
      name: "HTD Utilisation",
      key: "utilisation",
      sortable: true,
    },
    {
      name: "HTD Ratio",
      key: "HTDRatio",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Total HTD hours per Franchisee"
      navigateTo={`/analytics/hire-the-driver/total-htd-hours-per-franchisee`}
      style={{ maxHeight: 300 }}
    >
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="15% 10% 15% 15% 15% 10% 10% 10%"
        rowsHeight={255}
      />
    </Card>
  );
}
