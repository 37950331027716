import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";
import { formatAmount } from "../../../utils";

export function DamageWaiverUtilisationRateGraph() {
  const { url } = useRouteMatch();
  const { damageWaiverUtilisationReport } = useSelector((state) => state.analytic);
  const secondaryText = formatAmount(damageWaiverUtilisationReport?.totalIncome || 0, 0);
  return (
    <Card
      headerSize="small"
      header="Damage Waiver Utilisation Rate"
      navigateTo={`${url}/damage-waiver-utilisation-report`}
    >
      <PieGraph
        percentage={damageWaiverUtilisationReport?.percentage}
        secondaryText={secondaryText}
        overrideSubText={"Total income"}
      />
    </Card>
  );
}
