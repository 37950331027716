import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import ColumnInTable from "../../components/ColumnInTable";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import ExpandableCell from "../../components/ExpandableCell";
import CustomPagination from "../../components/Pagination";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { getTransaction } from "../../helpers/api";
import axiosInstance from "../../helpers/axios";
import useBoolean from "../../hooks/useBoolean";
import { useLoading } from "../../hooks/useLoading";
import TransferBookingDialog from "./TransferBookingDialog";
import DialogIdentifycation from "../../components/DialogIdentification";

const useStyles = makeStyles(() => ({
  outlinedButton: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
}));

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const UpcomingOrders = () => {
  const classes = useStyles();
  const history = useHistory();
  const [listDriverLicense, setListDriverLicense] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [showLoading, hideLoading] = useLoading();
  // const [customer, setCustomer] = useState(null);
  const [transferTarget, setTransferTarget] = useState(null);
  const [openTransferDialog, toggleOpenTransferDialog] = useBoolean(false);
  const [licensee, setLicensee] = useState(null);
  const { licenseeId } = useParams();
  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });
  // const [linkGetOrders, setLinkGetOrders] = useState(
  //   `/v1/admin/user?count=${pagination.count}&&skip=${pagination.skip}`
  // );

  useEffect(() => {
    try {
      showLoading();
      if (!licensee) getLicenseeData();
      getTransactionQuery();
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading();
    }
  }, [pagination.skip, pagination.count]);

  async function getLicenseeData() {
    const licenseeData = await axiosInstance.get(`v1/admin/detaillicensee/${licenseeId}`);
    setLicensee(licenseeData.data.licenseeUserObj);
  }

  async function getTransactionQuery() {
    const transactionQuery = await getTransaction(
      {
        ...pagination,
        licenseeId,
      },
      { params: { isUpcoming: true } }
    );

    setPagination({
      ...pagination,
      totalCount: transactionQuery.totalCount || 0,
    });
    setTransactions(transactionQuery.transactionsObj);
  }

  const navigateDetail = (id) => {
    history.push(`/transaction-history/${id}`);
  };

  const columns = [
    {
      title: <span className="title-table">Customer</span>,
      field: "users",
      render: (rowData) => {
        const customerInfo = rowData.customerInfor;
        return (
          <div
            style={{
              width: "250px",
              whiteSpace: "nowrap",
              lineHeight: "58px",
              paddingRight: "18px",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                {customerInfo.photo?.data ? (
                  <Avatar alt="Logo" src={customerInfo.photo.data}></Avatar>
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText>
                <span className="text-16 name-column">{customerInfo.name}</span>
              </ListItemText>
            </ListItem>
          </div>
        );
      },
    },
    {
      title: "Trailer type",
      field: "trailer_type",
      render: (rowData) => {
        return <ColumnInTable minWidth="150">{rowData.trailerType.name}</ColumnInTable>;
      },
    },
    {
      title: "Upsell items",
      field: "upsell_items",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="150">
            <ExpandableCell array={rowData.upsellItems.map((item) => item?.details?.name) || []} />
          </ColumnInTable>
        );
      },
    },
    {
      title: "Consumable items",
      field: "comsumable_items",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="150">
            <ExpandableCell
              array={rowData.consumableItems.map((item) => item?.details?.name) || []}
            />
          </ColumnInTable>
        );
      },
    },
    {
      title: "Drop Off / Pickup",
      field: "drop_pick",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="200">
            <Box display={"flex"} flexDirection={"column"}>
              <Box>{moment(rowData.startDate).format("hh:mma - DD/MM/YY")}</Box>
              <Box>{moment(rowData.endDate).format("hh:mma - DD/MM/YY")}</Box>
            </Box>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Customer Phone Number",
      field: "customer_phone",
      render: (rowData) => {
        return <ColumnInTable minWidth="150">{rowData.customerInfor?.mobile}</ColumnInTable>;
      },
    },
    {
      title: "Location",
      field: "location",
      render: (rowData) => {
        return <ColumnInTable minWidth="250">{rowData.customerLocation?.address}</ColumnInTable>;
      },
    },
    {
      title: "Drivers license",
      field: "driversLicense",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="120">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.outlinedButton}
              onClick={() =>
                setListDriverLicense(rowData?.customerInfor?.driverLicense?.scans ?? [])
              }
            >
              View
            </Button>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Driver Hired?",
      field: "driver_hired",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="120">
            <div className={rowData.charges?.hireTheDriverCharges?.length ? "text-success" : ""}>
              {rowData.charges?.hireTheDriverCharges?.length ? "Yes" : "No"}
            </div>
          </ColumnInTable>
        );
      },
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          // <WrapButtonFlexRight>
          //   <ButtonActionSubPage
          //     minWidth="144px"
          //     margin="0px 15px 0px 0px"
          //     onClick={toggleOpenTransferDialog}
          //     disabled
          //   >
          //     Transfer Booking
          //   </ButtonActionSubPage>
          //   <ButtonActionSubPage minWidth="114px" onClick={() => navigateDetail(rowData._id)}>
          //     View More
          //   </ButtonActionSubPage>
          // </WrapButtonFlexRight>
          <Box display="flex" justifyItems="center">
            <ButtonActionSubPage minWidth="114px" onClick={() => navigateDetail(rowData._id)}>
              View More
            </ButtonActionSubPage>
          </Box>
        );
      },
    },
  ];

  const onSelectTransferBooking = (item) => {
    toggleOpenTransferDialog();
    setTransferTarget(item);
  };

  const onConfirmTransfer = () => {
    setTransferTarget(null);
  };

  return licensee && transactions ? (
    <>
      <WrapHeaderSubPage
        nameHeader={`${licensee?.firstname}'s Upcoming Bookings`}
      ></WrapHeaderSubPage>
      <Breadcrumbs />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={transactions}
        options={tableOptions}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
      {/* <CustomerDialog open={!!customer} onClose={() => setCustomer(null)} data={customer} /> */}
      <DialogIdentifycation
        open={!!listDriverLicense}
        onClose={() => setListDriverLicense(null)}
        data={{
          header: "Drivers License",
          list: listDriverLicense,
        }}
      />
      <TransferBookingDialog
        open={!!openTransferDialog}
        onClose={toggleOpenTransferDialog}
        transferList={transferList}
        onSelect={(item) => onSelectTransferBooking(item)}
      />
      <ConfirmationDialog
        isOpen={!!transferTarget}
        header="Are you sure you want to transfer this booking to: "
        subtitle={transferTarget?.address}
        onConfirm={onConfirmTransfer}
        onCloseDialog={() => setTransferTarget(null)}
      />
    </>
  ) : null;
};

const transferList = [
  {
    id: "123456",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123457",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123458",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123459",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123410",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123458",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123459",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123410",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123459",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123410",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123458",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123459",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
  {
    id: "123410",
    name: "Gary Smith",
    address: "12 Simpson St, Coolangatta, QLD",
  },
];

export default UpcomingOrders;
