import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import getQueryKey, {
  GET_DISCOUNT_DETAILS,
  GET_DISCOUNT_LIST,
  GET_PROMOTIONAL_BANNERS,
} from "../../helpers/getQueryKey";
import axiosInstance from "../../helpers/axios";
import { common } from "../../constants/common";
import { convertObjectIntoQueryString } from "../../helpers/common";

export const TYPE_PROMOTION_ITEMS = {
  PRODUCTS: "products",
  LICENSEES: "licensees",
  CUSTOMERS: "customers",
};

export const useItemPromotions = (keyword, type) => {
  return useQuery({
    queryKey: getQueryKey.getItemPromotionsQueryKey(keyword, type),
    queryFn: async () => {
      const response = await axiosInstance.get(
        `v1/admin/search?type=${type}&limit=100&keyword=${keyword}`
      );
      if (response?.status === 200) {
        return response?.data || [];
      }
      return [];
    },
    staleTime: common.STALE_TIME.MIN_1,
  });
};

export const useUploadPromotionalBanners = () => {
  return useMutation({
    mutationFn: async (files) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      const response = await axiosInstance.post("v1/admin/upload-promotional-banner", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response?.data ?? [];
    },
  });
};

export const useGetPromotionalBanners = () => {
  return useQuery({
    queryKey: getQueryKey.getPromotionalBannersQueryKey(),
    queryFn: async () => {
      const response = await axiosInstance.get("v1/admin/promotional-banners");

      if (response?.status === 200) {
        return response?.data || [];
      }
      return [];
    },
  });
};

export const useUpdatePromotionalBanners = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return axiosInstance.post("v1/admin/promotional-banners", payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_PROMOTIONAL_BANNERS]);
    },
  });
};

export const useGetDiscountList = ({ limit = 20, skip = 0, type = "all", keyword = "" }) => {
  const params = {
    limit,
    skip,
    type: !Object.entries(common.DISCOUNT_PROMOTION_STATUS)
      .map(([, status]) => status)
      .includes(type)
      ? common.DISCOUNT_PROMOTION_STATUS.ALL
      : type,
    keyword,
  };
  return useQuery({
    queryKey: getQueryKey.getDiscountListQueryKey(params),
    queryFn: async () => {
      const query = convertObjectIntoQueryString(params);
      const response = await axiosInstance.get(`v1/admin/booking-discounts?${query}`);

      if (response?.status === 200) {
        return response?.data;
      }
      return null;
    },
  });
};

export const useDeleteDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => {
      return axiosInstance.delete(`v1/admin/booking-discounts/${id}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_DISCOUNT_LIST]);
    },
  });
};

export const useCreateNewOrEditDiscount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return axiosInstance.post("v1/admin/booking-discounts", payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_DISCOUNT_LIST]);
      queryClient.invalidateQueries([GET_DISCOUNT_DETAILS]);
    },
  });
};

export const useGetDiscountDetails = (id) => {
  return useQuery({
    enabled: !!id,
    queryKey: getQueryKey.getDiscountDetailsQueryKey(id),
    queryFn: async () => {
      const response = await axiosInstance.get(`v1/admin/booking-discounts/${id}`);

      if (response?.status === 200) {
        return response?.data;
      }
      return null;
    },
  });
};
