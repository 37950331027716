import {
  Box,
  Typography,
  IconButton,
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  styled,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { useFormikContext } from "formik";
import debounce from "lodash/debounce";
import { RECIPIENTS_TYPE } from "../../pages/notifications/components/CreateNewOrEditNotification.jsx/index.jsx";
import { useGetRecipientsItems } from "../../hooks/Notifications/useNotifications.jsx";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Autocomplete } from "@material-ui/lab";

const StyledListItem = styled(ListItem)({
  "& .MuiButtonBase-root": {
    marginLeft: 0,
  },
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const TEXT = {
  ADD_TEXT: "Select a region/area",
  PLACEHOLDER_TEXT: RECIPIENTS_TYPE?.[1].placeHolder,
};

const QUANTITY_LIMITATION = 20;

const SelectRegionAreaModal = ({ onCloseModal, data }) => {
  const {
    ready,
    setValue: setSuggestionValue,
    suggestions: { data: suggestions },
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue: "",
    requestOptions: {
      language: "en-AU",
    },
  });
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const { type, formikName, initialValues } = data;
  const [items, setItems] = useState(initialValues || []);
  const [search, setSearch] = useState("");
  const { data: result, isLoading } = useGetRecipientsItems(search, type ?? "");
  const isMaxQuantity = useMemo(() => items?.length >= QUANTITY_LIMITATION, [items]);

  const handleToggle = (toggleItem) => {
    const clonedItems = [...items];
    const foundItemIndex = clonedItems.findIndex((item) => item.id === toggleItem.id);
    if (foundItemIndex > -1) {
      clonedItems.splice(foundItemIndex, 1);
    } else if (!isMaxQuantity) {
      clonedItems.push({
        id: toggleItem.id,
        label: toggleItem.label,
        ...(toggleItem.type && { type: toggleItem.type }),
      });
    }
    setItems(clonedItems);
  };

  const onHandleSave = () => {
    setFieldValue(formikName, items);
    onCloseModal();
  };

  const onChangeSearch = debounce((value) => {
    setSuggestionValue(value);
  }, 500);

  let content = <Typography style={{ textAlign: "center" }}>No results</Typography>;
  if (isLoading && search) {
    content = (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (result && result?.totalCount > 0 && search) {
    const name = `${search} (${result?.totalCount} customers)`;
    content = (
      <StyledListItem
        role={undefined}
        button
        onClick={() => handleToggle({ id: search, label: name })}
      >
        <ListItemIcon style={{ margin: 0 }}>
          <Checkbox
            color="primary"
            edge="start"
            checked={items.some(({ id }) => id === search)}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": search }}
          />
        </ListItemIcon>
        <ListItemText id={search} primary={name} />
      </StyledListItem>
    );
  }

  const onHandleChange = (e, value) => {
    if (value) {
      setSearch(value.description);
    } else {
      setSearch("");
    }
    clearSuggestions();
  };

  return (
    <>
      <IconButton onClick={onCloseModal} style={{ position: "absolute", top: 0, right: 0 }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: "grid", gap: 8, mt: 2, minWidth: 432 }}>
        <Typography style={{ fontSize: 18, fontWeight: 700, textAlign: "center" }}>
          {TEXT.ADD_TEXT}
        </Typography>
        <Box sx={{ display: "flex", gap: 8 }}></Box>
        <Autocomplete
          id="location-search-input"
          options={suggestions ?? []}
          getOptionLabel={(option) => option.description}
          onInputChange={(event, newValue) => {
            onChangeSearch(newValue);
          }}
          onChange={onHandleChange}
          renderInput={(params) => (
            <TextField
              placeholder={TEXT.PLACEHOLDER_TEXT}
              fullWidth
              style={{ marginBottom: 0, marginTop: 16 }}
              variant="outlined"
              disabled={!ready}
              {...params}
            />
          )}
        />
        {isMaxQuantity ? (
          <Box>
            <Typography style={{ textAlign: "center", marginBlock: 8 }}>
              Max Quantity: {QUANTITY_LIMITATION}
            </Typography>
          </Box>
        ) : null}
        <List className={classes.root}>{content}</List>
        {items?.length > 0
          ? items?.map((item) => {
              if (search === item.id) return null;
              return (
                <StyledListItem
                  key={item.id}
                  role={undefined}
                  button
                  onClick={() => handleToggle(item)}
                >
                  <ListItemIcon style={{ margin: 0 }}>
                    <Checkbox
                      color="primary"
                      edge="start"
                      checked={items.some(({ id }) => id === item.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": item.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={item.id} primary={item.label} />
                </StyledListItem>
              );
            })
          : null}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }}>
          <Button variant={"outlined"} color="primary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button variant={"contained"} color="primary" onClick={onHandleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SelectRegionAreaModal;
