import { Box, Divider, Typography, styled } from "@material-ui/core";

export const CommonBox = styled(Box)({
  borderRadius: 8,
  border: "1px solid rgba(226, 233, 251, 0.298)",
  background: "rgba(251, 252, 254, 0.00)",
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
});

export const StyledDivider = styled(Divider)({
  backgroundColor: "#e2e9fb4c",
});

export const ContentOfBox = styled(Box)({
  padding: "1rem",
});

export const Title = styled(Typography)({
  fontSize: 18,
  fontWeight: 700,
});
