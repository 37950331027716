import { makeStyles } from "@material-ui/styles";
const SIDE_PADDING_CONTAINER_DIALOG = "44px";
const MARGIN_TOP_CONTAINER_DIALOG = "21px";
const MARGIN_BOTTOM_CONTAINER_DIALOG = "37px";
const MARGIN_TOP_SCROLL_CONTAINER = "131px";
const MARGIN_AXIS_Y_DEFAULT_DIALOG = "64px";
const HEIGHT_BUTTON_SUBMIT = "52px";
const MARGIN_BUTTON_SUBMIT = "110px";
export const useDialogServiceChecklistStyle = makeStyles({
  containerContentDialog: {
    margin: `
      ${MARGIN_TOP_CONTAINER_DIALOG} 
      ${SIDE_PADDING_CONTAINER_DIALOG} 
      ${MARGIN_BOTTOM_CONTAINER_DIALOG} 
      ${SIDE_PADDING_CONTAINER_DIALOG}
    `,
    width: `calc(432px - 2*${SIDE_PADDING_CONTAINER_DIALOG})`,
    boxSizing: "content-box",
    height: "100%",
    overflowY: "hidden",
  },

  scrollContainerContentDialog: {
    marginTop: `${MARGIN_TOP_SCROLL_CONTAINER}`,
    maxHeight: `
      calc(100vh 
      - ${MARGIN_TOP_SCROLL_CONTAINER}
      - ${MARGIN_BOTTOM_CONTAINER_DIALOG}
      - ${MARGIN_TOP_CONTAINER_DIALOG}
      - ${MARGIN_AXIS_Y_DEFAULT_DIALOG}
      - ${HEIGHT_BUTTON_SUBMIT}
      - ${MARGIN_BUTTON_SUBMIT}
      )`,
    overflowY: "scroll",
    boxSizing: "border-box",
  },

  fixedHeaderDialog: {
    position: "fixed",
    paddingTop: "31px",
    paddingBottom: "22px",
    width: "inherit",
    boxSizing: "content-box",
  },

  iconCloseDialog: {
    position: "absolute",
    cursor: "pointer",
    right: "-14px",
    top: 0,
    zIndex: "100",
  },

  headerContentDialog: {
    fontSize: "18px",
    lineHeight: "21.48px",
    fontWeight: 700,
  },

  titleContentDialog: {
    marginTop: "18px",
    fontSize: "14px",
    lineHeight: "16.71px",
    fontWeight: 400,
    color: "#DEE7FA",
    opacity: "0.8",
  },

  itemTittle: {
    width: "calc(100% - 50px)",
    lineHeight: "19px",
    // height: "19px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  itemContent: {
    padding: "16px 0 16px 0",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    gap: "4px",
  },

  borderBottom: {
    borderBottom: "1px solid rgba(83, 95, 120, 0.3)",
  },

  borderTop: {
    borderTop: "1px solid rgba(83, 95, 120, 0.3)",
  },
  errorMessage: {
    color: "#FF4848",
    marginTop: 20,
  },
});
