import React from "react";
import TransactionHistoryList from "./components/TransactionHistoryList";
import TransactionHistoryReport from "./components/TransactionHistoryReport";
import TransactionHistoryDetail from "./components/TransactionHistoryDetail";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import UpdateOrder from "./components/UpdateOrder";

TransactionHistoryComponent.propTypes = {};

function TransactionHistoryComponent() {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={match.url} component={TransactionHistoryList} exact />
        <Route path={`${match.url}/report`} component={TransactionHistoryReport} exact />
        <Route path={`${match.url}/:id`} component={TransactionHistoryDetail} exact />
        <Route path={`${match.url}/update-order/:id`} component={UpdateOrder} exact />
      </Switch>
    </div>
  );
}

export default TransactionHistoryComponent;
