import React from "react"
import { Box, makeStyles } from "@material-ui/core"
const useStyles = makeStyles({
  root: {
    textAlign: `right`,
    display: "flex",
    justifyContent: `flex-end`,
    alignItems: "center"
  }
})
const WrapButtonFlexRight = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {props.children}
    </Box>
  )
}
export default WrapButtonFlexRight