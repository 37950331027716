import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { toast } from "react-toastify";

import App from "./App";
import { common } from "./constants/common";
import OverLayProvider from "./context/OverlayProvider";
import store from "./redux/store";
import Themes from "./themes";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || "";
const stripePromise = loadStripe(STRIPE_KEY);

const showError = (error) => {
  let message = error?.response?.data?.message ?? error?.message ?? common.SOMETHING_WENT_WRONG;
  if (typeof message !== "string") {
    message = common.SOMETHING_WENT_WRONG;
  }
  // Fallback Error Catch If we don't define onError when using useQuery
  toast.error(message);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: showError,
      staleTime: common.STALE_TIME.MIN_1,
    },
    mutations: {
      retry: false,
      onError: showError,
    },
  },
});

const root = createRoot(document.getElementById("t2y-app"));
root.render(
  <Elements stripe={stripePromise}>
    <ThemeProvider theme={Themes.default}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <OverLayProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </OverLayProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </Elements>
);
