import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { PieGraph } from "../../../graphs";

export function LateHiresGraph() {
  const { url } = useRouteMatch();
  const { lateHiresData } = useSelector((state) => state.analytic);

  return (
    <Card headerSize="small" header="Late Hires %" navigateTo={`${url}/late-hires`}>
      <PieGraph percentage={lateHiresData?.percentage} outOfString="Total Hires" />
    </Card>
  );
}
