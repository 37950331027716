import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import SortableTable from "../../../../../components/SortableTable/SortableTable";
import { Card } from "../../../components";
import { formatHour } from "../../../utils";

export function TrailerUtilisationReport() {
  const { url } = useRouteMatch();
  const { trailerUtilisationReport } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (trailerUtilisationReport?.items) {
      const dataTransformed = [...trailerUtilisationReport?.items]
        .sort((a, b) => b?.income - a?.income)
        .map((item) => ({
          trailerRego: item?.registration ?? "--",
          trailerType: item?.trailer ?? "--",
          franchisee: item?.franchisee ?? "--",
          inService: item?.inService ?? "--",
          totalHireTime: formatHour(item?.totalHireDuration || 0),
          utilisation: item?.utilisation ?? "--",
        }));
      setData(dataTransformed);
    }
  }, [trailerUtilisationReport]);

  const columns = [
    {
      name: "Trailer Rego",
      key: "trailerRego",
      sortable: true,
    },
    {
      name: "Trailer type",
      key: "trailerType",
      sortable: true,
    },
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "In service",
      key: "inService",
      sortable: true,
    },
    {
      name: "Total hire time",
      key: "totalHireTime",
      sortable: true,
    },
    {
      name: "Utilisation",
      key: "utilisation",
      sortable: true,
    },
  ];

  return (
    <Card
      header="Trailer Utilisation Report"
      navigateTo={`${url}/trailer-utilisation-report`}
    >
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="10% 30% 30% 10% 10% 10%"
        rowsHeight={255}
      />
    </Card>
  );
}
