import React, { useEffect, useState } from "react";
import { Card } from "../../../components";
import { formatAmount } from "../../../utils";
import { useSelector } from "react-redux";
import SortableTable from '../../../../../components/SortableTable/SortableTable';

export function AvgIncomePerTrailerType() {
  const { incomePerTrailerData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (incomePerTrailerData?.items) {
      const dataTransformed = incomePerTrailerData.items.map((item) => ({
        name: item.trailer.name,
        totalSales: formatAmount(item.grossIncome, 0),
        totalHires: item.totalHires,
        averageIncome: formatAmount(item.grossIncome / item.totalHires, 0),
      }));
      setData(dataTransformed);
    }
  }, [incomePerTrailerData]);
  
  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
    {
      name: "Average Income",
      key: "averageIncome",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Average Income Per Trailer Type"
      navigateTo={`/analytics/avg-inc-per-trailer`}
      style={{ maxHeight: 300 }}
    >
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="40% 20% 20% 20%"
        rowsHeight={255}
      />
    </Card>
  );
}
