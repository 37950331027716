import { Box, Grid } from "@material-ui/core";
import * as React from "react";
import { useLayoutEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { Menu } from "../../components";
import {
  AddonsAndConsumables,
  AddonsAndConsumablesGraph,
  DamageWaiverUtilisationRateGraph,
  DamageWaiverUtilisationReport,
  ExtHiresReport,
  ExtHiresReportGraph,
  LateHires,
  LateHiresGraph,
  OutOfServiceTrailers,
  OutOfServiceTrailersGraph,
  TrailerUtilisationReport,
  TrailerUtilisationReportGraph,
} from "../../tabs/Reports";
import { useStyles } from "./ReportsPage.styles";
import {
  AvgDropOffDistance,
  AvgDropOffDistanceGraph,
  AvgPickUpDistance,
  AvgPickUpDistanceGraph,
} from "../../tabs/Reports/AvgDistance";
import {
  AvgFranchiseeIncomePerHireGraph,
  AvgFranchiseeIncomePerHireReport,
} from "../../tabs/Reports/AvgFranchiseeIncomePerHire";

export function ReportsPage() {
  const classes = useStyles();

  useLayoutEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Analytics";
  });

  return (
    <Box component="main" className={classes.analyticsContent}>
      <Breadcrumbs />
      <Menu overviewTabStyles={classes.overviewTab} reportsTabStyles={classes.reportsTab} />

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <AddonsAndConsumablesGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <AddonsAndConsumables />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <AvgFranchiseeIncomePerHireGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <AvgFranchiseeIncomePerHireReport />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <OutOfServiceTrailersGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <OutOfServiceTrailers />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <LateHiresGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <LateHires />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <ExtHiresReportGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <ExtHiresReport />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <TrailerUtilisationReportGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <TrailerUtilisationReport />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <DamageWaiverUtilisationRateGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <DamageWaiverUtilisationReport />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <AvgDropOffDistanceGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <AvgDropOffDistance />
        </Grid>
      </Grid>
      <Grid container spacing={7} className={classes.section}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <AvgPickUpDistanceGraph />
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <AvgPickUpDistance />
        </Grid>
      </Grid>
    </Box>
  );
}
