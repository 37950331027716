import React, { useState, useEffect } from "react";
import { imageItemStyles } from "./style";
import { Box, Button, Typography } from "@material-ui/core";
import { isImgFile } from "../../helpers/verify";
import { ReactComponent as Image } from "../../img/image.svg";
import { ReactComponent as PreviousArrow } from "../../img/previousArrow.svg";
import { ReactComponent as FowardArrow } from "../../img/fowardArrow.svg";
import { ReactComponent as CloneAvatar } from "../../img/cloneAvatar.svg";
import ButtonActionSubPage from "../ButtonActionSubPage";
import { nonAvatarStyle, avatarStyle, imageStyle } from "./style";
const TemplateImageItemLicensee = ({
  dataImageRef,
  photos,
  upload = true,
  avatar = false,
}) => {
  const [previewImage, setPreviewImage] = useState({
    url: [],
    info: [],
    current: 0,
  });
  async function AddImage(event) {
    // ADD MULTI
    if (event.target.files && isImgFile(event.target.files[0])) {
      const FILE_URL_LOCALSTORAGE = Array.from(event.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const FILE_INFO = Array.from(event.target.files);
      // image will add on list 
      dataImageRef.current = [...previewImage.info, ...FILE_INFO];
      setPreviewImage((prevState) => ({
        url: [...prevState.url, ...FILE_URL_LOCALSTORAGE],
        info: [...prevState.info, ...FILE_INFO],
        current: 0,
      }));
    }
  }

  function handleChangeCurrentImage(type) {
    action[type]();
  }

  function handlePreviousImage() {
    setPreviewImage((prevState) => ({
      ...prevState,
      current:
        prevState.current === 0
          ? previewImage.url.length - 1
          : prevState.current - 1,
    }));
  }

  function handleNextImage() {
    setPreviewImage((prevState) => ({
      ...prevState,
      current:
        prevState.current === previewImage.url.length - 1
          ? 0
          : prevState.current + 1,
    }));
  }

  function componentPreviewImageDependOnProps(img) {
    if (avatar) {
      return <img src={img.url[img.current]} style={{ ...avatarStyle }} />;
    }
    return <img src={img.url[img.current]} style={{ ...imageStyle }} />;
  }

  function componentNonImageDependOnProps() {
    if (avatar) {
      return <CloneAvatar style={{ ...nonAvatarStyle }} />;
    }
    return (
      <Box
        {...imageStyle}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image />
      </Box>
    );
  }
  
  useEffect(() => {
    if (photos?.length > 0) {
      setPreviewImage({ ...previewImage, url: [...photos] });
    }
  }, [photos]);
  
  const action = {
    back: () => handlePreviousImage(),
    next: () => handleNextImage(),
  };
  const { url } = previewImage;
  const cssImageItemLicensee = imageItemStyles();
  return (
    <Box classes={cssImageItemLicensee}>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent={url.length <= 1 ? "center" : "space-between"}
      >
        {url.length > 1 && !avatar && (
          <Button
            onClick={() => {
              previewImage.url?.length > 1 && handleChangeCurrentImage("back");
            }}
          >
            <PreviousArrow />
          </Button>
        )}
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          margin="0 25px 40px 25px"
          marginBottom="40px"
        >
          {url.length !== 0
            ? { ...componentPreviewImageDependOnProps(previewImage) }
            : { ...componentNonImageDependOnProps() }}
        </Box>
        {url?.length > 1 && !avatar && (
          <Button
            onClick={() => {
              url.length > 1 && handleChangeCurrentImage("next");
            }}
          >
            <FowardArrow />
          </Button>
        )}
      </Box>
      {upload && (
        <>
          <input
            accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
            multiple={!avatar}
            style={{ display: "none" }}
            id="jpg-file"
            type="file"
            onChange={AddImage}
          />
          <label htmlFor="jpg-file">
            <ButtonActionSubPage
              minWidth="360px"
              height="52px"
              component="span"
            >
              Upload Photo
            </ButtonActionSubPage>
          </label>
          <Typography align="center" style={{ marginTop: "13px" }}>
            PNG, JPG
          </Typography>
        </>
      )}
    </Box>
  );
};
export default TemplateImageItemLicensee;
