import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatAmount } from "../../utils";

export function FranchiseePerfPage() {
  const classes = useStyles();
  const { incomePerLicenseeData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Franchisee Performance';
    if (incomePerLicenseeData?.items) {
      const dataTransformed = [...incomePerLicenseeData.items]
        .sort((a, b) => b.grossIncome - a.grossIncome)
        .slice(0, 5)
        .map((item) => ({
          name: item.licensee.fullname,
          totalSales: formatAmount(item.grossIncome, 0),
          totalHires: item.totalHires,
        }));
      setData(dataTransformed);
    }
  }, [incomePerLicenseeData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Franchisee Performance">
        <SortableTable
          rows={data}
          columns={columns}
          hasIndexColumn
          gridTemplateColumns="10% 45% 25% 20%"
        />
      </Card>
    </Box>
  );
}
