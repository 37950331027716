const style = {
  header: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "8px",
    lineHeight: "21.48px",
    textTransform: "capitalize "
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "23px",
  },
  phone: {
    lineHeight: "23px",
  },
  container: {
    padding: "40px 62px",
    width: "fit-content",
    margin: "0 auto",
    border: "2px dashed #FF0000",
    borderRadius: "4px",
  },
};
export default style