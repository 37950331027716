import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  analyticsCard: {
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1C1C1C",
    borderRadius: 16,
    position: "relative",
    "& > header": {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "29px",
      color: "#fff",
      display: "grid",
      gridTemplateColumns: "1fr 71px",
      "& > .MuiButton-root": {
        width: 71,
        height: 27,
        border: "1px solid #39A8DF",
        borderRadius: 4,
        "& > .MuiButton-label": {
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 14,
          lineHeight: "17px",
          color: "#39A8DF",
        },
      },
    },
    "& > small": {
      marginTop: 8,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19px",
      color: "#44D62C",
    },
    "& > div": {
      marginTop: 16,
    },
  },
}));
