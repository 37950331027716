import React from "react";
import { TextField, withStyles } from "@material-ui/core";

const DynamicTextField = withStyles({
  root: {
    borderRadius: "7px",
    marginBottom: "24px",
    "& .MuiInputBase-root": {
      boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? 0 : "16px"),
      margin: (props) => (!props.multiline ? 0 : "16px"),
      lineHeight: "19.09px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
    },
    "& .MuiInputLabel-outlined": {
      color: "#9297a4",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& .MuiSelect-iconOutlined": {
      right: "30px",
    },
    "& .MuiFormLabel-root": {
      color: "white",
    },
  },
})((props) => {
  return <TextField variant="outlined" {...props} />;
});

export default DynamicTextField;
