import { Paper, useTheme } from "@material-ui/core";
import React from "react";

const StickyNavigationSection = (props) => {
  const additionalStyled = props?.isBottom ? { bottom: 0 } : { top: 0 };
  const theme = useTheme();
  return (
    <Paper
      style={{
        padding: "0 20px",
        borderRadius: 0,
        height: "auto",
        zIndex: theme.zIndex.appBar,
        position: "fixed",
        left: 0,
        right: 0,
        backgroundColor: "#202020",
        ...additionalStyled,
        ...props?.customStyled,
      }}
      elevation={0}
    >
      {/* <Container maxWidth="sm"> */}
      {props.children}
      {/* </Container> */}
    </Paper>
  );
};

export default StickyNavigationSection;
