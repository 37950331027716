import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "../../../components";
import { BarGraph } from "../../../graphs";

const chartLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function OrdersByDayOfTheWeek() {
  const { ordersByDayOfTheWeek } = useSelector((state) => state.analytic);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (ordersByDayOfTheWeek && ordersByDayOfTheWeek?.length > 0) {
      const data = ordersByDayOfTheWeek?.map((item) => item?.total ?? 0);
      setChartData(data);
    }
  }, [ordersByDayOfTheWeek]);

  return (
    <Card header="Orders by Day of the Week" onClick={() => {}}>
      <BarGraph
        labels={chartLabels}
        datasets={[
          {
            label: "Number of Orders",
            data: chartData ?? [],
            backgroundColor: "#44D62C",
            borderRadius: 4,
          },
        ]}
      />
    </Card>
  );
}
