import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatHour } from "../../utils";

export function AvgHireLengthPerTrailerPage() {
  const classes = useStyles();
  const { averageHireLength } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Average Hire Length Per Trailer";
    if (averageHireLength?.items) {
      const dataTransformed = averageHireLength.items.map((item) => ({
        name: item?.trailer?.name ?? "--",
        totalHireLength: formatHour(item.totalHireDuration),
        averageHireLength: formatHour(item.averageHireDuration),
      }));
      setData(dataTransformed);
    }
  }, [averageHireLength]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Hire Length",
      key: "totalHireLength",
      sortable: true,
    },
    {
      name: "Average Hire Length",
      key: "averageHireLength",
      sortable: true,
    },
  ];

  return (
    <>
      <Box component="main" className={classes.analyticsSinglePageContent}>
        <Card header="Average Hire Length Per Trailer Type">
          <SortableTable rows={data} columns={columns} gridTemplateColumns="40% 20% 20% 20%" />
        </Card>
      </Box>
    </>
  );
}
