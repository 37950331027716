import React from "react";
import { Field } from "formik";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    minHeight: 51,
  },
}));

const Select = (props) => {
  const classes = useStyles();
  const { name, label, options, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel htmlFor="age-native-simple">{label}</InputLabel>
            <MuiSelect
              variant="outlined"
              {...field}
              inputProps={{
                id: `${name}-native-simple`,
                onBlur: form.handleBlur,
              }}
              {...rest}
              onChange={(e) => {
                field.onChange(e);
              }}
              style={{
                borderRadius: 7,
                minHeight: 51,
              }}
              error={Boolean(meta.error)}
            >
              {options?.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </MuiSelect>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default Select;
