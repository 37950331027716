import React, { useState, useEffect, useMemo } from "react";
import axiosInstance from "../../../helpers/axios";
import WrapHeaderSubPage from "../../../components/WrapHeaderSubPage";
import DialogTransactionReportEmail from "./DialogTransactionReportEmail";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { Button, MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { useLoading } from "../../../hooks/useLoading";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isValidDateInstance } from "../../../helpers/verify";
import { getFranchiseeTransactionHistoryReportPDF } from "../../../helpers/api";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";

TransactionHistoryReport.propTypes = {};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 612,
    "& > div > div:nth-child(2)": {
      borderRadius: "8px",
      minHeight: "51px",
    },
  },
  labelOutlined: {
    transform: "translate(14px,12px)",
  },
  optionItem: {
    backgroundColor: "#000 !important",
    color: "#fff",
  },
  dateContainer: {
    margin: "16px 8px 39px",

    "& > div:nth-child(1)": {
      minWidth: "300px",
      padding: "4px 4px 4px 10px",
      margin: "0 12px 0 0",
      border: "1px solid #fff",

      "& > label": {
        marginLeft: "10px",
      },

      "& > div": {
        marginTop: "10px",
      },
    },

    "& > div:nth-child(2)": {
      minWidth: "300px",
      padding: "4px 4px 4px 10px",
      border: "1px solid #fff",
      margin: "0",

      "& > label": {
        marginLeft: "10px",
      },

      "& > div": {
        marginTop: "10px",
      },
    },
  },
  actionContainer: {
    margin: "0 8px",
    display: "flex",
    gap: "22px",
  },
  actionBtn: {
    minWidth: 233,
    minHeight: 41,
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  keyboardDatePicker: {
    borderRadius: "8px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "80%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const validationSchema = Yup.object({
  fromDate: Yup.date()
    .required()
    .typeError("Invalid date value")
    .when("toDate", (value, schema) =>
      isValidDateInstance(value)
        ? schema.max(value, "Date from can not be larger than date to")
        : undefined
    ),
  toDate: Yup.date().required().nullable(true).typeError("Invalid date value"),
  franchiseeId: Yup.string().required(),
});

const getDefaultValue = () => {
  const currentDate = new Date();
  const firstDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  return {
    fromDate: firstDateOfMonth,
    toDate: currentDate,
    franchiseeId: "",
  };
};

function TransactionHistoryReport() {
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  const [submitMode, setSubmitMode] = useState("downloadPDF");
  const [franchiseeList, setFranchiseeList] = useState([]);
  const [showSendEmailDialog, setShowSendEmailDialog] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (pdfFile) {
      setOpen(true);
    }
  }, [pdfFile]);

  const handleClose = () => {
    setPdfFile(null);
    setOpen(false);
  };

  const submitHandler = {
    viewPDF: async () => {
      try {
        showLoading();
        const res = await getFranchiseeTransactionHistoryReportPDF(values);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        setPdfFile(url);
      } catch (err) {
        toast.error("Error in downloading report");
      } finally {
        hideLoading();
      }
    },
    downloadPDF: async (values) => {
      try {
        showLoading();
        const res = await getFranchiseeTransactionHistoryReportPDF(values);
        const fileName = `report-${
          values.franchiseeId
        }-${values.fromDate.toLocaleDateString()}-${values.toDate.toLocaleDateString()}.pdf`;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        toast.error("Error in downloading report");
      } finally {
        hideLoading();
      }
    },
    sendReportEmail: async (/*values*/) => {
      setShowSendEmailDialog(true);
    },
  };

  const { handleSubmit, values, handleChange, setFieldValue, errors } = useFormik({
    initialValues: getDefaultValue(),
    validationSchema,
    onSubmit: submitHandler[submitMode],
  });

  const selectedFranchisee = useMemo(() => {
    const franchisee = franchiseeList.find(({ value }) => values.franchiseeId === value);
    if (!franchisee) return null;
    return {
      id: franchisee.value,
      name: franchisee.label,
    };
  }, [values.franchiseeId]);

  const getData = async () => {
    try {
      showLoading();
      const franchiseQueryData = await axiosInstance
        .get("/v1/admin/licensee/all")
        .then((res) => res.data);

      const franchiseListOptions = franchiseQueryData?.licenseeList?.map(
        ({ licenseeId, fullname }) => ({
          label: fullname,
          value: licenseeId._id,
        })
      );

      setFranchiseeList(franchiseListOptions);
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading();
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div>
      <WrapHeaderSubPage nameHeader="Export Report" style={{ marginBottoDatem: "50px" }} />
      <Breadcrumbs />

      <FormControl
        variant="outlined"
        className={classes.formControl}
        helperText={errors.franchiseeId}
        error={!!errors.franchiseeId}
      >
        <InputLabel classes={{ outlined: classes.labelOutlined }}>Select a franchisee</InputLabel>
        <Select
          id="outlined-select-franchisee-native"
          label="Select a franchisee"
          value={values.franchiseeId}
          name="franchiseeId"
          onChange={handleChange}
          variant="outlined"
        >
          {franchiseeList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.dateContainer}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Select a date from"
            format="dd/MM/yyyy"
            value={values.fromDate}
            onChange={(date) => setFieldValue("fromDate", date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            className={classes.keyboardDatePicker}
            helperText={errors.fromDate}
            error={!!errors.fromDate}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Select a date to"
            format="dd/MM/yyyy"
            value={values.toDate}
            onChange={(date) => setFieldValue("toDate", date)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            className={classes.keyboardDatePicker}
            helperText={errors.toDate}
            error={!!errors.toDate}
          />
        </div>
      </MuiPickersUtilsProvider>

      <div className={classes.actionContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          onClick={() => {
            setSubmitMode("viewPDF");
            handleSubmit();
          }}
        >
          View Report
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          onClick={() => {
            setSubmitMode("downloadPDF");
            handleSubmit();
          }}
        >
          Download Report
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          onClick={() => {
            setSubmitMode("sendReportEmail");
            handleSubmit();
          }}
        >
          Send Report Via Email
        </Button>
      </div>
      <div>
        <DialogTransactionReportEmail
          franchisee={selectedFranchisee}
          values={values}
          isOpen={showSendEmailDialog}
          onCloseDialog={() => setShowSendEmailDialog(false)}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {pdfFile && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  defaultScale={1}
                  fileUrl={pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default TransactionHistoryReport;
