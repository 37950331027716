import React from "react";
import { Typography, makeStyles, Radio } from "@material-ui/core";
const ItemTrailerFeatured = ({ name, urlImage, isChecked, trailer, onSelectTrailer }) => {
  const classes = useStyles();
  return (
    <div className={classes.itemAddTrailer}>
      <img src={urlImage} alt="Imgage" className={classes.imageTrailer} width="87" height="65" />
      <Typography variant="h6" className={classes.nameTrainer}>
        {name}
      </Typography>
      <div className="count-upsell-item">
        <Radio color="primary" checked={isChecked} onClick={() => onSelectTrailer(trailer)} />
      </div>
    </div>
  );
};

export default ItemTrailerFeatured;
const useStyles = makeStyles(() => ({
  itemAddTrailer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: "92px",
    borderTop: "2px solid #535f78",
  },
  imageTrailer: {
    marginLeft: 8,
    objectFit: "contain",
  },
  nameTrainer: {
    flex: 1,
    marginLeft: 16,
  },
  countUpsellItem: {
    padding: 6,
    "&.MuiIconButton-root.Mui-disabled": {
      color: "rgba(255,255,255,0.5) !important",
    },
  },
  iconCount: { fontSize: 28 },
  countUpsell: {
    padding: 6,
  },
}));
