import React from "react";
import { Typography, makeStyles, IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import themes from "../../themes";
const ItemAddTrailer = ({
  handleSubQuantity,
  handleAddQuantity,
  name,
  urlImage,
  quantity,
  min,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.itemAddTrailer}>
      <img
        src={urlImage}
        alt="Imgage"
        className={classes.imageTrailer}
        width="87"
        height="65"
      />
      <Typography variant="h6" className={classes.nameTrainer}>
        {name}
      </Typography>
      <div className="count-upsell-item">
        <IconButton
          disabled={quantity === min || quantity === 0}
          className={classes.countUpsellItem}
          onClick={handleSubQuantity}
        >
          <RemoveCircleOutlineIcon className={classes.iconCount} />
        </IconButton>
        <Typography
          variant="h6"
          style={{
            color: quantity > 0 ? themes.default.palette.primary.main : "#fff",
          }}
          className={classes.countUpsell}
        >
          {quantity}
        </Typography>
        <IconButton
          className={classes.countUpsellItem}
          onClick={handleAddQuantity}
        >
          <AddCircleOutlineIcon className={classes.iconCount} />
        </IconButton>
      </div>
    </div>
  );
};

export default ItemAddTrailer;
const useStyles = makeStyles(() => ({
  itemAddTrailer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: "92px",
    borderTop: "2px solid #535f78",
  },
  imageTrailer: {
    marginLeft: 8,
  },
  nameTrainer: {
    flex: 1,
    marginLeft: 16,
  },
  countUpsellItem: {
    padding: 6,
    "&.MuiIconButton-root.Mui-disabled": {
      color: "rgba(255,255,255,0.5) !important",
    },
  },
  iconCount: { fontSize: 28 },
  countUpsell: {
    padding: 6,
  },
}));
