import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import getQueryKey, { GET_TRAILERS } from "../../helpers/getQueryKey";
import axiosInstance from "../../helpers/axios";
import { toast } from "react-toastify";

export const useGetTrailers = () => {
  return useQuery({
    queryKey: getQueryKey.getTrailersQueryKey(),
    queryFn: () => axiosInstance.get(`v1/admin/get-all-features`),
  });
};

export const useAddNewTrailer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => axiosInstance.post("v1/admin/trailers/featured", payload),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TRAILERS]);
    },
  });
};

export const usDeleteTrailer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => axiosInstance.delete(`v1/admin/trailers/featured/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_TRAILERS]);
      toast.success("Deleted Trailer");
    },
  });
};
