import secureLocalStorage from "react-secure-storage";

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getFromLocalStorage = (key) => {
  const value = secureLocalStorage.getItem(key);
  return isJsonString(value) ? JSON.parse(value) : value;
};

export const copyAndSort = (items, key, isDescending) => {
  return items.slice(0).sort((a, b) => {
    /*
      Convert value for number with separator, decimal, percentage, dollar sign.
      Ex:
        $100,100.33
        3,123.30
        30%
    */
    const aValueConverted = isNaN(a[key]) ? a[key]?.replace(/(,|\.|\$|%)*/g, "") : a[key];
    const bValueConverted = isNaN(b[key]) ? b[key]?.replace(/(,|\.|\$|%)*/g, "") : b[key];
    const compare1 = isNaN(aValueConverted) ? aValueConverted : +aValueConverted;
    const compare2 = isNaN(bValueConverted) ? bValueConverted : +bValueConverted;
    return (isDescending ? compare1 < compare2 : compare1 > compare2) ? 1 : -1;
  });
};

export const isCurrency = (string) => {
  return string.match(/^\$?(\d{1,3}(,\d{3})*|(\d+))(\.\d*)?\$?$/g);
};

export const formatNameToLowercaseAndReplaceSpace = (string, replaceSpaceString = "_") => {
  return string.toLowerCase().replaceAll(" ", replaceSpaceString);
};
export const convertObjectIntoQueryString = (object = {}) => {
  return Object.keys(object)
    .filter((key) => object[key] !== undefined && object[key] !== null && object[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(object[key])}`)
    .join("&");
};

export const getIsAllowByUserRole = (roles) => {
  const user = getFromLocalStorage("user");
  return roles ? roles.includes(user?.role || "VIEWER") : true;
};

export const getCRUD_AllowByRoles = ({ C_Roles, R_Roles, U_Roles, D_Roles }) => {
  return {
    isAllowToCreate: getIsAllowByUserRole(C_Roles),
    isAllowToRead: getIsAllowByUserRole(R_Roles),
    isAllowToUpdate: getIsAllowByUserRole(U_Roles),
    isAllowToDelete: getIsAllowByUserRole(D_Roles),
  };
};
