import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  subHeader: {
    /* Styles to make the sticky header styles work on the adjacent table component */
    "& + div > div": {
      overflowX: "unset !important",
    },
    "& + div > div > div > div": {
      overflowY: "unset !important",
    },
  },
}));

const WrapHeaderSubPage = (props) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={props.flexDirection ? "column" : "row"}
      className={classes.subHeader}
    >
      <Typography variant="h2" component="h2" style={{ textTransform: "capitalize" }}>
        {props.nameHeader}
      </Typography>
      {props.children}
    </Box>
  );
};

export default WrapHeaderSubPage;
