import React, { useState } from "react";
import LoadingOverlay from "../../components/Loading";
export const OverLayContext = React.createContext({});

function OverLayProvider(props) {
  const [loading, setLoading] = useState(false);

  return (
    <OverLayContext.Provider
      value={{
        setLoading,
        loading,
      }}
    >
      {loading && <LoadingOverlay />}
      {props.children}
    </OverLayContext.Provider>
  );
}

export default OverLayProvider;
