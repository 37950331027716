import React from "react";

export const Image = ({ height = 128, width = 154 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 154 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2020:1527)">
      <path
        d="M0 24.1812V127.422H127.99V24.1812H0ZM28.4772 38.6668C30.8885 38.6668 33.2456 39.3818 35.2505 40.7215C37.2554 42.0611 38.818 43.9652 39.7408 46.1929C40.6636 48.4207 40.905 50.872 40.4346 53.237C39.9642 55.6019 38.803 57.7743 37.098 59.4793C35.3929 61.1843 33.2206 62.3455 30.8556 62.8159C28.4907 63.2863 26.0393 63.0449 23.8116 62.1221C21.5839 61.1994 19.6798 59.6367 18.3402 57.6318C17.0005 55.6269 16.2855 53.2698 16.2855 50.8585C16.2855 47.6251 17.57 44.5241 19.8564 42.2377C22.1427 39.9513 25.2437 38.6668 28.4772 38.6668ZM118.166 118.368H9.82391L47.9197 74.2793L69.7877 96.1473L103.796 56.1362L118.157 70.4999L118.166 118.368Z"
        fill="#44D62C"
      />
      <path
        d="M141.176 102.006H133.983V19.0575H27.2356V11.8644H141.176V102.006Z"
        fill="#44D62C"
      />
      <path
        d="M154 90.1413H146.807V7.18986H40.0593V-1.52588e-05H154V90.1413Z"
        fill="#44D62C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2020:1527">
        <rect width="154" height="127.422" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
