import "./App.scss";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import { Container } from "@material-ui/core";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";

import AppHeader from "./components/AppHeader";
import { PrivateRoute } from "./components/PrivateRoute";
import RatingStarUser from "./components/RatingStarUser";
import Page404 from "./pages/404";
import AddOrEditAdminUser from "./pages/admin_user/AddOrEditAdminUser";
import AdminUserList from "./pages/admin_user/AdminUserList";
import Analytics from "./pages/analytics";
import CustomerList from "./pages/customer/CustomerList.jsx";
import AcceptInvite from "./pages/employee/AcceptInvite";
import ForgotPassword from "./pages/employee/ForgotPassword";
import ResetEmail from "./pages/employee/ResetEmail";
import ResetPassword from "./pages/employee/ResetPassword";
import SetPassword from "./pages/employee/SetPassword";
import SignIn from "./pages/employee/SignIn";
import VerifyAccount from "./pages/employee/VerifyAccount";
import AddOrChangeTrailerFeatures from "./pages/featured/AddOrChangeTrailerFeatures";
import Featured from "./pages/featured/Featured";
import LicenseeAdd from "./pages/licensee/LicenseeAdd";
import LicenseeEdit from "./pages/licensee/LicenseeEdit";
import LicenseeList from "./pages/licensee/LicenseeList";
import AddTrailerLicensee from "./pages/licensee_rentalitems/AddTrailerLicensee";
import AddUpsellLicensee from "./pages/licensee_rentalitems/AddUpsellLicensee";
import EditTrailerItemInLicensee from "./pages/licensee_rentalitems/EditTrailerItemInLicensee";
import EmployeeListInLicensee from "./pages/licensee_rentalitems/EmployeeListInLicensee";
import EmployeesIdentification from "./pages/licensee_rentalitems/EmployeesIdentification";
import Identification from "./pages/licensee_rentalitems/Identification";
import ItemDocumentOfLicensee from "./pages/licensee_rentalitems/ItemDocumentOfLicensee";
import NewDocumentListInLicensee from "./pages/licensee_rentalitems/NewDocumentListInLicensee";
import TrailerListInLicensee from "./pages/licensee_rentalitems/TrailerListInLicensee";
import TrailerServiceInLicensee from "./pages/licensee_rentalitems/TrailerServiceInLicensee";
import UpSellDetailListInLicensee from "./pages/licensee_rentalitems/UpSellDetailListInLicensee";
import UpSellListInLicensee from "./pages/licensee_rentalitems/UpSellListInLicensee";
import GlobalHireRatePage from "./pages/rental/GlobalHireRatePage";
import TrailerAdd from "./pages/rental/TrailerAdd";
import TrailerEdit from "./pages/rental/TrailerEdit";
import TrailerList from "./pages/rental/TrailerList";
import UpsellAdd from "./pages/rental/UpsellAdd";
import UpsellEdit from "./pages/rental/UpsellEdit";
import UpsellList from "./pages/rental/UpsellList";
import TransactionHistoryComponent from "./pages/transaction";
import UpcomingOrders from "./pages/upcoming_orders/UpcomingOrders";
import DiscountsAndPromotionsComponent from "./pages/discounts_promotions";
import NotificationsComponent from "./pages/notifications/index.jsx";

//auth
//feature
//customer
//licensee
//sub page
// ADMIN USER
//rental
//transaction-history
// import TransactionHistoryList from "./pages/transaction/TransactionHistoryList";
// analytics
export const routes = [
  // FEATURED
  {
    path: "/",
    component: Featured,
    exact: true,
    name: "Home",
  },
  {
    path: "/customers",
    component: CustomerList,
    exact: true,
    name: "Customer List",
  },
  {
    path: "/licensee/rating-star/:licenseeId",
    component: RatingStarUser,
    exact: true,
    name: "Rating Star User",
  },

  {
    path: "/licensee",
    component: LicenseeList,
    exact: true,
    name: "Franchisee List",
  },

  {
    path: "/licensee/:firstname/:licenseeId/employee/list",
    component: EmployeeListInLicensee,
    exact: true,
    name: "Employee List",
  },
  {
    path: "/licensee/:licenseeId/trailer/edit/:trailerId",
    component: EditTrailerItemInLicensee,
    exact: true,
    name: "Edit Trailer",
  },
  {
    path: "/licensee/:firstname/:licenseeId/upsellitems",
    component: UpSellListInLicensee,
    exact: true,
    name: "Upsell Items",
  },
  {
    path: "/licensee/:firstname/:licenseeId/upsellitems/:upsellItemId",
    component: UpSellDetailListInLicensee,
    exact: true,
    name: "Upsell Detail List",
  },
  {
    path: "/licensee/:firstname/:licenseeId/trailers",
    component: TrailerListInLicensee,
    exact: true,
    name: "Trailer List",
  },
  {
    path: "/licensee/:licenseeId/trailers-service/:trailerId",
    component: TrailerServiceInLicensee,
    exact: true,
    name: "Trailer Service",
  },
  {
    path: "/licensee/:licenseeId/docs",
    component: NewDocumentListInLicensee,
    exact: true,
    name: "Document List",
  },
  {
    path: "/licensee/:licenseeId/licensee-upcoming-orders",
    component: UpcomingOrders,
    exact: true,
    name: "Upcoming Orders",
  },
  {
    path: "/licensee/:licenseeId/docs/identification",
    component: Identification,
    exact: true,
    name: "Identification",
  },
  {
    path: "/licensee/:licenseeId/docs/employees-identification",
    component: EmployeesIdentification,
    exact: true,
    name: "Employees Identification",
  },
  {
    path: "/licensee/:licenseeId/docs/business-registration",
    component: ItemDocumentOfLicensee,
    exact: true,
    name: "Document Of Licensee",
  },
  {
    path: "/licensee/:licenseeId/docs/business-insurance",
    component: ItemDocumentOfLicensee,
    exact: true,
    name: "Document Of Licensee",
  },
  {
    path: "/licensee/:licenseeId/docs/proof-of-incorporation",
    component: ItemDocumentOfLicensee,
    exact: true,
    name: "Document Of Licensee",
  },
  {
    path: "/licensee/:licenseeId/docs/trailer-registration",
    component: ItemDocumentOfLicensee,
    exact: true,
    name: "Document Of Licensee",
  },
  {
    path: "/admin",
    component: AdminUserList,
    exact: true,
    name: "Admin User",
  },
  {
    path: "/admin/add",
    component: AddOrEditAdminUser,
    exact: true,
    name: "Add",
  },
  {
    path: "/admin/:userId",
    component: AddOrEditAdminUser,
    exact: true,
    name: "Edit",
  },
  {
    path: "/rental/trailer",
    component: TrailerList,
    exact: true,
    name: "Trailer List",
  },
  {
    path: "/rental/trailer/add",
    component: TrailerAdd,
    exact: true,
    name: "Add",
  },
  {
    path: "/rental/trailer/edit/:id",
    component: TrailerEdit,
    exact: true,
    name: "Edit",
  },
  {
    path: "/rental/upsell",
    component: UpsellList,
    exact: true,
    name: "Upsell Items",
  },
  {
    path: "/rental/upsell/add",
    component: UpsellAdd,
    exact: true,
    name: "Add",
  },
  {
    path: "/rental/upsell/edit/:id",
    component: UpsellEdit,
    exact: true,
    name: "Edit",
  },
  {
    path: "/rental/hire-the-driver",
    component: GlobalHireRatePage,
    exact: true,
    name: "Global Hire Rate",
  },
  {
    path: "/transaction-history",
    component: TransactionHistoryComponent,
    // exact: true,
    name: "Transaction History",
  },
  {
    path: "/upcoming-orders",
    component: UpcomingOrders,
    exact: true,
    name: "Upcoming Orders",
  },
  {
    path: "/analytics",
    component: Analytics,
    name: "Analytics",
  },
  {
    path: "/discounts-and-promotions",
    component: DiscountsAndPromotionsComponent,
    name: "Discounts & Promotions",
  },
  {
    path: "/notifications",
    component: NotificationsComponent,
    name: "Notifications",
  },
];

const mainContainerStyle = {
  marginTop: "55px",
  padding: "24px 60px 0",
  width: "100%",
  maxWidth: "100%",
  boxSizing: "border-box",
};

export default function App() {
  toast.configure({
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return (
    <Router>
      <Switch>
        <Route path="/signin" exact>
          <SignIn></SignIn>
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/reset-password/:id" exact>
          <ResetPassword />
        </Route>
        <Route path="/reset-email/:id" exact>
          <ResetEmail />
        </Route>
        <Route path="/accept-invite/:id">
          <AcceptInvite />
        </Route>
        <Route path="/set-password/:id" exact>
          <SetPassword />
        </Route>
        <PrivateRoute component={LicenseeAdd} path="/licensee/signup" exact />
        <PrivateRoute component={LicenseeEdit} path="/licensee/signup/:id" exact />
        <PrivateRoute
          component={AddTrailerLicensee}
          path="/licensee/:firstname/:licenseeId/trailer/add"
          exact
        />
        <PrivateRoute
          component={AddUpsellLicensee}
          path="/licensee/:firstname/:licenseeId/upsellitems/add"
          exact
        />
        <PrivateRoute
          component={AddOrChangeTrailerFeatures}
          path="/featured-trailer/:featureId"
          exact
        />
        <PrivateRoute component={AddOrChangeTrailerFeatures} path="/featured-trailer/add" exact />
        <PrivateRoute component={VerifyAccount} path="/verify-account" exact />
        <Route path="/404">
          <Page404 />
        </Route>
        <Route>
          <AppHeader showMenu={true} />
          <Container maxWidth={false} style={mainContainerStyle}>
            <Switch>
              {routes.map((route, index) => (
                <PrivateRoute {...route} key={index} />
              ))}
            </Switch>
          </Container>
        </Route>
      </Switch>
    </Router>
  );
}
