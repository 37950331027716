export const generatePropTextField = (name, formik, showErrorText) => {
  let object = {
    id: name,
    name: name,
    autoComplete: "off",
    variant: "outlined",
    error: formik.touched[name] && Boolean(formik.errors[name]),
    value: formik.values[name],
    onChange: formik.handleChange,
    inputProps: {
      maxLength: 2000,
    },
  };
  if (showErrorText) {
    object = { ...object, helperText: formik.touched[name] && formik.errors[name] };
  }
  return object;
};

export const resetDefaultForm = (formik) => {
  formik.resetForm();
};
