import React, { useEffect, useMemo, useState } from "react";
import WrapHeaderSubPage from "../../../../components/WrapHeaderSubPage";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./styled";
import {
  useDeleteNotification,
  useGetNotificationList,
} from "../../../../hooks/Notifications/useNotifications";
import ColumnInTable from "../../../../components/ColumnInTable";
import WrapButtonFlexRight from "../../../../components/WrapButtonFlexRight";
import MaterialTable from "material-table";
import moment from "moment";
import { USER_ROLES, common } from "../../../../constants/common";
import DialogChangeStatus from "../../../../components/DialogChangeStatus";
import CustomPagination from "../../../../components/Pagination";
import debounce from "lodash/debounce";
import { getCRUD_AllowByRoles } from "../../../../helpers/common";
import { Stack } from "../../../../components/Common/Styled/CommonStyled";
import {
  Text14,
  Text14Weight400,
  Text16,
} from "../../../../components/Common/Styled/TypographyStyled";
import { COLOR_LIST } from "../../../../themes";
import { NOTIFICATION_TYPES } from "../CreateNewOrEditNotification.jsx";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const SearchSection = () => {
  const { isAllowToCreate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  const handleEnterSearch = () => {};
  const history = useHistory();
  const location = useLocation();

  const onHandleChangeFilerStatus = (type) => {
    const urlParams = new URLSearchParams(location.search);
    let params = {
      ...(urlParams.get("limit") && { limit: urlParams.get("limit") }),
      ...(urlParams.get("skip") && { skip: urlParams.get("skip") }),
      ...(urlParams.get("keywords") && { keywords: urlParams.get("keywords") }),
      type,
    };
    params = new URLSearchParams(params);
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const currentStatus = useMemo(() => {
    let type = common.NOTIFICATION_STATUS.ALL;
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("type") && urlParams.get("type") !== common.NOTIFICATION_STATUS.ALL) {
      type = urlParams.get("type");
    }
    return type;
  }, [location.search]);

  const onChangeSearch = debounce((value) => {
    const urlParams = new URLSearchParams(location.search);
    let params = {
      ...(urlParams.get("limit") && { limit: urlParams.get("limit") }),
      ...(urlParams.get("skip") && { skip: urlParams.get("skip") }),
      ...(urlParams.get("type") && { type: urlParams.get("type") }),
      keywords: value,
    };
    params = new URLSearchParams(params);
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, 500);

  return (
    <div className={`filter-action-container ${classes.topLevelContainer}`}>
      <div className="filter-container">
        <form onSubmit={handleEnterSearch}>
          <FormControl variant="outlined" className={classes.form_search}>
            <InputAdornment position="start" className={classes.icon}>
              <SearchIcon className={classes.search_icon} />
            </InputAdornment>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search notifications"
              autoComplete="new-password"
              defaultValue=""
              onChange={(event) => onChangeSearch(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </form>
        <Box sx={{ display: "flex", gap: 9 }}>
          {Object.entries(common.NOTIFICATION_STATUS).map(([, type]) => {
            return (
              <Button
                key={type}
                variant="contained"
                color={currentStatus === type ? "primary" : "default"}
                style={{ textTransform: "capitalize" }}
                onClick={() => onHandleChangeFilerStatus(type)}
              >
                {type}
              </Button>
            );
          })}
        </Box>
      </div>
      <Box sx={{ display: "flex", gap: 8 }}>
        {isAllowToCreate ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.addBtn}
            onClick={() => history.push("/notifications/create-new")}
          >
            Create new
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

const Dashboard = () => {
  const { isAllowToDelete } = getCRUD_AllowByRoles({
    D_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const { mutate: mutateDeleteNotification } = useDeleteNotification();
  const [pagination, setPagination] = useState({
    count: +urlParams.get("limit") || 20,
    skip: +urlParams.get("skip") || 0,
    totalCount: 0,
    type: urlParams.get("type") || common.NOTIFICATION_STATUS.ALL,
    keywords: urlParams.get("keywords") || "",
  });

  const { data, isLoading } = useGetNotificationList({
    limit: pagination.count,
    skip: pagination.skip,
    type: pagination.type,
    keywords: pagination.keywords,
  });

  useEffect(() => {
    if (
      urlParams.get("type") !== pagination.type ||
      urlParams.get("keywords") !== pagination.keywords
    ) {
      setPagination((prevState) => ({
        ...prevState,
        type: urlParams.get("type") || common.NOTIFICATION_STATUS.ALL,
        keywords: urlParams.get("keywords") || "",
      }));
    }
  }, [location.search]);

  useEffect(() => {
    if (pagination.count || pagination.skip) {
      const params = new URLSearchParams({
        limit: pagination.count,
        skip: pagination.skip,
        type: pagination.type,
      });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  }, [pagination.count, pagination.skip]);

  useEffect(() => {
    if (data?.totalCount) {
      setPagination((prevState) => ({
        ...prevState,
        totalCount: data?.totalCount || 0,
      }));
    }
  }, [data?.totalCount]);

  const handleDeleteNotification = (id) => {
    mutateDeleteNotification(id, {
      onSettled: () => {
        onHandleCloseDialog();
      },
    });
  };

  const [dataDialog, setDataDialog] = useState(null);

  const onHandleCloseDialog = () => {
    setDataDialog(null);
  };
  const onHandleOpenDeleteNotificationDialog = (id) => {
    setDataDialog({
      isActive: false,
      header: "Are you sure you want to delete this Notification?",
      submitTitle: "Yes",
      onActive: () => handleDeleteNotification(id),
    });
  };

  const columns = [
    {
      title: <span className="title-table">Title</span>,
      field: "title",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">
            <Stack sx={{ flexDirection: "column" }}>
              <Text16>{rowData?.title || "--"}</Text16>
              <Text14 style={{ color: COLOR_LIST.LAVENDER_MIST }} noWrap>
                {rowData?.message}
              </Text14>
            </Stack>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">
            <Text14Weight400
              style={{
                textTransform: "capitalize",
              }}
            >
              {rowData?.isPushed ? "Sent" : "Scheduled"}
            </Text14Weight400>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Sent/scheduled",
      field: "date",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">
            <Text14Weight400 style={{ color: COLOR_LIST.LAVENDER_MIST }}>
              {moment(rowData?.triggerTime).format(common.DATE_FORMAT.DATE_TIME_1)}
            </Text14Weight400>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Method",
      field: "method",
      render: (rowData) => {
        const notificationType = NOTIFICATION_TYPES.find(
          (item) => item.value === rowData?.notificationType
        );
        return (
          <ColumnInTable minWidth="300">
            <Text14Weight400>{notificationType?.label ?? "--"}</Text14Weight400>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Recipients",
      field: "recipients",
      render: (rowData) => {
        // const quantity = `(${rowData?.recipientCustomersCount ?? 0} customers)`;
        const text = `${rowData?.recipientCustomersCount ?? 0} customers`;
        // if (rowData?.recipientIds?.length > 0) {
        //   text = `Specific customers ${quantity}`;
        // } else if (rowData?.recipientRegions?.length > 0) {
        //   text = `Specific region or area ${quantity}`;
        // }
        return (
          <ColumnInTable minWidth="300">
            <Text14Weight400>{text}</Text14Weight400>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Opened",
      field: "opens",
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <Button
              variant="contained"
              color="primary"
              className={classes.addBtn}
              onClick={() => history.push(`/notifications/edit/${rowData._id}`)}
            >
              View
            </Button>
            {isAllowToDelete ? (
              <IconButton
                style={{
                  marginRight: "33px",
                  padding: "9.5px",
                  width: "fit-content",
                }}
                onClick={() => onHandleOpenDeleteNotificationDialog(rowData._id)}
              >
                <CloseIcon style={{ color: "red" }} />
              </IconButton>
            ) : null}
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <div>
      <DialogChangeStatus
        isActive={dataDialog?.isActive}
        isOpen={!!dataDialog}
        header={dataDialog?.header}
        subtitle={dataDialog?.subtitle}
        submitTitle={dataDialog?.submitTitle}
        onActive={dataDialog?.onActive}
        onCloseDialog={onHandleCloseDialog}
      />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={data?.data ?? []}
        options={tableOptions}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

function Notifications() {
  return (
    <>
      <WrapHeaderSubPage nameHeader="Notifications" style={{ marginBottom: "50px" }} />
      <Breadcrumbs />
      <SearchSection />
      <Dashboard />
    </>
  );
}

export default Notifications;
