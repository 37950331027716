import React, { useEffect, useState } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import { useParams, useLocation } from "react-router-dom";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import AlertMissingDocument from "../../components/AlertMissingDocument";
import axios from "../../helpers/axios";
// import moment from "moment";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import { checkIsDocumentExpiry } from "../../helpers/checkIsDocumentExpiry";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const tableOptions = {
  header: false,
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  cellStyle: {
    padding: "19.5px 0",
  },
  rowStyle: (rowData) => {
    return rowData.tableData.id === 0 && { borderTop: "1px solid #24272d" };
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

// const convertTimeTo_DD_MM_YY = (time) => {
//   return moment(time).format("DD/MM/YY");
// };

const PROOF_OF_INCORPORATION = "proofOfIncorporation";
const TRAILER_REGISTRATIONS = "trailerRegistrations";

const CONTENT_ALERT = {
  true: {
    header: "Document Missing",
  },
  7: {
    header: "Document due in 7 days",
    title: "Contact the Franchisee if needed",
  },
  30: {
    header: "Document due in 30 days",
    title: "Contact the Franchisee if needed",
  },
};

const trailerRegistrationAdditionalColumn = {
  field: "registrationNumber",
  render: (rowData) => {
    if (rowData?.registration) {
      return (
        <div style={{ fontSize: 16, lineHeight: "19px" }}>
          Registration Number {rowData.registration}
        </div>
      );
    }
    return null;
  },
};

const ItemDocumentOfLicensee = () => {
  const [data, setData] = useState({
    phone: "",
    list: [],
  });
  const [isExpiry, setExpiry] = useState(false);
  const paramId = useParams();
  const locationState = useLocation();
  const columns = [
    {
      field: "trailerType",
      render: (rowData) => {
        // const name =
        //   locationState?.state?.keyData === TRAILER_REGISTRATIONS
        //     ? rowData?.name
        //     : locationState?.state?.name;
        // const time = convertTimeTo_DD_MM_YY(
        //   locationState?.state?.keyData === TRAILER_REGISTRATIONS
        //     ? rowData?.expiryRegistration
        //     : rowData?.createdAt
        // );
        const title = rowData.data.split("/")[3].split("-")[0];
        return <div style={{ fontSize: 16, lineHeight: "19px" }}>{title}</div>;
      },
    },
    {
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage href={rowData.data}>View</ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  // Add additional column for trailerRegistration
  if (TRAILER_REGISTRATIONS === locationState?.state?.keyData) {
    columns.splice(1, 0, trailerRegistrationAdditionalColumn);
  }

  // const typeOfItemFileDocument = (type) => {
  //   return type?.split("/")[1].toUpperCase() ?? "";
  // };

  useEffect(() => {
    axios.get(`v1/admin/document-licensee/${paramId.licenseeId}`).then((response) => {
      let newList = [];
      if (locationState?.state?.keyData) {
        newList = response.data.licenseeDocObj?.[locationState?.state?.keyData] ?? [];
        if (TRAILER_REGISTRATIONS !== locationState?.state?.keyData) {
          newList = newList.reverse();
        }
        newList = newList.filter((item) => !!item.data);
        if (newList.length !== 0) {
          isDocumentExpiry();
        }
        if (newList.length === 0) {
          setExpiry(true);
        }
      }
      setData({
        phone: response.data.licenseeDocObj.mobileFormat,
        list: newList,
      });
    });
  }, [paramId]);

  function isDocumentExpiry() {
    axios
      .get(`v1/admin/detaillicensee/${paramId.licenseeId}`)
      .then((response) => {
        const keyExpiry = locationState.state.keyExpiry;
        if (
          [PROOF_OF_INCORPORATION, TRAILER_REGISTRATIONS].every(
            (item) => locationState?.state?.keyData !== item
          )
        ) {
          const time = response.data.licenseeUserObj.licenseeId[keyExpiry];
          const result = checkIsDocumentExpiry(time);
          return setExpiry(result);
        }
        return;
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  function outputTitleContentAlert(name = "") {
    return `Contact the Franchisee to upload a ${name.toLowerCase()} doc on`;
  }

  return (
    <>
      <WrapHeaderSubPage nameHeader={locationState?.state?.name}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Breadcrumbs />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={data.list}
        options={tableOptions}
      />
      {[PROOF_OF_INCORPORATION].every((item) => locationState?.state?.keyData !== item) &&
        isExpiry !== false && (
          <AlertMissingDocument
            header={`${locationState?.state?.name} ${CONTENT_ALERT[isExpiry].header}`}
            title={outputTitleContentAlert(locationState?.state?.name)}
            contact={data.phone}
          />
        )}
    </>
  );
};

export default ItemDocumentOfLicensee;
