import { Box, Container, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Add, Close } from "@material-ui/icons";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { usDeleteTrailer, useGetTrailers } from "../../hooks/Features/useFeatures";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { getCRUD_AllowByRoles } from "../../helpers/common";
import { USER_ROLES } from "../../constants/common";

const MAX_TRAILER = 12;
const MIN_TRAILER = 4;

const Featured = () => {
  const { isAllowToCreate, isAllowToUpdate, isAllowToDelete } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    D_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const [trailerList, setTrailerList] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const { data: response } = useGetTrailers();
  const [selectedTrailerId, setSelectedTrailerId] = useState(null);
  const { mutate } = usDeleteTrailer();
  const onHandleDeleteTrailer = () => {
    if (selectedTrailerId) {
      mutate(selectedTrailerId, {
        onSettled: () => {
          setSelectedTrailerId(null);
        },
      });
    }
  };
  useEffect(() => {
    const results = response?.data?.featuresTrailersObj;
    results &&
      setTrailerList(
        results.map((item) => ({
          _id: item._id,
          trailer: item.trailerId,
          backgroundColor: item.color,
        }))
      );
  }, [response]);
  return (
    <Container maxWidth="xl">
      <WrapHeaderSubPage nameHeader="Featured Trailers" />
      <Box
        display="flex"
        // justifyContent="space-between"
        paddingBottom="25px"
        flexWrap="wrap"
        marginX="-10.5px"
        sx={{ gap: 12, mt: 4 }}
      >
        {trailerList.map((item, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            margin="0 10.5px 21px 10.5px"
          >
            <Box
              width="400px"
              height="320px"
              padding="36px 40px 31px 40px"
              boxSizing="border-box"
              borderRadius="15px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              style={{
                backgroundColor: item.backgroundColor,
                position: "relative",
              }}
            >
              {trailerList?.length > MIN_TRAILER && isAllowToDelete ? (
                <Box sx={{ position: "absolute", top: 5, right: 5 }}>
                  <IconButton onClick={() => setSelectedTrailerId(item?._id)}>
                    <Close />
                  </IconButton>
                </Box>
              ) : null}
              {item.trailer === null ? (
                <>
                  <div style={{ width: "212px", height: "158px" }}></div>
                  <Typography classes={{ root: classes.itemTitle }}>Don't have trailer</Typography>
                </>
              ) : (
                <>
                  <img
                    src={item?.trailer?.photos?.[0]?.url}
                    style={{
                      width: "212px",
                      height: "auto",
                      objectFit: "contain",
                      aspectRatio: "1/1",
                    }}
                  />
                  <Typography classes={{ root: classes.itemTitle }}>
                    {item.trailer?.name}
                  </Typography>
                </>
              )}
            </Box>
            {isAllowToUpdate ? (
              <ButtonActionHeader
                onClick={() =>
                  history.push({
                    pathname: `/featured-trailer/${item._id}`,
                    state: {
                      trailer: item.trailer,
                    },
                  })
                }
                style={{ marginTop: "24px" }}
              >
                {item.trailer === null ? "Add" : "Change"}
              </ButtonActionHeader>
            ) : null}
          </Box>
        ))}
        {trailerList?.length < MAX_TRAILER && isAllowToCreate ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            margin="0 10.5px 21px 10.5px"
          >
            <Box
              width="400px"
              height="320px"
              boxSizing="border-box"
              borderRadius="15px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: "#494949",
              }}
            >
              <Add style={{ fontSize: 64 }} />
            </Box>
            <ButtonActionHeader
              onClick={() => {
                history.push({
                  pathname: "/featured-trailer/add",
                  state: {
                    lastIndex: trailerList.length,
                  },
                });
              }}
              style={{ marginTop: "24px" }}
            >
              Add
            </ButtonActionHeader>
          </Box>
        ) : null}
      </Box>
      <ConfirmationDialog
        isOpen={!!selectedTrailerId}
        header="Are you sure you want to Delete this Trailer?"
        onConfirm={onHandleDeleteTrailer}
        onCloseDialog={() => setSelectedTrailerId(null)}
      />
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  itemTitle: {
    fontSize: 18,
    lineHeight: "21.48px",
    fontWeight: 600,
    textAlign: "center",
  },
}));
export default Featured;
