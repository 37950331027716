import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useLayoutEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { useStyles } from "../../Analytics.styles";
import { Menu } from "../../components";
import {
  AvgHireLengthPerTrailerType,
  AvgIncomePerTrailerType,
  CancelledHires,
  FranchiseePerf,
  GeoPerformance,
  HiresPerTrailerType,
  OverviewMain,
  TrailerPerformance,
  UnservBookingEnq,
  UnavailableTrailerSearches,
  OrdersByDayOfTheWeek,
} from "../../tabs/Overview";

export function OverviewPage() {
  const classes = useStyles();

  useLayoutEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Analytics";
  });

  return (
    <Box component="main" className={classes.analyticsContent}>
      <Breadcrumbs />
      <Menu />
      <Box component="div" className={classes.analyticsOverview}>
        <OverviewMain />
      </Box>

      <Grid container spacing={7} className={classes.section}>
        <Grid item md={8} sm={12} xs={12}>
          <OrdersByDayOfTheWeek />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <CancelledHires />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item md={8} sm={12} xs={12}>
          <AvgIncomePerTrailerType />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <UnavailableTrailerSearches />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item md={8} sm={12} xs={12}>
          <AvgHireLengthPerTrailerType />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <UnservBookingEnq />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item md={8} sm={12} xs={12}>
          <TrailerPerformance />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <FranchiseePerf />
        </Grid>
      </Grid>

      <Grid container spacing={7} className={classes.section}>
        <Grid item md={8} sm={12} xs={12}>
          <HiresPerTrailerType />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <GeoPerformance />
        </Grid>
      </Grid>
    </Box>
  );
}
