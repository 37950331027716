import React from "react";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
const ButtonActionHeader = withStyles(() => ({
  root: {
    width: (props) => (props.width ? props.width : "173px"),
    height: "41px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(({ variant = "contained", ...props }) => (
  <Button variant={variant} color="primary" {...props}>
    {props.children}
  </Button>
));
export default ButtonActionHeader;
