import React, { useEffect, useMemo, useState } from "react";
import WrapHeaderSubPage from "../../../../components/WrapHeaderSubPage";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./styled";
import {
  useDeleteDiscount,
  useGetDiscountList,
} from "../../../../hooks/DiscountsPromotions/useDiscounts";
import ColumnInTable from "../../../../components/ColumnInTable";
import WrapButtonFlexRight from "../../../../components/WrapButtonFlexRight";
import MaterialTable from "material-table";
import moment from "moment";
import { USER_ROLES, common } from "../../../../constants/common";
import DialogChangeStatus from "../../../../components/DialogChangeStatus";
import CustomPagination from "../../../../components/Pagination";
import debounce from "lodash/debounce";
import { getCRUD_AllowByRoles } from "../../../../helpers/common";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

const SearchSection = () => {
  const { isAllowToCreate } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.MANAGER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  const handleEnterSearch = () => {};
  const history = useHistory();
  const location = useLocation();

  const onHandleChangeFilerStatus = (type) => {
    const urlParams = new URLSearchParams(location.search);
    let params = {
      ...(urlParams.get("limit") && { limit: urlParams.get("limit") }),
      ...(urlParams.get("skip") && { skip: urlParams.get("skip") }),
      ...(urlParams.get("keyword") && { keyword: urlParams.get("keyword") }),
      type,
    };
    params = new URLSearchParams(params);
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const currentStatus = useMemo(() => {
    let type = common.DISCOUNT_PROMOTION_STATUS.ALL;
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("type") && urlParams.get("type") !== common.DISCOUNT_PROMOTION_STATUS.ALL) {
      type = urlParams.get("type");
    }
    return type;
  }, [location.search]);

  const onChangeSearch = debounce((value) => {
    const urlParams = new URLSearchParams(location.search);
    let params = {
      ...(urlParams.get("limit") && { limit: urlParams.get("limit") }),
      ...(urlParams.get("skip") && { skip: urlParams.get("skip") }),
      ...(urlParams.get("type") && { type: urlParams.get("type") }),
      keyword: value,
    };
    params = new URLSearchParams(params);
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, 500);

  return (
    <div className={`filter-action-container ${classes.topLevelContainer}`}>
      <div className="filter-container">
        <form onSubmit={handleEnterSearch}>
          <FormControl variant="outlined" className={classes.form_search}>
            <InputAdornment position="start" className={classes.icon}>
              <SearchIcon className={classes.search_icon} />
            </InputAdornment>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Search discounts & promotions"
              autoComplete="new-password"
              defaultValue=""
              onChange={(event) => onChangeSearch(event.target.value)}
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                  input: classes.input,
                },
              }}
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </FormControl>
        </form>
        <Box sx={{ display: "flex", gap: 9 }}>
          {Object.entries(common.DISCOUNT_PROMOTION_STATUS).map(([, type]) => {
            return (
              <Button
                key={type}
                variant="contained"
                color={currentStatus === type ? "primary" : "default"}
                style={{ textTransform: "capitalize" }}
                onClick={() => onHandleChangeFilerStatus(type)}
              >
                {type}
              </Button>
            );
          })}
        </Box>
      </div>
      <Box sx={{ display: "flex", gap: 8 }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.addBtn}
          onClick={() => history.push("/discounts-and-promotions/promotional-banners")}
        >
          Promotional Banners
        </Button>
        {isAllowToCreate ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.addBtn}
            onClick={() => history.push("/discounts-and-promotions/create-new")}
          >
            Create new
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

const Dashboard = () => {
  const { isAllowToDelete } = getCRUD_AllowByRoles({
    D_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const { mutate: mutateDeleteDiscount } = useDeleteDiscount();
  const [pagination, setPagination] = useState({
    count: +urlParams.get("limit") || 20,
    skip: +urlParams.get("skip") || 0,
    totalCount: 0,
    type: urlParams.get("type") || common.DISCOUNT_PROMOTION_STATUS.ALL,
    keyword: urlParams.get("keyword") || "",
  });

  const { data, isLoading } = useGetDiscountList({
    limit: pagination.count,
    skip: pagination.skip,
    type: pagination.type,
    keyword: pagination.keyword,
  });

  useEffect(() => {
    if (
      urlParams.get("type") !== pagination.type ||
      urlParams.get("keyword") !== pagination.keyword
    ) {
      setPagination((prevState) => ({
        ...prevState,
        type: urlParams.get("type") || common.DISCOUNT_PROMOTION_STATUS.ALL,
        keyword: urlParams.get("keyword") || "",
      }));
    }
  }, [location.search]);

  useEffect(() => {
    if (pagination.count || pagination.skip) {
      const params = new URLSearchParams({
        limit: pagination.count,
        skip: pagination.skip,
        type: pagination.type,
      });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  }, [pagination.count, pagination.skip]);

  useEffect(() => {
    if (data?.totalCount) {
      setPagination((prevState) => ({
        ...prevState,
        totalCount: data?.totalCount || 0,
      }));
    }
  }, [data?.totalCount]);

  const handleDeleteDiscount = (id) => {
    mutateDeleteDiscount(id, {
      onSettled: () => {
        onHandleCloseDialog();
      },
    });
  };

  const [dataDialog, setDataDialog] = useState(null);

  const onHandleCloseDialog = () => {
    setDataDialog(null);
  };
  const onHandleOpenDeleteDiscountDialog = (id) => {
    setDataDialog({
      isActive: false,
      header: "Are you sure you want to delete this Discount",
      submitTitle: "Yes",
      onActive: () => handleDeleteDiscount(id),
    });
  };

  const columns = [
    {
      title: <span className="title-table">Title</span>,
      field: "title",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">{rowData?.title ?? rowData?.code ?? "--"}</ColumnInTable>
        );
      },
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">
            <span style={{ color: rowData?.isActive ? "#44D62C" : "#FF2020" }}>
              {rowData?.isActive ? "Active" : "Inactive"}
            </span>
            {[
              common.DISCOUNT_PROMOTION_STATUS.SCHEDULED,
              common.DISCOUNT_PROMOTION_STATUS.EXPIRED,
            ].some((item) => item === rowData?.status) ? (
              <span style={{ textTransform: "capitalize" }}>, {rowData?.status}</span>
            ) : null}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Start/End Date",
      field: "date",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">
            {moment(rowData?.startDate).format(common.DATE_FORMAT.DATE_1)}
            {rowData?.endDate
              ? ` - ${moment(rowData?.endDate).format(common.DATE_FORMAT.DATE_1)}`
              : ""}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Method",
      field: "method",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="300">
            {rowData?.title ? "Automatic" : rowData?.code ? "Discount Code" : "--"}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Used",
      field: "used",
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <Button
              variant="contained"
              color="primary"
              className={classes.addBtn}
              onClick={() => history.push(`/discounts-and-promotions/edit/${rowData._id}`)}
            >
              View
            </Button>
            {isAllowToDelete ? (
              <IconButton
                style={{
                  marginRight: "33px",
                  padding: "9.5px",
                  width: "fit-content",
                }}
                onClick={() => onHandleOpenDeleteDiscountDialog(rowData._id)}
              >
                <CloseIcon style={{ color: "red" }} />
              </IconButton>
            ) : null}
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <div>
      <DialogChangeStatus
        isActive={dataDialog?.isActive}
        isOpen={!!dataDialog}
        header={dataDialog?.header}
        subtitle={dataDialog?.subtitle}
        submitTitle={dataDialog?.submitTitle}
        onActive={dataDialog?.onActive}
        onCloseDialog={onHandleCloseDialog}
      />
      <MaterialTable
        className="table-data"
        columns={columns}
        data={data?.discounts ?? []}
        options={tableOptions}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

function DiscountsAndPromotions() {
  return (
    <>
      <WrapHeaderSubPage nameHeader="Discounts & Promotions" style={{ marginBottom: "50px" }} />
      <Breadcrumbs />
      <SearchSection />
      <Dashboard />
    </>
  );
}

export default DiscountsAndPromotions;
