import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ImageIcon from "@material-ui/icons/Image";
import CreateIcon from "@material-ui/icons/Create";
import {
  Button,
  Avatar,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";
import { ReactComponent as Delete } from "../../img/delete.svg";
import "../../App.scss";
import axios from "../../helpers/axios";
import SearchAndFilter from "../../components/SearchAndFilter";
import CustomPagination from "../../components/Pagination";
import ButtonView from "../../components/ButtonView";
import { useLoading } from "../../hooks/useLoading";
import ButtonActionListLicensee from "../../components/ButtonActionListLicensee";
import DialogChangeStatus from "../../components/DialogChangeStatus";
import { formatContacts } from "../../helpers/format";
import DialogHireTheDriver from "./DialogHireTheDriver";
import axiosInstance from "../../helpers/axios";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { getCRUD_AllowByRoles } from "../../helpers/common";
import { USER_ROLES } from "../../constants/common";

const tableOptions = {
  toolbar: false,
  showTitle: false,
  draggable: false,
  search: false,
  sorting: false,
  filtering: false,
  paging: false,
  headerStyle: {
    top: 54,
    backgroundColor: "#171717",
  },
  rowStyle: {
    color: "#fff",
    height: 73,
  },
  actionsCellStyle: { color: "#fff" },
  actionsColumnIndex: -1,
};

export default function LicenseeList() {
  const { isAllowToCreate, isAllowToUpdate, isAllowToDelete } = getCRUD_AllowByRoles({
    C_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    U_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
    D_Roles: [USER_ROLES.ADMIN_OWNER, USER_ROLES.ADMIN_DEVELOPER],
  });
  const classes = useStyles();
  let history = useHistory();

  const [showLoading, hideLoading] = useLoading();
  const [searchFilter, setSearchFilter] = useState([
    {
      star4: false,
      belowStar4: false,
    },
    { active: false, deactivated: false },
    { verified: false, unverified: false },
    { search: "" },
  ]);

  const [listLicensee, setListLicensee] = useState([]);

  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });
  const [linkGetLicense, setLinkGetLicensee] = useState(
    `/v1/admin/licensee?count=${pagination.count}&&skip=0&&filters=&&verified=&&accountStatus=&&ratingStar=`
  );
  const [settingDialogData, setSettingDialogData] = useState(null);
  const [globalHireRateSetting, setGlobalHireRateSetting] = useState(null);

  useEffect(() => {
    const temp = {
      search: "",
      verified: "",
      accountStatus: "",
      ratingStar: "",
    };
    if (searchFilter[0].star4 === searchFilter[0].belowStar4) {
      temp.ratingStar = "";
    } else if (searchFilter[0].star4) {
      temp.ratingStar = "above";
    } else if (searchFilter[0].belowStar4) {
      temp.ratingStar = "below";
    }
    if (searchFilter[1].active === searchFilter[1].deactivated) {
      temp.accountStatus = "";
    } else if (searchFilter[1].active) {
      temp.accountStatus = true;
    } else if (searchFilter[1].deactivated) {
      temp.accountStatus = false;
    }
    if (searchFilter[2].verified === searchFilter[2].unverified) {
      temp.verified = "";
    } else if (searchFilter[2].verified) {
      temp.verified = true;
    } else if (searchFilter[2].unverified) {
      temp.verified = false;
    }
    temp.search = searchFilter[3].search;
    let query = `/v1/admin/licensee?count=${pagination.count}&&skip=${pagination.skip}&&filters=${temp.search}&&verified=${temp.verified}&&accountStatus=${temp.accountStatus}&&ratingStar=${temp.ratingStar}`;
    setLinkGetLicensee(query);
  }, [searchFilter, pagination]);

  useEffect(() => {
    getListLicensee();
  }, [linkGetLicense]);

  const getListLicensee = () => {
    showLoading();
    axios
      .get(linkGetLicense)
      .then((response) => {
        hideLoading();
        setPagination({
          ...pagination,
          totalCount: response.data.licenseeList[0]?.count[0]?.totalCount
            ? response.data.licenseeList[0]?.count[0]?.totalCount
            : null,
        });
        return setListLicensee(response.data.licenseeList[0].licenseeData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getGlobalHireRate();
  }, []);

  const getGlobalHireRate = async () => {
    try {
      showLoading();
      const globalHireRateResponse = await axiosInstance.get("/v1/admin/get-hire-the-driver");
      const globalHireRateSettings = globalHireRateResponse?.data?.hireTheDriverSettings;
      if (globalHireRateSettings) {
        const { perHour, trailerTypes } = globalHireRateSettings;
        setGlobalHireRateSetting({
          perHour,
          trailers: trailerTypes,
        });
        hideLoading();
      }
    } catch (error) {
      hideLoading();
      toast.error(error?.response?.data?.message);
    }
  };

  //-------------------------------------------------------------------------
  // REF FOR TABLE LIST LICENSEE
  const tableRef = React.createRef();

  function handleChangeActivateLicensee(id) {
    axios
      .put(`v1/admin/active-licensee/${id}`)
      .then((response) => {
        getAllLicensee();
        setOpen(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function getAllLicensee() {
    showLoading();
    axios.get(linkGetLicense).then((response) => {
      hideLoading();
      setPagination({
        ...pagination,
        totalCount: response.data.licenseeList[0]?.count[0]?.totalCount
          ? response.data.licenseeList[0]?.count[0]?.totalCount
          : null,
      });
      return setListLicensee(response.data.licenseeList[0].licenseeData);
    });
  }

  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });

  function toggleDialog(data, method) {
    if (method === "active") {
      if (data.isActive) {
        setDataDialog({
          isActive: false,
          header: "Are you sure you want to deactivate the Franchisee from the system ?",
          subtitle:
            "The Franchisee will lose access to the app. You can grant them access once any issues are resolved",
          submitTitle: "Deactivate Franchisee",
          onActive: () => handleChangeActivateLicensee(data.id),
        });
      } else {
        setDataDialog({
          isActive: true,
          header: "Are you sure you want to reactivate the Franchisee from the system ?",
          subtitle: "The Franchisee will again access to the app",
          submitTitle: "Reactivate Franchisee",
          onActive: () => handleChangeActivateLicensee(data.id),
        });
      }
    }
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  function resendLoginLicensee(id) {
    axios
      .put(`v1/admin/resend-login/${id}`)
      .then((response) => {
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const columns = [
    {
      title: <span className="title-table">Franchisee</span>,
      field: "licenseeInfo",
      render: (rowData) => {
        return (
          <div className={classes.licenseeInfo}>
            <ListItem>
              <ListItemAvatar>
                {rowData.photo?.data ? (
                  <Avatar alt="Logo" src={rowData.photo.data} />
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText>
                <div className="list-contact">
                  <span className="text-16">{rowData.firstname + " " + rowData.lastname}</span>
                  <span className="text-14 text-secondary">
                    {rowData.licensee_data?.licenseeRef}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          </div>
        );
      },
    },
    {
      title: "Contact Details",
      field: "licenseeContact",
      render: (rowData) => {
        return (
          <div className="list-contact">
            <span className="text-14">
              {formatContacts(rowData?.mobileFormat)}
              {/* {formatContacts(rowData.mobileFormat?.replace("+61", "0"))} */}
            </span>
            <span className="text-12">{rowData.email}</span>
          </div>
        );
      },
    },
    {
      title: "Trailers",
      field: "trailers",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.btnInTable}
              onClick={() => {
                history.push({
                  pathname: `/licensee/${rowData.firstname}/${rowData.licenseeId}/trailers`,
                });
              }}
            >
              View
            </Button>
          </div>
        );
      },
    },
    {
      title: "Upsell Items",
      field: "upsellItems",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            <ButtonView
              router={`/licensee/${rowData.firstname}/${rowData.licenseeId}/upsellitems`}
            />
          </div>
        );
      },
    },
    {
      title: "Employee List",
      field: "employeeList",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            <ButtonView
              router={`/licensee/${rowData.firstname}/${rowData.licenseeId}/employee/list`}
            >
              View
            </ButtonView>
          </div>
        );
      },
    },
    {
      title: "Uploaded Docs",
      field: "docs",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            <ButtonView router={`/licensee/${rowData.licenseeId}/docs`}>View</ButtonView>
          </div>
        );
      },
    },
    {
      title: "Upcoming Bookings",
      field: "upcoming_bookings",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnView}>
            <ButtonView router={`/licensee/${rowData.licenseeId}/licensee-upcoming-orders`}>
              View
            </ButtonView>
          </div>
        );
      },
    },
    {
      title: "Hire the Driver",
      field: "setting",
      render: (rowData) => {
        return (
          <div style={{ minWidth: 130 }}>
            <Box display="flex" alignItems="center">
              <Box>
                {rowData.licensee_data?.hireTheDriver
                  ? `${rowData.licensee_data?.hireTheDriver?.perHour} per hour`
                  : "N/A"}
              </Box>
              {isAllowToUpdate && rowData.licensee_data?.hireTheDriver && (
                <CreateIcon
                  style={{ marginLeft: 10, cursor: "pointer" }}
                  onClick={() => setSettingDialogData(rowData)}
                />
              )}
            </Box>
          </div>
        );
      },
    },
    {
      title: "Invite Accepted",
      field: "proofOfIncorporationAccepted",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnInvite}>
            {rowData.acceptedInvite ? (
              <span className="text-success">Yes</span>
            ) : (
              <span className="text-error">No</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Rating",
      field: "rating",
      render: (rowData) => {
        return (
          <div className={classes.widthColumnRating}>
            {rowData.licensee_data?.ratingStar?.ratingStar >= 4 ? (
              <span className="text-success">{rowData.licensee_data?.ratingStar?.ratingStar}</span>
            ) : (
              <span className="text-error">{rowData.licensee_data?.ratingStar?.ratingStar}</span>
            )}
          </div>
        );
      },
    },
  ];
  if (isAllowToUpdate || isAllowToDelete) {
    columns.push({
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <div className="action-table">
            {isAllowToUpdate ? (
              !rowData.licensee_data.isActive ? (
                <ButtonActionListLicensee
                  onClick={() => {
                    toggleDialog(
                      {
                        id: rowData.licenseeId,
                        isActive: rowData.licensee_data.isActive,
                      },
                      "active"
                    );
                  }}
                  color="primary"
                  variant="contained"
                >
                  Reactivate
                </ButtonActionListLicensee>
              ) : (
                <ButtonActionListLicensee
                  disabled={rowData.acceptedInvite}
                  onClick={() => resendLoginLicensee(rowData.licensee_data._id)}
                  variant="contained"
                  color="secondary"
                >
                  Resend logins
                </ButtonActionListLicensee>
              )
            ) : null}
            <div>
              {rowData.licensee_data.isActive && (
                <div>
                  {isAllowToUpdate ? (
                    <CreateIcon
                      className={classes.iconEdit}
                      onClick={() => {
                        history.push(`/licensee/signup/${rowData.licenseeId}`);
                      }}
                    />
                  ) : null}
                  {isAllowToDelete ? (
                    <Delete
                      className={classes.iconCreate}
                      onClick={() =>
                        toggleDialog(
                          {
                            id: rowData.licenseeId,
                            isActive: rowData.licensee_data.isActive,
                          },
                          "active"
                        )
                      }
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
        );
      },
    });
  }

  return (
    <div className={`t2y-list t2y-licenseelist ${classes.root}`}>
      <Typography variant="h2" component="h2" className={classes.title}>
        Franchisee
      </Typography>
      <Breadcrumbs />
      <SearchAndFilter
        optionFilter={radioOptions}
        textAdd="Add Franchisee"
        textSearch="Franchisee"
        linkAdd="/licensee/signup"
        showAdd={isAllowToCreate}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        pagination={pagination}
        setPagination={setPagination}
      />
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        tableRef={tableRef}
        columns={columns}
        data={listLicensee}
        options={tableOptions}
      />
      {settingDialogData && (
        <DialogHireTheDriver
          open={!!settingDialogData}
          data={settingDialogData}
          globalHireRateSetting={globalHireRateSetting}
          onClose={() => setSettingDialogData(null)}
          getListLicensee={getListLicensee}
        />
      )}
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  title: {},
  iconEdit: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  iconCreate: {
    color: "#FF0000",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  btnInTable: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
  licenseeInfo: {
    minWidth: "250px",
    marginRight: "6px",
  },
  licenseeContact: {
    minWidth: "170px",
    marginRight: "6px",
  },
  widthColumnView: {
    minWidth: "100px",
  },
  widthColumnRating: {
    minWidth: "92px",
  },
  widthColumnInvite: {
    minWidth: "120px",
  },
}));
const radioOptions = [
  {
    name: "Star Rating",
    param: "rating",
    options: [
      {
        text: "4 Stars and above",
        value: "star4",
      },
      {
        text: "Below 4 Stars",
        value: "belowStar4",
      },
    ],
  },

  {
    name: "Account status",
    param: "status",
    options: [
      {
        text: "Active",
        value: "active",
      },
      {
        text: "Deactivated",
        value: "deactivated",
      },
    ],
  },
  {
    name: "Verification",
    param: "verification",
    options: [
      {
        text: "Verified",
        value: "verified",
      },
      {
        text: "Unverified",
        value: "unverified",
      },
    ],
  },
];
