import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatAmount } from "../../utils";

export function AvgFranchiseeIncomePerHirePage() {
  const classes = useStyles();
  const { incomePerLicenseeData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector("#analytic-title").textContent = "Average franchisee income per hire";
    if (incomePerLicenseeData?.items) {
      const dataTransformed = [...incomePerLicenseeData.items]
        .sort((a, b) => b.averageIncomePerHire - a.averageIncomePerHire)
        .slice(0, 5)
        .map((item) => ({
          franchisee: item.licensee.fullname,
          totalHires: item.totalHires,
          totalFranchiseeIncome: formatAmount(item?.licenseeIncome || 0),
          avgFranchiseeIncomePerHire: formatAmount(item?.averageIncomePerHire || 0),
        }));
      setData(dataTransformed);
    }
  }, [incomePerLicenseeData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: false,
    },
    {
      name: "Total hires",
      key: "totalHires",
      sortable: true,
    },
    {
      name: "Total Franchisee Income",
      key: "totalFranchiseeIncome",
      sortable: true,
    },
    {
      name: "Average Franchisee Income Per Hire",
      key: "avgFranchiseeIncomePerHire",
      sortable: true,
    },
  ];

  return (
    <>
      <Box component="main" className={classes.analyticsSinglePageContent}>
        <Card header="Average franchisee income per hire">
          <SortableTable rows={data} columns={columns} gridTemplateColumns="25% 25% 25% 25%" />
        </Card>
      </Box>
    </>
  );
}
