import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { formatAmount } from "../../../utils";
import { Box } from "@material-ui/core";
import { Text50 } from "../../../../../components/Common/Styled/TypographyStyled";

export function AvgFranchiseeIncomePerHireGraph() {
  const { url } = useRouteMatch();
  const { incomePerLicenseeData } = useSelector((state) => state.analytic);
  return (
    <Card
      headerSize="small"
      header="Average franchisee income per hire"
      navigateTo={`${url}/avg-franchisee-income-per-hire`}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: 220,
            height: 220,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Text50>{formatAmount(incomePerLicenseeData?.averageIncomePerHire || 0, 0)}</Text50>
        </Box>
      </Box>
    </Card>
  );
}
