import React from "react"
import { makeStyles } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { ButtonCircle } from "../ButtomCircle"
const useStyle = makeStyles({
  root: {
    width: "97px",
    height: "31px",
    padding: "7px 26px",
  },
})
const ButtonReturnPreviousPage = () => {
  const history = useHistory()
  const cssButtonCircle = useStyle()
  return (
    <ButtonCircle
      variant="contained"
      onClick={() => history.goBack()}
      classes={cssButtonCircle}
    >
      Exit
    </ButtonCircle>
  )
}
export default ButtonReturnPreviousPage