import React, { useEffect, useRef, useState } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import { useParams } from "react-router-dom";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import DialogIdentification from "../../components/DialogIdentification";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import axios from "../../helpers/axios";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

export const DIALOG_TYPES = {
  DRIVERS_OF_LICENSEE: "driversOfLicensee",
  PHOTO_OF_LICENSEE: "photoOfLicensee",
  PHOTO_OF_EMPLOYEE: "photoOfEmployee",
};

const optionalDataColumn = {
  name: "Employee Photo",
  type: DIALOG_TYPES.PHOTO_OF_EMPLOYEE,
};

const Identification = () => {
  const [dataColumn, setDataColumn] = useState([
    {
      name: "Drivers License",
      type: DIALOG_TYPES.DRIVERS_OF_LICENSEE,
    },
    {
      name: "Franchisee Photo",
      type: DIALOG_TYPES.PHOTO_OF_LICENSEE,
    },
  ]);
  const [dataList, setDataList] = useState({
    driversOfLicensee: [],
    photoOfLicensee: [],
  });
  const [open, setOpen] = useState(false);
  const typeOfDataList = useRef(DIALOG_TYPES.DRIVERS_OF_LICENSEE);
  const paramId = useParams();
  const mockData = [
    {
      field: "trailerType",
      render: (rowData) => {
        return (
          <div style={{ fontSize: 16, lineHeight: "19px" }}>{rowData.name}</div>
        );
      },
    },
    {
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage onClick={() => handleOpenDialog(rowData.type)}>
              View
            </ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  function handleOpenDialog(type) {
    typeOfDataList.current = type;
    setOpen(!open);
  }

  function handleClose() {
    setOpen(false);
  }

  function returnHeaderDialog(type) {
    return dataColumn.find((item) => item.type === type).name;
  }

  useEffect(() => {
    axios
      .get(`v1/admin/detaillicensee/${paramId.licenseeId}`)
      .then((response) => {
        const {
          driversOfLicensee,
          photo,
          employees,
        } = response.data.licenseeUserObj;
        const employeeList = employees?.filter((item) => !!item.data);
        setDataList({
          driversOfLicensee: [...driversOfLicensee],
          photoOfLicensee: [photo],
          ...(employeeList?.length > 0 && { photoOfEmployee: employeeList }),
        });
        employeeList?.length > 0 &&
          setDataColumn((prev) => [...prev, optionalDataColumn]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WrapHeaderSubPage nameHeader={`Identification`}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Breadcrumbs />
      <DialogIdentification
        open={open}
        onClose={handleClose}
        data={{
          header: returnHeaderDialog(typeOfDataList.current),
          list: dataList[typeOfDataList.current],
          type: typeOfDataList.current,
        }}
      />
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={dataColumn}
        options={{
          header: false,
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          rowStyle: (rowData) => {
            // FIRST ELEMENT
            return (
              rowData.tableData.id === 0 && { borderTop: "1px solid #24272d" }
            );
          },
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
};
export default Identification;
