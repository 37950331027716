import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../App";

const useStyle = makeStyles({
  root: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19px",
    marginTop: 14,
    marginBottom: 50,
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  activeLink: {
    color: "#44D62C",
  },
});

const Breadcrumbs = () => {
  const classes = useStyle();
  const params = useParams();
  const location = window.location.pathname;
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    // Convert current location to relative location
    const currentRelativeLocation = Object.keys(params).length
      ? location
          .split("/")
          .map((item) => {
            const matchedParams = Object.keys(params).find((key) => params[key] === item);
            return matchedParams ? `:${matchedParams}` : item;
          })
          .join("/")
      : location;

    // Filter all matched path from routes and map to breadcrumbs array
    const crumbs = routes
      .filter(({ path }) => currentRelativeLocation.includes(path))
      .map(({ path, name }) => ({
        path: Object.keys(params).length
          ? Object.keys(params).reduce(
              (path, param) => path.replace(`:${param}`, params[param]),
              path
            )
          : path,
        name,
      }));
    setBreadcrumbs(crumbs);
  }, [location]);

  return (
    <Box className={classes.root}>
      {breadcrumbs.map(({ name, path }, key) =>
        key + 1 === breadcrumbs.length ? (
          <span className={classes.activeLink} key={key}>
            {name}
          </span>
        ) : (
          <Link key={key} to={path} className={classes.link}>
            {`${name} / `}
          </Link>
        )
      )}
    </Box>
  );
};

export default Breadcrumbs;
