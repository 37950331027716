import { Button, makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { ButtonCircle } from "../../components/ButtomCircle";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { getFromLocalStorage } from "../../helpers/common";
import { generatePropTextField } from "../../helpers/formik-help";
import { useAuth, useVerify2FACode } from "../../hooks/useAuth";

const TEXT_CONTENT = {
  STEP_1: {
    title: "Setup 2-Factor Authentication",
    subTitle: "Open your Google Authenticator app and add a new code",
    instruction: "Scan the QR code",
  },
  STEP_2: {
    title: "Enter 2-Factor Authentication Code",
  },
};

const STEP = { STEP_1: 0, STEP_2: 1 };

const VerifyAccount = () => {
  const user = getFromLocalStorage("user");
  const [step, setStep] = useState(STEP.STEP_1);
  const { logout } = useAuth();
  const { mutate, isLoading } = useVerify2FACode();
  useEffect(() => {
    if (user && !user?.qrcode) {
      setStep(STEP.STEP_2);
    }
  }, []);
  const classes = useStyles();

  let history = useHistory();

  const formik = useFormik({
    initialValues: { code: "" },
    validationSchema: yup.object({
      code: yup
        .string()
        .required("Required")
        .matches(/^[0-9]{6}$/, "Must be exactly 6 digits"),
    }),
    onSubmit: (value) => {
      mutate(
        { code: value.code },
        {
          onSuccess: () => {
            history.push("/");
          },
        }
      );
      // onVerifyingCode(value.email, value.password);
    },
  });
  if (step === STEP.STEP_1) {
    return (
      <LayoutAddOrEdit titleDetail="">
        <div className=" btn-exit-login">
          <ButtonCircle
            variant="contained"
            onClick={() => {
              logout();
            }}
          >
            Exit
          </ButtonCircle>
        </div>
        <div className="layout-invite">
          <Typography variant="h3" className={classes.title}>
            {TEXT_CONTENT.STEP_1.title}
          </Typography>
          <Typography variant="h5" className={classes.subTitle}>
            {TEXT_CONTENT.STEP_1.subTitle}
          </Typography>
          <div className={classes.qrBox}>
            <img src={user?.qrcode} />
            <Typography variant="h5" className={classes.instructionText}>
              {TEXT_CONTENT.STEP_1.instruction}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={classes.buttonStack}>
              <Button
                className={classes.btnSubmit}
                color="primary"
                variant="contained"
                onClick={() => setStep(STEP.STEP_2)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </LayoutAddOrEdit>
    );
  }
  return (
    <LayoutAddOrEdit titleDetail="">
      <div className=" btn-exit-login">
        <ButtonCircle
          variant="contained"
          onClick={() => {
            logout();
          }}
        >
          Exit
        </ButtonCircle>
      </div>
      <div className="layout-invite">
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h3" className="title-send-invite">
            {TEXT_CONTENT.STEP_2.title}
          </Typography>
          <div className="form-signIn">
            <FieldFormAdd
              fullWidth
              {...generatePropTextField("code", formik, true /* showErrorText */)}
              id="code"
              name="code"
              label="Authentication Code"
              variant="outlined"
              inputProps={{
                maxLength: 6,
              }}
            />
            <div className={classes.buttonStack}>
              <Button
                disabled={isLoading}
                type="submit"
                className={classes.btnSubmit}
                color="primary"
                variant="contained"
              >
                Sign in
              </Button>
            </div>
          </div>
        </form>
      </div>
    </LayoutAddOrEdit>
  );
};

export default VerifyAccount;
const useStyles = makeStyles(() => ({
  btnSubmit: {
    width: "303px",
    height: 51,
    marginTop: 40,
  },
  buttonStack: {
    display: "flex",
    gap: 20,
  },
  title: {
    textAlign: "center",
    marginBottom: 10,
  },
  subTitle: {
    fontSize: "16px",
    textAlign: "center",
    marginBottom: 60,
  },
  instructionText: {
    fontSize: "16px",
    opacity: 0.7,
  },
  qrBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 30,
  },
}));
