import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  ImageListItem,
  ImageList,
  ImageListItemBar,
  Button,
  Box,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import Rating from "@material-ui/lab/Rating";
import { formatCurrency } from "../../../helpers/format";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { common } from "../../../constants/common";
import { Stack } from "../../../components/Common/Styled/CommonStyled";

InfoContainerForDetail.propTypes = {
  data: PropTypes.object,
};

const useStyles = makeStyles(() => ({
  root: {
    // border: "1px solid #fff",
    marginBottom: "25px",
    border: "1px solid rgba(226, 233, 251, 0.3)",
    boxSizing: "border-box",
    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))",
    borderRadius: "8px",
  },
  titleContainer: {
    padding: "12px 0 12px 17px",
    borderBottom: "1px solid #54565C",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    margin: 0,
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "21px",

    color: " #FFFFFF",
  },
  itemsWrapper: {
    padding: "20px 20px 0",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "16px",
  },
  nameInfo: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",

    color: "rgba(222, 231, 250, 0.8)",
    mixBlendMode: "normal",
    opacity: "0.8",
  },
  valueInfo: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",

    textAlign: "right",

    color: "#FFFFFF",
  },
  breakDown: {
    margin: "0",
  },
  breakDownLi: {
    "&, &:before, &:after": {
      color: "rgba(222, 231, 250, 0.8)",
    },
  },
  reviewWrapper: {
    minHeight: "150px",
  },
  emptyReview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  userReview: {
    margin: 0,
    fontSize: "20px",
    fontWeight: "800",
  },
  licenseeInfo: {
    paddingLeft: 20,
  },
}));

const renderFieldValue = (currency, dateTime, value) => {
  return currency
    ? formatCurrency(value)
    : dateTime
    ? moment(value).format("DD MMM YY, hh:mm a")
    : value;
};

const FIELDS_NAME_WITHOUT_VALUE = ["T2Y Received"];

function InfoContainerForDetail({
  data = {},
  isLatestTransaction = false,
  ableToUpdateOrder = false,
}) {
  const { id } = useParams();
  const classes = useStyles();

  if (!Object.keys(data).length) return null;

  const { title, items, data: reviewData } = data;

  if (title.includes(common.TRANSACTION_DETAILS_TITLE.DRIVERS_LICENSE)) {
    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <h4 className={classes.title}>{title}</h4>
        </div>
        <div className={classes.itemsWrapper}>
          {items[0].value ? (
            <ImageList container>
              {items.map(({ name, value, updatedAt }) => {
                return (
                  <ImageListItem
                    style={{
                      marginBottom: "1.5rem",
                    }}
                    key={name}
                  >
                    <img src={value} alt={name} />
                    {updatedAt ? (
                      <ImageListItemBar
                        subtitle={`Last Updated: ${moment(updatedAt).format("DD MMM YY, HH:mm a")}`}
                        position="bottom"
                      />
                    ) : (
                      ""
                    )}
                  </ImageListItem>
                );
              })}
            </ImageList>
          ) : (
            <h2 style={{ textAlign: "center", margin: "3rem", marginBottom: "4rem" }}>
              No driver's license yet
            </h2>
          )}
        </div>
      </div>
    );
  }
  if (title === common.TRANSACTION_DETAILS_TITLE.REVIEW) {
    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <h4 className={classes.title}>{title}</h4>
        </div>
        <div className={`${classes.reviewWrapper} ${!reviewData && classes.emptyReview}`}>
          {reviewData && reviewData?.user ? (
            <div className={classes.itemsWrapper}>
              <div className={classes.itemContainer}>
                <Rating readOnly value={reviewData?.rating ?? 0} size="large" />
              </div>
              <div className={classes.itemContainer}>
                <h3
                  className={classes.userReview}
                >{`${reviewData.user.firstName} ${reviewData.user.lastName}`}</h3>
              </div>
              {reviewData?.content && (
                <div className={classes.itemContainer}>
                  <span className={classes.nameInfo}>{reviewData.content}</span>
                </div>
              )}
            </div>
          ) : (
            <h2>No reviews for this order</h2>
          )}
        </div>
      </div>
    );
  }
  if (
    title.includes(common.TRANSACTION_DETAILS_TITLE.DELIVERED_BY) ||
    title.includes(common.TRANSACTION_DETAILS_TITLE.PICKED_UP_BY)
  ) {
    const arrival = data?.arrivalDetails?.date
      ? moment(data?.arrivalDetails?.date).format("DD MMM YYYY h:mm a")
      : "--";

    const confirmed = data?.arrivalDetails?.confirmedDate
      ? moment(data?.arrivalDetails?.confirmedDate).format("DD MMM YYYY h:mm a")
      : "--";

    const difference = data?.arrivalDetails?.difference;
    let isEarly = true;
    if (difference && data?.arrivalDetails?.date && data?.arrivalDetails?.confirmedDate) {
      const momentArrival = moment(data?.arrivalDetails?.date);
      const momentConfirmed = moment(data?.arrivalDetails?.confirmedDate);
      if (momentConfirmed.isAfter(momentArrival)) {
        isEarly = false;
      }
    }
    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <h4 className={classes.title}>{title}</h4>
        </div>
        <Stack sx={{ justifyContent: "space-between" }}>
          <div className={classes.licenseeInfo}>
            <ListItem>
              <ListItemAvatar>
                {reviewData.photo ? (
                  <Avatar alt="Logo" src={reviewData.photo} />
                ) : (
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText>
                <div className="list-contact">
                  <span className="text-16">{reviewData.name}</span>
                  <span className="text-14 text-secondary">{reviewData.role}</span>
                </div>
              </ListItemText>
            </ListItem>
          </div>
          <div className={classes.licenseeInfo}>
            <ListItem>
              <ListItemText>
                <Box sx={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                  <div className="list-contact">
                    <span className="text-14 text-secondary" style={{ textAlign: "right" }}>
                      Arrival: {arrival}{" "}
                    </span>
                    <span className="text-14 text-secondary" style={{ textAlign: "right" }}>
                      Confirmed: {confirmed}
                    </span>
                  </div>
                  {difference ? (
                    <span
                      style={{
                        color: isEarly ? "#14FF00" : "#ff2108",
                        fontSize: 10,
                        fontWeight: 700,
                      }}
                    >
                      {isEarly ? "-" : "+"}
                      {difference}m
                    </span>
                  ) : null}
                </Box>
              </ListItemText>
            </ListItem>
          </div>
        </Stack>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h4 className={classes.title}>{title}</h4>
        {ableToUpdateOrder && isLatestTransaction ? (
          <Button to={`/transaction-history/update-order/${id}`} color="primary" component={Link}>
            Change
          </Button>
        ) : null}
      </div>
      <div className={classes.itemsWrapper}>
        {items.map(({ name, value, currency, dateTime, breakdown, registration }, index) => {
          const fieldValue = renderFieldValue(currency, dateTime, value);
          const breakdownContent = breakdown ? (
            <ul className={classes.breakDown}>
              {breakdown.map(({ name, value, currency, dateTime }, index2) => {
                const breakDownFieldValue = renderFieldValue(currency, dateTime, value);
                return (
                  <li className={classes.breakDownLi} key={`${name}_${index2}`}>
                    <div className={classes.itemContainer}>
                      <span className={classes.nameInfo}>{name}</span>
                      <span className={classes.valueInfo}>{breakDownFieldValue}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : registration ? (
            <ul className={classes.breakDown}>
              <li className={classes.breakDownLi}>
                <div className={classes.itemContainer}>
                  <span className={classes.nameInfo}>Registration No: {registration}</span>
                </div>
              </li>
            </ul>
          ) : null;
          return (
            <Fragment key={`${name}_${index}`}>
              <div className={classes.itemContainer}>
                <span className={classes.nameInfo}>{name}</span>
                {!FIELDS_NAME_WITHOUT_VALUE.includes(name) && (
                  <span className={classes.valueInfo}>{fieldValue}</span>
                )}
              </div>
              {breakdownContent}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default InfoContainerForDetail;
