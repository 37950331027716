import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useRouteMatch } from "react-router-dom";
import SortableTable from "../../../../../components/SortableTable/SortableTable";
import { Card } from "../../../components";
import { formatAmount } from "../../../utils";

export function GeoPerformance() {
  // const { url } = useRouteMatch();
  const { incomePerCityData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (incomePerCityData?.items) {
      const dataTransformed = [...incomePerCityData.items]
        .sort((a, b) => b.grossIncome - a.grossIncome)
        .slice(0, 5)
        .map((item) => ({
          city: item._id,
          totalSales: formatAmount(item.grossIncome, 0),
          totalHires: item.totalHires,
        }));
      setData(dataTransformed);
    }
  }, [incomePerCityData]);

  const columns = [
    {
      name: "",
      key: "name",
      sortable: false,
    },
    {
      name: "Total Sales",
      key: "totalSales",
      sortable: true,
    },
    {
      name: "Total Hires",
      key: "totalHires",
      sortable: true,
    },
  ];

  return (
    <Card
      headerSize="small"
      header="Geographical Performance"
      navigateTo={`/analytics/geo-performance`}
    >
      <SortableTable
        rows={data}
        columns={columns}
        hasIndexColumn
        gridTemplateColumns="10% 40% 30% 20%"
        rowsWidth={255}
      />
    </Card>
  );
}
