import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SortableTable from "../../../../components/SortableTable/SortableTable";
import { useStyles } from "../../Analytics.styles";
import { Card } from "../../components";
import { formatPercentage } from "../../utils";

export function ExtHiresReportPage() {
  const classes = useStyles();
  const { extendedHiresData } = useSelector((state) => state.analytic);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Override the title of Page Container
    document.querySelector('#analytic-title').textContent = 'Extended Hires Report';
    if (extendedHiresData?.items) {
      const dataTransformed = [...extendedHiresData?.items]
        .sort((a, b) => b.grossIncome - a.grossIncome)
        .map((item) => ({
          franchisee: `${item?.licenseeUser?.firstname} ${item?.licenseeUser?.lastname}`,
          perFranchisee: formatPercentage((item.extendedBookingsPercentage || 0) / 100),
          numOfExtensions: item.totalExtendedBookings,
          avgHoursExtended: item.avgHoursExtended.toFixed(2),
        }));
      setData(dataTransformed);
    }
  }, [extendedHiresData]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Per Franchisee",
      key: "perFranchisee",
      sortable: true,
    },
    {
      name: "Number of Extensions",
      key: "numOfExtensions",
      sortable: true,
    },
    {
      name: "AVG hours Extended",
      key: "avgHoursExtended",
      sortable: true,
    },
  ];

  return (
    <Box component="main" className={classes.analyticsSinglePageContent}>
      <Card header="Extended Hires Report">
        <SortableTable rows={data} columns={columns} gridTemplateColumns="30% 25% 20% 20%" />
      </Card>
    </Box>
  );
}
