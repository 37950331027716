import { Backdrop, Box, CircularProgress, styled, Modal } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import {
  AddCategoriesModal,
  AddItemPromotionsModal,
  AddPaymentMethodModal,
  AddDiscountCodeModal,
  SelectRecipientsModal,
  SelectRegionAreaModal,
} from "../components/Modals";

export const MODAL_TYPES = {
  ADD_CATEGORIES_MODAL: AddCategoriesModal,
  ADD_ITEM_PROMOTIONS_MODAL: AddItemPromotionsModal,
  ADD_PAYMENT_METHOD_MODAL: AddPaymentMethodModal,
  ADD_DISCOUNT_CODE_MODAL: AddDiscountCodeModal,
  SELECT_RECIPIENTS_MODAL: SelectRecipientsModal,
  SELECT_REGION_AREA_MODAL: SelectRegionAreaModal,
};

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "#202020",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  width: {
    xs: "calc(100vw - 2rem)",
    md: "500px",
  },
};

const useModal = ({ isLoading, additionalStyles = {} }) => {
  const [openModal, setOpenModal] = useState(null);
  const onHandleCloseModal = useCallback(() => {
    setOpenModal(null);
  }, []);
  const renderModal = useMemo(() => {
    const { modalType: ModalComponent, data } = openModal ?? {};
    return openModal ? (
      <StyledModal
        open={!!openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={onHandleCloseModal}
        closeAfterTransition
        sx={{
          zIndex: (theme) => {
            return theme.zIndex.appBar + 1;
          },
        }}
      >
        <Box sx={{ ...style, ...additionalStyles }}>
          {isLoading && (
            <Backdrop open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {ModalComponent ? <ModalComponent data={data} onCloseModal={onHandleCloseModal} /> : null}
        </Box>
      </StyledModal>
    ) : null;
  }, [additionalStyles, isLoading, onHandleCloseModal, openModal]);

  return { renderModal, setOpenModal, onHandleCloseModal };
};

export default useModal;
