import React, { useState, useEffect } from "react";
import { imageItemStyles } from "./style";
import { Box, Button, Typography } from "@material-ui/core";
import { isImgFile } from "../../helpers/verify";
import { ReactComponent as Image } from "../../img/image.svg";
import { ReactComponent as PreviousArrow } from "../../img/previousArrow.svg";
import { ReactComponent as FowardArrow } from "../../img/fowardArrow.svg";
import { ReactComponent as CloneAvatar } from "../../img/cloneAvatar.svg";
import ButtonActionSubPage from "../ButtonActionSubPage";
import { nonAvatarStyle, avatarStyle, imageStyle } from "./style";
const TemplateImageModifyItemLicensee = ({
  dataImageRef,
  dataImageDeleteRef,
  photos,
  upload = true,
  avatar = false,
}) => {
  const [previewImage, setPreviewImage] = useState({
    list: [],
    info: [],
    current: 0,
  });
  async function AddImage(event) {
    // ADD MULTI
    if (event.target.files && isImgFile(event.target.files[0])) {
      const FILE_URL_LOCALSTORAGE = [];
      const FILE_INFO = [];
      Array.from(event.target.files).map((file) => {
        const _id = `${Math.random().toString(36).substring(2, 10)}-specific`;
        FILE_URL_LOCALSTORAGE.push({ _id: _id, url: URL.createObjectURL(file) });
        FILE_INFO.push({ _id: _id, file: file });
      });
      // BACKEND JUST ADD NEW ARRAY => NEED CREATE NEW ARRAY ITEM WANT TO ADD
      dataImageRef.current = [...previewImage.info, ...FILE_INFO];
      setPreviewImage((prevState) => ({
        list: [...prevState.list, ...FILE_URL_LOCALSTORAGE],
        info: [...prevState.info, ...FILE_INFO],
        current: 0,
      }));
    }
  }

  function handleChangeCurrentImage(type) {
    action[type]();
  }

  function handlePreviousImage() {
    setPreviewImage((prevState) => ({
      ...prevState,
      current: prevState.current === 0 ? previewImage.list.length - 1 : prevState.current - 1,
    }));
  }

  function handleNextImage() {
    setPreviewImage((prevState) => ({
      ...prevState,
      current: prevState.current === previewImage.list.length - 1 ? 0 : prevState.current + 1,
    }));
  }

  function componentPreviewImageDependOnProps(data) {
    if (avatar) {
      return (
        <img
          key={data.list[data.current]._id}
          src={data.list[data.current].url}
          style={{ ...avatarStyle }}
        />
      );
    }
    return (
      <img
        key={data.list[data.current]._id}
        src={data.list[data.current].url}
        style={{ ...imageStyle }}
      />
    );
  }

  function componentNonImageDependOnProps() {
    if (avatar) {
      return <CloneAvatar style={{ ...nonAvatarStyle }} />;
    }
    return (
      <Box {...imageStyle} display="flex" alignItems="center" justifyContent="center">
        <Image />
      </Box>
    );
  }

  function handleDeleteImage(index) {
    const { list, info } = previewImage;
    const imageWantDelete = list[index];
    const indexItemInList = list.findIndex((item) => item._id === imageWantDelete._id);
    let newInfo = [];
    if (imageWantDelete._id.includes("-specific") === false) {
      // IF NOT NEW IMAGE ADDED BEFORE
      dataImageDeleteRef.current = [...dataImageDeleteRef.current, imageWantDelete._id];
      newInfo = info;
    }
    if (imageWantDelete._id.includes("-specific") === true) {
      // NEW IMAGE ADDED BEFORE
      const indexItemInList = info.findIndex((item) => item._id === imageWantDelete._id);
      newInfo = [...info.slice(0, indexItemInList), ...info.slice(indexItemInList + 1)];
      dataImageRef.current = newInfo;
    }
    setPreviewImage((prevState) => ({
      list: [
        ...prevState.list.slice(0, indexItemInList),
        ...prevState.list.slice(indexItemInList + 1),
      ],
      info: newInfo,
      current: 0,
    }));
  }

  useEffect(() => {
    if (photos?.length > 0) {
      setPreviewImage({ ...previewImage, list: photos });
    }
  }, [photos]);
  const action = {
    back: () => handlePreviousImage(),
    next: () => handleNextImage(),
  };
  const { list } = previewImage;
  const cssImageItemLicensee = imageItemStyles();
  return (
    <Box classes={cssImageItemLicensee}>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent={list.length <= 1 ? "center" : "space-between"}
      >
        {list.length > 1 && !avatar && (
          <Button
            onClick={() => {
              previewImage.list?.length > 1 && handleChangeCurrentImage("back");
            }}
          >
            <PreviousArrow />
          </Button>
        )}
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          margin="0 25px 40px 25px"
          marginBottom="40px"
        >
          {list.length !== 0
            ? { ...componentPreviewImageDependOnProps(previewImage) }
            : { ...componentNonImageDependOnProps() }}
        </Box>
        {list.length > 1 && !avatar && (
          <Button
            onClick={() => {
              list.length > 1 && handleChangeCurrentImage("next");
            }}
          >
            <FowardArrow />
          </Button>
        )}
      </Box>
      {upload && (
        <>
          {list.length !== 0 && list.length > 1 && (
            <p
              style={{
                color: "red",
                fontSize: 14,
                cursor: "pointer",
              }}
              onClick={() => handleDeleteImage(previewImage.current)}
            >
              Delete Image
            </p>
          )}
          <input
            accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
            multiple={!avatar}
            style={{ display: "none" }}
            id="jpg-file"
            type="file"
            onChange={AddImage}
          />
          <label htmlFor="jpg-file">
            <ButtonActionSubPage minWidth="360px" height="52px" component="span">
              Upload Photo
            </ButtonActionSubPage>
          </label>
          <Typography align="center" style={{ marginTop: "13px" }}>
            PNG, JPG
          </Typography>
        </>
      )}
    </Box>
  );
};
export default TemplateImageModifyItemLicensee;
