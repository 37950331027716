import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import SortableTable from "../../../../../components/SortableTable/SortableTable";
import { Card } from "../../../components";
import { formatDistance } from "../../../utils/formatDistance";

export function AvgDropOffDistance() {
  const { url } = useRouteMatch();
  const { avgDropOffDistanceByFranchisee } = useSelector(
    (state) => state.analytic
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    if (avgDropOffDistanceByFranchisee?.items) {
      const dataTransformed = [...avgDropOffDistanceByFranchisee?.items].map(
        (item) => ({
          franchisee: item?.licensee?.fullname ?? "--",
          distance: item?.distance ? formatDistance(item.distance) : "--",
        })
      );
      setData(dataTransformed);
    }
  }, [avgDropOffDistanceByFranchisee]);

  const columns = [
    {
      name: "Franchisee",
      key: "franchisee",
      sortable: true,
    },
    {
      name: "Distance (KM)",
      key: "distance",
      sortable: true,
    },
  ];

  return (
    <Card
      header="Average Drop-off Distance by Franchisee"
      navigateTo={`${url}/avg-drop-off-distance`}
    >
      <SortableTable
        rows={data}
        columns={columns}
        gridTemplateColumns="80% 20%"
        rowsHeight={255}
      />
    </Card>
  );
}
