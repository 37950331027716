import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Card } from "../../../components";
import { Box } from "@material-ui/core";
import { Text50 } from "../../../../../components/Common/Styled/TypographyStyled";
import { formatDistance } from "../../../utils/formatDistance";

export function AvgDropOffDistanceGraph() {
  const { url } = useRouteMatch();
  const { avgDistanceGraphData } = useSelector((state) => state.analytic);

  return (
    <Card
      headerSize="small"
      header="Average Drop-off Distance"
      navigateTo={`${url}/avg-drop-off-distance`}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: 220,
            height: 220,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Text50>
            {formatDistance(avgDistanceGraphData?.avgDropOffDistance?.value)}
          </Text50>
        </Box>
      </Box>
    </Card>
  );
}
