import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    padding: "0 60px",
    height: "55px",
    backgroundColor: "#202020!important",
  },
  root: {
    display: "flex",
    position: "fixed",
    width: "100%",
    zIndex: 1000
  },
  toolbar: {
    padding: 0,
    height: "55px",
  },
  title: {
    margin: "10px 30px 10px 10px",
  },
  headerLink: {
    margin: theme.spacing(2),
    color: "#FFFFFF",
  },
  navButton: {
    padding: 0,
    marginLeft: "40px",
    boxShadow: "none",
    height: "100%",
    margin: "0",
    color: "#Dee7fa",
    minWidth: "unset",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(0,0,0,0)",
    },
    "&:focus": {
      backgroundColor: 'none'
    }
  },
  navSubButton: {
    padding: 0,
    boxShadow: "none",
    height: "100%",
    margin: "0",
    color: "#Dee7fa",
    minWidth: "unset",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  navProfileButton: {
    marginLeft: "8px",
    padding: 0,
    boxShadow: "none",
    height: "100%",
    margin: "0",
    color: "#Dee7fa",
    minWidth: "unset",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  navLogoutButton: {
    minWidth: "fit-content",
    padding: 0,
    color: "#FF0000",
    "&:hover": {
      backgroundColor: "#202020",
    },
  },
  navButtonActive: {
    color: theme.palette.primary.main,
    backgroundColor: "rgba(0,0,0,0)",
  },
  navButtonGroupRight: {
    position: "absolute",
    right: "10px",
  },
  profileAdmin: {
    display: "flex",
    alignItems: "center",
  },
  avatarAdmin: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    width: "35px",
    height: "35px",
  },
}))
