import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "../../../components";
import { BarGraph } from "../../../graphs";

export function HiresPerTrailerType() {
  const { incomePerTrailerData } = useSelector((state) => state.analytic);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (incomePerTrailerData?.items) {
      const sortedData = [...incomePerTrailerData.items]
        .sort((a, b) => b.totalHires - a.totalHires)
        .slice(0, 10);
      const labels = sortedData.map((item) => item.trailer.name);
      const data = sortedData.map((item) => item.totalHires);
      setChartLabels(labels);
      setChartData(data);
    }
  }, [incomePerTrailerData]);

  return (
    <Card header="Top 10 Hires Per Trailer Type" onClick={() => {}}>
      <BarGraph
        labels={chartLabels}
        datasets={[
          {
            label: "Total Hires",
            data: chartData,
            backgroundColor: "#44D62C",
            borderRadius: 4,
          },
        ]}
      />
    </Card>
  );
}
