import * as React from "react";

export function CustomFilterIcon({ width = 24, height = 24, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.8181 2.867C21.6823 2.60532 21.4772 2.38601 21.2251 2.2331C20.9731 2.08018 20.6839 1.99954 20.3891 2H4.61106C4.31634 1.99972 4.02719 2.08034 3.77514 2.23308C3.52308 2.38582 3.31779 2.60481 3.18163 2.86619C3.04547 3.12758 2.98367 3.42132 3.00297 3.71541C3.02226 4.0095 3.12191 4.29265 3.29106 4.534L3.31506 4.568L10.0001 13.338V21C10 21.181 10.0492 21.3587 10.1422 21.514C10.2352 21.6693 10.3687 21.7965 10.5283 21.8819C10.6879 21.9673 10.8677 22.0078 11.0486 21.999C11.2294 21.9902 11.4045 21.9325 11.5551 21.832L14.5551 19.832C14.692 19.7407 14.8042 19.6169 14.8819 19.4718C14.9595 19.3266 15.0001 19.1646 15.0001 19V13.337L21.7091 4.537C21.8789 4.29551 21.9789 4.0119 21.9981 3.71731C22.0173 3.42272 21.955 3.12852 21.8181 2.867Z"
        fill={color}
      />
    </svg>
  );
}
